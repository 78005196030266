.videoconsult {
  .hidden {
    display: none;
  }

  .radio-label {
    margin-right: 1rem;
  }
}

#videoconsult-date {
  background-image: url(/typo3conf/ext/za_phoneconsult/Resources/Public/Icons/calendar.svg);
}

[rel=time-select] select {
  background-image: url(/typo3conf/ext/za_phoneconsult/Resources/Public/Icons/clock.svg);
}

.videoconsult-type-wrapper {

  input[type=radio] + .radio-label {
    background-color: $gray;
    padding: .5rem 1rem;

    &:after {
      display: none;
    }

    &:before {
      display: none;
    }

    svg {
      height: 1rem;
      width: auto;
      margin-right: 1rem;
      * {
        fill: black;
      }
    }
  }

  input[type=radio]:checked + .radio-label {
    background-color: $primary;
    color: white;
    svg {
      * {
        fill: white;
      }
    }
  }
}
