body .tx-cart, body .tx-cart-products {

  .bg-light-grey {
    background-color: white;
  }

  fieldset {
    border: none;
    padding: 0;
  }

  .checkout-step-content {
    padding: 0;
    padding-top: 1rem;
  }

  .checkbox label {
    display: inline-block;
  }

  form {
    max-width: inherit;

    .row .col-md-6:first-of-type {
      margin-right: 0;
    }

    .row .col-md-6 {
      width: 100%;
      display: table-cell;
    }
  }

  &#cart-preview {
    height: 100%;

    .checkout-link {
      &.visible {
        display: flex;
      }
      &.invisible {
        display: none;
      }
      justify-content: center;
      align-items: center;
      height: 100%;

      div {
        position: relative;
        height: 1.5rem;

        svg {
          position: absolute;
          left: 0;
          top: 0;
          height: 1.5rem;
          width: 1.5rem;
          fill: white;
        }

        .cart-badge {
          color: white;
          border-radius: .6125rem;
          height: 1.25rem;
          padding: 0 .25rem;
          text-align: center;
          background-color: $primary;
          margin-left: 1.2rem;
        }
      }
    }
  }

  .payment-radio {

    font-family: lato_regular, Helvetica, sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    color: #929292;
    line-height: 2;
    letter-spacing: 0.0625rem;
    display: inline-block;
    position: relative;
    padding-left: 2.125rem;
    margin-bottom: 0.625rem;
    cursor: pointer;
    user-select: none;
    padding-bottom: 0;


    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 1.5rem;
      width: 1.5rem;
      background-color: #fff;
      border: 0.125rem solid #bdbdbd;
      border-radius: 50%;


      &.checked {
        background-color: #e20079;
        border: 0.125rem solid #e20079;

        &:after {
          content: "";
          background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns= 'http://www.w3.org/2000/svg' xmlns:xlink= 'http://www.w3.org/1999/xlink' viewBox= '0 0 16 16' %3E%3Cdefs%3E%3Cpath fill= '%23fff' id= 'a' d= 'M14 2.8L6.8 14 2 9.2l1.485-2.012 3.046 3.046L11.6 2z' /%3E%3C/defs%3E%3Cuse xlink:href= '%23a' fill-rule= 'evenodd' /%3E%3C/svg%3E");
          background-size: 80%;
          background-position: 50%;
          background-repeat: no-repeat;
          position: absolute;
          width: 100%;
          height: 100%;
          display: none;
        }
      }

      &:before {
        // nur der Haken in der Box
        content: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 16 16'%3E%3Cdefs%3E%3Cpath fill='%23fff' id='a' d='M14 2.8L6.8 14 2 9.2l1.485-2.012 3.046 3.046L11.6 2z'/%3E%3C/defs%3E%3Cuse xlink:href='%23a' fill-rule='evenodd'/%3E%3C/svg%3E");
      }
    }
  }
  .btn {
    white-space: normal;
  }

  .regular_price {
    font-size: 150%;
  }

  a.btn-default{
    appearance: none;
    background-color: #f1f1f1;
    padding: 1rem;
    color: #000;
    border: none;
    outline: none;
    border-radius: 0;
    box-shadow: none;
    transition: background-color 0.3s, color 0.3s;
    font-size: 0.875rem;
    width: 100%;
    font-family: lato_regular, Helvetica, sans-serif;
    font-weight: 400;
  }

  #checkout-step-shipping-method{
    display: none;
  }
}
