.select2-container .selection .select2-selection {
  border: none;
  background-color: #f1f1f1;
  height: 2.875rem;
  font-size: .875rem;
  padding: .5rem;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 16 16'%3E%3Cdefs%3E%3Cpath fill='%23E20079' id='a' d='M9.883 13a.216.216 0 01-.155-.066L5.064 8.158a.227.227 0 010-.317l4.664-4.775a.216.216 0 01.31 0l.897.919a.228.228 0 010 .317L7.324 8l3.612 3.698a.228.228 0 010 .317l-.898.92a.218.218 0 01-.155.065z'/%3E%3C/defs%3E%3Cuse transform='rotate(-90 8 8)' xlink:href='%23a'/%3E%3C/svg%3E");
  background-position: right 0.75rem center;
  background-size: 1.5rem 1.5rem;
  background-repeat: no-repeat;
}
.select2-container-open .selection .select2-selection {

}
.select2-selection__arrow {
 display: none;
}
