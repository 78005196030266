.easycaptcha {
  display: flex;
  flex-wrap: wrap;
  .easycaptcha {
    flex: 1 0 70%;
  }
  .form-group {
    flex: 0 0 100%;
  }
  .captcha-actions {
    img {
      width: 2rem;
    }
  }
  .f3-form-error:after {
    display: block;
    content: 'Dies ist ein Pflichtfeld.';
    color: $primary;
    font-weight: bold;
  }
}
