.seminare-header {
  width: 100%;
  float: left;
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;

  #datepicker {

    .datepicker-inline {
      background-color: #f5f5f5;
      width: 320px;

      td, th {

        width: 45px;
        height: 30px;
      }

      .prev, .next, .datepicker-switch {
        color: $primary;
      }

      .active {
        background-color: $primary;
        background-image: none;
      }

      .day {
        position: relative;
      }

      .highlight {

        &:after {
          border-bottom: 3px solid $primary;
          border-radius: 100%;
          content: "";
          width: 3px;
          position: absolute;
          margin: 0 auto;
          bottom: -2px;
          left: 0;
          right: 0;
        }
      }
    }

  }
}

