/* The overlay effect with black background */
.searchbutton {
  width: 100%;
  text-align: right;

  svg {
    height: 20px;
    float: right;
    text-align: right;
    width: 20px;
    fill: $primary
  }
}

.solrOverlay {
  height: 100%;
  width: 100%;
  display: none;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: rgb(255, 255, 255);
  background-color: rgba(255, 255, 255, 0.9); /* Black with a little bit see-through */
  .main-search {
    width: 100%;
  }

  /* The content */
  .overlay-content {
    position: relative;
    top: 46%;
    width: 60%;
    text-align: center;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }

  /* Close button */
  .closebtn {
    position: absolute;
    top: 20px;
    right: 45px;
    font-size: 60px;
    cursor: pointer;
    color: $primary;
  }

  .closebtn:hover {
    color: $primary;
  }

  form {
    width: 100%;
  }

  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: grey;
    opacity: 1; /* Firefox */
  }

  /* Style the search field */
  #tx-indexedsearch-searchbox-sword {
    padding: 15px;
    font-size: 17px;
    float: left;
    width: 100%;
    background: white;
    border: 2px solid $primary;

  }

  #tx-indexedsearch-searchbox-sword:hover {
    background: $grey;
  }

  /* Style the submit button */
  button {
    float: left;
    width: 20%;
    padding: 15px;
    background: $primary;
    font-size: 17px;
    border: none;
    cursor: pointer;

    svg {
      height: 30px;
      float: right;
      text-align: right;
      width: 30px;
      margin-top: -5px;
      fill: white;
    }
  }

  #tx-indexedsearch-searchbox-button-submit {
    position: relative;
    left: 90%;
    width: 60px;
    height: 45px;
    top: -52px;
  }

  button:hover {
    background: white;

    svg {
      height: 30px;
      float: right;
      text-align: right;
      width: 30px;
      margin-top: -5px;
      fill: $primary;
    }
  }
}

.tx-solr-autosuggest {
  background: white;
  padding: 5px;
  line-height: 30px;
  max-height: 190px !important;
  overflow-y: scroll;
  width: auto !important;
}

.search-browsebox {

  ul {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 3.75rem 0;

    li {
      display: -ms-flexbox;
      display: flex;
      line-height: 1.5rem;
      margin-right: 1.25rem;

      &:before {
        display: none;
      }

      svg {
        height: 20px;
      }

      .next {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }
}