.mobile-navigation-wrapper {

  .meta-navigation-wrapper {
    ul {
      margin-bottom: 100px;
      display: block;
    }
  }
}

.footer-middle, .footer-left {
  .link-container {
    .frame-type-menu_pages {
      padding: 0;

      ul {
        li {
          padding: 0;

          &:before {
            display: none;
          }

        }
      }
    }
  }

}