.checkbox-optout {
    width: inherit;
    display: inline-block !important;
    appearance: auto;
    -webkit-appearance: checkbox;
    padding: 0;
    background-color: transparent;
    margin-right: 0.5rem;
        transform: translateY(-2px);
}
.cookie-choice .form-check-inline {
    margin-right: .75rem;
    transform: translateY(3px);
}
