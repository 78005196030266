.flatpickr-calendar {
    background: transparent;
    opacity: 0;
    display: none;
    text-align: center;
    visibility: hidden;
    padding: 0;
    -webkit-animation: none;
    animation: none;
    direction: ltr;
    border: 0;
    font-size: 14px;
    line-height: 24px;
    border-radius: 5px;
    position: absolute;
    width: 307.875px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    background: #fff;
    -webkit-box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgba(0, 0, 0, .08);
    box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgba(0, 0, 0, .08)
}

.flatpickr-calendar.inline, .flatpickr-calendar.open {
    opacity: 1;
    max-height: 640px;
    visibility: visible
}

.flatpickr-calendar.open {
    display: inline-block;
    z-index: 99999
}

.flatpickr-calendar.animate.open {
    -webkit-animation: fpFadeInDown .3s cubic-bezier(.23, 1, .32, 1);
    animation: fpFadeInDown .3s cubic-bezier(.23, 1, .32, 1)
}

.flatpickr-calendar.inline {
    display: block;
    position: relative;
    top: 2px
}

.flatpickr-calendar.static {
    position: absolute;
    top: calc(100% + 2px)
}

.flatpickr-calendar.static.open {
    z-index: 999;
    display: block
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+1) .flatpickr-day.inRange:nth-child(7n+7) {
    -webkit-box-shadow: none !important;
    box-shadow: none !important
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+2) .flatpickr-day.inRange:nth-child(7n+1) {
    -webkit-box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
    box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6
}

.flatpickr-calendar .hasTime .dayContainer, .flatpickr-calendar .hasWeeks .dayContainer {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.flatpickr-calendar .hasWeeks .dayContainer {
    border-left: 0
}

.flatpickr-calendar.showTimeInput.hasTime .flatpickr-time {
    height: 40px;
    border-top: 1px solid #e6e6e6
}

.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
    height: auto
}

.flatpickr-calendar:after, .flatpickr-calendar:before {
    position: absolute;
    display: block;
    pointer-events: none;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    left: 22px
}

.flatpickr-calendar.rightMost:after, .flatpickr-calendar.rightMost:before {
    left: auto;
    right: 22px
}

.flatpickr-calendar:before {
    border-width: 5px;
    margin: 0 -5px
}

.flatpickr-calendar:after {
    border-width: 4px;
    margin: 0 -4px
}

.flatpickr-calendar.arrowTop:after, .flatpickr-calendar.arrowTop:before {
    bottom: 100%
}

.flatpickr-calendar.arrowTop:before {
    border-bottom-color: #e6e6e6
}

.flatpickr-calendar.arrowTop:after {
    border-bottom-color: #fff
}

.flatpickr-calendar.arrowBottom:after, .flatpickr-calendar.arrowBottom:before {
    top: 100%
}

.flatpickr-calendar.arrowBottom:before {
    border-top-color: #e6e6e6
}

.flatpickr-calendar.arrowBottom:after {
    border-top-color: #fff
}

.flatpickr-calendar:focus {
    outline: 0
}

.flatpickr-wrapper {
    position: relative;
    display: inline-block
}

.flatpickr-months {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex
}

.flatpickr-months .flatpickr-month {
    background: transparent;
    color: rgba(0, 0, 0, .9);
    fill: rgba(0, 0, 0, .9);
    height: 34px;
    line-height: 1;
    text-align: center;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    overflow: hidden;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1
}

.flatpickr-months .flatpickr-next-month, .flatpickr-months .flatpickr-prev-month {
    text-decoration: none;
    cursor: pointer;
    position: absolute;
    top: 0;
    height: 34px;
    padding: 10px;
    z-index: 3;
    color: rgba(0, 0, 0, .9);
    fill: rgba(0, 0, 0, .9)
}

.flatpickr-months .flatpickr-next-month.flatpickr-disabled, .flatpickr-months .flatpickr-prev-month.flatpickr-disabled {
    display: none
}

.flatpickr-months .flatpickr-next-month i, .flatpickr-months .flatpickr-prev-month i {
    position: relative
}

.flatpickr-months .flatpickr-next-month.flatpickr-prev-month, .flatpickr-months .flatpickr-prev-month.flatpickr-prev-month {
    left: 0
}

.flatpickr-months .flatpickr-next-month.flatpickr-next-month, .flatpickr-months .flatpickr-prev-month.flatpickr-next-month {
    right: 0
}

.flatpickr-months .flatpickr-next-month:hover, .flatpickr-months .flatpickr-prev-month:hover {
    color: #959ea9
}

.flatpickr-months .flatpickr-next-month:hover svg, .flatpickr-months .flatpickr-prev-month:hover svg {
    fill: #f64747
}

.flatpickr-months .flatpickr-next-month svg, .flatpickr-months .flatpickr-prev-month svg {
    width: 14px;
    height: 14px
}

.flatpickr-months .flatpickr-next-month svg path, .flatpickr-months .flatpickr-prev-month svg path {
    -webkit-transition: fill .1s;
    transition: fill .1s;
    fill: inherit
}

.numInputWrapper {
    position: relative;
    height: auto
}

.numInputWrapper input, .numInputWrapper span {
    display: inline-block
}

.numInputWrapper input {
    width: 100%
}

.numInputWrapper input::-ms-clear {
    display: none
}

.numInputWrapper input::-webkit-inner-spin-button, .numInputWrapper input::-webkit-outer-spin-button {
    margin: 0;
    -webkit-appearance: none
}

.numInputWrapper span {
    position: absolute;
    right: 0;
    width: 14px;
    padding: 0 4px 0 2px;
    height: 50%;
    line-height: 50%;
    opacity: 0;
    cursor: pointer;
    border: 1px solid rgba(57, 57, 57, .15);
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

.numInputWrapper span:hover {
    background: rgba(0, 0, 0, .1)
}

.numInputWrapper span:active {
    background: rgba(0, 0, 0, .2)
}

.numInputWrapper span:after {
    display: block;
    content: "";
    position: absolute
}

.numInputWrapper span.arrowUp {
    top: 0;
    border-bottom: 0
}

.numInputWrapper span.arrowUp:after {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid rgba(57, 57, 57, .6);
    top: 26%
}

.numInputWrapper span.arrowDown {
    top: 50%
}

.numInputWrapper span.arrowDown:after {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid rgba(57, 57, 57, .6);
    top: 40%
}

.numInputWrapper span svg {
    width: inherit;
    height: auto
}

.numInputWrapper span svg path {
    fill: rgba(0, 0, 0, .5)
}

.numInputWrapper:hover {
    background: rgba(0, 0, 0, .05)
}

.numInputWrapper:hover span {
    opacity: 1
}

.flatpickr-current-month {
    font-size: 135%;
    line-height: inherit;
    font-weight: 300;
    color: inherit;
    position: absolute;
    width: 75%;
    left: 12.5%;
    padding: 7.48px 0 0;
    line-height: 1;
    height: 34px;
    display: inline-block;
    text-align: center;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
}

.flatpickr-current-month span.cur-month {
    font-family: inherit;
    font-weight: 700;
    color: inherit;
    display: inline-block;
    margin-left: .5ch;
    padding: 0
}

.flatpickr-current-month span.cur-month:hover {
    background: rgba(0, 0, 0, .05)
}

.flatpickr-current-month .numInputWrapper {
    width: 6ch;
    width: 7ch \0;
    display: inline-block
}

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
    border-bottom-color: rgba(0, 0, 0, .9)
}

.flatpickr-current-month .numInputWrapper span.arrowDown:after {
    border-top-color: rgba(0, 0, 0, .9)
}

.flatpickr-current-month input.cur-year {
    background: transparent;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: inherit;
    cursor: text;
    padding: 0 0 0 .5ch;
    margin: 0;
    display: inline-block;
    font-size: inherit;
    font-family: inherit;
    font-weight: 300;
    line-height: inherit;
    height: auto;
    border: 0;
    border-radius: 0;
    vertical-align: initial;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield
}

.flatpickr-current-month input.cur-year:focus {
    outline: 0
}

.flatpickr-current-month input.cur-year[disabled], .flatpickr-current-month input.cur-year[disabled]:hover {
    font-size: 100%;
    color: rgba(0, 0, 0, .5);
    background: transparent;
    pointer-events: none
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
    appearance: menulist;
    background: transparent;
    border: none;
    border-radius: 0;
    box-sizing: border-box;
    color: inherit;
    cursor: pointer;
    font-size: inherit;
    font-family: inherit;
    font-weight: 300;
    height: auto;
    line-height: inherit;
    margin: -1px 0 0;
    outline: none;
    padding: 0 0 0 .5ch;
    position: relative;
    vertical-align: initial;
    -webkit-box-sizing: border-box;
    -webkit-appearance: menulist;
    -moz-appearance: menulist;
    width: auto
}

.flatpickr-current-month .flatpickr-monthDropdown-months:active, .flatpickr-current-month .flatpickr-monthDropdown-months:focus {
    outline: none
}

.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
    background: rgba(0, 0, 0, .05)
}

.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
    background-color: transparent;
    outline: none;
    padding: 0
}

.flatpickr-weekdays {
    background: transparent;
    text-align: center;
    overflow: hidden;
    width: 100%;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    height: 28px
}

.flatpickr-weekdays, .flatpickr-weekdays .flatpickr-weekdaycontainer {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex
}

.flatpickr-weekdays .flatpickr-weekdaycontainer, span.flatpickr-weekday {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1
}

span.flatpickr-weekday {
    cursor: default;
    font-size: 90%;
    background: transparent;
    color: rgba(0, 0, 0, .54);
    line-height: 1;
    margin: 0;
    text-align: center;
    display: block;
    font-weight: bolder
}

.dayContainer, .flatpickr-weeks {
    padding: 1px 0 0
}

.flatpickr-days {
    position: relative;
    overflow: hidden;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    width: 307.875px
}

.flatpickr-days:focus {
    outline: 0
}

.dayContainer {
    padding: 0;
    outline: 0;
    text-align: left;
    width: 307.875px;
    min-width: 307.875px;
    max-width: 307.875px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: inline-block;
    display: -ms-flexbox;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-around;
    justify-content: space-around;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1
}

.dayContainer + .dayContainer {
    -webkit-box-shadow: -1px 0 0 #e6e6e6;
    box-shadow: -1px 0 0 #e6e6e6
}

.flatpickr-day {
    background: none;
    border: 1px solid transparent;
    border-radius: 150px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #393939;
    cursor: pointer;
    font-weight: 400;
    width: 14.2857143%;
    -webkit-flex-basis: 14.2857143%;
    -ms-flex-preferred-size: 14.2857143%;
    flex-basis: 14.2857143%;
    max-width: 39px;
    height: 39px;
    line-height: 39px;
    margin: 0;
    display: inline-block;
    position: relative;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center
}

.flatpickr-day.inRange, .flatpickr-day.nextMonthDay.inRange, .flatpickr-day.nextMonthDay.today.inRange, .flatpickr-day.nextMonthDay:focus, .flatpickr-day.nextMonthDay:hover, .flatpickr-day.prevMonthDay.inRange, .flatpickr-day.prevMonthDay.today.inRange, .flatpickr-day.prevMonthDay:focus, .flatpickr-day.prevMonthDay:hover, .flatpickr-day.today.inRange, .flatpickr-day:focus, .flatpickr-day:hover {
    cursor: pointer;
    outline: 0;
    background: #e6e6e6;
    border-color: #e6e6e6
}

.flatpickr-day.today {
    border-color: #959ea9
}

.flatpickr-day.today:focus, .flatpickr-day.today:hover {
    border-color: #959ea9;
    background: #959ea9;
    color: #fff
}

.flatpickr-day.endRange, .flatpickr-day.endRange.inRange, .flatpickr-day.endRange.nextMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.endRange:focus, .flatpickr-day.endRange:hover, .flatpickr-day.selected, .flatpickr-day.selected.inRange, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.selected:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange, .flatpickr-day.startRange.inRange, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.startRange:focus, .flatpickr-day.startRange:hover {
    background: #569ff7;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #fff;
    border-color: #569ff7
}

.flatpickr-day.endRange.startRange, .flatpickr-day.selected.startRange, .flatpickr-day.startRange.startRange {
    border-radius: 50px 0 0 50px
}

.flatpickr-day.endRange.endRange, .flatpickr-day.selected.endRange, .flatpickr-day.startRange.endRange {
    border-radius: 0 50px 50px 0
}

.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)) {
    -webkit-box-shadow: -10px 0 0 #569ff7;
    box-shadow: -10px 0 0 #569ff7
}

.flatpickr-day.endRange.startRange.endRange, .flatpickr-day.selected.startRange.endRange, .flatpickr-day.startRange.startRange.endRange {
    border-radius: 50px
}

.flatpickr-day.inRange {
    border-radius: 0;
    -webkit-box-shadow: -5px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
    box-shadow: -5px 0 0 #e6e6e6, 5px 0 0 #e6e6e6
}

.flatpickr-day.flatpickr-disabled, .flatpickr-day.flatpickr-disabled:hover, .flatpickr-day.nextMonthDay, .flatpickr-day.notAllowed, .flatpickr-day.notAllowed.nextMonthDay, .flatpickr-day.notAllowed.prevMonthDay, .flatpickr-day.prevMonthDay {
    color: rgba(57, 57, 57, .3);
    background: transparent;
    border-color: transparent;
    cursor: default
}

.flatpickr-day.flatpickr-disabled, .flatpickr-day.flatpickr-disabled:hover {
    cursor: not-allowed;
    color: rgba(57, 57, 57, .1)
}

.flatpickr-day.week.selected {
    border-radius: 0;
    -webkit-box-shadow: -5px 0 0 #569ff7, 5px 0 0 #569ff7;
    box-shadow: -5px 0 0 #569ff7, 5px 0 0 #569ff7
}

.flatpickr-day.hidden {
    visibility: hidden
}

.rangeMode .flatpickr-day {
    margin-top: 1px
}

.flatpickr-weekwrapper {
    float: left
}

.flatpickr-weekwrapper .flatpickr-weeks {
    padding: 0 12px;
    -webkit-box-shadow: 1px 0 0 #e6e6e6;
    box-shadow: 1px 0 0 #e6e6e6
}

.flatpickr-weekwrapper .flatpickr-weekday {
    float: none;
    width: 100%;
    line-height: 28px
}

.flatpickr-weekwrapper span.flatpickr-day, .flatpickr-weekwrapper span.flatpickr-day:hover {
    display: block;
    width: 100%;
    max-width: none;
    color: rgba(57, 57, 57, .3);
    background: transparent;
    cursor: default;
    border: none
}

.flatpickr-innerContainer {
    display: block;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden
}

.flatpickr-innerContainer, .flatpickr-rContainer {
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

.flatpickr-rContainer {
    display: inline-block;
    padding: 0
}

.flatpickr-time {
    text-align: center;
    outline: 0;
    display: block;
    height: 0;
    line-height: 40px;
    max-height: 40px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    overflow: hidden;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex
}

.flatpickr-time:after {
    content: "";
    display: table;
    clear: both
}

.flatpickr-time .numInputWrapper {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    width: 40%;
    height: 40px;
    float: left
}

.flatpickr-time .numInputWrapper span.arrowUp:after {
    border-bottom-color: #393939
}

.flatpickr-time .numInputWrapper span.arrowDown:after {
    border-top-color: #393939
}

.flatpickr-time.hasSeconds .numInputWrapper {
    width: 26%
}

.flatpickr-time.time24hr .numInputWrapper {
    width: 49%
}

.flatpickr-time input {
    background: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 0;
    border-radius: 0;
    text-align: center;
    margin: 0;
    padding: 0;
    height: inherit;
    line-height: inherit;
    color: #393939;
    font-size: 14px;
    position: relative;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield
}

.flatpickr-time input.flatpickr-hour {
    font-weight: 700
}

.flatpickr-time input.flatpickr-minute, .flatpickr-time input.flatpickr-second {
    font-weight: 400
}

.flatpickr-time input:focus {
    outline: 0;
    border: 0
}

.flatpickr-time .flatpickr-am-pm, .flatpickr-time .flatpickr-time-separator {
    height: inherit;
    float: left;
    line-height: inherit;
    color: #393939;
    font-weight: 700;
    width: 2%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center
}

.flatpickr-time .flatpickr-am-pm {
    outline: 0;
    width: 18%;
    cursor: pointer;
    text-align: center;
    font-weight: 400
}

.flatpickr-time .flatpickr-am-pm:focus, .flatpickr-time .flatpickr-am-pm:hover, .flatpickr-time input:focus, .flatpickr-time input:hover {
    background: #eee
}

.flatpickr-input[readonly] {
    cursor: pointer
}

@-webkit-keyframes fpFadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -20px, 0);
        transform: translate3d(0, -20px, 0)
    }
    to {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0)
    }
}

@keyframes fpFadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -20px, 0);
        transform: translate3d(0, -20px, 0)
    }
    to {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0)
    }
}

@font-face {
    font-family: lato_regular;
    src: url(/typo3conf/ext/px_basis_config/Resources/Public/Fonts/Lato-Regular.woff) format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: lato_italic;
    src: url(/typo3conf/ext/px_basis_config/Resources/Public/Fonts/Lato-Italic.woff) format("woff");
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: lato_bold;
    src: url(/typo3conf/ext/px_basis_config/Resources/Public/Fonts/Lato-Bold.woff) format("woff");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: lato_black;
    src: url(/typo3conf/ext/px_basis_config/Resources/Public/Fonts/Lato-Black.woff) format("woff");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@-webkit-keyframes expand-bounce {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0)
    }
    50% {
        -webkit-transform: scale(1.25);
        transform: scale(1.25)
    }
    to {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

@keyframes expand-bounce {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0)
    }
    50% {
        -webkit-transform: scale(1.25);
        transform: scale(1.25)
    }
    to {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

@-webkit-keyframes shrink {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
    to {
        -webkit-transform: scale(0);
        transform: scale(0)
    }
}

@keyframes shrink {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
    to {
        -webkit-transform: scale(0);
        transform: scale(0)
    }
}

body {
    text-align: left;
    background: #fff;
    color: #000
}

sub, sup {
    vertical-align: inherit
}

blockquote, body, dd, dl, fieldset, figure, h1, h2, h3, h4, h5, h6, hr, ol, p, pre, ul {
    margin: 0
}

legend, ol, td, th, ul {
    padding: 0
}

address, b, cite, code, dfn, em, h1, h2, h3, h4, h5, h6, i, kbd, pre, samp, small, strong, sub, sup, th, var {
    font: inherit
}

caption, th {
    text-align: inherit
}

pre {
    white-space: inherit
}

mark {
    background: transparent
}

a, del, ins, s, u {
    text-decoration: none
}

ol, ul {
    list-style: none
}

q {
    quotes: none
}

a, mark {
    color: inherit
}

*, :after, :before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

body, html {
    width: 100%;
    margin: 0;
    padding: 0;
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden
}

html {
    -webkit-text-size-adjust: none;
    -moz-text-size-adjust: none;
    -ms-text-size-adjust: none;
    text-size-adjust: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    text-rendering: optimizeLegibility;
    background-color: #fff;
    scroll-behavior: smooth
}

body {
    padding-top: 2.8125rem;
    font-family: lato_regular, Helvetica, sans-serif;
    font-weight: 400
}

@media (min-width: 62rem) {
    body {
        padding-top: 8.4375rem
    }
}

body.newsletter-registration {
    padding-top: 0
}

img {
    height: auto
}

img, svg {
    width: 100%;
    display: block
}

svg {
    height: 100%
}

body.cke_editable {
    padding: 0
}

.site-content {
    width: 100%
}

.main-section {
    min-height: 37.5rem
}

.frame-layout-0 {
    max-width: 1440px;
    height: inherit;
    margin: 0 auto;
    position: relative;
    padding: 0 1.5rem
}

@media (min-width: 62rem) {
    .frame-layout-0 {
        padding: 0 4.861%
    }
}

@media (min-width: 90rem) {
    .frame-layout-0 {
        padding: 0 4.375rem
    }
}

.frame-layout-0.frame-type-slider {
    padding-left: 0;
    padding-right: 0
}

.stagecontainer .frame-layout-0 {
    margin-top: 0
}

h1 {
    font-family: lato_black, Helvetica, sans-serif;
    font-weight: 900;
    color: #000;
    line-height: 1.166666666666667;
    margin-bottom: 1.5rem;
    text-transform: uppercase;
    font-size: 1.625rem;
    letter-spacing: .25rem;
    -webkit-transition: font-size .6s cubic-bezier(.68, -.55, .27, 1.55);
    transition: font-size .6s cubic-bezier(.68, -.55, .27, 1.55)
}

@media (min-width: 36rem) {
    h1 {
        font-size: calc(2.546vw + .70833rem)
    }
}

@media (min-width: 90rem) {
    h1 {
        font-size: 3rem
    }
}

@media (min-width: 125rem) {
    h1 {
        font-size: calc(2.857vw + -.57143rem)
    }
}

h2 {
    font-family: lato_black, Helvetica, sans-serif;
    font-weight: 900;
    color: #000;
    line-height: 1.333333333333333;
    margin-bottom: 2rem;
    text-transform: uppercase;
    font-size: 1.5rem;
    letter-spacing: .125rem;
    -webkit-transition: font-size .6s cubic-bezier(.68, -.55, .27, 1.55);
    transition: font-size .6s cubic-bezier(.68, -.55, .27, 1.55)
}

@media (min-width: 36rem) {
    h2 {
        font-size: calc(.231vw + 1.29167rem)
    }
}

@media (min-width: 90rem) {
    h2 {
        font-size: 1.5rem
    }
}

@media (min-width: 125rem) {
    h2 {
        font-size: calc(2.857vw + -2.07143rem)
    }
}

h3 {
    font-family: lato_black, Helvetica, sans-serif;
    font-weight: 900;
    color: #000;
    line-height: 1.454545454545455;
    margin-bottom: 1.25rem;
    font-size: 1.375rem;
    letter-spacing: .125rem;
    -webkit-transition: font-size .6s cubic-bezier(.68, -.55, .27, 1.55);
    transition: font-size .6s cubic-bezier(.68, -.55, .27, 1.55)
}

@media (min-width: 36rem) {
    h3 {
        font-size: calc(.231vw + 1.16667rem)
    }
}

@media (min-width: 90rem) {
    h3 {
        font-size: 1.375rem
    }
}

@media (min-width: 125rem) {
    h3 {
        font-size: calc(1.786vw + -.85714rem)
    }
}

h4 {
    line-height: 1.333333333333333;
    font-size: 1.125rem
}

h4, h5 {
    font-family: lato_black, Helvetica, sans-serif;
    font-weight: 900;
    color: #000;
    margin-bottom: .5rem;
    letter-spacing: .125rem
}

h5 {
    line-height: 1.5;
    font-size: 1rem
}

h6 {
    font-family: lato_black, Helvetica, sans-serif;
    font-weight: 900;
    color: #000;
    line-height: 1.714285714285714;
    font-size: .875rem;
    letter-spacing: .125rem
}

strong {
    font-family: lato_bold, Helvetica, sans-serif;
    font-weight: 700
}

em {
    font-family: lato_italic, Helvetica, sans-serif;
    font-style: italic;
    font-weight: 400
}

u {
    text-decoration: underline
}

p {
    font-family: lato_regular, Helvetica, sans-serif;
    font-weight: 400;
    color: #000;
    line-height: 1.5rem;
    margin-bottom: 1.5rem;
    font-size: 1rem;
    letter-spacing: .0625rem
}

p:last-child {
    margin-bottom: 0
}

p.big {
    font-family: lato_regular, Helvetica, sans-serif;
    font-weight: 400;
    color: #000;
    line-height: 1.5rem;
    margin-bottom: 1.5rem;
    font-size: 1rem;
    letter-spacing: .0625rem;
    font-size: 1.125rem;
    line-height: 1.75rem
}

p.big:last-child {
    margin-bottom: 0
}

p.medium {
    font-family: lato_regular, Helvetica, sans-serif;
    font-weight: 400;
    color: #000;
    line-height: 1.5rem;
    margin-bottom: 1.5rem;
    font-size: 1rem;
    letter-spacing: .0625rem
}

p.medium:last-child {
    margin-bottom: 0
}

p.small {
    font-family: lato_regular, Helvetica, sans-serif;
    font-weight: 400;
    color: #000;
    margin-bottom: 1.5rem;
    font-size: 1rem;
    letter-spacing: .0625rem;
    font-size: .875rem;
    line-height: 1.5rem
}

p.small:last-child {
    margin-bottom: 0
}

p.legal, span.help-block {
    font-family: lato_regular, Helvetica, sans-serif;
    font-weight: 400;
    font-size: .75rem;
    color: #929292;
    line-height: 2;
    padding-bottom: .5rem;
    letter-spacing: .0625rem
}

p.legal:last-child, span.help-block:last-child {
    margin-bottom: 0
}

small {
    font-family: lato_regular, Helvetica, sans-serif;
    font-weight: 400;
    font-size: .75rem;
    color: #929292;
    line-height: 2;
    padding-bottom: .5rem;
    letter-spacing: .0625rem
}

button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    padding: 0
}

form {
    max-width: 40rem
}

.frame-1 form {
    margin: 0 auto
}

form .form-group:not(:last-of-type) {
    margin-bottom: 1.25rem
}

form .parsley-errors-list {
    font-family: lato_regular, Helvetica, sans-serif;
    font-weight: 400;
    color: #e20079;
    font-size: .875rem;
    margin-top: .3125rem
}

form .row {
    margin-bottom: 1.5rem
}

form .row .col-md-6 {
    width: auto;
    display: inline-block
}

form .row .col-md-6:first-of-type {
    margin-right: .625rem
}

form .row .col-md-6 .checkbox-label {
    margin: 0
}

form .row .frame-type-text {
    padding: 0
}

form .row .ce-text {
    padding: 0 0 0 2.1875rem
}

form .clearfix > p {
    font-family: lato_regular, Helvetica, sans-serif;
    font-weight: 400;
    font-size: .75rem;
    color: #929292;
    line-height: 2;
    padding-bottom: .5rem;
    letter-spacing: .0625rem;
    margin-top: 0 !important
}

form .clearfix > p, form .clearfix > p:last-child {
    margin-bottom: 2.5rem
}

form input[type=text]::-ms-clear, form input[type=text]::-ms-reveal {
    display: none;
    width: 0;
    height: 0
}

form input[type=number] {
    -moz-appearance: textfield
}

form input::-webkit-inner-spin-button, form input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0
}

form ::-webkit-input-placeholder {
    color: #bdbdbd;
    font-family: lato_italic, Helvetica, sans-serif;
    font-style: italic;
    font-weight: 400;
    letter-spacing: .125rem
}

form ::-moz-placeholder {
    color: #bdbdbd;
    font-family: lato_italic, Helvetica, sans-serif;
    font-style: italic;
    font-weight: 400;
    letter-spacing: .125rem
}

form :-ms-input-placeholder {
    color: #bdbdbd;
    font-family: lato_italic, Helvetica, sans-serif;
    font-style: italic;
    font-weight: 400;
    letter-spacing: .125rem
}

form :-moz-placeholder {
    color: #bdbdbd;
    font-family: lato_italic, Helvetica, sans-serif;
    font-style: italic;
    font-weight: 400;
    letter-spacing: .125rem
}

input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #f1f1f1;
    padding: 1rem;
    color: #000;
    border: none;
    outline: none;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: background-color .3s, color .3s;
    transition: background-color .3s, color .3s;
    font-size: .875rem;
    width: 100%;
    font-family: lato_regular, Helvetica, sans-serif;
    font-weight: 400
}

input:active, input:focus {
    -webkit-box-shadow: .125rem .125rem .625rem 0 rgba(54, 175, 253, .2);
    box-shadow: .125rem .125rem .625rem 0 rgba(54, 175, 253, .2);
    background-color: #fff
}

input[type=radio] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: none
}

input[type=radio]:checked + label:after {
    opacity: 1;
    -webkit-transition: opacity .3s;
    transition: opacity .3s
}

input[type=radio] + .radio-label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    cursor: pointer;
    padding-top: .3125rem
}

input[type=radio] + .radio-label:after {
    position: absolute;
    content: "";
    opacity: 0;
    border-radius: 50%;
    background-color: #e20079;
    width: .5rem;
    height: .5rem;
    left: .3125rem;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

input[type=radio] + .radio-label:before {
    margin-right: .375rem;
    content: "";
    width: 1.125rem;
    height: 1.125rem;
    border-radius: 50%;
    display: inline-block;
    background-color: #fff;
    -webkit-box-shadow: inset 0 0 0 .125rem #e20079;
    box-shadow: inset 0 0 0 .125rem #e20079
}

.radio-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.form-check input {
    display: none
}

.form-check input + span {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    cursor: pointer
}

.form-check input + span:before {
    margin-right: .5rem;
    content: "";
    -webkit-box-flex: 0;
    -ms-flex: 0 0 1.5rem;
    flex: 0 0 1.5rem;
    width: 1.5rem;
    height: 1.5rem;
    display: inline-block;
    background-color: #fff;
    -webkit-box-shadow: inset 0 0 0 .125rem #929292;
    box-shadow: inset 0 0 0 .125rem #929292;
    background-size: 1rem 1rem;
    background-position: 50% 100%;
    background-repeat: no-repeat;
    -webkit-transition: background-position .3s, background-color .3s;
    transition: background-position .3s, background-color .3s;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 16 16'%3E%3Cdefs%3E%3Cpath fill='%23fff' id='a' d='M14 2.8L6.8 14 2 9.2l1.485-2.012 3.046 3.046L11.6 2z'/%3E%3C/defs%3E%3Cuse xlink:href='%23a' fill-rule='evenodd'/%3E%3C/svg%3E")
}

.form-check input:checked + span:before {
    background-position: 50% 50%;
    background-color: #e20079;
    -webkit-box-shadow: inset 0 0 0 .125rem #e20079;
    box-shadow: inset 0 0 0 .125rem #e20079
}

textarea {
    -moz-appearance: none;
    appearance: none;
    background-color: #f1f1f1;
    padding: 1rem;
    color: #000;
    border: none;
    outline: none;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: background-color .3s, color .3s;
    transition: background-color .3s, color .3s;
    font-size: .875rem;
    width: 100%;
    font-family: lato_regular, Helvetica, sans-serif;
    font-weight: 400;
    -webkit-appearance: none;
    min-height: 12.5rem;
    resize: vertical
}

textarea:active, textarea:focus {
    -webkit-box-shadow: .125rem .125rem .625rem 0 rgba(54, 175, 253, .2);
    box-shadow: .125rem .125rem .625rem 0 rgba(54, 175, 253, .2);
    background-color: #fff
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #f1f1f1;
    padding: 1rem;
    color: #000;
    border: none;
    outline: none;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: background-color .3s, color .3s;
    transition: background-color .3s, color .3s;
    font-size: .875rem;
    width: 100%;
    font-family: lato_regular, Helvetica, sans-serif;
    font-weight: 400;
    cursor: pointer;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 16 16'%3E%3Cdefs%3E%3Cpath fill='%23E20079' id='a' d='M9.883 13a.216.216 0 01-.155-.066L5.064 8.158a.227.227 0 010-.317l4.664-4.775a.216.216 0 01.31 0l.897.919a.228.228 0 010 .317L7.324 8l3.612 3.698a.228.228 0 010 .317l-.898.92a.218.218 0 01-.155.065z'/%3E%3C/defs%3E%3Cuse transform='rotate(-90 8 8)' xlink:href='%23a'/%3E%3C/svg%3E");
    background-position: right .75rem center;
    background-size: 1.5rem 1.5rem;
    background-repeat: no-repeat
}

select:active, select:focus {
    -webkit-box-shadow: .125rem .125rem .625rem 0 rgba(54, 175, 253, .2);
    box-shadow: .125rem .125rem .625rem 0 rgba(54, 175, 253, .2);
    background-color: #fff
}

select::-ms-expand {
    display: none
}

label {
    font-family: lato_black, Helvetica, sans-serif;
    font-weight: 900;
    color: #000;
    line-height: 1.714285714285714;
    font-size: .875rem;
    letter-spacing: .125rem;
    padding-bottom: .3125rem;
    display: inline-block
}

label.checkbox-label {
    font-family: lato_regular, Helvetica, sans-serif;
    font-weight: 400;
    font-size: .75rem;
    color: #929292;
    line-height: 2;
    letter-spacing: .0625rem;
    display: inline-block;
    position: relative;
    padding-left: 2.125rem;
    margin-bottom: .625rem;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding-bottom: 0
}

label.checkbox-label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0
}

label.checkbox-label input:checked ~ .checkmark {
    background-color: #e20079;
    border: .125rem solid #e20079
}

label.checkbox-label input:checked ~ .checkmark:after {
    display: block
}

label.checkbox-label .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 1.5rem;
    width: 1.5rem;
    background-color: #fff;
    border: .125rem solid #bdbdbd
}

label.checkbox-label .checkmark:after {
    content: "";
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 16 16'%3E%3Cdefs%3E%3Cpath fill='%23fff' id='a' d='M14 2.8L6.8 14 2 9.2l1.485-2.012 3.046 3.046L11.6 2z'/%3E%3C/defs%3E%3Cuse xlink:href='%23a' fill-rule='evenodd'/%3E%3C/svg%3E");
    background-size: 80%;
    background-position: 50%;
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    height: 100%;
    display: none
}

label.checkbox-label + .parsley-errors-list {
    margin: 0
}

label.checkbox-label.parsley-error .checkmark {
    border-color: #e20079
}

label.checkbox-label.parsley-error + .parsley-errors-list {
    display: none
}

.help-block {
    font-family: lato_regular, Helvetica, sans-serif;
    font-weight: 400;
    font-size: .75rem;
    color: #929292;
    line-height: 2;
    padding-bottom: .5rem;
    letter-spacing: .0625rem;
    display: block
}

ul:not([class]) {
    margin: 0 0 1.25rem .625rem;
    padding: 0;
    line-height: 1.5rem
}

ul:not([class]) li {
    position: relative;
    padding: 0 0 1.25rem 1.5625rem;
    letter-spacing: .0625rem
}

ul:not([class]) li:before {
    background: #000;
    border-radius: 50%;
    content: "";
    height: .3125rem;
    left: 0;
    top: .625rem;
    position: absolute;
    width: .3125rem
}

ul.unordered-list {
    font-family: lato_regular, Helvetica, sans-serif;
    font-weight: 400;
    color: #000;
    line-height: 1.5rem;
    margin: 0 0 1.5rem .625rem;
    font-size: 1rem;
    letter-spacing: .0625rem;
    padding: 0
}

ul.unordered-list li {
    position: relative;
    padding: 0 0 1.25rem 1.5625rem;
    letter-spacing: .0625rem
}

ul.unordered-list li:before {
    background: #000;
    border-radius: 50%;
    content: "";
    height: .3125rem;
    left: 0;
    top: .625rem;
    position: absolute;
    width: .3125rem
}

ul.unordered-list-big {
    font-family: lato_regular, Helvetica, sans-serif;
    font-weight: 400;
    color: #000;
    line-height: 1.5rem;
    margin: 0 0 1.5rem .625rem;
    font-size: 1rem;
    letter-spacing: .0625rem;
    padding: 0
}

ul.unordered-list-big li {
    position: relative;
    padding: 0 0 1.25rem 1.5625rem;
    letter-spacing: .0625rem
}

ul.unordered-list-big li:before {
    background: #000;
    border-radius: 50%;
    content: "";
    height: .3125rem;
    left: 0;
    top: .625rem;
    position: absolute;
    width: .3125rem;
    top: .6875rem
}

@media (min-width: 62rem) {
    ul.unordered-list-big {
        font-family: lato_regular, Helvetica, sans-serif;
        font-weight: 400;
        color: #000;
        line-height: 1.5rem;
        margin-bottom: 1.5rem;
        font-size: 1rem;
        letter-spacing: .0625rem;
        font-size: 1.125rem;
        line-height: 1.75rem;
        padding-bottom: 0
    }
}

ul.unordered-list-medium {
    font-family: lato_regular, Helvetica, sans-serif;
    font-weight: 400;
    color: #000;
    line-height: 1.5rem;
    margin: 0 0 1.5rem .625rem;
    font-size: 1rem;
    letter-spacing: .0625rem;
    padding: 0
}

ul.unordered-list-medium li {
    position: relative;
    padding: 0 0 1.25rem 1.5625rem;
    letter-spacing: .0625rem
}

ul.unordered-list-medium li:before {
    background: #000;
    border-radius: 50%;
    content: "";
    height: .3125rem;
    left: 0;
    position: absolute;
    width: .3125rem;
    top: .625rem
}

ul.unordered-list-small {
    font-family: lato_regular, Helvetica, sans-serif;
    font-weight: 400;
    color: #000;
    margin: 0 0 1.5rem .625rem;
    font-size: 1rem;
    letter-spacing: .0625rem;
    font-size: .875rem;
    line-height: 1.5rem;
    padding: 0
}

ul.unordered-list-small li {
    position: relative;
    padding: 0 0 1.25rem 1.5625rem;
    letter-spacing: .0625rem
}

ul.unordered-list-small li:before {
    background: #000;
    border-radius: 50%;
    content: "";
    height: .3125rem;
    left: 0;
    top: .625rem;
    position: absolute;
    width: .3125rem
}

ol:not([class]) {
    margin-bottom: 1.25rem;
    padding: 0 0 0 1.875rem;
    line-height: 1.5rem;
    list-style: decimal;
    letter-spacing: .0625rem
}

ol:not([class]) li {
    padding-bottom: 1.25rem;
    padding-left: .3125rem
}

ol.ordered-list {
    list-style: decimal;
    font-family: lato_regular, Helvetica, sans-serif;
    font-weight: 400;
    color: #000;
    line-height: 1.5rem;
    margin-bottom: 1.5rem;
    font-size: 1rem;
    letter-spacing: .0625rem;
    padding: 0 0 0 1.875rem
}

ol.ordered-list li {
    padding-bottom: 1.25rem;
    padding-left: .3125rem
}

ol.ordered-list-big {
    list-style: decimal;
    font-family: lato_regular, Helvetica, sans-serif;
    font-weight: 400;
    color: #000;
    line-height: 1.5rem;
    margin-bottom: 1.5rem;
    font-size: 1rem;
    letter-spacing: .0625rem;
    padding: 0 0 0 1.875rem
}

ol.ordered-list-big li {
    padding-bottom: 1.25rem;
    padding-left: .3125rem
}

ol.ordered-list-big li:before {
    top: .6875rem
}

@media (min-width: 62rem) {
    ol.ordered-list-big {
        font-family: lato_regular, Helvetica, sans-serif;
        font-weight: 400;
        color: #000;
        line-height: 1.5rem;
        margin-bottom: 1.5rem;
        font-size: 1rem;
        letter-spacing: .0625rem;
        font-size: 1.125rem;
        line-height: 1.75rem;
        padding-bottom: 0
    }
}

ol.ordered-list-medium {
    list-style: decimal;
    font-family: lato_regular, Helvetica, sans-serif;
    font-weight: 400;
    color: #000;
    line-height: 1.5rem;
    margin-bottom: 1.5rem;
    font-size: 1rem;
    letter-spacing: .0625rem;
    padding: 0 0 0 1.875rem
}

ol.ordered-list-medium li {
    padding-bottom: 1.25rem;
    padding-left: .3125rem
}

ol.ordered-list-medium li:before {
    top: .5625rem
}

ol.ordered-list-small {
    list-style: decimal;
    font-family: lato_regular, Helvetica, sans-serif;
    font-weight: 400;
    color: #000;
    margin-bottom: 1.5rem;
    font-size: 1rem;
    letter-spacing: .0625rem;
    font-size: .875rem;
    line-height: 1.5rem;
    padding: 0 0 0 1.875rem
}

ol.ordered-list-small li {
    padding-bottom: 1.25rem;
    padding-left: .3125rem
}

a.link, a:not([class]):not([id]) {
    font-family: lato_regular, Helvetica, sans-serif;
    font-weight: 400;
    color: #000;
    line-height: 1.5rem;
    font-size: 1rem;
    letter-spacing: .0625rem;
    font-family: lato_bold, Helvetica, sans-serif;
    font-weight: 700;
    color: #e20079;
    margin-bottom: 0;
    -webkit-transition: color .3s;
    transition: color .3s;
    text-decoration: none;
    display: inline;
    font-size: inherit;
    line-height: inherit
}

a.link:hover, a:not([class]):not([id]):hover {
    color: #000
}

a.external-link {
    font-family: lato_regular, Helvetica, sans-serif;
    font-weight: 400;
    color: #000;
    line-height: 1.5rem;
    font-size: 1rem;
    letter-spacing: .0625rem;
    font-family: lato_bold, Helvetica, sans-serif;
    font-weight: 700;
    color: #e20079;
    margin-bottom: 0;
    -webkit-transition: color .3s;
    transition: color .3s;
    text-decoration: none
}

a.external-link:hover {
    color: #000
}

a.external-link:before {
    content: "";
    display: inline-block;
    height: 1rem;
    margin: 0 .25rem;
    width: 1rem;
    -webkit-transition: background-image .3s ease;
    transition: background-image .3s ease;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEgMTZjLS40ODEgMC0xLS41MTktMS0xVjIuODc1QzAgMi4zOTQuMzk0IDIgLjg3NSAySDh2MkgydjEwaDEwVjhoMnY3YzAgLjQ4MS0uNTE5IDEtMSAxSDF6bTYtOC4yNzlsNS44NzktNS44OTRIMTFWMGg1djVoLTEuODIzVjMuMTA3TDguMjc2IDkgNyA3LjcyMXoiIGZpbGw9IiNFMjAwNzkiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==");
    position: relative;
    top: .125rem
}

a.external-link:hover:before {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTYiIGhlaWdodD0iMTYiPjxkZWZzPjxwYXRoIGlkPSJhIiBkPSJNMSAxNmMtLjQ4MSAwLTEtLjUxOS0xLTFWMi44NzVDMCAyLjM5NC4zOTQgMiAuODc1IDJIOHYySDJ2MTBoMTBWOGgydjdjMCAuNDgxLS41MTkgMS0xIDFIMXptNi04LjI4bDUuODc5LTUuODkzSDExVjBoNXY1aC0xLjgyM1YzLjEwN0w4LjI3NyA5IDcgNy43MnoiLz48L2RlZnM+PHVzZSB4bGluazpocmVmPSIjYSIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+")
}

a.internal-link {
    font-family: lato_regular, Helvetica, sans-serif;
    font-weight: 400;
    color: #000;
    line-height: 1.5rem;
    font-size: 1rem;
    letter-spacing: .0625rem;
    font-family: lato_bold, Helvetica, sans-serif;
    font-weight: 700;
    color: #e20079;
    margin-bottom: 0;
    -webkit-transition: color .3s;
    transition: color .3s;
    text-decoration: none
}

a.internal-link:hover {
    color: #000
}

a.internal-link:before {
    content: "";
    display: inline-block;
    height: .75rem;
    margin: 0 .125rem;
    width: .75rem;
    -webkit-transition: background-image .3s ease;
    transition: background-image .3s ease;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAAAXNSR0IB2cksfwAAAGNQTFRFAAAA/wCA4QB44QB5/wCA4QB44gB54gB5/wBV4gB34QB54gB4/wAA4QB44QB54QB54wB54gB44gB44gB44gB54gB54QB54gB44gB54wB54QB54QB54gB44gB54gB54QB5/wCAuGtZWgAAACF0Uk5TAAa7tQSb/68DPveoAUT5mkr8k1f9i13+fGVnbn1Q+6ICmS9UZwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAFhJREFUeJxdj0USgEAMBIM1Dou7/P+VFLdkc0tXjYmIBGEUi74E0kyDvICy0qRuoDUi10FvyDDCZMi8wGrIBuw+ODzJqf7LM3W3jfWL/dUfXf0f9+pEM/8D3wUDr7K4jO4AAAAASUVORK5CYII=");
    background-size: .75rem;
    background-repeat: no-repeat
}

a.internal-link:hover:before {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAAAXNSR0IB2cksfwAAAGNQTFRFAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAZmh03gAAACF0Uk5TAAa7tQSb/68DPveoAUT5mkr8k1f9i13+fGVnbn1Q+6ICmS9UZwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAFhJREFUeJxdj0USgEAMBIM1Dou7/P+VFLdkc0tXjYmIBGEUi74E0kyDvICy0qRuoDUi10FvyDDCZMi8wGrIBuw+ODzJqf7LM3W3jfWL/dUfXf0f9+pEM/8D3wUDr7K4jO4AAAAASUVORK5CYII=")
}

a.download-link {
    font-family: lato_regular, Helvetica, sans-serif;
    font-weight: 400;
    color: #000;
    line-height: 1.5rem;
    font-size: 1rem;
    letter-spacing: .0625rem;
    font-family: lato_bold, Helvetica, sans-serif;
    font-weight: 700;
    color: #e20079;
    margin-bottom: 0;
    -webkit-transition: color .3s;
    transition: color .3s;
    text-decoration: none
}

a.download-link:hover {
    color: #000
}

a.download-link:before {
    content: "";
    display: inline-block;
    height: 1rem;
    margin: 0 .125rem;
    width: 1rem;
    -webkit-transition: background-image .4s ease;
    transition: background-image .4s ease;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTkuNjkgNS4yMTloNC4yODN2MTAuMDY3YzAgLjM3My0uMjgxLjY3OC0uNjI1LjY3OEgyLjYyNWMtLjM0NCAwLS42MjUtLjMwNS0uNjI1LS42NzhWLjY3OEMyIC4zMDUgMi4yODEgMCAyLjYyNSAwaDYuNDR2NC41NDFjMCAuMzczLjI4MS42NzguNjI1LjY3OHptLjYxNC0xLjM0OVYuMzQzTDE0IDMuODM3di4wMzNoLTMuNjk2eiIgZmlsbD0iI2UyMDA3OSIvPjwvc3ZnPg==");
    position: relative;
    top: .125rem
}

a.download-link:hover:before {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTkuNjkgNS4yMTloNC4yODN2MTAuMDY3YzAgLjM3My0uMjgxLjY3OC0uNjI1LjY3OEgyLjYyNWMtLjM0NCAwLS42MjUtLjMwNS0uNjI1LS42NzhWLjY3OEMyIC4zMDUgMi4yODEgMCAyLjYyNSAwaDYuNDR2NC41NDFjMCAuMzczLjI4MS42NzguNjI1LjY3OHptLjYxNC0xLjM0OVYuMzQzTDE0IDMuODM3di4wMzNoLTMuNjk2eiIvPjwvc3ZnPg==")
}

.btn-primary {
    font-family: lato_bold, Helvetica, sans-serif;
    font-weight: 700;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 1rem;
    color: #fff;
    line-height: 1.5;
    letter-spacing: .125rem;
    border: .1875rem solid #e20079;
    background-color: #e20079;
    padding: .5625rem 2.1875rem;
    -webkit-transition: color .3s, background-color .3s, border-color .3s;
    transition: color .3s, background-color .3s, border-color .3s;
    position: relative;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    cursor: pointer
}

.btn-primary.icon-left {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

.btn-primary.icon-left svg {
    margin-right: .625rem
}

.btn-primary.icon-right {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start
}

.btn-primary.icon-right svg {
    margin-left: .625rem
}

.btn-primary.icon-only {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: .625rem
}

.btn-primary svg {
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 50%;
    background-color: #fff;
    padding: .1875rem;
    -webkit-transition: background-color .3s;
    transition: background-color .3s
}

.btn-primary svg use {
    fill: #e20079;
    -webkit-transition: fill .3s;
    transition: fill .3s
}

.btn-primary:focus, .btn-primary:hover {
    background-color: transparent;
    color: #e20079;
    border-color: #e20079
}

.btn-primary:focus svg, .btn-primary:hover svg {
    background-color: #e20079
}

.btn-primary:focus svg use, .btn-primary:hover svg use {
    fill: #fff
}

.btn-primary.disabled, .btn-primary[disabled] {
    background-color: #d7d7d7;
    color: #bdbdbd;
    border-color: #d7d7d7;
    cursor: not-allowed;
    -webkit-box-shadow: none;
    box-shadow: none
}

.btn-primary.disabled span:first-child, .btn-primary[disabled] span:first-child {
    display: none
}

.btn-primary.disabled span:last-child, .btn-primary[disabled] span:last-child {
    -webkit-transform: none;
    transform: none
}

.btn-primary-inverted {
    font-family: lato_bold, Helvetica, sans-serif;
    font-weight: 700;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 1rem;
    color: #e20079;
    line-height: 1.5;
    letter-spacing: .125rem;
    border: .1875rem solid #fff;
    background-color: #fff;
    padding: .5625rem 2.1875rem;
    -webkit-transition: color .3s, background-color .3s, border-color .3s;
    transition: color .3s, background-color .3s, border-color .3s;
    position: relative;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    cursor: pointer
}

.btn-primary-inverted.icon-left {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

.btn-primary-inverted.icon-left svg {
    margin-right: .625rem
}

.btn-primary-inverted.icon-right {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start
}

.btn-primary-inverted.icon-right svg {
    margin-left: .625rem
}

.btn-primary-inverted.icon-only {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: .625rem
}

.btn-primary-inverted svg {
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 50%;
    background-color: #e20079;
    padding: .1875rem;
    -webkit-transition: background-color .3s;
    transition: background-color .3s
}

.btn-primary-inverted svg use {
    fill: #fff;
    -webkit-transition: fill .3s;
    transition: fill .3s
}

.btn-primary-inverted:focus, .btn-primary-inverted:hover {
    background-color: transparent;
    color: #fff;
    border-color: #fff
}

.btn-primary-inverted:focus svg, .btn-primary-inverted:hover svg {
    background-color: #fff
}

.btn-primary-inverted:focus svg use, .btn-primary-inverted:hover svg use {
    fill: #000
}

.btn-primary-inverted.disabled, .btn-primary-inverted[disabled] {
    background-color: #d7d7d7;
    color: #bdbdbd;
    border-color: #d7d7d7;
    cursor: not-allowed;
    -webkit-box-shadow: none;
    box-shadow: none
}

.btn-primary-inverted.disabled span:first-child, .btn-primary-inverted[disabled] span:first-child {
    display: none
}

.btn-primary-inverted.disabled span:last-child, .btn-primary-inverted[disabled] span:last-child {
    -webkit-transform: none;
    transform: none
}

.btn-secondary {
    font-family: lato_bold, Helvetica, sans-serif;
    font-weight: 700;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 1rem;
    color: #e20079;
    line-height: 1.5;
    letter-spacing: .125rem;
    border: .125rem solid #fff;
    background-color: #fff;
    padding: .9375rem 2.1875rem;
    -webkit-transition: color .3s, background-color .3s, border-color .3s;
    transition: color .3s, background-color .3s, border-color .3s;
    position: relative
}

.btn-secondary svg {
    fill: #e20079;
    -webkit-transition: fill .3s;
    transition: fill .3s
}

.btn-secondary:focus, .btn-secondary:hover {
    background-color: #000;
    color: #fff;
    border-color: #fff
}

.btn-secondary:focus svg, .btn-secondary:hover svg {
    fill: #fff
}

.btn-secondary:focus span:first-child, .btn-secondary:hover span:first-child {
    opacity: 1;
    fill: #d7d7d7;
    -webkit-transform: rotate(0);
    transform: rotate(0)
}

.btn-secondary:focus span:last-child, .btn-secondary:hover span:last-child {
    -webkit-transform: translateX(1.09375rem);
    transform: translateX(1.09375rem)
}

.btn-secondary[disabled] {
    background-color: transparent;
    color: #bdbdbd;
    border-color: #bdbdbd;
    cursor: not-allowed
}

.btn-secondary[disabled] span:first-child {
    display: none
}

.btn-secondary[disabled] span:last-child {
    -webkit-transform: none;
    transform: none
}

.extbase-debugger-center pre {
    white-space: pre
}

header h1.header, header h2.header {
    font-family: lato_black, Helvetica, sans-serif;
    font-weight: 900;
    color: #000;
    line-height: 1.166666666666667;
    margin-bottom: 1.5rem;
    text-transform: uppercase;
    font-size: 1.625rem;
    letter-spacing: .25rem;
    -webkit-transition: font-size .6s cubic-bezier(.68, -.55, .27, 1.55);
    transition: font-size .6s cubic-bezier(.68, -.55, .27, 1.55)
}

@media (min-width: 36rem) {
    header h1.header, header h2.header {
        font-size: calc(2.546vw + .70833rem)
    }
}

@media (min-width: 90rem) {
    header h1.header, header h2.header {
        font-size: 3rem
    }
}

@media (min-width: 125rem) {
    header h1.header, header h2.header {
        font-size: calc(2.857vw + -.57143rem)
    }
}

header h3.header + .subheader {
    line-height: 1.333333333333333;
    font-size: 1.125rem
}

header h3.header + .subheader, header h4.header + .subheader {
    font-family: lato_black, Helvetica, sans-serif;
    font-weight: 900;
    color: #000;
    margin-bottom: .5rem;
    letter-spacing: .125rem
}

header h4.header + .subheader {
    line-height: 1.5;
    font-size: 1rem
}

header h5.header + .subheader {
    line-height: 1.714285714285714;
    font-size: .875rem
}

header .subheader, header h5.header + .subheader {
    font-family: lato_black, Helvetica, sans-serif;
    font-weight: 900;
    color: #000;
    letter-spacing: .125rem
}

header .subheader {
    line-height: 1.454545454545455;
    margin-bottom: 1.25rem;
    font-size: 1.375rem;
    -webkit-transition: font-size .6s cubic-bezier(.68, -.55, .27, 1.55);
    transition: font-size .6s cubic-bezier(.68, -.55, .27, 1.55)
}

@media (min-width: 36rem) {
    header .subheader {
        font-size: calc(.231vw + 1.16667rem)
    }
}

@media (min-width: 90rem) {
    header .subheader {
        font-size: 1.375rem
    }
}

@media (min-width: 125rem) {
    header .subheader {
        font-size: calc(1.786vw + -.85714rem)
    }
}

.frame-layout-1 .ce-right .ce-textpic-wrapper, .frame-layout-1 .ce-textpic-wrapper {
    padding: 0 1.5rem
}

@media (min-width: 48rem) {
    .frame-layout-1 .ce-right .ce-textpic-wrapper {
        padding: 0 0 0 1.25rem
    }
}

.frame-layout-1 .ce-left .ce-textpic-wrapper {
    padding: 0 1.5rem
}

@media (min-width: 48rem) {
    .frame-layout-1 .ce-left .ce-textpic-wrapper {
        padding: 0 1.25rem 0 0
    }
}

.cke_panel_listItem.cke_selected a h2, .cke_panel_listItem.cke_selected a h3, .cke_panel_listItem.cke_selected a h4, .cke_panel_listItem.cke_selected a h5, .cke_panel_listItem.cke_selected a h6, .cke_panel_listItem a h2, .cke_panel_listItem a h3, .cke_panel_listItem a h4, .cke_panel_listItem a h5, .cke_panel_listItem a h6 {
    color: #fff
}

.cke_panel_listItem.cke_selected a:active h2, .cke_panel_listItem.cke_selected a:active h3, .cke_panel_listItem.cke_selected a:active h4, .cke_panel_listItem.cke_selected a:active h5, .cke_panel_listItem.cke_selected a:active h6, .cke_panel_listItem.cke_selected a:focus h2, .cke_panel_listItem.cke_selected a:focus h3, .cke_panel_listItem.cke_selected a:focus h4, .cke_panel_listItem.cke_selected a:focus h5, .cke_panel_listItem.cke_selected a:focus h6, .cke_panel_listItem.cke_selected a:hover h2, .cke_panel_listItem.cke_selected a:hover h3, .cke_panel_listItem.cke_selected a:hover h4, .cke_panel_listItem.cke_selected a:hover h5, .cke_panel_listItem.cke_selected a:hover h6, .cke_panel_listItem a:active h2, .cke_panel_listItem a:active h3, .cke_panel_listItem a:active h4, .cke_panel_listItem a:active h5, .cke_panel_listItem a:active h6, .cke_panel_listItem a:focus h2, .cke_panel_listItem a:focus h3, .cke_panel_listItem a:focus h4, .cke_panel_listItem a:focus h5, .cke_panel_listItem a:focus h6, .cke_panel_listItem a:hover h2, .cke_panel_listItem a:hover h3, .cke_panel_listItem a:hover h4, .cke_panel_listItem a:hover h5, .cke_panel_listItem a:hover h6 {
    color: #e9e9e9
}

.cke_panel_listItem h2, .cke_panel_listItem h3, .cke_panel_listItem h4, .cke_panel_listItem h5, .cke_panel_listItem h6, .cke_panel_listItem p {
    margin-bottom: 0;
    padding-bottom: 0
}

.cke_panel_listItem h2:before, .cke_panel_listItem h3:before, .cke_panel_listItem h4:before, .cke_panel_listItem h5:before, .cke_panel_listItem h6:before, .cke_panel_listItem p:before {
    color: #000
}

.cke_panel_listItem h2:before {
    content: "H2"
}

.cke_panel_listItem h3:before {
    content: "H3"
}

.cke_panel_listItem h4:before {
    content: "H4"
}

.cke_panel_listItem h5:before {
    content: "H5"
}

.cke_panel_listItem h6:before {
    content: "H6"
}

.frame-type-text {
    padding-top: 0;
    padding-bottom: 0
}

@media (min-width: 48rem) {
    .frame-type-text .ce-text, .frame-type-text header {
        padding-right: 33.33333333333333%
    }
}

@media (min-width: 48rem) {
    .frame-type-text.frame-1 {
        padding-left: 16.66666666666667%;
        padding-right: 16.66666666666667%
    }
}

.frame-type-text.frame-1 > * {
    padding-right: 0
}

@media (min-width: 48rem) {
    .frame-type-table.frame-101 {
        padding-right: 33.33333333333333%
    }
}

@media (min-width: 48rem) {
    .frame-type-form_formframework.frame-1, .frame-type-table.frame-102 {
        padding-left: 16.66666666666667%;
        padding-right: 16.66666666666667%
    }
}

@media (min-width: 48rem) {
    .frame-type-accordion .accordion, .frame-type-accordion header, .frame-type-image_gallery.frame-103 .image-gallery, .frame-type-image_gallery header, .frame-type-table header {
        padding-right: 33.33333333333333%
    }
}

.frame-type-accordion, .frame-type-form_formframework, .frame-type-image_gallery, .frame-type-list, .frame-type-listteaser, .frame-type-slider, .frame-type-textmedia, .frame-type-textpic, .frame-type-video {
    padding-bottom: 1.25rem;
    padding-top: 1.25rem
}

@media (min-width: 48rem) {
    .frame-type-accordion, .frame-type-form_formframework, .frame-type-image_gallery, .frame-type-list, .frame-type-listteaser, .frame-type-slider, .frame-type-textmedia, .frame-type-textpic, .frame-type-video {
        padding-bottom: 2.5rem;
        padding-top: 2.5rem
    }
}

@media (min-width: 64rem) {
    .frame-type-accordion, .frame-type-form_formframework, .frame-type-image_gallery, .frame-type-list, .frame-type-listteaser, .frame-type-slider, .frame-type-textmedia, .frame-type-textpic, .frame-type-video {
        padding-bottom: 3.75rem;
        padding-top: 3.75rem
    }
}

.stagecontainer .frame-type-accordion, .stagecontainer .frame-type-form_formframework, .stagecontainer .frame-type-image_gallery, .stagecontainer .frame-type-list, .stagecontainer .frame-type-listteaser, .stagecontainer .frame-type-slider, .stagecontainer .frame-type-textmedia, .stagecontainer .frame-type-textpic, .stagecontainer .frame-type-video {
    padding-bottom: 0;
    padding-top: 0
}

.frame-type-heroeteaser + :not(.frame-type-heroeteaser):not(.frame-type-list) {
    padding-top: 1.25rem
}

@media (min-width: 48rem) {
    .frame-type-heroeteaser + :not(.frame-type-heroeteaser):not(.frame-type-list) {
        padding-top: 2.5rem
    }
}

@media (min-width: 64rem) {
    .frame-type-heroeteaser + :not(.frame-type-heroeteaser):not(.frame-type-list) {
        padding-top: 3.75rem
    }
}

.frame-layout-0:not(.frame-type-heroeteaser) + .frame-type-heroeteaser, .frame-layout-1:not(.frame-type-heroeteaser) + .frame-type-heroeteaser {
    padding-top: 1.25rem
}

@media (min-width: 48rem) {
    .frame-layout-0:not(.frame-type-heroeteaser) + .frame-type-heroeteaser, .frame-layout-1:not(.frame-type-heroeteaser) + .frame-type-heroeteaser {
        padding-top: 2.5rem
    }
}

@media (min-width: 64rem) {
    .frame-layout-0:not(.frame-type-heroeteaser) + .frame-type-heroeteaser, .frame-layout-1:not(.frame-type-heroeteaser) + .frame-type-heroeteaser {
        padding-top: 3.75rem
    }
}

.frame-type-image_gallery header, .frame-type-list header, .frame-type-listteaser header, .frame-type-table header, .frame-type-text header, .frame-type-textmedia header, .frame-type-textpic header {
    margin-bottom: 1.5rem
}

.frame-type-image_gallery header .header, .frame-type-image_gallery header .subheader, .frame-type-list header .header, .frame-type-list header .subheader, .frame-type-listteaser header .header, .frame-type-listteaser header .subheader, .frame-type-table header .header, .frame-type-table header .subheader, .frame-type-text header .header, .frame-type-text header .subheader, .frame-type-textmedia header .header, .frame-type-textmedia header .subheader, .frame-type-textpic header .header, .frame-type-textpic header .subheader {
    margin-bottom: 0;
    padding-bottom: 0
}

.breadcrumb-wrapper + .container .frame:first-child {
    padding-top: 0
}

.news-detail-header .headline {
    margin-bottom: 0
}

.accordion-content-text h2, .accordion-content-text h3, .accordion-content-text h4, .accordion-content-text h5, .accordion-content-text h6, .accordion-content-text ol, .accordion-content-text ul, .cke_editable h2, .cke_editable h3, .cke_editable h4, .cke_editable h5, .cke_editable h6, .cke_editable ol, .cke_editable ul, .frame-type-text h2, .frame-type-text h3, .frame-type-text h4, .frame-type-text h5, .frame-type-text h6, .frame-type-textmedia h2, .frame-type-textmedia h3, .frame-type-textmedia h4, .frame-type-textmedia h5, .frame-type-textmedia h6, .frame-type-textmedia ol, .frame-type-textmedia ul, .frame-type-text ol, .frame-type-textpic h2, .frame-type-textpic h3, .frame-type-textpic h4, .frame-type-textpic h5, .frame-type-textpic h6, .frame-type-textpic ol, .frame-type-textpic ul, .frame-type-text ul, .heroteaser-bodytext h2, .heroteaser-bodytext h3, .heroteaser-bodytext h4, .heroteaser-bodytext h5, .heroteaser-bodytext h6, .heroteaser-bodytext ol, .heroteaser-bodytext ul, .news-detail-bodytext h2, .news-detail-bodytext h3, .news-detail-bodytext h4, .news-detail-bodytext h5, .news-detail-bodytext h6, .news-detail-bodytext ol, .news-detail-bodytext ul {
    margin-bottom: .75rem;
    padding-bottom: 0
}

.accordion-content-text ol li, .accordion-content-text ul li, .cke_editable ol li, .cke_editable ul li, .frame-type-textmedia ol li, .frame-type-textmedia ul li, .frame-type-text ol li, .frame-type-textpic ol li, .frame-type-textpic ul li, .frame-type-text ul li, .heroteaser-bodytext ol li, .heroteaser-bodytext ul li, .news-detail-bodytext ol li, .news-detail-bodytext ul li {
    margin-bottom: 0;
    padding-bottom: 0
}

.accordion-content-text p, .cke_editable p, .frame-type-textmedia p, .frame-type-text p, .frame-type-textpic p, .heroteaser-bodytext p, .news-detail-bodytext p {
    margin-bottom: .75rem
}

.frame-type-accordion header {
    margin-bottom: 1.5rem
}

.frame-type-accordion header .header, .frame-type-accordion header .subheader {
    margin-bottom: 0;
    padding-bottom: 0
}

.accordion-item {
    padding-bottom: 1.875rem
}

.accordion-item:last-child {
    padding-bottom: 0
}

.accordion-item .accordion-header .toggle-icon {
    width: 1.5rem;
    height: 1.5rem;
    display: inline-block;
    position: absolute;
    -webkit-transition: -webkit-transform .3s ease;
    transition: -webkit-transform .3s ease;
    transition: transform .3s ease;
    transition: transform .3s ease, -webkit-transform .3s ease;
    cursor: pointer;
    left: 0;
    margin: 0;
    position: relative;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transition: -webkit-transform .2s ease;
    transition: -webkit-transform .2s ease;
    transition: transform .2s ease;
    transition: transform .2s ease, -webkit-transform .2s ease
}

@media (min-width: 75rem) {
    .accordion-item .accordion-header .toggle-icon {
        display: none
    }
}

.accordion-item .accordion-header .toggle-icon svg {
    width: 100%;
    height: 100%
}

.accordion-item .accordion-header .toggle-icon svg use {
    fill: #fff;
    -webkit-transition: fill .3s ease;
    transition: fill .3s ease
}

.accordion-item .accordion-header .toggle-icon:hover svg use {
    fill: #e20079
}

@media (min-width: 75rem) {
    .accordion-item .accordion-header .toggle-icon {
        display: inline-block
    }
}

.accordion-item .accordion-header .toggle-icon svg use {
    fill: #e20079
}

.accordion-item .accordion-header .accordion-title {
    padding-bottom: 0;
    position: relative
}

.accordion-item .accordion-header .accordion-title-link {
    font-family: lato_regular, Helvetica, sans-serif;
    font-weight: 400;
    line-height: 1.5rem;
    font-size: 1rem;
    letter-spacing: .0625rem;
    font-family: lato_bold, Helvetica, sans-serif;
    font-weight: 700;
    color: #e20079;
    margin-bottom: 0;
    -webkit-transition: color .3s;
    transition: color .3s;
    text-decoration: none;
    color: #000;
    position: relative;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    width: 100%
}

.accordion-item .accordion-header .accordion-title-link:hover {
    color: #000
}

@media (min-width: 48rem) {
    .accordion-item .accordion-header .accordion-title-link {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }
}

.accordion-item .accordion-header .accordion-title-link-text {
    display: block;
    padding-left: 1rem;
    -webkit-transition: color .3s ease;
    transition: color .3s ease;
    width: calc(100% - 24px)
}

.accordion-item .accordion-header .accordion-title-link-text:hover {
    color: #e20079
}

.accordion-item .accordion-body {
    padding-left: 2.5rem;
    max-height: 0;
    -webkit-transition: max-height .3s cubic-bezier(0, 1.05, 0, 1), opacity .3s;
    transition: max-height .3s cubic-bezier(0, 1.05, 0, 1), opacity .3s;
    overflow: hidden;
    opacity: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    background: #fff;
    -webkit-transition-delay: .3s;
    transition-delay: .3s
}

.accordion-item .accordion-body .accordion-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column wrap;
    flex-flow: column wrap;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    width: 100%
}

@media (min-width: 36rem) {
    .accordion-item .accordion-body .accordion-content {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap
    }
}

.accordion-item .accordion-body .accordion-content .accordion-content-media {
    width: 100%;
    min-width: unset;
    max-width: unset;
    margin: 0 0 1.25rem
}

@media (min-width: 36rem) {
    .accordion-item .accordion-body .accordion-content .accordion-content-media {
        min-width: 30%;
        max-width: 30%;
        margin: 0 1.25rem 0 0
    }
}

.accordion-item .accordion-body .accordion-content .accordion-content-media img {
    width: 100%;
    height: auto
}

.accordion-item.active .toggle-icon {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
}

.accordion-item.active .accordion-title-link-text {
    color: #e20079
}

.accordion-item.active .accordion-body {
    max-height: none;
    opacity: 1;
    -webkit-transition: max-height .3s cubic-bezier(1, 0, 1, 0), opacity .3s;
    transition: max-height .3s cubic-bezier(1, 0, 1, 0), opacity .3s
}

.breadcrumb {
    display: none;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

@media (min-width: 48rem) {
    .breadcrumb {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex
    }
}

.breadcrumb-wrapper {
    max-width: 1440px;
    height: inherit;
    margin: 0 auto;
    position: relative;
    padding: 1.875rem 1.5rem
}

@media (min-width: 62rem) {
    .breadcrumb-wrapper {
        padding: 1.875rem 4.861%
    }
}

@media (min-width: 90rem) {
    .breadcrumb-wrapper {
        padding: 1.875rem 4.375rem
    }
}

.breadcrumb-divider {
    width: 1rem;
    height: 1rem
}

.breadcrumb-divider path {
    fill: #000
}

.breadcrumb li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-family: lato_bold, Helvetica, sans-serif;
    font-weight: 700;
    font-size: .8125rem;
    line-height: 1rem;
    color: #000;
    letter-spacing: .0625rem;
    -webkit-transition: color .3s ease;
    transition: color .3s ease
}

.breadcrumb li:hover {
    color: #e20079
}

.breadcrumb li.current {
    color: #656565;
    font-family: lato_regular, Helvetica, sans-serif;
    font-weight: 400
}

.breadcrumb li:not(:last-of-type) {
    margin-right: .3125rem
}

.frame-type-list {
    padding-top: 0
}

.tx-px-contact-finder .contact-search-wrapper {
    margin-bottom: 5rem
}

.tx-px-contact-finder .contact-search-form {
    max-width: none
}

.tx-px-contact-finder .contact-search-content-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column wrap;
    flex-flow: column wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    -webkit-box-pack: left;
    -ms-flex-pack: left;
    justify-content: left
}

@media (min-width: 62rem) {
    .tx-px-contact-finder .contact-search-content-wrapper {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap
    }
}

.tx-px-contact-finder .contact-search-content-wrapper .form-label {
    padding-right: 0;
    margin-bottom: 1.25rem
}

@media (min-width: 62rem) {
    .tx-px-contact-finder .contact-search-content-wrapper .form-label {
        margin-bottom: 0;
        padding-right: 1.25rem
    }
}

.tx-px-contact-finder .contact-search-content-wrapper input {
    min-width: 12.5rem
}

.tx-px-contact-finder .contact-search-content-wrapper input.contact-form-submit {
    margin: 1.25rem 0 0
}

@media (min-width: 36rem) {
    .tx-px-contact-finder .contact-search-content-wrapper input.contact-form-submit {
        margin: 0 0 0 1.25rem
    }
}

.tx-px-contact-finder .contact-search-content-wrapper .search-button-input-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column wrap;
    flex-flow: column wrap;
    width: 100%
}

@media (min-width: 36rem) {
    .tx-px-contact-finder .contact-search-content-wrapper .search-button-input-wrapper {
        width: auto;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap
    }
}

.tx-px-contact-finder .contact-search-content-wrapper .search-button-input-wrapper .btn-primary {
    display: block
}

.tx-px-contact-finder .contact-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap
}

@media (min-width: 62rem) {
    .tx-px-contact-finder .contact-wrapper {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap
    }
}

.tx-px-contact-finder .contact-wrapper .contact-image {
    width: 100%
}

@media (min-width: 62rem) {
    .tx-px-contact-finder .contact-wrapper .contact-image {
        width: 50%;
        min-width: 32.9375rem
    }
}

.tx-px-contact-finder .contact-wrapper .contact-information {
    background: #f5f5f5;
    padding: 3.125rem 8.33333%;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column wrap;
    flex-flow: column wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

@media (min-width: 62rem) {
    .tx-px-contact-finder .contact-wrapper .contact-information {
        width: 50%;
        padding: 1.5rem 8.33333% 1.5rem 1.5rem
    }
}

@media (min-width: 75rem) {
    .tx-px-contact-finder .contact-wrapper .contact-information {
        width: 41.66666666666667%
    }
}

.tx-px-contact-finder .contact-wrapper .contact-information .contact-information-block {
    margin-bottom: 1.5rem
}

@media (min-width: 62rem) {
    .tx-px-contact-finder .contact-wrapper .contact-information .contact-information-block {
        margin-bottom: 0
    }
}

.tx-px-contact-finder .contact-wrapper .contact-information .btn-primary {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.tx-px-contact-finder .contact-wrapper .contact-information .contact-person-name {
    margin-bottom: 0
}

.tx-px-contact-finder .contact-wrapper .contact-information span {
    font-size: .875rem;
    line-height: 1.5rem
}

.tx-px-contact-finder .contact-wrapper .contact-information .label {
    font-family: lato_bold, Helvetica, sans-serif;
    font-weight: 700;
    width: 6.25rem;
    display: inline-block
}

.donations-grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 -.625rem
}

.donations-grid-item {
    margin: 0 .625rem;
    width: calc(100% - 20px);
    padding-bottom: 2.5rem
}

@media (min-width: 48rem) {
    .donations-grid-item {
        width: calc(50% - 20px)
    }
}

@media (min-width: 62rem) {
    .donations-grid-item {
        width: calc(33.33333% - 20px)
    }
}

.donations-grid-item-label {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column wrap;
    flex-flow: column wrap;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    cursor: pointer;
    height: 100%
}

.donations-grid-item-label:after, .donations-grid-item-label:before {
    display: none
}

.donations-grid-item-label-inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: .5rem
}

.donations-grid-item-input {
    display: none
}

.donations-grid-item-input:checked ~ label .donations-selected-icon {
    background: #e20079;
    -webkit-box-shadow: inset 0 0 0 .0625rem #e20079;
    box-shadow: inset 0 0 0 .0625rem #e20079
}

.donations-grid-item-input:checked ~ label .donations-selected-icon svg {
    opacity: 1;
    -webkit-transform: translateY(-125%) translateX(-50%);
    transform: translateY(-125%) translateX(-50%)
}

.donations-grid-item-input:checked ~ label .donations-selected-text-not-selected {
    opacity: 0;
    visibility: visible
}

.donations-grid-item-input:checked ~ label .donations-selected-text-selected {
    color: #e20079;
    visibility: visible;
    opacity: 1
}

.donations-headline {
    margin: 0
}

.donations-donation-count {
    background-color: #e20079;
    padding: .625rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-family: lato_black, Helvetica, sans-serif;
    font-weight: 900;
    color: #fff;
    line-height: 1.5;
    font-size: 1rem;
    letter-spacing: .125rem;
    text-transform: uppercase;
    margin: 0
}

.donations-description {
    font-family: lato_regular, Helvetica, sans-serif;
    font-weight: 400;
    color: #000;
    line-height: 1.5rem;
    font-size: 1rem;
    letter-spacing: .0625rem;
    padding: 1.25rem;
    display: inline-block;
    background-color: #f5f5f5;
    margin-bottom: 0
}

.donations-selected {
    position: relative;
    height: 1.5rem;
    width: 100%;
    -ms-flex-item-align: end;
    align-self: flex-end
}

.donations-selected-icon {
    width: 1.5rem;
    height: 1.5rem;
    background-color: #d7d7d7;
    display: inline-block;
    -webkit-box-shadow: inset 0 0 0 .0625rem #bdbdbd;
    box-shadow: inset 0 0 0 .0625rem #bdbdbd;
    margin-right: .625rem;
    -webkit-transition: background-color .3s, -webkit-box-shadow .3s;
    transition: background-color .3s, -webkit-box-shadow .3s;
    transition: background-color .3s, box-shadow .3s;
    transition: background-color .3s, box-shadow .3s, -webkit-box-shadow .3s;
    position: relative;
    -ms-flex-negative: 0;
    flex-shrink: 0
}

.donations-selected-icon svg {
    width: 1rem;
    height: 1rem;
    fill: #fff;
    opacity: 0;
    -webkit-transition: opacity .3s, -webkit-transform .3s;
    transition: opacity .3s, -webkit-transform .3s;
    transition: opacity .3s, transform .3s;
    transition: opacity .3s, transform .3s, -webkit-transform .3s;
    position: absolute;
    top: 100%;
    left: 50%;
    -webkit-transform: translateY(0) translateX(-50%);
    transform: translateY(0) translateX(-50%)
}

.donations-selected-text {
    -webkit-transition: opacity .3s;
    transition: opacity .3s;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 2.125rem;
    font-family: lato_regular, Helvetica, sans-serif;
    font-weight: 400;
    color: #000;
    line-height: 1.5rem;
    margin-bottom: 1.5rem;
    font-size: .875rem;
    font-family: lato_bold, Helvetica, sans-serif;
    font-weight: 700;
    padding: 0;
    letter-spacing: .0625rem
}

.donations-selected-text-wrapper {
    height: 100%;
    display: inline-block
}

.donations-selected-text-not-selected {
    color: #bdbdbd
}

.donations-selected-text-selected {
    visibility: hidden;
    opacity: 0
}

.donations-image {
    background-color: #f5f5f5;
    height: 13.25rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    overflow: hidden
}

.donations-image img {
    height: 100%;
    width: auto;
    max-height: 13.25rem
}

.frame-type-slider {
    position: relative;
    padding-bottom: .75rem
}

.main-carousel {
    overflow: hidden;
    position: relative
}

.main-carousel:focus {
    outline: none
}

.three-colored-border {
    background: #009ee0;
    height: 1rem;
    width: 100%;
    position: relative;
    position: absolute;
    bottom: 0
}

.three-colored-border:before {
    left: 0;
    background: #003378
}

.three-colored-border:after, .three-colored-border:before {
    content: "";
    width: 33%;
    height: 1rem;
    display: block;
    position: absolute;
    bottom: 0;
    z-index: 1
}

.three-colored-border:after {
    right: 0;
    background: #e20079
}

.flickity-slider {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: static;
    width: 100%
}

.flickity-slider img, .slider-single-image {
    width: 100%;
    height: auto
}

.carousel-cell {
    width: 100%;
    cursor: -webkit-grab;
    cursor: grab;
    background-color: transparent !important;
    position: static !important;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    margin-right: 1.25rem
}

.flickity-prev-next-button {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 2.5rem;
    height: 2.5rem;
    display: none;
    background: #e20079;
    -webkit-transition: background .3s ease;
    transition: background .3s ease
}

.flickity-prev-next-button:hover {
    background: #ff49aa
}

@media (min-width: 48rem) {
    .flickity-prev-next-button {
        display: block
    }
}

.flickity-prev-next-button.previous {
    left: 1.25rem
}

.flickity-prev-next-button.next {
    right: 1.25rem
}

.flickity-prev-next-button[disabled] {
    opacity: .3;
    cursor: default
}

.flickity-prev-next-button .flickity-button-icon {
    width: 1rem;
    height: 1rem;
    display: inline-block;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%)
}

.flickity-prev-next-button .flickity-button-icon path {
    width: 100%;
    height: 100%;
    fill: #fff;
    -webkit-transition: fill .3s;
    transition: fill .3s
}

.flickity-page-dots {
    position: absolute;
    left: 50%;
    -webkit-transform: translate(-50%, 50%);
    transform: translate(-50%, 50%);
    bottom: 2.5rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.flickity-page-dots li {
    height: .5rem;
    width: .5rem;
    border-radius: 50%;
    background: #d7d7d7;
    cursor: pointer
}

.flickity-page-dots li.is-selected {
    background: #e20079
}

.flickity-page-dots li:not(:last-of-type) {
    margin-right: .625rem
}

.footer-navigation {
    display: block
}

@media (min-width: 75rem) {
    .footer-navigation {
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr;
        row-gap: 3.75rem;
        -webkit-column-gap: 1.25rem;
        -moz-column-gap: 1.25rem;
        column-gap: 1.25rem
    }
}

.footer-navigation .footer-navigation-item.first-level {
    font-family: lato_black, Helvetica, sans-serif;
    font-weight: 900;
    text-transform: none;
    color: #fff;
    font-size: 1.125rem;
    letter-spacing: .125rem;
    text-align: center;
    margin-bottom: 2.5rem
}

.footer-navigation .footer-navigation-item.first-level .link-wrapper {
    cursor: pointer
}

.footer-navigation .footer-navigation-item.first-level .link-wrapper > a {
    -webkit-transition: color .3s;
    transition: color .3s
}

.footer-navigation .footer-navigation-item.first-level .link-wrapper > a.mobile-only {
    display: inline-block
}

@media (min-width: 75rem) {
    .footer-navigation .footer-navigation-item.first-level .link-wrapper > a.mobile-only {
        display: none
    }
}

.footer-navigation .footer-navigation-item.first-level .link-wrapper > a.large-only {
    display: none
}

@media (min-width: 75rem) {
    .footer-navigation .footer-navigation-item.first-level .link-wrapper > a.large-only {
        display: inline-block
    }
}

.footer-navigation .footer-navigation-item.first-level .link-wrapper:hover > a {
    color: #e20079
}

.footer-navigation .footer-navigation-item.first-level .link-wrapper:hover .toggle-icon svg use {
    fill: #e20079
}

@media (min-width: 75rem) {
    .footer-navigation .footer-navigation-item.first-level {
        text-align: left
    }
}

@media (-ms-high-contrast: none) and (min-width: 75rem) {
    .footer-navigation .footer-navigation-item.first-level {
        max-width: 13.375rem
    }

    .footer-navigation .footer-navigation-item.first-level:first-of-type {
        -ms-grid-column: 1;
        -ms-grid-row: 1
    }

    .footer-navigation .footer-navigation-item.first-level:nth-of-type(2) {
        -ms-grid-column: 2;
        -ms-grid-row: 1
    }

    .footer-navigation .footer-navigation-item.first-level:nth-of-type(3) {
        -ms-grid-column: 3;
        -ms-grid-row: 1
    }

    .footer-navigation .footer-navigation-item.first-level:nth-of-type(4) {
        -ms-grid-column: 1;
        -ms-grid-row: 2
    }

    .footer-navigation .footer-navigation-item.first-level:nth-of-type(5) {
        -ms-grid-column: 2;
        -ms-grid-row: 2
    }

    .footer-navigation .footer-navigation-item.first-level:nth-of-type(6) {
        -ms-grid-column: 3;
        -ms-grid-row: 2
    }
}

.footer-navigation .footer-navigation-item.first-level.open .footer-navigation.level-2 {
    max-height: 50rem;
    -webkit-transition: max-height .3s ease-in;
    transition: max-height .3s ease-in
}

.footer-navigation .footer-navigation-item.first-level.open .toggle-icon {
    -webkit-transform: rotateX(-180deg);
    transform: rotateX(-180deg)
}

.footer-navigation .footer-navigation-item.first-level .toggle-icon {
    width: 1.5rem;
    height: 1.5rem;
    display: inline-block;
    position: absolute;
    margin-left: .625rem;
    -webkit-transition: -webkit-transform .3s;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    cursor: pointer
}

@media (min-width: 75rem) {
    .footer-navigation .footer-navigation-item.first-level .toggle-icon {
        display: none
    }
}

.footer-navigation .footer-navigation-item.first-level .toggle-icon svg {
    width: 100%;
    height: 100%
}

.footer-navigation .footer-navigation-item.first-level .toggle-icon svg use {
    fill: #fff;
    -webkit-transition: fill .3s;
    transition: fill .3s
}

.footer-navigation .footer-navigation-item.first-level .toggle-icon:hover svg use {
    fill: #e20079
}

.footer-navigation .footer-navigation.level-2 {
    display: block;
    overflow: hidden;
    max-height: 0;
    -webkit-transition: max-height .3s ease-out;
    transition: max-height .3s ease-out
}

@media (min-width: 75rem) {
    .footer-navigation .footer-navigation.level-2 {
        max-height: none;
        margin-top: 1.5rem
    }
}

.footer-navigation .footer-navigation.level-2 .footer-navigation-item {
    font-family: lato_bold, Helvetica, sans-serif;
    font-weight: 700;
    font-size: .8125rem;
    line-height: 1.375rem;
    width: 100%
}

.footer-navigation .footer-navigation.level-2 .footer-navigation-item:first-of-type {
    margin-top: 1.25rem
}

@media (min-width: 75rem) {
    .footer-navigation .footer-navigation.level-2 .footer-navigation-item:first-of-type {
        margin-top: 0
    }
}

.footer-navigation .footer-navigation.level-2 .footer-navigation-item:not(:last-of-type) {
    margin-bottom: .875rem
}

.footer-navigation .footer-navigation.level-2 .footer-navigation-item > a {
    -webkit-transition: color .3s;
    transition: color .3s
}

.footer-navigation .footer-navigation.level-2 .footer-navigation-item > a:hover {
    color: #e20079
}

.footer {
    background-color: #102036
}

.footer .content-wrapper {
    max-width: 1440px;
    height: inherit;
    margin: 0 auto;
    position: relative;
    padding: 2.5rem 1.875rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column wrap;
    flex-flow: column wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

@media (min-width: 62rem) {
    .footer .content-wrapper {
        padding: 3.75rem 4.375rem;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start
    }
}

.footer-left {
    width: 100%;
    padding-bottom: 3.75rem
}

@media (min-width: 62rem) {
    .footer-left {
        width: 33%;
        padding: 0
    }
}

.footer-left .link-container {
    width: 100%;
    text-align: center;
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2
}

@media (min-width: 62rem) {
    .footer-left .link-container {
        width: auto;
        text-align: left;
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1
    }
}

.footer-left .link-container ul li {
    font-family: lato_regular, Helvetica, sans-serif;
    font-weight: 400;
    color: #000;
    line-height: 1.5rem;
    font-size: 1rem;
    letter-spacing: .0625rem;
    font-size: .8125rem;
    line-height: 1.375rem;
    letter-spacing: .125rem;
    margin-bottom: .5rem
}

.footer-left .link-container ul li a {
    font-family: lato_bold, Helvetica, sans-serif;
    font-weight: 700;
    color: #fff;
    -webkit-transition: color .3s;
    transition: color .3s
}

.footer-left .link-container ul li a:hover {
    color: #e20079
}

.footer-left .link-container ul li:last-child {
    margin-bottom: 0
}

.footer-left .link-container .za-link {
    background-color: #e20079;
    text-transform: uppercase;
    font-family: lato_black, Helvetica, sans-serif;
    font-weight: 900;
    color: #000;
    line-height: 1.5;
    font-size: 1rem;
    color: #fff;
    -webkit-transition: background-color .3s;
    transition: background-color .3s;
    margin: 1.875rem 0 0;
    display: inline-block;
    padding: 0;
    letter-spacing: .125rem
}

.footer-left .link-container .za-link:hover {
    background-color: #ff49aa
}

.footer-left .link-container .za-link a {
    display: inline-block;
    padding: .75rem 1.5rem
}

.footer-right {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column wrap;
    flex-flow: column wrap;
    max-width: 33.125rem
}

@media (min-width: 62rem) {
    .footer-right {
        padding: 0;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start
    }
}

.footer-right .social-container {
    width: 100%;
    text-align: center
}

@media (min-width: 62rem) {
    .footer-right .social-container {
        width: auto;
        text-align: left
    }
}

.footer-right .social-container h5 {
    color: #fff;
    letter-spacing: .125rem
}

.footer-right .social-container .social-media-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 1.5rem;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

@media (min-width: 62rem) {
    .footer-right .social-container .social-media-list {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start
    }
}

.footer-right .social-container .social-media-list li {
    cursor: pointer
}

.footer-right .social-container .social-media-list li:not(:last-of-type) {
    margin-right: 1.875rem
}

.footer-right .social-container .social-media-list li:hover svg use {
    fill: #e20079
}

.footer-right .social-container .social-media-list li svg {
    width: 1rem;
    height: 1rem
}

.footer-right .social-container .social-media-list li svg use {
    fill: #fff;
    -webkit-transition: fill .3s;
    transition: fill .3s
}

.footer-right .newsletter {
    max-width: 31.25rem;
    text-align: center;
    margin-bottom: 3.75rem
}

@media (min-width: 62rem) {
    .footer-right .newsletter {
        max-width: none;
        margin-bottom: 5rem;
        text-align: left
    }
}

.footer-right .newsletter h4, .footer-right .newsletter p {
    color: #fff;
    letter-spacing: .125rem;
    margin-bottom: 1.5rem
}

.footer-right .newsletter p {
    font-family: lato_bold, Helvetica, sans-serif;
    font-weight: 700;
    font-size: .8125rem
}

.footer-right .newsletter-input-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

@media (min-width: 62rem) {
    .footer-right .newsletter-input-wrapper :first-child {
        width: calc(100% - 220px)
    }
}

.footer-right .newsletter-input-wrapper :last-child {
    margin-top: .625rem
}

@media (min-width: 62rem) {
    .footer-right .newsletter-input-wrapper :last-child {
        width: 13.75rem;
        margin-top: 0
    }
}

.footer-right .newsletter-input {
    background-color: #334153;
    color: #fff;
    font-size: .8125rem;
    padding: .875rem;
    border: .125rem solid #334153;
    -webkit-transition: border .3s;
    transition: border .3s
}

.footer-right .newsletter-input:active, .footer-right .newsletter-input:focus {
    border: .125rem solid #fff
}

.footer-right .newsletter-input::-webkit-input-placeholder {
    font-family: lato_italic, Helvetica, sans-serif;
    font-style: italic;
    font-weight: 400;
    color: #fff;
    letter-spacing: .21875rem;
    opacity: .8
}

.footer-right .newsletter-input::-moz-placeholder {
    font-family: lato_italic, Helvetica, sans-serif;
    font-style: italic;
    font-weight: 400;
    color: #fff;
    letter-spacing: .21875rem;
    opacity: .8
}

.footer-right .newsletter-input:-ms-input-placeholder {
    font-family: lato_italic, Helvetica, sans-serif;
    font-style: italic;
    font-weight: 400;
    color: #fff;
    letter-spacing: .21875rem;
    opacity: .8
}

.footer-right .newsletter-input:-moz-placeholder {
    font-family: lato_italic, Helvetica, sans-serif;
    font-style: italic;
    font-weight: 400;
    color: #fff;
    letter-spacing: .21875rem;
    opacity: .8
}

.footer-right .newsletter-submit {
    background-color: #e20079;
    text-transform: uppercase;
    cursor: pointer;
    font-family: lato_black, Helvetica, sans-serif;
    font-weight: 900;
    color: #000;
    line-height: 1.5;
    font-size: 1rem;
    color: #fff;
    -webkit-transition: background-color .3s;
    transition: background-color .3s;
    margin: 1.875rem 0 0;
    display: inline-block;
    padding: .75rem 0;
    letter-spacing: .125rem
}

.footer-right .newsletter-submit:hover {
    background-color: #ff49aa
}

.footer-right.full {
    margin: 0 auto;
    padding: 3.75rem 1rem
}

.footer-right.full h4, .footer-right.full p {
    color: #000
}

.footer-right.full .newsletter-input:active, .footer-right.full .newsletter-input:focus {
    border: .125rem solid #334153
}

.footer-right.full .newsletter p {
    font-family: lato_regular, Helvetica, sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: .0625rem
}

.footer-lower {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #0b1729;
    padding: 1.5rem 1.875rem;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.footer-lower .footer-copyright-container {
    color: #fff;
    font-family: lato_bold, Helvetica, sans-serif;
    font-weight: 700;
    font-size: .6875rem;
    letter-spacing: .1875rem;
    width: 100%;
    text-align: center
}

@media (min-width: 62rem) {
    .footer-lower .footer-copyright-container {
        width: auto;
        text-align: left
    }
}

.newsletter-privacy {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 1.25rem
}

.newsletter-privacy .newsletter-privacy-link {
    padding: 0 .3125rem;
    font-family: lato_bold, Helvetica, sans-serif;
    font-weight: 700;
    -webkit-transition: color .3s;
    transition: color .3s
}

.newsletter-privacy .newsletter-privacy-link:hover {
    color: #e20079
}

form[id*=rechnungskopie-] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap
}

@media (min-width: 62rem) {
    form[id*=rechnungskopie-] {
        max-width: calc(640px + 33%);
        padding-right: 33%
    }
}

form[id*=rechnungskopie-] .radio-label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

form[id*=rechnungskopie-] .radio-label:not(:last-of-type) {
    margin-right: 4.375rem
}

form[id*=rechnungskopie-] h2 {
    margin-bottom: 0
}

form[id*=rechnungskopie-] h2 + p:last-child:last-child:not(class) {
    margin-bottom: 1.875rem
}

form[id*=rechnungskopie-] > div:first-of-type {
    display: none
}

form[id*=rechnungskopie-] .row {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 100%;
    flex: 1 0 100%;
    margin-bottom: 0;
    max-width: 100%
}

@media (min-width: 62rem) {
    form[id*=rechnungskopie-] .row {
        -webkit-box-flex: 1;
        -ms-flex: 1 0 33%;
        flex: 1 0 33%
    }
}

form[id*=rechnungskopie-] .row div > .form-group {
    padding-bottom: 1.25rem
}

form[id*=rechnungskopie-] .row div .label-straße.type-text {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 60%;
    flex: 1 0 60%
}

form[id*=rechnungskopie-] .row div .label-plz.type-number, form[id*=rechnungskopie-] .row div .label-straße.type-text + .type-text {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%
}

form[id*=rechnungskopie-] .row div .label-plz.type-number + .label-ort.type-text {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 60%;
    flex: 1 0 60%
}

form[id*=rechnungskopie-] .row .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

form[id*=rechnungskopie-] .row ~ .row div:nth-of-type(4) .row div:first-of-type {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 60%;
    flex: 1 0 60%
}

form[id*=rechnungskopie-] .row ~ .row div:nth-of-type(4) .row div:last-of-type, form[id*=rechnungskopie-] .row ~ .row div:nth-of-type(5) .row div:first-of-type {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%
}

form[id*=rechnungskopie-] .row ~ .row div:nth-of-type(5) .row div:last-of-type {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 60%;
    flex: 1 0 60%
}

@media (min-width: 62rem) {
    form[id*=rechnungskopie-] .row ~ .row {
        margin-left: 8.125rem
    }
}

form[id*=rechnungskopie-] .row .col-lg-1, form[id*=rechnungskopie-] .row .col-md-1, form[id*=rechnungskopie-] .row .col-sm-1, form[id*=rechnungskopie-] .row .col-xs-1 {
    position: relative
}

form[id*=rechnungskopie-] .row .label-art.type-radiobutton, form[id*=rechnungskopie-] .row .label-geburtstag.type-date, form[id*=rechnungskopie-] .row .label-vorname.type-text, form[id*=rechnungskopie-] .row .type-gridrow {
    width: 100%;
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 0 100% !important;
    flex: 1 0 100% !important;
    margin-right: 0;
    z-index: 1
}

form[id*=rechnungskopie-] .row .label-art.type-radiobutton + .label-content.type-contentelement, form[id*=rechnungskopie-] .row .label-geburtstag.type-date + .label-content.type-contentelement, form[id*=rechnungskopie-] .row .label-vorname.type-text + .label-content.type-contentelement, form[id*=rechnungskopie-] .row .type-gridrow + .label-content.type-contentelement {
    width: 100%
}

@media (min-width: 62rem) {
    form[id*=rechnungskopie-] .row .label-art.type-radiobutton + .label-content.type-contentelement, form[id*=rechnungskopie-] .row .label-geburtstag.type-date + .label-content.type-contentelement, form[id*=rechnungskopie-] .row .label-vorname.type-text + .label-content.type-contentelement, form[id*=rechnungskopie-] .row .type-gridrow + .label-content.type-contentelement {
        position: absolute;
        height: 100%;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%)
    }
}

@media (min-width: 62rem) {
    form[id*=rechnungskopie-] .row .label-art.type-radiobutton + .label-contentelement.type-contentelement .frame-type-accordion {
        top: .3125rem
    }
}

form[id*=rechnungskopie-] .actions {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%
}

form[id*=rechnungskopie-] button[type=submit] {
    width: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

form[id*=rechnungskopie-] input[type=checkbox] + span {
    font-family: lato_regular, Helvetica, sans-serif;
    font-weight: 400;
    color: #929292;
    margin-bottom: 1.5rem;
    font-size: .75rem;
    letter-spacing: .0625rem;
    line-height: 1.5rem
}

form[id*=rechnungskopie-] input[type=date].hidden {
    display: none
}

form[id*=rechnungskopie-] p {
    font-family: lato_regular, Helvetica, sans-serif;
    font-weight: 400;
    color: #929292;
    margin-bottom: 1.5rem;
    font-size: .75rem;
    letter-spacing: .0625rem;
    line-height: 1.5rem;
    margin-top: .9375rem
}

form[id*=rechnungskopie-] .frame-type-accordion {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    z-index: 1
}

@media (min-width: 62rem) {
    form[id*=rechnungskopie-] .frame-type-accordion {
        width: 50%;
        padding: 0;
        position: absolute;
        left: calc(100% + 20px);
        top: 2.5rem
    }
}

form[id*=rechnungskopie-] .frame-type-accordion .accordion {
    padding: 0
}

form[id*=rechnungskopie-] .frame-type-accordion .accordion-item .accordion-body {
    overflow: hidden
}

form[id*=rechnungskopie-] .frame-type-accordion .accordion-item.active .accordion-body {
    overflow: visible
}

form[id*=rechnungskopie-] .frame-type-accordion .accordion .toggle-icon {
    -webkit-transform: none !important;
    transform: none !important
}

form[id*=rechnungskopie-] .frame-type-accordion .accordion .toggle-icon svg {
    display: none
}

form[id*=rechnungskopie-] .frame-type-accordion .accordion .toggle-icon:before {
    content: "?";
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #f1f1f1;
    color: #656565;
    width: 1.5rem;
    height: 1.5rem;
    top: 0;
    left: 0
}

form[id*=rechnungskopie-] .frame-type-accordion .accordion-body {
    background-color: transparent;
    padding-left: 0;
    -webkit-transition: none;
    transition: none
}

@media (min-width: 62rem) {
    form[id*=rechnungskopie-] .frame-type-accordion .accordion-body {
        margin-top: -2.5rem;
        padding-left: 2.5rem;
        -webkit-transition: opacity .3s;
        transition: opacity .3s
    }
}

form[id*=rechnungskopie-] .frame-type-accordion .accordion-content {
    padding: 1.25rem;
    -webkit-box-shadow: 0 .625rem 2.5rem 0 rgba(0, 51, 120, .1);
    box-shadow: 0 .625rem 2.5rem 0 rgba(0, 51, 120, .1);
    background-color: #fff
}

form[id*=rechnungskopie-] .frame-type-accordion .accordion-body, form[id*=rechnungskopie-] .frame-type-accordion .accordion-content {
    overflow: visible;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

form[id*=rechnungskopie-] .frame-type-accordion .accordion-body-media, form[id*=rechnungskopie-] .frame-type-accordion .accordion-content-media {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    max-width: none !important;
    width: 100%;
    margin-right: 0 !important;
    margin-top: 1.25rem !important
}

form[id*=rechnungskopie-] .frame-type-accordion .accordion-body-text, form[id*=rechnungskopie-] .frame-type-accordion .accordion-content-text {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1
}

form[id*=rechnungskopie-] .frame-type-accordion .accordion-title-link-text {
    display: none
}

form[id*=karriere-] div:nth-of-type(6) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap
}

@media (min-width: 36rem) {
    form[id*=karriere-] div:nth-of-type(6) {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap
    }
}

form[id*=karriere-] div:nth-of-type(6) div:first-of-type {
    -webkit-box-flex: 100%;
    -ms-flex: 100%;
    flex: 100%;
    margin: 0
}

@media (min-width: 36rem) {
    form[id*=karriere-] div:nth-of-type(6) div:first-of-type {
        -webkit-box-flex: 1;
        -ms-flex: 1 0 60%;
        flex: 1 0 60%
    }
}

form[id*=karriere-] div:nth-of-type(6) div:first-of-type .form-group {
    margin: 0 0 1.5rem
}

@media (min-width: 36rem) {
    form[id*=karriere-] div:nth-of-type(6) div:first-of-type .form-group {
        margin: 0 .625rem 0 0
    }
}

form[id*=karriere-] div:nth-of-type(6) div:last-of-type {
    -webkit-box-flex: 100%;
    -ms-flex: 100%;
    flex: 100%
}

@media (min-width: 36rem) {
    form[id*=karriere-] div:nth-of-type(6) div:last-of-type {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%
    }
}

form[id*=karriere-] div:nth-of-type(7) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap
}

@media (min-width: 36rem) {
    form[id*=karriere-] div:nth-of-type(7) {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap
    }
}

form[id*=karriere-] div:nth-of-type(7) div:first-of-type {
    -webkit-box-flex: 100%;
    -ms-flex: 100%;
    flex: 100%;
    margin: 0
}

@media (min-width: 36rem) {
    form[id*=karriere-] div:nth-of-type(7) div:first-of-type {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%
    }
}

form[id*=karriere-] div:nth-of-type(7) div:first-of-type .form-group {
    margin: 0 0 1.5rem
}

@media (min-width: 36rem) {
    form[id*=karriere-] div:nth-of-type(7) div:first-of-type .form-group {
        margin: 0 .625rem 0 0
    }
}

form[id*=karriere-] div:nth-of-type(7) div:last-of-type {
    -webkit-box-flex: 100%;
    -ms-flex: 100%;
    flex: 100%
}

@media (min-width: 36rem) {
    form[id*=karriere-] div:nth-of-type(7) div:last-of-type {
        -webkit-box-flex: 1;
        -ms-flex: 1 0 60%;
        flex: 1 0 60%
    }
}

form[id*=donations-] .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 100%;
    flex: 1 0 100%;
    margin-bottom: 0;
    max-width: 100%
}

@media (min-width: 62rem) {
    form[id*=donations-] .row {
        -webkit-box-flex: 1;
        -ms-flex: 1 0 33%;
        flex: 1 0 33%
    }
}

form[id*=donations-] .row div > .form-group {
    padding-bottom: 1.25rem
}

form[id*=donations-] .row div.label-straße.type-text {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 60%;
    flex: 1 0 60%
}

form[id*=donations-] .row div.label-plz.type-number, form[id*=donations-] .row div.label-straße.type-text + .type-text {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%
}

form[id*=donations-] .row div.label-plz.type-number + .label-ort.type-text {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 60%;
    flex: 1 0 60%
}

form[id*=rewards-] {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    max-width: 60rem;
    margin-left: 0
}

form[id*=rewards-], form[id*=rewards-] .radio-label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

form[id*=rewards-] .radio-label {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

form[id*=rewards-] .radio-label:not(:last-of-type) {
    margin-right: 4.375rem
}

form[id*=rewards-] > div:first-of-type {
    display: none
}

form[id*=rewards-] .row {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 100%;
    flex: 1 0 100%
}

@media (min-width: 62rem) {
    form[id*=rewards-] .row {
        -webkit-box-flex: 1;
        -ms-flex: 1 0 33%;
        flex: 1 0 33%
    }
}

form[id*=rewards-] .row div > .form-group {
    padding-bottom: 1.25rem
}

form[id*=rewards-] .row div:nth-of-type(7) .row div:first-of-type {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 60%;
    flex: 1 0 60%
}

form[id*=rewards-] .row div:nth-of-type(7) .row div:last-of-type, form[id*=rewards-] .row div:nth-of-type(8) .row div:first-of-type {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%
}

form[id*=rewards-] .row div:nth-of-type(8) .row div:last-of-type {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 60%;
    flex: 1 0 60%
}

form[id*=rewards-] .row .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

form[id*=rewards-] .row ~ .row div:nth-of-type(6) .row div:first-of-type {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 60%;
    flex: 1 0 60%
}

form[id*=rewards-] .row ~ .row div:nth-of-type(6) .row div:last-of-type, form[id*=rewards-] .row ~ .row div:nth-of-type(7) .row div:first-of-type {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%
}

form[id*=rewards-] .row ~ .row div:nth-of-type(7) .row div:last-of-type {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 60%;
    flex: 1 0 60%
}

@media (min-width: 62rem) {
    form[id*=rewards-] .row ~ .row {
        margin-left: 8.125rem
    }
}

form[id*=rewards-] .actions {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%
}

form[id*=rewards-] button[type=submit] {
    width: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: 1.875rem
}

form[id*=rewards-] input[type=checkbox] + span, form[id*=rewards-] p {
    font-family: lato_regular, Helvetica, sans-serif;
    font-weight: 400;
    color: #929292;
    margin-bottom: 1.5rem;
    font-size: .75rem;
    letter-spacing: .0625rem;
    line-height: 1.5rem
}

form[id*=rewards-] p {
    margin-top: .9375rem
}

.main-header {
    z-index: 999;
    overflow: visible;
    position: fixed;
    top: 0;
    width: 100%
}

.main-header .upper-header-wrapper {
    background-color: #000;
    z-index: 1
}

.main-header .upper-header-wrapper .content-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

.main-header .upper-header-wrapper .content-wrapper .meta-navigation-wrapper {
    display: none
}

@media (min-width: 62rem) {
    .main-header .upper-header-wrapper .content-wrapper .meta-navigation-wrapper {
        display: block
    }
}

.main-header .lower-header-wrapper {
    background-color: #fff;
    overflow: visible;
    position: relative;
    z-index: 1;
    height: 2.8125rem
}

.main-header .lower-header-wrapper:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    -webkit-box-shadow: 0 .625rem 2.5rem 0 rgba(0, 51, 120, .1);
    box-shadow: 0 .625rem 2.5rem 0 rgba(0, 51, 120, .1)
}

@media (min-width: 62rem) {
    .main-header .lower-header-wrapper {
        height: auto
    }
}

.main-header .lower-header-wrapper .mobile-menu-search-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

@media (min-width: 62rem) {
    .main-header .lower-header-wrapper .mobile-menu-search-wrapper {
        display: none
    }
}

.main-header .lower-header-wrapper .mobile-menu-search-wrapper .mobile-search-trigger {
    margin-right: .625rem;
    cursor: pointer;
    height: 2.875rem;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 .625rem
}

.main-header .lower-header-wrapper .mobile-menu-search-wrapper .mobile-search-trigger:after {
    content: "";
    height: .25rem;
    background-color: #e20079;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    -webkit-transition: width .3s;
    transition: width .3s
}

.main-header .lower-header-wrapper .mobile-menu-search-wrapper .mobile-search-trigger .icon {
    width: 1rem;
    height: 1rem;
    -webkit-transition: fill .3s;
    transition: fill .3s
}

.main-header .lower-header-wrapper .mobile-menu-search-wrapper .mobile-search-trigger.active:after {
    width: 100%
}

.main-header .lower-header-wrapper .mobile-menu-search-wrapper .mobile-search-trigger.active .icon {
    fill: #e20079
}

.main-header .lower-header-wrapper .mobile-menu-search-wrapper .mobile-menu-wrapper {
    height: 2.875rem;
    width: 2.875rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.main-header .lower-header-wrapper .mobile-menu-search-wrapper .mobile-menu-wrapper .mobile-menu {
    height: 2.5rem;
    width: 2.5rem;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.main-header .lower-header-wrapper .mobile-menu-search-wrapper .mobile-menu-wrapper .mobile-menu span {
    display: block;
    position: absolute;
    height: .25rem;
    background: #d3531a;
    opacity: 1;
    left: .1875rem;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    transition: .25s ease-in-out
}

.main-header .lower-header-wrapper .mobile-menu-search-wrapper .mobile-menu-wrapper .mobile-menu span:first-child {
    top: .5625rem;
    width: 1.875rem;
    background: #003378
}

.main-header .lower-header-wrapper .mobile-menu-search-wrapper .mobile-menu-wrapper .mobile-menu span:nth-child(2), .main-header .lower-header-wrapper .mobile-menu-search-wrapper .mobile-menu-wrapper .mobile-menu span:nth-child(3) {
    top: 1.125rem;
    width: 1.4375rem;
    background: #009ee0
}

.main-header .lower-header-wrapper .mobile-menu-search-wrapper .mobile-menu-wrapper .mobile-menu span:nth-child(4) {
    top: 1.6875rem;
    width: 2.125rem;
    background: #e20079
}

.main-header .lower-header-wrapper .mobile-menu-search-wrapper .mobile-menu-wrapper .mobile-menu.open span:first-child {
    top: 18px;
    width: 0;
    left: 50%
}

.main-header .lower-header-wrapper .mobile-menu-search-wrapper .mobile-menu-wrapper .mobile-menu.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    width: 2.125rem;
    background: #e20079
}

.main-header .lower-header-wrapper .mobile-menu-search-wrapper .mobile-menu-wrapper .mobile-menu.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    width: 2.125rem;
    background: #e20079
}

.main-header .lower-header-wrapper .mobile-menu-search-wrapper .mobile-menu-wrapper .mobile-menu.open span:nth-child(4) {
    top: 18px;
    width: 0;
    left: 50%
}

.main-header .lower-header-wrapper .content-wrapper {
    overflow: visible;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 1.5rem;
    position: relative;
    z-index: -1
}

@media (min-width: 62rem) {
    .main-header .lower-header-wrapper .content-wrapper {
        padding: 0 1.875rem
    }
}

.main-header .lower-header-wrapper .content-wrapper .logo {
    width: 2rem;
    height: 2rem
}

@media (min-width: 62rem) {
    .main-header .lower-header-wrapper .content-wrapper .logo {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        width: 3.75rem;
        height: 3.75rem
    }
}

.main-header .lower-header-wrapper .content-wrapper .logo-link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.main-header .lower-header-wrapper .content-wrapper .logo svg {
    width: 100%;
    height: 100%
}

.main-header .lower-header-wrapper .content-wrapper .navi-search-wrapper {
    display: none
}

@media (min-width: 62rem) {
    .main-header .lower-header-wrapper .content-wrapper .navi-search-wrapper {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex
    }
}

.main-header .lower-header-wrapper .content-wrapper .main-navigation-wrapper {
    margin-right: 1.25rem;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.main-header .lower-header-wrapper .content-wrapper .search-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 10rem
}

.main-header .mobile-search-container {
    position: fixed;
    top: -1.25rem;
    -webkit-transition: top .3s, opacity .3s;
    transition: top .3s, opacity .3s;
    width: 100%;
    padding: 1.25rem;
    background-color: #fff;
    opacity: 0
}

@media (min-width: 62rem) {
    .main-header .mobile-search-container {
        display: none
    }
}

.main-header .mobile-search-container.active {
    top: 2.8125rem;
    opacity: 1
}

.main-header .mobile-search-container .main-search {
    max-width: none
}

.main-header .mobile-search-container .main-search .tx-indexedsearch-searchbox-sword {
    margin: 0;
    background-image: none
}

.main-header .mobile-search-container .main-search #tx-indexedsearch-searchbox-button-submit {
    background-color: #e20079;
    opacity: 1;
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 3.75rem;
    font-family: lato_bold, Helvetica, sans-serif;
    font-weight: 700;
    font-size: .8125rem;
    line-height: 0;
    text-transform: uppercase;
    letter-spacing: .0625rem
}

.main-header .mobile-search-container .main-search #tx-indexedsearch-searchbox-button-submit:hover {
    background-color: #ff49aa
}

.heroteaser {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-bottom: 0 !important
}

@media (min-width: 48rem) {
    .heroteaser {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap
    }
}

.heroteaser-image-container {
    width: 100%;
    border: 0
}

@media (min-width: 48rem) {
    .heroteaser-image-container {
        width: 50%;
        border-left: 0;
        border-right: .25rem solid #e20079
    }
}

.heroteaser-text-container {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border: 0;
    padding: 3.125rem 1.25rem
}

@media (min-width: 48rem) {
    .heroteaser-text-container {
        width: 50%;
        border-right: 0;
        border-left: .25rem solid #e20079;
        padding: .625rem 1.25rem
    }
}

@media (min-width: 62rem) {
    .heroteaser-text-container {
        padding: 1.25rem 2.5rem
    }
}

.heroteaser-text-container-wrapper {
    -webkit-transition: max-width .3s ease;
    transition: max-width .3s ease;
    width: 100%
}

@media (min-width: 48rem) {
    .heroteaser-text-container-wrapper {
        max-width: 100%;
        width: auto
    }
}

@media (min-width: 120rem) {
    .heroteaser-text-container-wrapper {
        max-width: 70%
    }
}

.heroteaser-text-container .btn-primary, .heroteaser-text-container .btn-primary-inverted {
    margin-top: 1.875rem
}

@media (min-width: 36rem) {
    .heroteaser-text-container .btn-primary, .heroteaser-text-container .btn-primary-inverted {
        font-size: calc(.231vw + .91667rem)
    }
}

@media (min-width: 90rem) {
    .heroteaser-text-container .btn-primary, .heroteaser-text-container .btn-primary-inverted {
        font-size: 1.125rem
    }
}

@media (min-width: 125rem) {
    .heroteaser-text-container .btn-primary, .heroteaser-text-container .btn-primary-inverted {
        font-size: calc(1.071vw + -.21429rem)
    }
}

.heroteaser-text-container .heroteaser-headline {
    font-family: lato_black, Helvetica, sans-serif;
    font-weight: 900;
    font-size: 1.375rem;
    letter-spacing: .25rem;
    margin-bottom: 1.25rem;
    -webkit-transition: font-size .6s cubic-bezier(.68, -.55, .27, 1.55), margin-bottom .3s ease;
    transition: font-size .6s cubic-bezier(.68, -.55, .27, 1.55), margin-bottom .3s ease
}

@media (min-width: 36rem) {
    .heroteaser-text-container .heroteaser-headline {
        font-size: calc(3.009vw + .29167rem)
    }
}

@media (min-width: 75rem) {
    .heroteaser-text-container .heroteaser-headline {
        margin-bottom: 2.5rem
    }
}

@media (min-width: 90rem) {
    .heroteaser-text-container .heroteaser-headline {
        font-size: 3rem
    }
}

@media (min-width: 125rem) {
    .heroteaser-text-container .heroteaser-headline {
        font-size: calc(2.857vw + -.57143rem)
    }
}

.heroteaser-text-container .heroteaser-bodytext p {
    font-size: .8125rem;
    -webkit-transition: font-size .6s cubic-bezier(.68, -.55, .27, 1.55);
    transition: font-size .6s cubic-bezier(.68, -.55, .27, 1.55)
}

@media (min-width: 36rem) {
    .heroteaser-text-container .heroteaser-bodytext p {
        font-size: calc(.347vw + .6875rem)
    }
}

@media (min-width: 90rem) {
    .heroteaser-text-container .heroteaser-bodytext p {
        font-size: 1rem
    }
}

@media (min-width: 125rem) {
    .heroteaser-text-container .heroteaser-bodytext p {
        font-size: calc(1.429vw + -.78571rem)
    }
}

.heroteaser-text-container.magenta {
    background: #e20079
}

.heroteaser-text-container.magenta .heroteaser-headline, .heroteaser-text-container.magenta .heroteaser-subheadline, .heroteaser-text-container.magenta p {
    color: #fff
}

.heroteaser-text-container.magenta .toggle-icon svg use {
    fill: #fff
}

.heroteaser-text-container.magenta a:hover .toggle-icon svg use {
    fill: #000
}

.heroteaser-text-container.magenta a:hover .heroteaser-subheadline, .heroteaser-text-container.magenta a:hover p {
    color: #000
}

.heroteaser.image-right .heroteaser-image-container {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    border: 0
}

@media (min-width: 48rem) {
    .heroteaser.image-right .heroteaser-image-container {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2;
        border-right: 0;
        border-left: .25rem solid #e20079
    }
}

.heroteaser.image-right .heroteaser-text-container {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    border: 0
}

@media (min-width: 48rem) {
    .heroteaser.image-right .heroteaser-text-container {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
        border-left: 0;
        border-right: .25rem solid #e20079
    }
}

.heroteaser.full-bg .heroteaser-image-container, .heroteaser.full-bg .heroteaser-text-container {
    border-left: none;
    border-right: none
}

.heroteaser.full-bg .heroteaser-text-container {
    background-image: none
}

@media (min-width: 48rem) {
    .heroteaser.full-bg .heroteaser-text-container {
        background-image: url(/typo3conf/ext/px_basis_config/Resources/Public/img/Heroteaser-bg-desktop.jpg);
        background-position: 0 0;
        background-size: cover;
        background-repeat: no-repeat
    }
}

.heroteaser.full-bg .heroteaser-image-container {
    background-image: url(/typo3conf/ext/px_basis_config/Resources/Public/img/Heroteaser-bg-desktop.jpg);
    background-position: 0 0;
    background-size: cover;
    background-repeat: no-repeat
}

.heroteaser-text-container-listelement, .listteaser-item {
    padding-bottom: 1.875rem;
    position: relative;
    -webkit-transition: padding .3s ease;
    transition: padding .3s ease
}

@media (min-width: 48rem) {
    .heroteaser-text-container-listelement, .listteaser-item {
        padding-bottom: .9375rem
    }
}

@media (min-width: 62rem) {
    .heroteaser-text-container-listelement, .listteaser-item {
        padding-bottom: 1.25rem
    }
}

@media (min-width: 75rem) {
    .heroteaser-text-container-listelement, .listteaser-item {
        padding-bottom: 2.5rem
    }
}

.heroteaser-text-container-listelement:last-child, .listteaser-item:last-child {
    padding-bottom: 0
}

.heroteaser-text-container-listelement p, .listteaser-item p {
    font-size: .8125rem;
    margin-left: 1.625rem;
    -webkit-transition: font-size .6s cubic-bezier(.68, -.55, .27, 1.55), color .3s;
    transition: font-size .6s cubic-bezier(.68, -.55, .27, 1.55), color .3s
}

@media (min-width: 36rem) {
    .heroteaser-text-container-listelement p, .listteaser-item p {
        font-size: calc(.347vw + .6875rem)
    }
}

@media (min-width: 62rem) {
    .heroteaser-text-container-listelement p, .listteaser-item p {
        margin-left: 2.125rem
    }
}

@media (min-width: 90rem) {
    .heroteaser-text-container-listelement p, .listteaser-item p {
        font-size: 1rem
    }
}

@media (min-width: 125rem) {
    .heroteaser-text-container-listelement p, .listteaser-item p {
        font-size: calc(1.429vw + -.78571rem)
    }
}

.heroteaser-text-container-listelement .toggle-icon, .listteaser-item .toggle-icon {
    width: 1.5rem;
    height: 1.5rem;
    display: inline-block;
    position: absolute;
    -webkit-transition: -webkit-transform .3s ease;
    transition: -webkit-transform .3s ease;
    transition: transform .3s ease;
    transition: transform .3s ease, -webkit-transform .3s ease;
    cursor: pointer;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    margin: 0;
    width: 1rem;
    height: 1rem;
    position: relative;
    -webkit-transition: height .6s cubic-bezier(.68, -.55, .27, 1.55), width .6s cubic-bezier(.68, -.55, .27, 1.55);
    transition: height .6s cubic-bezier(.68, -.55, .27, 1.55), width .6s cubic-bezier(.68, -.55, .27, 1.55)
}

@media (min-width: 75rem) {
    .heroteaser-text-container-listelement .toggle-icon, .listteaser-item .toggle-icon {
        display: none
    }
}

.heroteaser-text-container-listelement .toggle-icon svg, .listteaser-item .toggle-icon svg {
    width: 100%;
    height: 100%
}

.heroteaser-text-container-listelement .toggle-icon svg use, .listteaser-item .toggle-icon svg use {
    fill: #fff;
    -webkit-transition: fill .3s ease;
    transition: fill .3s ease
}

.heroteaser-text-container-listelement .toggle-icon:hover svg use, .listteaser-item .toggle-icon:hover svg use {
    fill: #e20079
}

@media (min-width: 75rem) {
    .heroteaser-text-container-listelement .toggle-icon, .listteaser-item .toggle-icon {
        display: inline-block
    }
}

@media (min-width: 62rem) {
    .heroteaser-text-container-listelement .toggle-icon, .listteaser-item .toggle-icon {
        width: 1.5rem;
        height: 1.5rem
    }
}

.heroteaser-text-container-listelement .toggle-icon svg use, .listteaser-item .toggle-icon svg use {
    fill: #e20079
}

.heroteaser-text-container-listelement a:hover .heroteaser-subheadline, .heroteaser-text-container-listelement a:hover .listteaser-item-headline, .heroteaser-text-container-listelement a:hover p, .listteaser-item a:hover .heroteaser-subheadline, .listteaser-item a:hover .listteaser-item-headline, .listteaser-item a:hover p {
    color: #e20079
}

.heroteaser-text-container-listelement > .listteaser-item-headline-wrapper .toggle-icon, .listteaser-item > .listteaser-item-headline-wrapper .toggle-icon {
    cursor: default
}

.heroteaser-subheadline, .listteaser-item-headline {
    margin-bottom: 0;
    padding-bottom: 0;
    font-size: 1rem;
    width: calc(100% - 26px);
    -webkit-transition: font-size .6s cubic-bezier(.68, -.55, .27, 1.55), color .3s;
    transition: font-size .6s cubic-bezier(.68, -.55, .27, 1.55), color .3s
}

@media (min-width: 36rem) {
    .heroteaser-subheadline, .listteaser-item-headline {
        font-size: calc(.694vw + .75rem)
    }
}

@media (min-width: 62rem) {
    .heroteaser-subheadline, .listteaser-item-headline {
        width: calc(100% - 34px)
    }
}

@media (min-width: 90rem) {
    .heroteaser-subheadline, .listteaser-item-headline {
        font-size: 1.375rem
    }
}

@media (min-width: 125rem) {
    .heroteaser-subheadline, .listteaser-item-headline {
        font-size: calc(1.786vw + -.85714rem)
    }
}

.heroteaser-subheadline-wrapper, .listteaser-item-headline-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.frame-type-image_gallery.frame-layout-1 header {
    padding: 0 1.25rem
}

@media (min-width: 48rem) {
    .frame-type-image_gallery.frame-layout-1 header {
        padding: 0 33.33333% 0 1.25rem
    }
}

.frame-type-image_gallery.frame-layout-1.frame-slider-both, .frame-type-image_gallery.frame-layout-1.frame-slider-mobile {
    padding-left: 1.25rem;
    padding-right: 0
}

@media (min-width: 48rem) {
    .frame-type-image_gallery.frame-layout-1.frame-slider-both, .frame-type-image_gallery.frame-layout-1.frame-slider-mobile {
        padding-left: 0
    }
}

.frame-type-image_gallery.frame-layout-1.frame-slider-both header, .frame-type-image_gallery.frame-layout-1.frame-slider-mobile header {
    padding-left: 0
}

@media (min-width: 48rem) {
    .frame-type-image_gallery.frame-layout-1.frame-slider-both header, .frame-type-image_gallery.frame-layout-1.frame-slider-mobile header {
        padding-left: 1.25rem
    }
}

.frame-type-image_gallery.frame-layout-0 header {
    padding: 0 1.25rem 0 0
}

@media (min-width: 48rem) {
    .frame-type-image_gallery.frame-layout-0 header {
        padding: 0
    }
}

.frame-type-image_gallery.frame-layout-0.frame-slider-both, .frame-type-image_gallery.frame-layout-0.frame-slider-mobile {
    padding-right: 0
}

@media (min-width: 48rem) {
    .frame-type-image_gallery.frame-layout-0.frame-slider-both, .frame-type-image_gallery.frame-layout-0.frame-slider-mobile {
        padding-right: 1.25rem
    }
}

.frame-type-image_gallery.frame-layout-0.frame-slider-none header {
    padding: 0
}

@media (min-width: 48rem) {
    .frame-type-image_gallery.frame-layout-0.frame-slider-none header {
        padding: 0 33.33333333333333% 0 0
    }
}

empty {
    content: 1
}

.main-navigation-wrapper .navigation {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
    overflow: visible
}

.main-navigation-wrapper .navigation > .navigation-item {
    position: relative;
    cursor: pointer;
    z-index: 1;
    margin: 0 1.25rem;
    font-family: lato_bold, Helvetica, sans-serif;
    font-weight: 700;
    font-size: .8125rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.main-navigation-wrapper .navigation > .navigation-item > a {
    display: inline-block;
    text-transform: uppercase;
    letter-spacing: .125rem;
    padding: 2.3125rem 0;
    white-space: nowrap
}

.main-navigation-wrapper .navigation > .navigation-item:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: .4375rem;
    width: 0;
    background-color: #e20079;
    -webkit-transition: width .3s;
    transition: width .3s
}

.main-navigation-wrapper .navigation > .navigation-item.hover, .main-navigation-wrapper .navigation > .navigation-item:hover {
    color: #e20079
}

.main-navigation-wrapper .navigation > .navigation-item.hover:after, .main-navigation-wrapper .navigation > .navigation-item:hover:after {
    width: 100%
}

.main-navigation-wrapper .navigation > .navigation-item.hover .navigation-lvl2, .main-navigation-wrapper .navigation > .navigation-item:hover .navigation-lvl2 {
    visibility: visible
}

.main-navigation-wrapper .navigation-lvl2 {
    visibility: hidden;
    background-color: #fff;
    position: absolute;
    top: 100%;
    left: 0;
    -webkit-box-shadow: 0 .625rem 2.5rem 0 rgba(0, 51, 120, .1);
    box-shadow: 0 .625rem 2.5rem 0 rgba(0, 51, 120, .1)
}

.main-navigation-wrapper .navigation-lvl2 .navigation-item {
    background: #fff;
    text-transform: none;
    -webkit-transition: background .3s;
    transition: background .3s;
    white-space: nowrap
}

.main-navigation-wrapper .navigation-lvl2 .navigation-item.has-sub {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 16 16'%3E%3Cdefs%3E%3Cpath fill='%23E20079' id='a' d='M9.883 13a.216.216 0 01-.155-.066L5.064 8.158a.227.227 0 010-.317l4.664-4.775a.216.216 0 01.31 0l.897.919a.228.228 0 010 .317L7.324 8l3.612 3.698a.228.228 0 010 .317l-.898.92a.218.218 0 01-.155.065z'/%3E%3C/defs%3E%3Cuse transform='matrix(-1 0 0 1 16 0)' xlink:href='%23a'/%3E%3C/svg%3E");
    background-color: #fff;
    background-position: right 1.5rem center;
    background-size: 1rem 1rem;
    background-repeat: no-repeat;
    position: relative
}

.main-navigation-wrapper .navigation-lvl2 .navigation-item > a {
    display: inline-block;
    padding: 1.5rem 3rem 1.5rem 1.5rem;
    color: #000;
    letter-spacing: .125rem;
    -webkit-transition: color .3s;
    transition: color .3s
}

.main-navigation-wrapper .navigation-lvl2 .navigation-item.hover, .main-navigation-wrapper .navigation-lvl2 .navigation-item:hover {
    background: #e20079
}

.main-navigation-wrapper .navigation-lvl2 .navigation-item.hover.has-sub, .main-navigation-wrapper .navigation-lvl2 .navigation-item:hover.has-sub {
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 16 16'%3E%3Cdefs%3E%3Cpath fill='%23fff' id='a' d='M9.883 13a.216.216 0 01-.155-.066L5.064 8.158a.227.227 0 010-.317l4.664-4.775a.216.216 0 01.31 0l.897.919a.228.228 0 010 .317L7.324 8l3.612 3.698a.228.228 0 010 .317l-.898.92a.218.218 0 01-.155.065z'/%3E%3C/defs%3E%3Cuse transform='matrix(-1 0 0 1 16 0)' xlink:href='%23a'/%3E%3C/svg%3E");
    background-color: #e20079;
    background-position: right 1.5rem center;
    background-size: 1rem 1rem;
    background-repeat: no-repeat
}

.main-navigation-wrapper .navigation-lvl2 .navigation-item.hover > a, .main-navigation-wrapper .navigation-lvl2 .navigation-item:hover > a {
    color: #fff
}

.main-navigation-wrapper .navigation-lvl2 .navigation-item.hover .navigation-lvl-3, .main-navigation-wrapper .navigation-lvl2 .navigation-item:hover .navigation-lvl-3 {
    visibility: visible
}

.main-navigation-wrapper .navigation-lvl-3 {
    visibility: hidden;
    z-index: -1;
    position: absolute;
    left: 100%;
    top: 0;
    -webkit-box-shadow: 0 .625rem 2.5rem 0 rgba(0, 51, 120, .1);
    box-shadow: 0 .625rem 2.5rem 0 rgba(0, 51, 120, .1)
}

.main-navigation-wrapper .navigation-lvl-3 .navigation-item {
    background: #fff
}

.main-navigation-wrapper .navigation-lvl-3 .navigation-item.hover, .main-navigation-wrapper .navigation-lvl-3 .navigation-item:hover {
    background: #e20079
}

.main-navigation-wrapper .navigation-lvl-3 .navigation-item > a {
    letter-spacing: .125rem
}

.main-navigation-wrapper .navigation .navigation-item .navigation-lvl2 .navigation-lvl-3.left {
    left: auto;
    right: 100%
}

.meta-navigation-wrapper .meta-navigation {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%
}

.meta-navigation-wrapper .meta-navigation-item {
    color: #fff;
    font-family: lato_regular, Helvetica, sans-serif;
    font-weight: 400;
    letter-spacing: .125rem;
    font-size: .75rem;
    -webkit-transition: color .3s;
    transition: color .3s
}

.meta-navigation-wrapper .meta-navigation-item > a {
    padding: .9375rem;
    display: inline-block
}

.meta-navigation-wrapper .meta-navigation-item:hover {
    color: #e20079
}

.meta-navigation-wrapper .meta-navigation-item.pink {
    background-color: #e20079;
    margin-left: .9375rem;
    -webkit-transition: background-color .3s;
    transition: background-color .3s
}

.meta-navigation-wrapper .meta-navigation-item.pink:hover {
    background-color: #ff49aa;
    color: #fff
}

.mobile-navigation-wrapper {
    display: block;
    z-index: 999;
    position: fixed;
    top: 2.8125rem;
    right: 100%;
    -webkit-transition: -webkit-transform .3s;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    height: calc(100% - 45px);
    background-color: #fff
}

.mobile-navigation-wrapper.open {
    -webkit-transform: translateX(100%);
    transform: translateX(100%)
}

@media (min-width: 62rem) {
    .mobile-navigation-wrapper {
        display: none
    }
}

.mobile-navigation-wrapper .navigation {
    display: block;
    width: 100%
}

.mobile-navigation-wrapper .navigation-item {
    margin: 0;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.mobile-navigation-wrapper .navigation-item > a {
    padding: 1.25rem .625rem 1.25rem 1.5rem;
    width: 100%;
    font-family: lato_bold, Helvetica, sans-serif;
    font-weight: 700;
    font-size: .75rem;
    letter-spacing: .125rem
}

.mobile-navigation-wrapper .navigation-item > a:hover {
    background-color: #e20079;
    color: #fff;
    -webkit-transition: background-color .3s, color .3s;
    transition: background-color .3s, color .3s
}

.mobile-navigation-wrapper .navigation-item.has-sub > a {
    width: calc(100% - 53px)
}

.mobile-navigation-wrapper .navigation-item .submenu-trigger {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 3.4375rem;
    width: 3.375rem;
    background-color: #fff;
    cursor: pointer;
    -webkit-transition: background-color .3s;
    transition: background-color .3s;
    position: relative
}

.mobile-navigation-wrapper .navigation-item .submenu-trigger svg {
    height: 1.25rem;
    width: 1.25rem
}

.mobile-navigation-wrapper .navigation-item .submenu-trigger svg use {
    -webkit-transition: fill .3s;
    transition: fill .3s;
    fill: #e20079
}

.mobile-navigation-wrapper .navigation-item .submenu-trigger:hover {
    background-color: #e20079
}

.mobile-navigation-wrapper .navigation-item .submenu-trigger:hover svg use {
    fill: #fff
}

.mobile-navigation-wrapper .navigation-item .submenu-trigger:hover:before {
    background-color: #e20079
}

.mobile-navigation-wrapper .navigation-item .submenu-trigger:before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    height: 60%;
    width: .0625rem;
    background-color: #d7d7d7;
    -webkit-transition: background-color .3s;
    transition: background-color .3s
}

.mobile-navigation-wrapper .navigation-item ul {
    display: block;
    width: 100%;
    height: 100%;
    background-color: #fff;
    position: absolute;
    top: 0;
    left: 100%;
    -webkit-transition: -webkit-transform .3s;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    z-index: 1
}

.mobile-navigation-wrapper .navigation-item ul.open {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%)
}

.mobile-navigation-wrapper .navigation-item ul .back {
    width: 100%;
    border-bottom: .0625rem solid #d7d7d7;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer
}

.mobile-navigation-wrapper .navigation-item ul .back span {
    font-family: lato_bold, Helvetica, sans-serif;
    font-weight: 700;
    font-size: .75rem;
    letter-spacing: .125rem;
    margin-left: .625rem
}

.mobile-navigation-wrapper .navigation-item ul .back .logo-wrapper {
    height: 3.4375rem;
    width: 3.4375rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: #e20079;
    -webkit-transition: background-color .3s;
    transition: background-color .3s
}

.mobile-navigation-wrapper .navigation-item ul .back .logo-wrapper svg {
    height: 1.25rem;
    width: 1.25rem
}

.mobile-navigation-wrapper .navigation-item ul .back .logo-wrapper svg use {
    fill: #fff
}

.mobile-navigation-wrapper .navigation-item ul .back:hover .logo-wrapper {
    background-color: #ff49aa
}

.mobile-navigation-wrapper .navigation-item ul .navigation-item a > {
    color: #000
}

.mobile-navigation-wrapper .navigation-item ul .navigation-item a > :hover {
    color: #fff
}

.mobile-navigation-wrapper .meta-navigation {
    margin-top: 3.75rem
}

.mobile-navigation-wrapper .meta-navigation-item > a {
    padding: .9375rem 1.25rem
}

.mobile-navigation-wrapper .meta-navigation-item:not(.pink) {
    color: #000
}

.mobile-navigation-wrapper .meta-navigation-item:not(.pink):hover {
    color: #e20079
}

.news-list-view-items-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap
}

@media (min-width: 36rem) {
    .news-list-view-items-wrapper {
        margin: -.625rem
    }
}

.news-list-view-items-wrapper .article {
    margin: .625rem 0;
    padding-bottom: 0;
    width: 100%;
    -webkit-transition: min-height .6s ease, width .3s ease;
    transition: min-height .6s ease, width .3s ease
}

@media (min-width: 36rem) {
    .news-list-view-items-wrapper .article {
        margin: .625rem;
        min-height: 15.625rem;
        width: calc(50% - 20px)
    }
}

@media (min-width: 62rem) {
    .news-list-view-items-wrapper .article {
        min-height: 19.375rem;
        width: calc(33.33333% - 20px)
    }
}

@media (min-width: 75rem) {
    .news-list-view-items-wrapper .article {
        width: calc(25% - 20px)
    }
}

@media (min-width: 36rem) {
    .news-list-view-items-wrapper .article:last-child {
        margin-bottom: .625rem
    }
}

.news-item-link-wrapper {
    background: #f5f5f5;
    display: block;
    height: 100%;
    padding: 1.5625rem 1.25rem;
    -webkit-transition: background .3s ease;
    transition: background .3s ease
}

.news-item-link-wrapper:hover {
    background: #e20079
}

.news-content-wrapper {
    height: 100%;
    position: relative;
    padding-bottom: 3.9375rem
}

.news-list-date {
    font-family: lato_regular, Helvetica, sans-serif;
    font-weight: 400;
    color: #000;
    font-size: 1rem;
    letter-spacing: .0625rem;
    font-size: .875rem;
    line-height: 1.5rem;
    display: inline-block;
    margin-bottom: .5rem;
    -webkit-transition: color .3s ease;
    transition: color .3s ease
}

.news-item-link-wrapper:hover .news-list-date {
    color: #fff
}

.news-headline {
    margin-bottom: .5rem
}

.news-headline h3 {
    line-height: 2rem;
    -webkit-transition: color .3s ease;
    transition: color .3s ease
}

.news-item-link-wrapper:hover .news-headline h3 {
    color: #fff
}

.news-teaser-text div * {
    font-size: .875rem;
    line-height: 1.5rem;
    -webkit-transition: color .3s ease;
    transition: color .3s ease
}

.news-item-link-wrapper:hover .news-teaser-text div * {
    color: #fff
}

.news-read-more-link {
    bottom: 0;
    height: 3rem;
    left: 0;
    line-height: 0;
    position: absolute;
    width: 3rem
}

.news-read-more-link use {
    fill: #e20079;
    -webkit-transition: fill .3s ease;
    transition: fill .3s ease
}

.news-item-link-wrapper:hover .news-read-more-link use {
    fill: #fff
}

.news-list-button-wrapper {
    text-align: center
}

.news-single .frame-layout-0 {
    padding-left: 0;
    padding-right: 0
}

.news-detail-wrapper {
    padding-bottom: 1.25rem
}

@media (min-width: 48rem) {
    .news-detail-wrapper {
        padding-bottom: 2.5rem
    }
}

@media (min-width: 64rem) {
    .news-detail-wrapper {
        padding-bottom: 3.75rem
    }
}

@media (min-width: 48rem) {
    .news-detail-bodytext, .news-detail-header {
        padding-right: 33.33333333333333%
    }
}

.news-detail-additional-infos {
    font-family: lato_regular, Helvetica, sans-serif;
    font-weight: 400;
    color: #000;
    font-size: 1rem;
    letter-spacing: .0625rem;
    font-size: .875rem;
    line-height: 1.5rem;
    margin-bottom: 0
}

.f3-widget-paginator, .tx-indexedsearch-browsebox {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 2.5rem 0
}

@media (min-width: 48rem) {
    .f3-widget-paginator, .tx-indexedsearch-browsebox {
        margin: 3.75rem 0
    }
}

.f3-widget-paginator li, .tx-indexedsearch-browsebox li {
    line-height: 1.5rem;
    display: none
}

@media (min-width: 48rem) {
    .f3-widget-paginator li, .tx-indexedsearch-browsebox li {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex
    }
}

.f3-widget-paginator li a:not([class]), .tx-indexedsearch-browsebox li a:not([class]) {
    padding-bottom: 0;
    color: #1c1c1c;
    font-size: .875rem;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-decoration: underline
}

.f3-widget-paginator li a:not([class]):hover, .tx-indexedsearch-browsebox li a:not([class]):hover {
    color: #e20079
}

.f3-widget-paginator li:not(:last-of-type), .tx-indexedsearch-browsebox li:not(:last-of-type) {
    margin-right: 1.25rem
}

.f3-widget-paginator li.current, .f3-widget-paginator li.tx-indexedsearch-browselist-currentPage, .tx-indexedsearch-browsebox li.current, .tx-indexedsearch-browsebox li.tx-indexedsearch-browselist-currentPage {
    font-family: lato_bold, Helvetica, sans-serif;
    font-weight: 700;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: .25rem;
    background-color: #e20079;
    color: #fff;
    font-size: .875rem;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 2rem
}

.f3-widget-paginator li.first, .f3-widget-paginator li.last, .tx-indexedsearch-browsebox li.first, .tx-indexedsearch-browsebox li.last {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.f3-widget-paginator li.next, .f3-widget-paginator li.previous, .tx-indexedsearch-browsebox li.next, .tx-indexedsearch-browsebox li.previous {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border: .1875rem solid #e20079;
    -webkit-transition: background .3s ease;
    transition: background .3s ease
}

.f3-widget-paginator li.next a, .f3-widget-paginator li.previous a, .tx-indexedsearch-browsebox li.next a, .tx-indexedsearch-browsebox li.previous a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 0;
    text-indent: -9999px;
    height: 1.625rem;
    width: 1.625rem
}

.f3-widget-paginator li.next a:after, .f3-widget-paginator li.previous a:after, .tx-indexedsearch-browsebox li.next a:after, .tx-indexedsearch-browsebox li.previous a:after {
    content: "";
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTYiIGhlaWdodD0iMTYiPjxkZWZzPjxwYXRoIGlkPSJhIiBkPSJNOC44ODMgMTNhLjIxNi4yMTYgMCAwMS0uMTU1LS4wNjZMNC4wNjQgOC4xNThhLjIyNy4yMjcgMCAwMTAtLjMxN2w0LjY2NC00Ljc3NWEuMjE2LjIxNiAwIDAxLjMxIDBsLjg5Ny45MTlhLjIyOC4yMjggMCAwMTAgLjMxN0w2LjMyNCA4bDMuNjEyIDMuNjk4YS4yMjguMjI4IDAgMDEwIC4zMTdsLS44OTguOTJhLjIxOC4yMTggMCAwMS0uMTU1LjA2NXoiLz48L2RlZnM+PHVzZSBmaWxsPSIjZTIwMDc5IiB4bGluazpocmVmPSIjYSIvPjwvc3ZnPg==");
    width: 1rem;
    height: 1rem;
    -webkit-transition: background .3s ease;
    transition: background .3s ease
}

.f3-widget-paginator li.next:hover, .f3-widget-paginator li.previous:hover, .tx-indexedsearch-browsebox li.next:hover, .tx-indexedsearch-browsebox li.previous:hover {
    background: #e20079
}

.f3-widget-paginator li.next:hover a:after, .f3-widget-paginator li.previous:hover a:after, .tx-indexedsearch-browsebox li.next:hover a:after, .tx-indexedsearch-browsebox li.previous:hover a:after {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQBAMAAADt3eJSAAAAAXNSR0IB2cksfwAAADBQTFRFAAAA////////////////////////////////////////////////////////////O00EMAAAABB0Uk5TAAi9ewb/rLi1BLvBq8cKw5417qoAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAAySURBVHicY2DACxiVoQynMAjNUtoJYUyPcoAwUjdClRy9B2VMj3ZAU8zgFAMz8DN+CwGHLglv8bSV6AAAAABJRU5ErkJggg==")
}

.f3-widget-paginator li.next a:after, .tx-indexedsearch-browsebox li.next a:after {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTYiIGhlaWdodD0iMTYiPjxkZWZzPjxwYXRoIGlkPSJhIiBkPSJNOS44ODMgMTNhLjIxNi4yMTYgMCAwMS0uMTU1LS4wNjZMNS4wNjQgOC4xNThhLjIyNy4yMjcgMCAwMTAtLjMxN2w0LjY2NC00Ljc3NWEuMjE2LjIxNiAwIDAxLjMxIDBsLjg5Ny45MTlhLjIyOC4yMjggMCAwMTAgLjMxN0w3LjMyNCA4bDMuNjEyIDMuNjk4YS4yMjguMjI4IDAgMDEwIC4zMTdsLS44OTguOTJhLjIxOC4yMTggMCAwMS0uMTU1LjA2NXoiLz48L2RlZnM+PHVzZSBmaWxsPSIjZTIwMDc5IiB0cmFuc2Zvcm09Im1hdHJpeCgtMSAwIDAgMSAxNiAwKSIgeGxpbms6aHJlZj0iI2EiLz48L3N2Zz4=")
}

.f3-widget-paginator li.next:hover a:after, .tx-indexedsearch-browsebox li.next:hover a:after {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAAAXNSR0IB2cksfwAAADNQTFRFAAAA////////////////////////////////////////////////////////////////t5XiggAAABF0Uk5TAH69Bqz/uAS7CMGxCsepqq5ZLBorAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAN0lEQVR4nGNgIBMwMjGjCrCwsqGKsHOwsrGjiHBysXKjivDwsvKhC/Dj08KMbqgAurUYDiMJAACbkAEoVUqTqgAAAABJRU5ErkJggg==")
}

.pxlatest {
    padding: 2.5rem 0;
    background-image: url(/typo3conf/ext/px_basis_config/Resources/Public/img/px_latest-background.jpg);
    background-position: 0 0;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden
}

@media (min-width: 48rem) {
    .pxlatest {
        padding: 5rem 0
    }
}

.pxlatest-filter:not(.pxlatest-list-filter-item) {
    width: 100%;
    font-weight: 700;
    font-size: .875rem;
    letter-spacing: .125rem;
    line-height: 1.5rem;
    padding: .75rem 0;
    text-align: center;
    background-color: #fff;
    -webkit-transition: color .3s, background-color .3s;
    transition: color .3s, background-color .3s
}

@media (min-width: 48rem) {
    .pxlatest-filter:not(.pxlatest-list-filter-item) {
        width: auto;
        min-width: 19.25rem
    }
}

.pxlatest-filter:not(.pxlatest-list-filter-item):first-of-type {
    margin: 0 0 .625rem
}

@media (min-width: 48rem) {
    .pxlatest-filter:not(.pxlatest-list-filter-item):first-of-type {
        margin: 0 .25rem 0 0
    }
}

.pxlatest-filter:not(.pxlatest-list-filter-item).active, .pxlatest-filter:not(.pxlatest-list-filter-item):hover {
    background-color: #e20079;
    color: #fff
}

.pxlatest-filter:not(.pxlatest-list-filter-item).active:hover {
    background-color: #ff49aa
}

.pxlatest-filter-wrapper {
    display: none;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 0 1.5rem
}

@media (min-width: 48rem) {
    .pxlatest-filter-wrapper {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex
    }
}

.pxlatest-content {
    opacity: 0;
    visibility: hidden;
    height: 0;
    -webkit-transition: visibility 0s, opacity .3s linear;
    transition: visibility 0s, opacity .3s linear;
    overflow: hidden
}

.pxlatest-content.visible {
    opacity: 1;
    visibility: visible;
    height: auto
}

.pxlatest-content-wrapper {
    max-width: 1440px;
    height: inherit;
    margin: 0 auto;
    position: relative;
    padding: 2.5rem 1.5rem 0
}

@media (min-width: 62rem) {
    .pxlatest-content-wrapper {
        padding: 2.5rem 4.861% 0
    }
}

@media (min-width: 90rem) {
    .pxlatest-content-wrapper {
        padding: 2.5rem 4.375rem 0
    }
}

.pxlatest-list-button {
    text-align: center
}

@media (min-width: 48rem) {
    .pxlatest-list-button {
        min-width: 25rem
    }
}

.pxlatest-list-button:active, .pxlatest-list-button:focus, .pxlatest-list-button:hover {
    background-color: #ff49aa;
    border: .1875rem solid #ff49aa;
    color: #fff
}

.pxlatest-list-button-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: 2.5rem
}

.pxlatest .news-item-link-wrapper, .pxlatest .seminar-list-container {
    background-color: #fff
}

.pxlatest .news-item-link-wrapper:hover, .pxlatest .seminar-list-container:hover {
    background-color: #e20079
}

.pxlatest-list-filter {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    background-color: #fff;
    top: 100%;
    z-index: 1;
    width: 100%;
    cursor: pointer
}

.pxlatest-list-filter, .pxlatest-list-filter.visible {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.pxlatest-list-filter-box {
    display: none
}

.pxlatest-list-filter-wrapper {
    margin: auto;
    width: 100%;
    padding: 0 1.5rem
}

@media (min-width: 48rem) {
    .pxlatest-list-filter-wrapper {
        display: none
    }
}

.pxlatest-list-filter-button {
    display: block;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #f1f1f1;
    padding: 1rem;
    color: #000;
    border: none;
    outline: none;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: background-color .3s, color .3s;
    transition: background-color .3s, color .3s;
    font-size: .875rem;
    width: 100%;
    font-family: lato_regular, Helvetica, sans-serif;
    font-weight: 400;
    cursor: pointer;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 16 16'%3E%3Cdefs%3E%3Cpath fill='%23E20079' id='a' d='M9.883 13a.216.216 0 01-.155-.066L5.064 8.158a.227.227 0 010-.317l4.664-4.775a.216.216 0 01.31 0l.897.919a.228.228 0 010 .317L7.324 8l3.612 3.698a.228.228 0 010 .317l-.898.92a.218.218 0 01-.155.065z'/%3E%3C/defs%3E%3Cuse transform='rotate(-90 8 8)' xlink:href='%23a'/%3E%3C/svg%3E");
    background-position: right .75rem center;
    background-size: 1.5rem 1.5rem;
    background-repeat: no-repeat;
    background-color: #fff
}

.pxlatest-list-filter-button:active, .pxlatest-list-filter-button:focus {
    -webkit-box-shadow: .125rem .125rem .625rem 0 rgba(54, 175, 253, .2);
    box-shadow: .125rem .125rem .625rem 0 rgba(54, 175, 253, .2);
    background-color: #fff
}

.pxlatest-list-filter-button::-ms-expand {
    display: none
}

.pxlatest-list-filter-button.active {
    color: #fff;
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 16 16'%3E%3Cdefs%3E%3Cpath fill='%23fff' id='a' d='M9.883 13a.216.216 0 01-.155-.066L5.064 8.158a.227.227 0 010-.317l4.664-4.775a.216.216 0 01.31 0l.897.919a.228.228 0 010 .317L7.324 8l3.612 3.698a.228.228 0 010 .317l-.898.92a.218.218 0 01-.155.065z'/%3E%3C/defs%3E%3Cuse fill='%23fff' transform='rotate(-90 8 8)' xlink:href='%23a'/%3E%3C/svg%3E") calc(100% - 12px)/24px no-repeat;
    background-color: #e20079
}

.pxlatest-list-filter-item {
    -webkit-transition: color .3s;
    transition: color .3s;
    width: 100%;
    font-size: .875rem;
    padding: 1rem
}

.pxlatest-list-filter-item:hover {
    background-color: #e20079;
    color: #fff;
    -webkit-transition: color .3s, background-color .3s;
    transition: color .3s, background-color .3s
}

.quickentry {
    background-color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

@media (min-width: 48rem) {
    .quickentry {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap
    }
}

.quickentry-left, .quickentry-right {
    width: 100%
}

@media (min-width: 48rem) {
    .quickentry-left, .quickentry-right {
        width: 50%
    }
}

.quickentry-left {
    background: #000;
    display: none
}

@media (min-width: 48rem) {
    .quickentry-left {
        display: block
    }
}

.quickentry-right {
    background: #fff;
    padding: 2rem 1rem;
    -webkit-transition: padding .3s ease;
    transition: padding .3s ease
}

@media (min-width: 36rem) {
    .quickentry-right {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 3.75rem 2.5rem
    }
}

@media (min-width: 48rem) {
    .quickentry-right {
        padding: 2.5rem
    }
}

.quickentry-right-wrapper {
    -webkit-transition: max-width .3s ease;
    transition: max-width .3s ease
}

@media (min-width: 90rem) {
    .quickentry-right-wrapper {
        max-width: 33.125rem
    }
}

@media (min-width: 120rem) {
    .quickentry-right-wrapper {
        max-width: 100%
    }
}

.quickentry-video-wrapper {
    position: relative;
    padding-top: 100%;
    width: 100%
}

.quickentry-video-wrapper video {
    left: 0;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%
}

.quickentry-subheadline {
    font-size: .875rem;
    line-height: 1.3;
    letter-spacing: .125rem;
    margin-bottom: 1.5rem;
    padding-bottom: 0;
    -webkit-transition: font-size .6s cubic-bezier(.68, -.55, .27, 1.55), margin-bottom .3s ease;
    transition: font-size .6s cubic-bezier(.68, -.55, .27, 1.55), margin-bottom .3s ease
}

@media (min-width: 36rem) {
    .quickentry-subheadline {
        font-size: calc(.463vw + .70833rem)
    }
}

@media (min-width: 48rem) {
    .quickentry-subheadline {
        max-width: 33.125rem;
        margin-bottom: 1rem
    }
}

@media (min-width: 62rem) {
    .quickentry-subheadline {
        margin-bottom: 1.5rem
    }
}

@media (min-width: 75rem) {
    .quickentry-subheadline {
        margin-bottom: 2.5rem
    }
}

@media (min-width: 90rem) {
    .quickentry-subheadline {
        font-size: 1.125rem
    }
}

@media (min-width: 125rem) {
    .quickentry-subheadline {
        font-size: calc(1.071vw + -.21429rem)
    }
}

.quickentry-headline {
    color: #e20079;
    display: inline-block;
    font-size: 2rem;
    margin-bottom: 1.5rem;
    -webkit-transition: font-size .6s cubic-bezier(.68, -.55, .27, 1.55), margin-bottom .3s ease;
    transition: font-size .6s cubic-bezier(.68, -.55, .27, 1.55), margin-bottom .3s ease
}

@media (min-width: 36rem) {
    .quickentry-headline {
        font-size: calc(3.704vw + .66667rem)
    }
}

@media (min-width: 48rem) {
    .quickentry-headline {
        letter-spacing: .5vw;
        line-height: 1.125;
        margin-bottom: 1rem
    }
}

@media (min-width: 62rem) {
    .quickentry-headline {
        margin-bottom: 1.5rem
    }
}

@media (min-width: 75rem) {
    .quickentry-headline {
        letter-spacing: .375rem;
        margin-bottom: 2.5rem
    }
}

@media (min-width: 90rem) {
    .quickentry-headline {
        font-size: 4rem
    }
}

@media (min-width: 125rem) {
    .quickentry-headline {
        font-size: calc(2.857vw + .42857rem)
    }
}

.quickentry-records {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start
}

.quickentry-records, .quickentry-records-request, .quickentry-records-target {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap
}

.quickentry-records-request, .quickentry-records-target {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 1.5rem;
    -webkit-transition: margin-bottom .3s ease;
    transition: margin-bottom .3s ease
}

@media (min-width: 36rem) {
    .quickentry-records-request, .quickentry-records-target {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }
}

@media (min-width: 48rem) {
    .quickentry-records-request, .quickentry-records-target {
        margin-bottom: 1rem;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-flow: column wrap;
        flex-flow: column wrap;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        width: 100%
    }
}

@media (min-width: 62rem) {
    .quickentry-records-request, .quickentry-records-target {
        margin-bottom: 1.5rem;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }
}

@media (min-width: 120rem) {
    .quickentry-records-request, .quickentry-records-target {
        margin-bottom: 2.5rem
    }
}

.quickentry-records-request label, .quickentry-records-target label {
    font-family: lato_black, Helvetica, sans-serif;
    font-weight: 900;
    color: #000;
    line-height: 1.333333333333333;
    font-size: 1.125rem;
    letter-spacing: .125rem;
    font-size: .875rem;
    line-height: 1.3;
    margin-bottom: .5rem;
    padding-bottom: 0;
    -webkit-transition: font-size .6s cubic-bezier(.68, -.55, .27, 1.55), margin-bottom .3s ease;
    transition: font-size .6s cubic-bezier(.68, -.55, .27, 1.55), margin-bottom .3s ease
}

@media (min-width: 36rem) {
    .quickentry-records-request label, .quickentry-records-target label {
        font-size: calc(.463vw + .70833rem);
        margin-bottom: 0
    }
}

@media (min-width: 48rem) {
    .quickentry-records-request label, .quickentry-records-target label {
        margin-bottom: .5rem
    }
}

@media (min-width: 62rem) {
    .quickentry-records-request label, .quickentry-records-target label {
        margin-bottom: 0
    }
}

@media (min-width: 90rem) {
    .quickentry-records-request label, .quickentry-records-target label {
        font-size: 1.125rem
    }
}

@media (min-width: 125rem) {
    .quickentry-records-request label, .quickentry-records-target label {
        font-size: calc(1.071vw + -.21429rem)
    }
}

.quickentry-records select {
    background-color: #fff;
    border-bottom: .25rem solid #000;
    font-size: 1rem;
    letter-spacing: .125rem;
    line-height: 2;
    padding: 0 2.25rem .125rem 0;
    -webkit-transition: width .3s ease;
    transition: width .3s ease;
    width: 100%
}

@media (min-width: 36rem) {
    .quickentry-records select {
        font-size: calc(.463vw + .70833rem);
        width: calc(100% - 120px)
    }
}

@media (min-width: 48rem) {
    .quickentry-records select {
        width: 100%
    }
}

@media (min-width: 62rem) {
    .quickentry-records select {
        width: calc(100% - 160px)
    }
}

@media (min-width: 120rem) {
    .quickentry-records select {
        width: 70%
    }
}

@media (min-width: 90rem) {
    .quickentry-records select {
        font-size: 1.125rem
    }
}

@media (min-width: 125rem) {
    .quickentry-records select {
        font-size: calc(1.071vw + -.21429rem)
    }
}

.quickentry-records select:invalid, .quickentry-records select[disabled] {
    color: #bdbdbd;
    font-family: lato_regular, Helvetica, sans-serif;
    font-weight: 400;
    letter-spacing: .125rem
}

.quickentry-records select:active, .quickentry-records select:focus {
    -webkit-box-shadow: none;
    box-shadow: none
}

.quickentry-records select option {
    color: #000;
    font-style: normal
}

.quickentry-records select option:first-child {
    display: none
}

.quickentry-records select[disabled] {
    cursor: default
}

.quickentry-records select[disabled]:focus {
    -webkit-box-shadow: none;
    box-shadow: none
}

.quickentry-records-headline {
    font-family: lato_black, Helvetica, sans-serif;
    font-weight: 900;
    margin-bottom: .5rem;
    padding-bottom: 1.5rem
}

.quickentry-records-headline, .quickentry-records-submit {
    color: #fff;
    line-height: 1.5;
    font-size: 1rem;
    letter-spacing: .125rem
}

.quickentry-records-submit {
    font-family: lato_bold, Helvetica, sans-serif;
    font-weight: 700;
    text-decoration: none;
    text-transform: uppercase;
    border: .1875rem solid #e20079;
    background-color: #e20079;
    padding: .5625rem 1.25rem;
    -webkit-transition: color .3s, background-color .3s, border-color .3s;
    transition: color .3s, background-color .3s, border-color .3s;
    position: relative;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    cursor: pointer;
    width: 100%
}

.quickentry-records-submit.icon-left {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

.quickentry-records-submit.icon-left svg {
    margin-right: .625rem
}

.quickentry-records-submit.icon-right {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start
}

.quickentry-records-submit.icon-right svg {
    margin-left: .625rem
}

.quickentry-records-submit.icon-only {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: .625rem
}

.quickentry-records-submit svg {
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 50%;
    background-color: #fff;
    padding: .1875rem;
    -webkit-transition: background-color .3s;
    transition: background-color .3s
}

.quickentry-records-submit svg use {
    fill: #e20079;
    -webkit-transition: fill .3s;
    transition: fill .3s
}

.quickentry-records-submit:focus, .quickentry-records-submit:hover {
    background-color: transparent;
    color: #e20079;
    border-color: #e20079
}

.quickentry-records-submit:focus svg, .quickentry-records-submit:hover svg {
    background-color: #e20079
}

.quickentry-records-submit:focus svg use, .quickentry-records-submit:hover svg use {
    fill: #fff
}

.quickentry-records-submit.disabled, .quickentry-records-submit[disabled] {
    background-color: #d7d7d7;
    color: #bdbdbd;
    border-color: #d7d7d7;
    cursor: not-allowed;
    -webkit-box-shadow: none;
    box-shadow: none
}

.quickentry-records-submit.disabled span:first-child, .quickentry-records-submit[disabled] span:first-child {
    display: none
}

.quickentry-records-submit.disabled span:last-child, .quickentry-records-submit[disabled] span:last-child {
    -webkit-transform: none;
    transform: none
}

@media (min-width: 36rem) {
    .quickentry-records-submit {
        font-size: calc(.231vw + .91667rem);
        width: auto
    }
}

@media (min-width: 90rem) {
    .quickentry-records-submit {
        font-size: 1.125rem
    }
}

@media (min-width: 125rem) {
    .quickentry-records-submit {
        font-size: calc(1.071vw + -.21429rem)
    }
}

.quickentry .more-info-link {
    color: #fff !important;
    margin-top: 1.5rem
}

.quickentry .request-data-wrapper {
    display: none
}

.quickentry-three-colored-border {
    background: #009ee0;
    height: 1rem;
    width: 100%;
    position: relative
}

.quickentry-three-colored-border:before {
    left: 0;
    background: #003378
}

.quickentry-three-colored-border:after, .quickentry-three-colored-border:before {
    content: "";
    width: 33%;
    height: 1rem;
    display: block;
    position: absolute;
    bottom: 0;
    z-index: 1
}

.quickentry-three-colored-border:after {
    right: 0;
    background: #e20079
}

.tx-px-rates-calculator .rates-calculator form {
    max-width: none
}

@media (min-width: 48rem) {
    .tx-px-rates-calculator .rates-calculator form {
        max-width: 40rem
    }
}

.tx-px-rates-calculator .rates-calculator form .form-group {
    position: relative
}

.tx-px-rates-calculator .rates-calculator .rates-calculator-invoice {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    max-width: 90rem;
    margin: 0 auto;
    padding: 0 1.5rem 3.75rem;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap
}

@media (min-width: 48rem) {
    .tx-px-rates-calculator .rates-calculator .rates-calculator-invoice {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap
    }
}

@media (min-width: 62rem) {
    .tx-px-rates-calculator .rates-calculator .rates-calculator-invoice {
        padding: 0 4.861% 3.75rem
    }
}

@media (min-width: 90rem) {
    .tx-px-rates-calculator .rates-calculator .rates-calculator-invoice {
        padding: 0 4.375rem 3.75rem
    }
}

.tx-px-rates-calculator .rates-calculator .rates-calculator-invoice-form {
    width: 100%
}

@media (min-width: 48rem) {
    .tx-px-rates-calculator .rates-calculator .rates-calculator-invoice-form {
        width: 58.3333%
    }
}

.tx-px-rates-calculator .rates-calculator .rates-calculator-invoice-result {
    width: 100%;
    -webkit-transition: width .3s ease;
    transition: width .3s ease;
    padding-top: 3.75rem
}

@media (min-width: 48rem) {
    .tx-px-rates-calculator .rates-calculator .rates-calculator-invoice-result {
        width: 38%;
        padding-top: 0
    }
}

@media (min-width: 62rem) {
    .tx-px-rates-calculator .rates-calculator .rates-calculator-invoice-result {
        width: 33%
    }
}

.tx-px-rates-calculator .rates-calculator .rates-calculator-invoice-result-list {
    border: .1875rem solid #e20079
}

.tx-px-rates-calculator .rates-calculator .rates-calculator-invoice-result-list-container {
    padding: 1.25rem
}

.tx-px-rates-calculator .rates-calculator .rates-calculator-invoice-result-list .result-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding-bottom: 1.25rem
}

.tx-px-rates-calculator .rates-calculator .rates-calculator-invoice-result-list .result-monthly-rate {
    background-color: #e20079;
    width: 100%;
    padding: 1.25rem 1.875rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    color: #fff
}

.tx-px-rates-calculator .rates-calculator .rates-calculator-contact {
    background: #f5f5f5
}

.tx-px-rates-calculator .rates-calculator .rates-calculator-contact-container {
    padding: 3.75rem 1.5rem;
    max-width: 90rem;
    margin: 0 auto
}

@media (min-width: 62rem) {
    .tx-px-rates-calculator .rates-calculator .rates-calculator-contact-container {
        padding: 3.75rem 4.861%
    }
}

@media (min-width: 90rem) {
    .tx-px-rates-calculator .rates-calculator .rates-calculator-contact-container {
        padding: 3.75rem 4.375rem
    }
}

.tx-px-rates-calculator .rates-calculator .rates-calculator-contact-container input:not(.btn-primary) {
    background-color: #fff
}

.tx-px-rates-calculator .rates-calculator .rates-calculator-contact-container > form {
    max-width: none
}

.tx-px-rates-calculator .rates-calculator .rates-calculator-contact-container > form .two-cols-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

@media (min-width: 48rem) {
    .tx-px-rates-calculator .rates-calculator .rates-calculator-contact-container > form .two-cols-group {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap
    }
}

.tx-px-rates-calculator .rates-calculator .rates-calculator-contact-container > form .two-cols-group .form-group {
    width: 100%
}

@media (min-width: 48rem) {
    .tx-px-rates-calculator .rates-calculator .rates-calculator-contact-container > form .two-cols-group .form-group {
        width: 50%
    }
}

.tx-px-rates-calculator .rates-calculator .rates-calculator-contact-container > form .two-cols-group .form-group:first-child {
    padding-right: 0
}

@media (min-width: 48rem) {
    .tx-px-rates-calculator .rates-calculator .rates-calculator-contact-container > form .two-cols-group .form-group:first-child {
        padding-right: 1.25rem
    }
}

.tx-px-rates-calculator .rates-calculator .rates-calculator-contact-container > form .btn-primary {
    width: auto
}

.tx-px-rates-calculator .rates-calculator .form-group {
    margin-bottom: 1.5rem
}

.tx-px-rates-calculator .rates-calculator .form-group.disabled {
    opacity: .4
}

.tx-px-rates-calculator .rates-calculator .form-group.disabled select {
    cursor: default
}

.hint-wrapper {
    display: inline-block;
    cursor: pointer
}

@media (min-width: 48rem) {
    .hint-wrapper {
        position: relative
    }
}

.hint-wrapper .hint-click {
    width: 1.125rem;
    height: 1.125rem;
    display: block;
    position: relative
}

.hint-wrapper .hint-click > svg {
    position: absolute;
    top: .125rem
}

.hint-wrapper .hint-text {
    display: none;
    position: absolute;
    background: #e20079;
    width: 15.625rem;
    padding: 1.25rem;
    z-index: 2;
    -webkit-box-shadow: 0 .625rem 2.5rem 0 rgba(0, 51, 120, .1);
    box-shadow: 0 .625rem 2.5rem 0 rgba(0, 51, 120, .1);
    -webkit-transform-origin: 0 1.875rem;
    transform-origin: 0 1.875rem;
    top: 1.875rem;
    left: 0;
    max-width: 100%
}

@media (min-width: 48rem) {
    .hint-wrapper .hint-text {
        top: .125rem;
        left: 2.1875rem;
        max-width: none
    }

    .hint-wrapper .hint-text:before {
        content: "";
        width: .875rem;
        height: .875rem;
        left: -.4375rem;
        top: .1875rem;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        display: block;
        background: #e20079;
        position: absolute
    }
}

.hint-wrapper .hint-text > p {
    font-size: .875rem;
    color: #fff
}

.hint-wrapper .hint-text.hidden {
    -webkit-animation-name: shrink;
    animation-name: shrink;
    -webkit-animation-duration: .3s;
    animation-duration: .3s
}

.hint-wrapper .hint-text.show {
    display: block;
    -webkit-animation-name: expand-bounce;
    animation-name: expand-bounce;
    -webkit-animation-duration: .3s;
    animation-duration: .3s
}

.rewards-grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-bottom: 2.5rem;
    margin: 0 -.625rem
}

.rewards-grid-item {
    margin: 0 .625rem;
    width: calc(100% - 20px);
    padding-bottom: 2.5rem
}

@media (min-width: 48rem) {
    .rewards-grid-item {
        width: calc(50% - 20px)
    }
}

@media (min-width: 62rem) {
    .rewards-grid-item {
        width: calc(25% - 20px)
    }
}

.rewards-grid-item-label {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column wrap;
    flex-flow: column wrap;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    cursor: pointer;
    height: 100%
}

.rewards-grid-item-input, .rewards-grid-item-label:after, .rewards-grid-item-label:before {
    display: none
}

.rewards-grid-item-input:checked ~ label .rewards-selected-icon {
    background: #e20079;
    -webkit-box-shadow: inset 0 0 0 .0625rem #e20079;
    box-shadow: inset 0 0 0 .0625rem #e20079
}

.rewards-grid-item-input:checked ~ label .rewards-selected-icon svg {
    opacity: 1;
    -webkit-transform: translateY(-125%) translateX(-50%);
    transform: translateY(-125%) translateX(-50%)
}

.rewards-grid-item-input:checked ~ label .rewards-selected-text-not-selected {
    opacity: 0;
    visibility: visible
}

.rewards-grid-item-input:checked ~ label .rewards-selected-text-selected {
    color: #e20079;
    visibility: visible;
    opacity: 1
}

.rewards-headline {
    margin-top: .75rem
}

.rewards-description {
    font-family: lato_regular, Helvetica, sans-serif;
    font-weight: 400;
    color: #000;
    line-height: 1.5rem;
    font-size: 1rem;
    letter-spacing: .0625rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: inline-block
}

.rewards-selected {
    position: relative;
    height: 1.5rem;
    width: 100%;
    -ms-flex-item-align: end;
    align-self: flex-end
}

.rewards-selected-icon {
    width: 1.5rem;
    height: 1.5rem;
    background-color: #d7d7d7;
    display: inline-block;
    -webkit-box-shadow: inset 0 0 0 .0625rem #bdbdbd;
    box-shadow: inset 0 0 0 .0625rem #bdbdbd;
    margin-right: .625rem;
    -webkit-transition: background-color .3s, -webkit-box-shadow .3s;
    transition: background-color .3s, -webkit-box-shadow .3s;
    transition: background-color .3s, box-shadow .3s;
    transition: background-color .3s, box-shadow .3s, -webkit-box-shadow .3s;
    position: relative
}

.rewards-selected-icon svg {
    width: 1rem;
    height: 1rem;
    fill: #fff;
    opacity: 0;
    -webkit-transition: opacity .3s, -webkit-transform .3s;
    transition: opacity .3s, -webkit-transform .3s;
    transition: opacity .3s, transform .3s;
    transition: opacity .3s, transform .3s, -webkit-transform .3s;
    position: absolute;
    top: 100%;
    left: 50%;
    -webkit-transform: translateY(0) translateX(-50%);
    transform: translateY(0) translateX(-50%)
}

.rewards-selected-text {
    -webkit-transition: opacity .3s;
    transition: opacity .3s;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 2.125rem;
    font-family: lato_regular, Helvetica, sans-serif;
    font-weight: 400;
    color: #000;
    line-height: 1.5rem;
    margin-bottom: 1.5rem;
    font-size: .875rem;
    font-family: lato_bold, Helvetica, sans-serif;
    font-weight: 700;
    padding: 0;
    letter-spacing: .0625rem
}

.rewards-selected-text-wrapper {
    height: 100%;
    display: inline-block
}

.rewards-selected-text-not-selected {
    color: #bdbdbd
}

.rewards-selected-text-selected {
    visibility: hidden;
    opacity: 0
}

.rewards-text {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto
}

.rewards-image {
    background-color: #f5f5f5;
    max-height: 13.25rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%
}

.rewards-image img {
    height: 100%;
    width: auto;
    max-height: 13.25rem
}

.main-search, .tx-indexedsearch-searchbox {
    margin: auto 0;
    position: relative
}

.main-search #tx-indexedsearch-searchbox-button-submit, .tx-indexedsearch-searchbox #tx-indexedsearch-searchbox-button-submit {
    opacity: 0;
    position: absolute;
    height: 100%;
    width: 2.8125rem;
    top: 0;
    right: 0;
    cursor: pointer
}

.main-search .tx-indexedsearch-searchbox-sword, .tx-indexedsearch-searchbox .tx-indexedsearch-searchbox-sword {
    color: #999;
    font-size: .8125rem;
    padding: .875rem 2.8125rem .875rem 1.25rem;
    margin: .625rem 0;
    position: relative;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M5.586 11.69L2.15 15.108a1 1 0 01-1.412 0l-.03-.032a1 1 0 010-1.417l3.458-3.442a6.5 6.5 0 111.418 1.472zM9.5 11a4.5 4.5 0 100-9 4.5 4.5 0 000 9z'/%3E%3C/svg%3E");
    background-position: right .9375rem center;
    background-repeat: no-repeat;
    background-size: 1rem 1rem
}

.tx-indexedsearch-searchbox form {
    margin: 0;
    width: 100%
}

@media (min-width: 36rem) {
    .tx-indexedsearch-searchbox form {
        width: 50%
    }
}

@media (min-width: 48rem) {
    .tx-indexedsearch-searchbox form {
        width: 30%
    }
}

.search-count-wrapper {
    margin-bottom: 1.25rem
}

.search-result-list-wrapper {
    display: block
}

.search-result-list-wrapper .search-list-item {
    margin-bottom: 1.25rem;
    padding-bottom: 0;
    width: 100%;
    -webkit-transition: min-height .6s ease, width .3s ease;
    transition: min-height .6s ease, width .3s ease
}

.search-result-list-wrapper .search-list-item .search-list-item-content-wrapper {
    display: block;
    background-color: #f5f5f5;
    -webkit-transition: background .3s ease;
    transition: background .3s ease;
    padding: 1.25rem 1.25rem 5rem;
    width: 100%;
    position: relative
}

@media (min-width: 48rem) {
    .search-result-list-wrapper .search-list-item .search-list-item-content-wrapper {
        padding: 1.5625rem 6.25rem 1.5625rem 2.5rem
    }
}

.search-result-list-wrapper .search-list-item .search-list-item-content-wrapper .search-list-item-description {
    -webkit-transition: color .3s ease;
    transition: color .3s ease;
    margin-bottom: 0
}

.search-result-list-wrapper .search-list-item .search-list-item-content-wrapper .search-list-item-description .tx-indexedsearch-redMarkup {
    color: #e20079;
    -webkit-transition: color .3s ease;
    transition: color .3s ease
}

.search-result-list-wrapper .search-list-item .search-list-item-content-wrapper .search-list-item-title {
    -webkit-transition: color .3s ease;
    transition: color .3s ease
}

.search-result-list-wrapper .search-list-item .search-list-item-content-wrapper:hover {
    background-color: #e20079
}

.search-result-list-wrapper .search-list-item .search-list-item-content-wrapper:hover .search-list-item-description {
    color: #fff
}

.search-result-list-wrapper .search-list-item .search-list-item-content-wrapper:hover .search-list-item-description .tx-indexedsearch-redMarkup {
    color: #000
}

.search-result-list-wrapper .search-list-item .search-list-item-content-wrapper:hover .search-list-item-title {
    color: #fff
}

.search-result-list-wrapper .search-list-item .search-list-item-content-wrapper:hover .search-list-more-link use {
    fill: #fff
}

.search-result-list-wrapper .search-list-item .search-list-item-content-wrapper .search-list-more-link {
    width: 2.5rem;
    height: 2.5rem;
    line-height: 0;
    position: absolute;
    left: 0;
    bottom: 1.25rem;
    -webkit-transform: translateX(50%);
    transform: translateX(50%)
}

@media (min-width: 48rem) {
    .search-result-list-wrapper .search-list-item .search-list-item-content-wrapper .search-list-more-link {
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        right: 1.875rem;
        left: auto
    }
}

.search-result-list-wrapper .search-list-item .search-list-item-content-wrapper .search-list-more-link use {
    fill: #e20079;
    -webkit-transition: fill .3s ease;
    transition: fill .3s ease
}

.news-item-link-wrapper:hover .search-result-list-wrapper .search-list-item .search-list-item-content-wrapper .search-list-more-link use {
    fill: #fff
}

.tx-px-seminars {
    margin-bottom: 2.5rem
}

.seminar-list-filter {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    background-color: #f5f5f5;
    top: 100%;
    z-index: 1;
    width: 100%
}

.seminar-list-filter, .seminar-list-filter.visible {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.seminar-list-filter-box {
    display: none
}

.seminar-list-filter-wrapper {
    margin-bottom: 1.25rem;
    max-width: 100%
}

@media (min-width: 48rem) {
    .seminar-list-filter-wrapper {
        margin-left: auto;
        max-width: 18.75rem
    }
}

.seminar-list-filter-button {
    display: block;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #f1f1f1;
    padding: 1rem;
    color: #000;
    border: none;
    outline: none;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: background-color .3s, color .3s;
    transition: background-color .3s, color .3s;
    font-size: .875rem;
    width: 100%;
    font-family: lato_regular, Helvetica, sans-serif;
    font-weight: 400;
    cursor: pointer;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 16 16'%3E%3Cdefs%3E%3Cpath fill='%23E20079' id='a' d='M9.883 13a.216.216 0 01-.155-.066L5.064 8.158a.227.227 0 010-.317l4.664-4.775a.216.216 0 01.31 0l.897.919a.228.228 0 010 .317L7.324 8l3.612 3.698a.228.228 0 010 .317l-.898.92a.218.218 0 01-.155.065z'/%3E%3C/defs%3E%3Cuse transform='rotate(-90 8 8)' xlink:href='%23a'/%3E%3C/svg%3E");
    background-position: right .75rem center;
    background-size: 1.5rem 1.5rem;
    background-repeat: no-repeat
}

.seminar-list-filter-button:active, .seminar-list-filter-button:focus {
    -webkit-box-shadow: .125rem .125rem .625rem 0 rgba(54, 175, 253, .2);
    box-shadow: .125rem .125rem .625rem 0 rgba(54, 175, 253, .2);
    background-color: #fff
}

.seminar-list-filter-button::-ms-expand {
    display: none
}

.seminar-list-filter-button.active {
    color: #fff;
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 16 16'%3E%3Cdefs%3E%3Cpath fill='%23fff' id='a' d='M9.883 13a.216.216 0 01-.155-.066L5.064 8.158a.227.227 0 010-.317l4.664-4.775a.216.216 0 01.31 0l.897.919a.228.228 0 010 .317L7.324 8l3.612 3.698a.228.228 0 010 .317l-.898.92a.218.218 0 01-.155.065z'/%3E%3C/defs%3E%3Cuse fill='%23fff' transform='rotate(-90 8 8)' xlink:href='%23a'/%3E%3C/svg%3E") calc(100% - 12px)/24px no-repeat;
    background-color: #e20079
}

.seminar-list-filter-item {
    -webkit-transition: color .3s;
    transition: color .3s;
    width: 100%;
    font-size: .875rem;
    padding: 1rem
}

.seminar-list-filter-item:hover {
    background-color: #e20079;
    color: #fff;
    -webkit-transition: color .3s, background-color .3s;
    transition: color .3s, background-color .3s
}

.seminar-list-container {
    background-color: #f5f5f5;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1.25rem;
    margin-bottom: 1.25rem;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

@media (min-width: 48rem) {
    .seminar-list-container {
        padding: 1.5625rem 1.25rem;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row
    }
}

.seminar-list-container:hover {
    background-color: #e20079;
    -webkit-transition: background-color .3s;
    transition: background-color .3s
}

.seminar-list-container:hover .seminar-list-date, .seminar-list-container:hover .seminar-list-name, .seminar-list-container:hover .seminar-list-speaker {
    color: #fff;
    -webkit-transition: color .3s;
    transition: color .3s
}

.seminar-list-container:hover .seminar-list-icon svg use {
    -webkit-transition: fill .3s;
    transition: fill .3s;
    fill: #fff !important
}

.seminar-list-details {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

@media (min-width: 48rem) {
    .seminar-list-details {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }
}

@media (min-width: 48rem) {
    .seminar-list-name {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 55%;
        flex: 0 0 55%;
        padding: 0 .625rem;
        margin-bottom: 0
    }
}

@media (min-width: 48rem) {
    .seminar-list-date, .seminar-list-speaker {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 22.5%;
        flex: 0 0 22.5%;
        padding: 0 .625rem;
        margin-bottom: 0
    }
}

.seminar-list-date span {
    padding-left: .625rem
}

@media (min-width: 48rem) {
    .seminar-list-date span {
        padding: 0
    }
}

.seminar-list-date span:before {
    content: "|";
    white-space: pre;
    padding-right: .625rem
}

@media (min-width: 48rem) {
    .seminar-list-date span:before {
        content: "\A";
        white-space: pre
    }
}

.seminar-list-link {
    padding-bottom: 0 !important;
    line-height: 0 !important
}

.seminar-list-icon {
    width: 1.5rem;
    height: 1.5rem;
    display: inline-block;
    position: absolute;
    margin-left: .625rem;
    -webkit-transition: -webkit-transform .3s ease;
    transition: -webkit-transform .3s ease;
    transition: transform .3s ease;
    transition: transform .3s ease, -webkit-transform .3s ease;
    cursor: pointer;
    position: relative;
    height: 2.5rem;
    width: 2.5rem;
    display: inline-block !important
}

@media (min-width: 75rem) {
    .seminar-list-icon {
        display: none
    }
}

.seminar-list-icon svg {
    width: 100%;
    height: 100%
}

.seminar-list-icon svg use {
    fill: #fff;
    -webkit-transition: fill .3s ease;
    transition: fill .3s ease
}

.seminar-list-icon:hover svg use {
    fill: #e20079
}

.seminar-list-icon svg use {
    fill: #e20079 !important
}

.seminar-detail-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.seminar-detail-wrapper-left {
    width: 100%
}

@media (min-width: 62rem) {
    .seminar-detail-wrapper-left {
        width: 58.3333%;
        min-width: 40.625rem
    }
}

@media (min-width: 62rem) {
    .seminar-detail-wrapper {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap
    }
}

.seminar-detail-name {
    padding-bottom: .3125rem
}

.seminar-detail-headline {
    padding-bottom: 2.5rem
}

.seminar-detail-date-container {
    background-color: #f5f5f5;
    padding: .9375rem;
    width: 100%;
    position: relative
}

@media (min-width: 62rem) {
    .seminar-detail-date-container {
        padding: 1.875rem
    }
}

.seminar-detail-date-headline {
    padding-bottom: .625rem
}

.seminar-detail-date-table {
    width: auto;
    font-size: 1rem;
    letter-spacing: .0625rem
}

.seminar-detail-date-table td {
    padding: 0 1.875rem .625rem 0
}

.seminar-detail-date-price {
    padding-bottom: 0
}

.seminar-detail-date-button {
    padding-top: .9375rem
}

@media (min-width: 62rem) {
    .seminar-detail-date-button {
        position: absolute;
        right: 1.875rem;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        padding-top: 0
    }
}

.seminar-detail-date-button a {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.seminar-detail-speaker-container {
    margin-bottom: 2.5rem
}

@media (min-width: 62rem) {
    .seminar-detail-speaker-container {
        padding-left: 1.25rem;
        width: 33%
    }
}

.seminar-detail-speaker-details {
    margin: 1.5625rem 0
}

.seminar-detail-speaker-details h3, .seminar-detail-speaker-details p {
    padding-bottom: 0
}

.seminar-detail-speaker-link {
    width: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.seminar-detail-content {
    max-width: 46.875rem
}

.seminar-detail-description, .seminar-detail-details {
    margin: 2.5rem 0
}

.seminar-detail-details h3 {
    padding-bottom: .3125rem
}

.seminar-detail-details-place-label, .seminar-detail-details p:last-of-type {
    padding-bottom: 0
}

.seminar-detail-details-additionalInformation, .seminar-detail-details-place {
    padding-bottom: .625rem
}

.seminar-detail-form form {
    margin: 0;
    max-width: 26.25rem
}

.seminar-detail-form .radio-label:not(:last-of-type) {
    padding-right: 1.5625rem
}

.seminar-detail-form .radio-wrapper {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.seminar-detail-form-submit {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    max-width: 100%
}

.seminar-detail-form .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 100%;
    flex: 1 0 100%
}

.seminar-detail-form .row div.big {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 60%;
    flex: 1 0 60%
}

.seminar-detail-form .row div.small {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%
}

.seminar-detail-form .ce-text, .seminar-detail-form .frame-layout-0 {
    padding: 0
}

.table-container {
    overflow-x: auto;
    width: 100%
}

.ce-table {
    border-spacing: 0;
    border-collapse: collapse;
    width: 100%;
    zoom: 1
}

.ce-table tbody tr:nth-child(odd) {
    background: #fff
}

.ce-table tbody tr:nth-child(2n) {
    background: #f1f1f1
}

.ce-table tbody tr:nth-child(2n) td {
    border-color: #fff
}

.ce-table th {
    font-family: lato_black, Helvetica, sans-serif;
    font-weight: 900;
    color: #000;
    line-height: 1.714285714285714;
    font-size: .875rem;
    letter-spacing: .125rem;
    border-right: .0625rem solid #fff;
    color: #fff;
    background: #e20079;
    font-size: 1rem;
    padding: 1rem .75rem
}

@media (min-width: 62rem) {
    .ce-table th {
        border: none
    }
}

.ce-table th:last-child {
    border: none
}

.ce-table td {
    font-family: lato_regular, Helvetica, sans-serif;
    font-weight: 400;
    color: #000;
    line-height: 1.5rem;
    font-size: 1rem;
    letter-spacing: .0625rem;
    border-right: .0625rem solid #f1f1f1;
    margin-bottom: 0;
    min-width: 9.375rem;
    padding: 1rem .75rem
}

@media (min-width: 36rem) {
    .ce-table td {
        min-width: 12.5rem
    }
}

@media (min-width: 62rem) {
    .ce-table td {
        border: none;
        min-width: auto
    }
}

.ce-table td:last-child {
    border: none
}

.teaser-container {
    width: 100%
}

.teaser-container .main-carousel {
    padding-bottom: 1.875rem
}

.teaser-container .main-carousel .flickity-button-icon path {
    fill: #fff
}

.teaser-container .main-carousel .flickity-button-icon:hover path {
    background: #ff49aa
}

.teaser-container .main-carousel .flickity-button[disabled] .flickity-button-icon:hover path {
    fill: #e20079
}

.teaser-container .main-carousel .flickity-page-dots {
    bottom: .9375rem;
    -webkit-transform: translate(-50%, 50%);
    transform: translate(-50%, 50%)
}

.teaser-container .main-carousel.slider-option-none {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%
}

.teaser-container .main-carousel.slider-option-none .carousel-cell {
    margin: 0 0 1.25rem;
    cursor: auto
}

.teaser-container .main-carousel.slider-option-none.teaser-column-1 .carousel-cell {
    width: 100%
}

@media (min-width: 36rem) {
    .teaser-container .main-carousel.slider-option-none.teaser-column-2 .carousel-cell {
        width: calc(50% - 10px)
    }

    .teaser-container .main-carousel.slider-option-none.teaser-column-2 .carousel-cell:nth-of-type(odd) {
        margin-right: 1.25rem
    }
}

.teaser-container .main-carousel.slider-option-none:after {
    content: ""
}

@media (min-width: 36rem) {
    .teaser-container .main-carousel.slider-option-none {
        margin-bottom: .625rem
    }
}

@media (min-width: 48rem) {
    .teaser-container .main-carousel.slider-option-none {
        margin-bottom: 1.875rem
    }
}

@media (max-width: 35.9375rem) {
    .teaser-container .main-carousel.slider-option-none .carousel-cell:last-child {
        margin-bottom: .625rem
    }
}

.teaser-container .main-carousel.slider-option-mobile {
    margin-bottom: .625rem
}

.teaser-container .main-carousel.slider-option-mobile.teaser-column-1 .carousel-cell {
    width: calc(90% - 10px)
}

@media (min-width: 36rem) {
    .teaser-container .main-carousel.slider-option-mobile.teaser-column-1 .carousel-cell {
        width: calc(90% - 20px)
    }
}

@media (min-width: 48rem) {
    .teaser-container .main-carousel.slider-option-mobile.teaser-column-1 .carousel-cell {
        width: calc(95% - 20px)
    }
}

@media (min-width: 75rem) {
    .teaser-container .main-carousel.slider-option-mobile.teaser-column-1 .carousel-cell {
        width: 100%
    }
}

.teaser-container .main-carousel.slider-option-mobile.teaser-column-2 {
    width: 100%
}

.teaser-container .main-carousel.slider-option-mobile.teaser-column-2 .carousel-cell {
    width: calc(90% - 10px)
}

@media (min-width: 36rem) {
    .teaser-container .main-carousel.slider-option-mobile.teaser-column-2 .carousel-cell {
        width: calc(90% - 20px)
    }
}

@media (min-width: 48rem) {
    .teaser-container .main-carousel.slider-option-mobile.teaser-column-2 .carousel-cell {
        width: calc(44% - 10px)
    }
}

@media (min-width: 75rem) {
    .teaser-container .main-carousel.slider-option-mobile.teaser-column-2 .carousel-cell {
        width: calc(50% - 10px)
    }
}

.teaser-container .main-carousel.slider-option-mobile:after {
    content: "flickity";
    display: none
}

@media (min-width: 48rem) {
    .teaser-container .main-carousel.slider-option-mobile {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: 1.875rem
    }

    .teaser-container .main-carousel.slider-option-mobile .carousel-cell {
        margin: 0 0 1.25rem;
        cursor: auto
    }

    .teaser-container .main-carousel.slider-option-mobile.teaser-column-1 .carousel-cell {
        width: 100%
    }
}

@media (min-width: 48rem) and (min-width: 36rem) {
    .teaser-container .main-carousel.slider-option-mobile.teaser-column-2 .carousel-cell {
        width: calc(50% - 10px)
    }

    .teaser-container .main-carousel.slider-option-mobile.teaser-column-2 .carousel-cell:nth-of-type(odd) {
        margin-right: 1.25rem
    }
}

@media (min-width: 48rem) {
    .teaser-container .main-carousel.slider-option-mobile:after {
        content: ""
    }
}

.teaser-container .main-carousel.slider-option-both {
    margin-bottom: 1.875rem
}

.teaser-container .main-carousel.slider-option-both.teaser-column-1 .carousel-cell {
    width: calc(90% - 10px)
}

@media (min-width: 36rem) {
    .teaser-container .main-carousel.slider-option-both.teaser-column-1 .carousel-cell {
        width: calc(90% - 20px)
    }
}

@media (min-width: 48rem) {
    .teaser-container .main-carousel.slider-option-both.teaser-column-1 .carousel-cell {
        width: calc(95% - 20px)
    }
}

@media (min-width: 75rem) {
    .teaser-container .main-carousel.slider-option-both.teaser-column-1 .carousel-cell {
        width: 100%
    }
}

.teaser-container .main-carousel.slider-option-both.teaser-column-2 {
    width: 100%
}

.teaser-container .main-carousel.slider-option-both.teaser-column-2 .carousel-cell {
    width: calc(90% - 10px)
}

@media (min-width: 36rem) {
    .teaser-container .main-carousel.slider-option-both.teaser-column-2 .carousel-cell {
        width: calc(90% - 20px)
    }
}

@media (min-width: 48rem) {
    .teaser-container .main-carousel.slider-option-both.teaser-column-2 .carousel-cell {
        width: calc(44% - 10px)
    }
}

@media (min-width: 75rem) {
    .teaser-container .main-carousel.slider-option-both.teaser-column-2 .carousel-cell {
        width: calc(50% - 10px)
    }
}

.teaser-container .main-carousel.slider-option-both:after {
    content: "flickity";
    display: none
}

@media (min-width: 48rem) {
    .teaser-container .main-carousel.slider-option-both {
        margin-bottom: 3.125rem
    }
}

.teaser-container .main-carousel .carousel-cell {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.teaser-container .main-carousel .teaser {
    position: relative;
    width: 100%
}

.teaser-container .main-carousel .teaser-image {
    width: 100%;
    height: auto
}

.teaser-container .main-carousel .teaser-image-wrapper {
    width: 100%;
    line-height: 0
}

.frame-type-text .ce-headline-101 {
    text-align: left
}

.frame-type-text .ce-headline-102 {
    text-align: center
}

.frame-type-text .ce-headline-103 {
    text-align: right
}

.ce-image.ce-intext.ce-left, .ce-image.ce-intext.ce-right, .ce-textpic.ce-intext.ce-left, .ce-textpic.ce-intext.ce-right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap
}

@media (min-width: 48rem) {
    .ce-image.ce-intext.ce-left, .ce-image.ce-intext.ce-right, .ce-textpic.ce-intext.ce-left, .ce-textpic.ce-intext.ce-right {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap
    }
}

@media (min-width: 48rem) {
    .ce-image.ce-intext.ce-left .ce-textpic-wrapper, .ce-image.ce-intext.ce-right .ce-textpic-wrapper, .ce-textpic.ce-intext.ce-left .ce-textpic-wrapper, .ce-textpic.ce-intext.ce-right .ce-textpic-wrapper {
        width: 50%
    }
}

.ce-image.ce-intext.ce-left .gallery-row, .ce-image.ce-intext.ce-right .gallery-row, .ce-textpic.ce-intext.ce-left .gallery-row, .ce-textpic.ce-intext.ce-right .gallery-row {
    display: inline-block;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    margin-bottom: 1.25rem
}

@media (min-width: 48rem) {
    .ce-image.ce-intext.ce-left .gallery-row, .ce-image.ce-intext.ce-right .gallery-row, .ce-textpic.ce-intext.ce-left .gallery-row, .ce-textpic.ce-intext.ce-right .gallery-row {
        margin-bottom: .625rem;
        margin-right: 8.333333333333332%;
        width: 41.66666666666667%
    }
}

@media (min-width: 48rem) {
    .ce-image.ce-intext.ce-right .gallery-row, .ce-textpic.ce-intext.ce-right .gallery-row {
        margin-left: 8.333333333333332%;
        margin-right: 0;
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
        text-align: right
    }
}

@media (min-width: 48rem) {
    .ce-image.ce-intext.ce-right .gallery-row .gallery-item, .ce-textpic.ce-intext.ce-right .gallery-row .gallery-item {
        display: inline-block
    }
}

.ce-image .gallery-item, .ce-textpic .gallery-item {
    margin-bottom: 1.25rem;
    max-width: 33.125rem;
    width: 100%
}

.ce-image .gallery-item:last-child, .ce-textpic .gallery-item:last-child {
    margin-bottom: 0
}

.ce-image .gallery-item .image-embed-item, .ce-textpic .gallery-item .image-embed-item {
    display: block;
    height: auto;
    width: auto;
    max-width: 100%
}

@media (min-width: 48rem) {
    .ce-image .gallery-item .image-embed-item, .ce-textpic .gallery-item .image-embed-item {
        margin: 0 auto
    }
}

.ce-image .gallery-item .video-embed, .ce-textpic .gallery-item .video-embed {
    position: relative;
    padding-bottom: 56.25%
}

.ce-image .gallery-item .video-embed iframe, .ce-image .gallery-item .video-embed video, .ce-textpic .gallery-item .video-embed iframe, .ce-textpic .gallery-item .video-embed video {
    border: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

.video-playlist-tabs {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem
}

.video-playlist-tabs-upper-content {
    margin-bottom: 2.5rem
}

.video-playlist-tabs-upper-content .video-playlist-tabs-headline {
    font-family: lato_black, Helvetica, sans-serif;
    font-weight: 900;
    color: #000;
    line-height: 1.166666666666667;
    margin-bottom: 1.5rem;
    text-transform: uppercase;
    font-size: 1.625rem;
    letter-spacing: .25rem;
    -webkit-transition: font-size .6s cubic-bezier(.68, -.55, .27, 1.55);
    transition: font-size .6s cubic-bezier(.68, -.55, .27, 1.55)
}

@media (min-width: 36rem) {
    .video-playlist-tabs-upper-content .video-playlist-tabs-headline {
        font-size: calc(2.546vw + .70833rem)
    }
}

@media (min-width: 90rem) {
    .video-playlist-tabs-upper-content .video-playlist-tabs-headline {
        font-size: 3rem
    }
}

@media (min-width: 125rem) {
    .video-playlist-tabs-upper-content .video-playlist-tabs-headline {
        font-size: calc(2.857vw + -.57143rem)
    }
}

.video-playlist-tabs-upper-content .video-playlist-tabs-subheadline {
    font-family: lato_black, Helvetica, sans-serif;
    font-weight: 900;
    color: #000;
    line-height: 1.333333333333333;
    margin-bottom: 2rem;
    text-transform: uppercase;
    font-size: 1.5rem;
    letter-spacing: .125rem;
    -webkit-transition: font-size .6s cubic-bezier(.68, -.55, .27, 1.55);
    transition: font-size .6s cubic-bezier(.68, -.55, .27, 1.55)
}

@media (min-width: 36rem) {
    .video-playlist-tabs-upper-content .video-playlist-tabs-subheadline {
        font-size: calc(.231vw + 1.29167rem)
    }
}

@media (min-width: 90rem) {
    .video-playlist-tabs-upper-content .video-playlist-tabs-subheadline {
        font-size: 1.5rem
    }
}

@media (min-width: 125rem) {
    .video-playlist-tabs-upper-content .video-playlist-tabs-subheadline {
        font-size: calc(2.857vw + -2.07143rem)
    }
}

.video-playlist-tabs-upper-content .video-playlist-tabs-headline + .video-playlist-tabs-rte-content, .video-playlist-tabs-upper-content .video-playlist-tabs-subheadline + .video-playlist-tabs-rte-content {
    margin-top: 1.5rem
}

.video-playlist-tabs-upper-content .video-playlist-tabs-rte-content p {
    line-height: 2rem
}

.video-playlist-tabs-lower-content {
    display: block
}

@media (min-width: 48rem) {
    .video-playlist-tabs-lower-content {
        height: 100%
    }
}

.video-playlist-tabs-lower-content .accordion {
    padding: 0
}

.video-playlist-tabs-lower-content .accordion-body {
    padding-left: 0
}

.video-playlist-tabs-lower-content .accordion-content {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important
}

.video-playlist-tabs-lower-content .accordion .arrow-right {
    display: none
}

@media (min-width: 48rem) {
    .video-playlist-tabs-lower-content .accordion {
        display: none
    }

    .video-playlist-tabs-lower-content .accordion .arrow-right {
        display: inline
    }
}

.video-playlist-tabs-lower-content .accordion .tab-image {
    margin: 0 auto;
    max-width: 19.375rem;
    width: 100%
}

@media (min-width: 36rem) {
    .video-playlist-tabs-lower-content .accordion-content-media + .accordion-content-text {
        width: calc(70% - 20px)
    }
}

.video-playlist-tabs-lower-content .accordion-content-text {
    width: 100%
}

.video-playlist-tabs-lower-content .accordion .tab-button-text {
    text-transform: uppercase
}

.video-playlist-tabs-lower-content .accordion-item:last-of-type {
    padding-bottom: 0
}

.video-playlist-tabs-lower-content .accordion .tab-content-rte-content-main-content {
    padding-bottom: 5rem;
    position: relative
}

@media (min-width: 48rem) {
    .video-playlist-tabs-lower-content .accordion .tab-content-rte-content-main-content {
        padding-bottom: 0
    }
}

.video-playlist-tabs-lower-content .accordion .tab-content-rte-content-main-content .video-metadata-wrapper h2 {
    font-size: 1rem
}

.video-playlist-tabs-lower-content .accordion .tab-content-rte-content-sidebar-content {
    background-color: #f5f5f5;
    padding: 1.25rem 0
}

.video-playlist-tabs-lower-content .video-playlist-tabs-buttons {
    display: none;
    position: relative
}

@media (min-width: 48rem) {
    .video-playlist-tabs-lower-content .video-playlist-tabs-buttons {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        width: 100%;
        margin-bottom: 5.5rem
    }
}

.video-playlist-tabs-lower-content .video-playlist-tabs-buttons .tab-button {
    cursor: pointer;
    display: inline-block;
    -webkit-transition: background-color .3s;
    transition: background-color .3s;
    white-space: nowrap;
    background-color: #f5f5f5;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    padding: .75rem;
    text-align: center
}

.video-playlist-tabs-lower-content .video-playlist-tabs-buttons .tab-button:not(:last-of-type) {
    margin-right: .25rem
}

.video-playlist-tabs-lower-content .video-playlist-tabs-buttons .tab-button-icon {
    display: inline-block;
    height: 1.5rem;
    width: 1.5rem;
    margin-right: .5rem
}

.video-playlist-tabs-lower-content .video-playlist-tabs-buttons .tab-button-text {
    display: inline-block;
    font-size: .875rem;
    font-weight: 700;
    line-height: 1.5rem;
    color: #000;
    -webkit-transition: color .3s;
    transition: color .3s;
    letter-spacing: 2px
}

.video-playlist-tabs-lower-content .video-playlist-tabs-buttons .tab-button .arrow-right {
    height: 100%;
    width: 100%
}

.video-playlist-tabs-lower-content .video-playlist-tabs-buttons .tab-button .arrow-right path {
    -webkit-transition: fill .3s;
    transition: fill .3s;
    fill: #003378
}

.video-playlist-tabs-lower-content .video-playlist-tabs-buttons .tab-button:hover {
    background-color: #e20079;
    -webkit-transition: background-color .3s;
    transition: background-color .3s
}

.video-playlist-tabs-lower-content .video-playlist-tabs-buttons .tab-button:hover .tab-button-text {
    color: #fff;
    -webkit-transition: color .3s;
    transition: color .3s
}

.video-playlist-tabs-lower-content .video-playlist-tabs-buttons .tab-button:hover .arrow-right path {
    -webkit-transition: fill .3s;
    transition: fill .3s;
    fill: #fff
}

.video-playlist-tabs-lower-content .video-playlist-tabs-buttons .tab-button.active {
    background-color: #e20079
}

.video-playlist-tabs-lower-content .video-playlist-tabs-buttons .tab-button.active:hover {
    background-color: #ff49aa
}

.video-playlist-tabs-lower-content .video-playlist-tabs-buttons .tab-button.active .tab-button-text {
    color: #fff
}

.video-playlist-tabs-lower-content .video-playlist-tabs-content-wrapper {
    display: none;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

@media (min-width: 48rem) {
    .video-playlist-tabs-lower-content .video-playlist-tabs-content-wrapper {
        display: inline-block;
        width: 100%
    }
}

.video-playlist-tabs-lower-content .video-playlist-tabs-content-wrapper .tab-content {
    display: none;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

@media (min-width: 64rem) {
    .video-playlist-tabs-lower-content .video-playlist-tabs-content-wrapper .tab-content {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row
    }
}

.video-playlist-tabs-lower-content .video-playlist-tabs-content-wrapper .tab-content.active {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-animation-name: tabAnimation;
    animation-name: tabAnimation;
    -webkit-animation-duration: .6s;
    animation-duration: .6s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards
}

.video-playlist-tabs-lower-content .video-playlist-tabs-content-wrapper .tab-content-rte-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
    max-height: 38.75rem;
    padding-bottom: 5rem;
    position: relative;
    height: 100%
}

.video-playlist-tabs-lower-content .video-playlist-tabs-content-wrapper .tab-content-rte-content h2, .video-playlist-tabs-lower-content .video-playlist-tabs-content-wrapper .tab-content-rte-content h3, .video-playlist-tabs-lower-content .video-playlist-tabs-content-wrapper .tab-content-rte-content h4, .video-playlist-tabs-lower-content .video-playlist-tabs-content-wrapper .tab-content-rte-content h5, .video-playlist-tabs-lower-content .video-playlist-tabs-content-wrapper .tab-content-rte-content h6 {
    margin-bottom: 1.375rem
}

.video-playlist-tabs-lower-content .video-playlist-tabs-content-wrapper .tab-content-rte-content-main-content {
    width: calc(66.6666% - 10px);
    background-color: #f5f5f5
}

.video-playlist-tabs-lower-content .video-playlist-tabs-content-wrapper .tab-content-rte-content-sidebar-content {
    background-color: #f5f5f5;
    width: calc(33.33333% - 10px);
    padding: 1.5rem;
    overflow-y: scroll;
    max-height: 32.5rem
}

.video-playlist-tabs-lower-content .video-playlist-tabs-content-wrapper .tab-content-rte-content-link {
    font-family: lato_regular, Helvetica, sans-serif;
    font-weight: 400;
    color: #000;
    line-height: 1.5rem;
    font-size: 1rem;
    letter-spacing: .0625rem;
    font-family: lato_bold, Helvetica, sans-serif;
    font-weight: 700;
    color: #e20079;
    margin-bottom: 0;
    -webkit-transition: color .3s;
    transition: color .3s;
    text-decoration: none;
    font-size: .875rem;
    line-height: 1.75rem
}

.video-playlist-tabs-lower-content .video-playlist-tabs-content-wrapper .tab-content-rte-content-link:hover {
    color: #000
}

.video-playlist-tabs-lower-content .video-playlist-tabs-content-wrapper .tab-content .tab-image {
    width: 100%;
    max-width: 19.375rem;
    margin-bottom: 1.25rem
}

@media (min-width: 64rem) {
    .video-playlist-tabs-lower-content .video-playlist-tabs-content-wrapper .tab-content .tab-image {
        margin-bottom: 0;
        margin-right: 1.25rem
    }
}

.video-playlist-tabs-lower-content .video-playlist-tabs-content-wrapper .tab-content .tab-image + .tab-content-rte-content {
    -webkit-transition: all .3s ease;
    transition: all .3s ease
}

@media (min-width: 75rem) {
    .video-playlist-tabs-lower-content .video-playlist-tabs-content-wrapper .tab-content .tab-image + .tab-content-rte-content {
        width: calc(100% - 330px)
    }
}

.video-playlist-tabs-lower-content .tab-video-wrapper {
    width: 100%;
    display: block
}

.video-playlist-tabs .video-thumbnail-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column wrap;
    flex-flow: column wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    cursor: pointer;
    z-index: 9999;
    -webkit-transition: color .3s;
    transition: color .3s
}

@media (min-width: 62rem) {
    .video-playlist-tabs .video-thumbnail-wrapper {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap
    }
}

.video-playlist-tabs .video-thumbnail-wrapper.active, .video-playlist-tabs .video-thumbnail-wrapper:hover {
    color: #e20079
}

.video-playlist-tabs .video-thumbnail-wrapper:not(:last-of-type) {
    padding-bottom: 1.5rem
}

.video-playlist-tabs .video-thumbnail-wrapper .video-thumbnail-wrapper-image {
    width: 75%
}

@media (min-width: 62rem) {
    .video-playlist-tabs .video-thumbnail-wrapper .video-thumbnail-wrapper-image {
        width: 33%
    }
}

.video-playlist-tabs .video-thumbnail-wrapper .video-thumbnail-wrapper-text {
    width: 75%;
    padding: .3125rem 0 0
}

@media (min-width: 62rem) {
    .video-playlist-tabs .video-thumbnail-wrapper .video-thumbnail-wrapper-text {
        padding: 0 0 0 1.5rem
    }
}

.video-playlist-tabs .video-thumbnail-wrapper .video-thumbnail-wrapper-text p {
    font-size: .875rem;
    line-height: 1.5rem;
    letter-spacing: .0625rem
}

.video-playlist-tabs .video-thumbnail-wrapper .hidden-youtube-link, .video-playlist-tabs .video-thumbnail-wrapper .video-metadata-hidden {
    visibility: hidden !important;
    display: none !important
}

.video-playlist-tabs .video-metadata-wrapper {
    position: absolute;
    bottom: 0
}

.video-playlist-tabs .video-metadata-wrapper h2 {
    text-transform: none
}

.youtube-video-wrapper {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden
}

.youtube-video-wrapper embed, .youtube-video-wrapper iframe, .youtube-video-wrapper object {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

@-webkit-keyframes tabAnimation {
    0% {
        opacity: 0;
        display: none
    }
    1% {
        display: block
    }
    to {
        opacity: 1
    }
}

@keyframes tabAnimation {
    0% {
        opacity: 0;
        display: none
    }
    1% {
        display: block
    }
    to {
        opacity: 1
    }
}

.content-type-video .video-wrapper {
    position: relative;
    padding-bottom: 56.25%;
    overflow: hidden;
    width: 100%;
    height: auto
}

.content-type-video .video-wrapper iframe, .content-type-video .video-wrapper video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none
}

.text-center{
    text-align: center;
}

.text-left{
    text-align: left;
}

.text-right{
    text-align: right;
}

.text-justify{
    text-align:justify
}