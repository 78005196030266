.form-error {
  font-size: .75rem;
  color: $primary;
}

.has-error label {
  //color: $primary;
}

.seminar-detail-form {
  ::placeholder {
    color: #000;
  }

  .checkbox-label {

    font-size: 1rem;
    color: #000;

    .checkmark {
      border: .125rem solid #000;
    }

  }
}

#praxistrainerMasterclass-1241-date-1 {
  display: none;
}

.frame-type-form_formframework {
  form {
    h2 {
      font-family: lato_black, Helvetica, sans-serif;
      font-weight: 900;
      color: #000;
      line-height: 1.7142857143;
      font-size: 0.875rem;
      letter-spacing: 0.125rem;
      padding-bottom: 0.3125rem;
      display: inline-block;
      text-transform: capitalize;
      margin-bottom:0;
    }
  }
}
