@import "../../public/typo3conf/ext/px_basis_config/Resources/Public/Css/styles.min";
@import "../../public/typo3conf/ext/px_seminars/Resources/Public/Css/style";
@import "variables";


.bootstrap {
  @import "bootstrap";

}

form.bootstrap.fullwidth {
  max-width: none;

  .hidden {
    display: none;
  }

}

.btn-block {
  display: block;
}

@import 'components/videoconsult';
@import 'components/alerts';
@import 'components/corona';
@import 'components/subjectarea';
@import 'components/cart';
@import 'components/form';
@import 'components/captcha';
@import 'components/layout';
@import 'components/forms';
@import 'components/user';
@import 'components/search';
@import 'components/datepicker';
@import 'components/seminars';
@import 'components/quickentry';
@import 'components/navigation';
@import '../../node_modules/select2/dist/css/select2.min';
@import "../../node_modules/bootstrap-datepicker/dist/css/bootstrap-datepicker";
@import 'components/select2';
//@import 'components/search';

@media (max-width: 500px) {
  .frame-type-text img {
    width: 100% !important;
    height: auto !important;
    display: block;
  }
  .header {
    word-wrap: break-word;
    overflow-wrap: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
  }
}