.tx-px-seminars {
  min-height: 400px;

  @media (max-width: 420px) {
    .seminare-header {

      display: block;
      text-align: center;

      .filter.date {
        float: none;
        margin: 0 auto;
        width: 100%;
        text-align: center;

        .datepicker-inline {
          margin: 0 auto;
        }
      }
    }
    .filter {
      width: 100%;
      padding: 0;
      margin-bottom:10px;
    }
  }

  #resetdate {
    float: right;
    color: $primary;
  }

  .f3-widget-paginator {
    display: block;
    margin: 0 auto;
    width: 100%;
    float: left;
  }
}