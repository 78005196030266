.subjectarea-teaser {
  .subject-title, .subject-text {
    text-align: center;
  }
  .subject-text {
    height: 90px;
    overflow: hidden;
  }
  .subject-title {
    font-size: 1rem;
    font-weight: bold;
    overflow: hidden;
  }
  .subject-btn {
    width: 100%;
  }
  .subject-img {
    height: auto;
    max-height: 180px;
    width: 100%;
    overflow: hidden;
  }
}

.subjarea-row {
  margin-right: calc(-100vw + 80rem);
}

.subjectarea-teaser.active {
  .subject-btn {
    background: #fff;
    color: $primary;
    border-color: $primary;
  }
}