.tx-px-seminars .filter {
    border: 0px;
    float: left;
}

.tx-px-seminars .speaker {
    width: 33%;
    padding-right: 0;

}

.tx-px-seminars .city {
    width: 34%;

}

.tx-px-seminars .category {
    width: 33%;
    padding-left: 0;

}


.tx-px-seminars #caleandar {
    min-height: 800px;
}

.cld-main {
    width: 100%;
}

.cld-main a {
    color: #7B00FF;
}

.cld-datetime {
    position: relative;
    width: 66%;
    min-width: 100px;
    max-width: 300px;
    margin: auto;
    overflow: hidden;
    margin-bottom: 50px;
}

.cld-datetime .today {
    position: relative;
    float: left;
    width: calc(100% - 40px);
    margin: auto;
    text-align: center;
}

.cld-nav {
    position: relative;
    width: 20px;
    height: 20px;
    margin-top: 2px;
}

.cld-nav:hover {
    cursor: pointer;
}

.cld-nav:hover svg {
    fill: #666;
}

.cld-rwd {
    float: left;
}

.cld-fwd {
    float: right;
}

.cld-nav svg:hover {

}

.cld-labels, .cld-days {
    padding-left: 0;
}

.cld-label, .cld-day {
    box-sizing: border-box;
    display: inline-block;
    width: 14.28%;
    text-align: center;
}

.cld-day {
    display: block;
    float: left;
    position: relative;
    margin: 0;
    padding: 5px;
    height: 120px;
    border: 1px solid #ddd;
    overflow-y: auto;
}

.cld-day.clickable:hover {
    cursor: pointer;
}

.cld-day.today {
    border: 1px solid #7B00FF;
}

.cld-day.disableDay {
    opacity: 0.5;
}

.cld-day.nextMonth, .cld-day.prevMonth {
    opacity: 0.33;
}

.cld-number {
    margin: 0;
    text-align: left;
}

.cld-title {
    font-size: 10px;
    display: block;
    margin: 0;
    font-weight: normal;
}

.cld-day:hover {
    background: #eee;
}

.cld-number.eventday {
    font-weight: bold;
}

.cld-number.eventday:hover {
    background: #eee;
}

.today .cld-number.eventday:hover {
}


