.alert {
  padding: 1rem;
  border: 1px solid $primary;
  margin-bottom: 1rem;
}

.alert-danger {
  background-color: $primary;
  &, * {
    color: white;
  }
}
