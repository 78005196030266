@media (min-width: 64rem) {
  .frame-type-login {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
  }
}

.three-colored-border{
  box-shadow: 0px -5px 7px -5px rgba(0,0,0,0.25);
}

.footer-middle {
  @extend .footer-left;
}