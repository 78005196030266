@charset "UTF-8";
.flatpickr-calendar {
  background: transparent;
  opacity: 0;
  display: none;
  text-align: center;
  visibility: hidden;
  padding: 0;
  -webkit-animation: none;
  animation: none;
  direction: ltr;
  border: 0;
  font-size: 14px;
  line-height: 24px;
  border-radius: 5px;
  position: absolute;
  width: 307.875px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  background: #fff;
  -webkit-box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgba(0, 0, 0, 0.08);
  box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgba(0, 0, 0, 0.08);
}

.flatpickr-calendar.inline, .flatpickr-calendar.open {
  opacity: 1;
  max-height: 640px;
  visibility: visible;
}

.flatpickr-calendar.open {
  display: inline-block;
  z-index: 99999;
}

.flatpickr-calendar.animate.open {
  -webkit-animation: fpFadeInDown 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeInDown 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}

.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: 2px;
}

.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px);
}

.flatpickr-calendar.static.open {
  z-index: 999;
  display: block;
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+1) .flatpickr-day.inRange:nth-child(7n+7) {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+2) .flatpickr-day.inRange:nth-child(7n+1) {
  -webkit-box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
  box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
}

.flatpickr-calendar .hasTime .dayContainer, .flatpickr-calendar .hasWeeks .dayContainer {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.flatpickr-calendar .hasWeeks .dayContainer {
  border-left: 0;
}

.flatpickr-calendar.showTimeInput.hasTime .flatpickr-time {
  height: 40px;
  border-top: 1px solid #e6e6e6;
}

.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto;
}

.flatpickr-calendar:after, .flatpickr-calendar:before {
  position: absolute;
  display: block;
  pointer-events: none;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  left: 22px;
}

.flatpickr-calendar.rightMost:after, .flatpickr-calendar.rightMost:before {
  left: auto;
  right: 22px;
}

.flatpickr-calendar:before {
  border-width: 5px;
  margin: 0 -5px;
}

.flatpickr-calendar:after {
  border-width: 4px;
  margin: 0 -4px;
}

.flatpickr-calendar.arrowTop:after, .flatpickr-calendar.arrowTop:before {
  bottom: 100%;
}

.flatpickr-calendar.arrowTop:before {
  border-bottom-color: #e6e6e6;
}

.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #fff;
}

.flatpickr-calendar.arrowBottom:after, .flatpickr-calendar.arrowBottom:before {
  top: 100%;
}

.flatpickr-calendar.arrowBottom:before {
  border-top-color: #e6e6e6;
}

.flatpickr-calendar.arrowBottom:after {
  border-top-color: #fff;
}

.flatpickr-calendar:focus {
  outline: 0;
}

.flatpickr-wrapper {
  position: relative;
  display: inline-block;
}

.flatpickr-months {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.flatpickr-months .flatpickr-month {
  background: transparent;
  color: rgba(0, 0, 0, 0.9);
  fill: rgba(0, 0, 0, 0.9);
  height: 34px;
  line-height: 1;
  text-align: center;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.flatpickr-months .flatpickr-next-month, .flatpickr-months .flatpickr-prev-month {
  text-decoration: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  height: 34px;
  padding: 10px;
  z-index: 3;
  color: rgba(0, 0, 0, 0.9);
  fill: rgba(0, 0, 0, 0.9);
}

.flatpickr-months .flatpickr-next-month.flatpickr-disabled, .flatpickr-months .flatpickr-prev-month.flatpickr-disabled {
  display: none;
}

.flatpickr-months .flatpickr-next-month i, .flatpickr-months .flatpickr-prev-month i {
  position: relative;
}

.flatpickr-months .flatpickr-next-month.flatpickr-prev-month, .flatpickr-months .flatpickr-prev-month.flatpickr-prev-month {
  left: 0;
}

.flatpickr-months .flatpickr-next-month.flatpickr-next-month, .flatpickr-months .flatpickr-prev-month.flatpickr-next-month {
  right: 0;
}

.flatpickr-months .flatpickr-next-month:hover, .flatpickr-months .flatpickr-prev-month:hover {
  color: #959ea9;
}

.flatpickr-months .flatpickr-next-month:hover svg, .flatpickr-months .flatpickr-prev-month:hover svg {
  fill: #f64747;
}

.flatpickr-months .flatpickr-next-month svg, .flatpickr-months .flatpickr-prev-month svg {
  width: 14px;
  height: 14px;
}

.flatpickr-months .flatpickr-next-month svg path, .flatpickr-months .flatpickr-prev-month svg path {
  -webkit-transition: fill 0.1s;
  transition: fill 0.1s;
  fill: inherit;
}

.numInputWrapper {
  position: relative;
  height: auto;
}

.numInputWrapper input, .numInputWrapper span {
  display: inline-block;
}

.numInputWrapper input {
  width: 100%;
}

.numInputWrapper input::-ms-clear {
  display: none;
}

.numInputWrapper input::-webkit-inner-spin-button, .numInputWrapper input::-webkit-outer-spin-button {
  margin: 0;
  -webkit-appearance: none;
}

.numInputWrapper span {
  position: absolute;
  right: 0;
  width: 14px;
  padding: 0 4px 0 2px;
  height: 50%;
  line-height: 50%;
  opacity: 0;
  cursor: pointer;
  border: 1px solid rgba(57, 57, 57, 0.15);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.numInputWrapper span:hover {
  background: rgba(0, 0, 0, 0.1);
}

.numInputWrapper span:active {
  background: rgba(0, 0, 0, 0.2);
}

.numInputWrapper span:after {
  display: block;
  content: "";
  position: absolute;
}

.numInputWrapper span.arrowUp {
  top: 0;
  border-bottom: 0;
}

.numInputWrapper span.arrowUp:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid rgba(57, 57, 57, 0.6);
  top: 26%;
}

.numInputWrapper span.arrowDown {
  top: 50%;
}

.numInputWrapper span.arrowDown:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(57, 57, 57, 0.6);
  top: 40%;
}

.numInputWrapper span svg {
  width: inherit;
  height: auto;
}

.numInputWrapper span svg path {
  fill: rgba(0, 0, 0, 0.5);
}

.numInputWrapper:hover {
  background: rgba(0, 0, 0, 0.05);
}

.numInputWrapper:hover span {
  opacity: 1;
}

.flatpickr-current-month {
  font-size: 135%;
  line-height: inherit;
  font-weight: 300;
  color: inherit;
  position: absolute;
  width: 75%;
  left: 12.5%;
  padding: 7.48px 0 0;
  line-height: 1;
  height: 34px;
  display: inline-block;
  text-align: center;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.flatpickr-current-month span.cur-month {
  font-family: inherit;
  font-weight: 700;
  color: inherit;
  display: inline-block;
  margin-left: 0.5ch;
  padding: 0;
}

.flatpickr-current-month span.cur-month:hover {
  background: rgba(0, 0, 0, 0.05);
}

.flatpickr-current-month .numInputWrapper {
  width: 6ch;
  width: 7ch \0 ;
  display: inline-block;
}

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: rgba(0, 0, 0, 0.9);
}

.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: rgba(0, 0, 0, 0.9);
}

.flatpickr-current-month input.cur-year {
  background: transparent;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: inherit;
  cursor: text;
  padding: 0 0 0 0.5ch;
  margin: 0;
  display: inline-block;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  line-height: inherit;
  height: auto;
  border: 0;
  border-radius: 0;
  vertical-align: initial;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

.flatpickr-current-month input.cur-year:focus {
  outline: 0;
}

.flatpickr-current-month input.cur-year[disabled], .flatpickr-current-month input.cur-year[disabled]:hover {
  font-size: 100%;
  color: rgba(0, 0, 0, 0.5);
  background: transparent;
  pointer-events: none;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  appearance: menulist;
  background: transparent;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  height: auto;
  line-height: inherit;
  margin: -1px 0 0;
  outline: none;
  padding: 0 0 0 0.5ch;
  position: relative;
  vertical-align: initial;
  -webkit-box-sizing: border-box;
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  width: auto;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:active, .flatpickr-current-month .flatpickr-monthDropdown-months:focus {
  outline: none;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background: rgba(0, 0, 0, 0.05);
}

.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
  background-color: transparent;
  outline: none;
  padding: 0;
}

.flatpickr-weekdays {
  background: transparent;
  text-align: center;
  overflow: hidden;
  width: 100%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 28px;
}

.flatpickr-weekdays, .flatpickr-weekdays .flatpickr-weekdaycontainer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.flatpickr-weekdays .flatpickr-weekdaycontainer, span.flatpickr-weekday {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

span.flatpickr-weekday {
  cursor: default;
  font-size: 90%;
  background: transparent;
  color: rgba(0, 0, 0, 0.54);
  line-height: 1;
  margin: 0;
  text-align: center;
  display: block;
  font-weight: bolder;
}

.dayContainer, .flatpickr-weeks {
  padding: 1px 0 0;
}

.flatpickr-days {
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  width: 307.875px;
}

.flatpickr-days:focus {
  outline: 0;
}

.dayContainer {
  padding: 0;
  outline: 0;
  text-align: left;
  width: 307.875px;
  min-width: 307.875px;
  max-width: 307.875px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  opacity: 1;
}

.dayContainer + .dayContainer {
  -webkit-box-shadow: -1px 0 0 #e6e6e6;
  box-shadow: -1px 0 0 #e6e6e6;
}

.flatpickr-day {
  background: none;
  border: 1px solid transparent;
  border-radius: 150px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #393939;
  cursor: pointer;
  font-weight: 400;
  width: 14.2857143%;
  -webkit-flex-basis: 14.2857143%;
  -ms-flex-preferred-size: 14.2857143%;
  flex-basis: 14.2857143%;
  max-width: 39px;
  height: 39px;
  line-height: 39px;
  margin: 0;
  display: inline-block;
  position: relative;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}

.flatpickr-day.inRange, .flatpickr-day.nextMonthDay.inRange, .flatpickr-day.nextMonthDay.today.inRange, .flatpickr-day.nextMonthDay:focus, .flatpickr-day.nextMonthDay:hover, .flatpickr-day.prevMonthDay.inRange, .flatpickr-day.prevMonthDay.today.inRange, .flatpickr-day.prevMonthDay:focus, .flatpickr-day.prevMonthDay:hover, .flatpickr-day.today.inRange, .flatpickr-day:focus, .flatpickr-day:hover {
  cursor: pointer;
  outline: 0;
  background: #e6e6e6;
  border-color: #e6e6e6;
}

.flatpickr-day.today {
  border-color: #959ea9;
}

.flatpickr-day.today:focus, .flatpickr-day.today:hover {
  border-color: #959ea9;
  background: #959ea9;
  color: #fff;
}

.flatpickr-day.endRange, .flatpickr-day.endRange.inRange, .flatpickr-day.endRange.nextMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.endRange:focus, .flatpickr-day.endRange:hover, .flatpickr-day.selected, .flatpickr-day.selected.inRange, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.selected:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange, .flatpickr-day.startRange.inRange, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.startRange:focus, .flatpickr-day.startRange:hover {
  background: #569ff7;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  border-color: #569ff7;
}

.flatpickr-day.endRange.startRange, .flatpickr-day.selected.startRange, .flatpickr-day.startRange.startRange {
  border-radius: 50px 0 0 50px;
}

.flatpickr-day.endRange.endRange, .flatpickr-day.selected.endRange, .flatpickr-day.startRange.endRange {
  border-radius: 0 50px 50px 0;
}

.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)) {
  -webkit-box-shadow: -10px 0 0 #569ff7;
  box-shadow: -10px 0 0 #569ff7;
}

.flatpickr-day.endRange.startRange.endRange, .flatpickr-day.selected.startRange.endRange, .flatpickr-day.startRange.startRange.endRange {
  border-radius: 50px;
}

.flatpickr-day.inRange {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
  box-shadow: -5px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
}

.flatpickr-day.flatpickr-disabled, .flatpickr-day.flatpickr-disabled:hover, .flatpickr-day.nextMonthDay, .flatpickr-day.notAllowed, .flatpickr-day.notAllowed.nextMonthDay, .flatpickr-day.notAllowed.prevMonthDay, .flatpickr-day.prevMonthDay {
  color: rgba(57, 57, 57, 0.3);
  background: transparent;
  border-color: transparent;
  cursor: default;
}

.flatpickr-day.flatpickr-disabled, .flatpickr-day.flatpickr-disabled:hover {
  cursor: not-allowed;
  color: rgba(57, 57, 57, 0.1);
}

.flatpickr-day.week.selected {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 #569ff7, 5px 0 0 #569ff7;
  box-shadow: -5px 0 0 #569ff7, 5px 0 0 #569ff7;
}

.flatpickr-day.hidden {
  visibility: hidden;
}

.rangeMode .flatpickr-day {
  margin-top: 1px;
}

.flatpickr-weekwrapper {
  float: left;
}

.flatpickr-weekwrapper .flatpickr-weeks {
  padding: 0 12px;
  -webkit-box-shadow: 1px 0 0 #e6e6e6;
  box-shadow: 1px 0 0 #e6e6e6;
}

.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  width: 100%;
  line-height: 28px;
}

.flatpickr-weekwrapper span.flatpickr-day, .flatpickr-weekwrapper span.flatpickr-day:hover {
  display: block;
  width: 100%;
  max-width: none;
  color: rgba(57, 57, 57, 0.3);
  background: transparent;
  cursor: default;
  border: none;
}

.flatpickr-innerContainer {
  display: block;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
}

.flatpickr-innerContainer, .flatpickr-rContainer {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.flatpickr-rContainer {
  display: inline-block;
  padding: 0;
}

.flatpickr-time {
  text-align: center;
  outline: 0;
  display: block;
  height: 0;
  line-height: 40px;
  max-height: 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.flatpickr-time:after {
  content: "";
  display: table;
  clear: both;
}

.flatpickr-time .numInputWrapper {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  width: 40%;
  height: 40px;
  float: left;
}

.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: #393939;
}

.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: #393939;
}

.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%;
}

.flatpickr-time.time24hr .numInputWrapper {
  width: 49%;
}

.flatpickr-time input {
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  text-align: center;
  margin: 0;
  padding: 0;
  height: inherit;
  line-height: inherit;
  color: #393939;
  font-size: 14px;
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

.flatpickr-time input.flatpickr-hour {
  font-weight: 700;
}

.flatpickr-time input.flatpickr-minute, .flatpickr-time input.flatpickr-second {
  font-weight: 400;
}

.flatpickr-time input:focus {
  outline: 0;
  border: 0;
}

.flatpickr-time .flatpickr-am-pm, .flatpickr-time .flatpickr-time-separator {
  height: inherit;
  float: left;
  line-height: inherit;
  color: #393939;
  font-weight: 700;
  width: 2%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.flatpickr-time .flatpickr-am-pm {
  outline: 0;
  width: 18%;
  cursor: pointer;
  text-align: center;
  font-weight: 400;
}

.flatpickr-time .flatpickr-am-pm:focus, .flatpickr-time .flatpickr-am-pm:hover, .flatpickr-time input:focus, .flatpickr-time input:hover {
  background: #eee;
}

.flatpickr-input[readonly] {
  cursor: pointer;
}

@-webkit-keyframes fpFadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}
@keyframes fpFadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}
@font-face {
  font-family: lato_regular;
  src: url(/typo3conf/ext/px_basis_config/Resources/Public/Fonts/Lato-Regular.woff) format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: lato_italic;
  src: url(/typo3conf/ext/px_basis_config/Resources/Public/Fonts/Lato-Italic.woff) format("woff");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: lato_bold;
  src: url(/typo3conf/ext/px_basis_config/Resources/Public/Fonts/Lato-Bold.woff) format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: lato_black;
  src: url(/typo3conf/ext/px_basis_config/Resources/Public/Fonts/Lato-Black.woff) format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@-webkit-keyframes expand-bounce {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1.25);
    transform: scale(1.25);
  }
  to {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes expand-bounce {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1.25);
    transform: scale(1.25);
  }
  to {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@-webkit-keyframes shrink {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  to {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}
@keyframes shrink {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  to {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}
body {
  text-align: left;
  background: #fff;
  color: #000;
}

sub, sup {
  vertical-align: inherit;
}

blockquote, body, dd, dl, fieldset, figure, h1, h2, h3, h4, h5, h6, hr, ol, p, pre, ul {
  margin: 0;
}

legend, ol, td, th, ul {
  padding: 0;
}

address, b, cite, code, dfn, em, h1, h2, h3, h4, h5, h6, i, kbd, pre, samp, small, strong, sub, sup, th, var {
  font: inherit;
}

caption, th {
  text-align: inherit;
}

pre {
  white-space: inherit;
}

mark {
  background: transparent;
}

a, del, ins, s, u {
  text-decoration: none;
}

ol, ul {
  list-style: none;
}

q {
  quotes: none;
}

a, mark {
  color: inherit;
}

*, :after, :before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body, html {
  width: 100%;
  margin: 0;
  padding: 0;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
}

html {
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-rendering: optimizeLegibility;
  background-color: #fff;
  scroll-behavior: smooth;
}

body {
  padding-top: 2.8125rem;
  font-family: lato_regular, Helvetica, sans-serif;
  font-weight: 400;
}

@media (min-width: 62rem) {
  body {
    padding-top: 8.4375rem;
  }
}
body.newsletter-registration {
  padding-top: 0;
}

img {
  height: auto;
}

img, svg {
  width: 100%;
  display: block;
}

svg {
  height: 100%;
}

body.cke_editable {
  padding: 0;
}

.site-content {
  width: 100%;
}

.main-section {
  min-height: 37.5rem;
}

.frame-layout-0 {
  max-width: 1440px;
  height: inherit;
  margin: 0 auto;
  position: relative;
  padding: 0 1.5rem;
}

@media (min-width: 62rem) {
  .frame-layout-0 {
    padding: 0 4.861%;
  }
}
@media (min-width: 90rem) {
  .frame-layout-0 {
    padding: 0 4.375rem;
  }
}
.frame-layout-0.frame-type-slider {
  padding-left: 0;
  padding-right: 0;
}

.stagecontainer .frame-layout-0 {
  margin-top: 0;
}

h1 {
  font-family: lato_black, Helvetica, sans-serif;
  font-weight: 900;
  color: #000;
  line-height: 1.1666666667;
  margin-bottom: 1.5rem;
  text-transform: uppercase;
  font-size: 1.625rem;
  letter-spacing: 0.25rem;
  -webkit-transition: font-size 0.6s cubic-bezier(0.68, -0.55, 0.27, 1.55);
  transition: font-size 0.6s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

@media (min-width: 36rem) {
  h1 {
    font-size: calc(2.546vw + .70833rem);
  }
}
@media (min-width: 90rem) {
  h1 {
    font-size: 3rem;
  }
}
@media (min-width: 125rem) {
  h1 {
    font-size: calc(2.857vw + -.57143rem);
  }
}
h2 {
  font-family: lato_black, Helvetica, sans-serif;
  font-weight: 900;
  color: #000;
  line-height: 1.3333333333;
  margin-bottom: 2rem;
  text-transform: uppercase;
  font-size: 1.5rem;
  letter-spacing: 0.125rem;
  -webkit-transition: font-size 0.6s cubic-bezier(0.68, -0.55, 0.27, 1.55);
  transition: font-size 0.6s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

@media (min-width: 36rem) {
  h2 {
    font-size: calc(.231vw + 1.29167rem);
  }
}
@media (min-width: 90rem) {
  h2 {
    font-size: 1.5rem;
  }
}
@media (min-width: 125rem) {
  h2 {
    font-size: calc(2.857vw + -2.07143rem);
  }
}
h3 {
  font-family: lato_black, Helvetica, sans-serif;
  font-weight: 900;
  color: #000;
  line-height: 1.4545454545;
  margin-bottom: 1.25rem;
  font-size: 1.375rem;
  letter-spacing: 0.125rem;
  -webkit-transition: font-size 0.6s cubic-bezier(0.68, -0.55, 0.27, 1.55);
  transition: font-size 0.6s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

@media (min-width: 36rem) {
  h3 {
    font-size: calc(.231vw + 1.16667rem);
  }
}
@media (min-width: 90rem) {
  h3 {
    font-size: 1.375rem;
  }
}
@media (min-width: 125rem) {
  h3 {
    font-size: calc(1.786vw + -.85714rem);
  }
}
h4 {
  line-height: 1.3333333333;
  font-size: 1.125rem;
}

h4, h5 {
  font-family: lato_black, Helvetica, sans-serif;
  font-weight: 900;
  color: #000;
  margin-bottom: 0.5rem;
  letter-spacing: 0.125rem;
}

h5 {
  line-height: 1.5;
  font-size: 1rem;
}

h6 {
  font-family: lato_black, Helvetica, sans-serif;
  font-weight: 900;
  color: #000;
  line-height: 1.7142857143;
  font-size: 0.875rem;
  letter-spacing: 0.125rem;
}

strong {
  font-family: lato_bold, Helvetica, sans-serif;
  font-weight: 700;
}

em {
  font-family: lato_italic, Helvetica, sans-serif;
  font-style: italic;
  font-weight: 400;
}

u {
  text-decoration: underline;
}

p {
  font-family: lato_regular, Helvetica, sans-serif;
  font-weight: 400;
  color: #000;
  line-height: 1.5rem;
  margin-bottom: 1.5rem;
  font-size: 1rem;
  letter-spacing: 0.0625rem;
}

p:last-child {
  margin-bottom: 0;
}

p.big {
  font-family: lato_regular, Helvetica, sans-serif;
  font-weight: 400;
  color: #000;
  line-height: 1.5rem;
  margin-bottom: 1.5rem;
  font-size: 1rem;
  letter-spacing: 0.0625rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
}

p.big:last-child {
  margin-bottom: 0;
}

p.medium {
  font-family: lato_regular, Helvetica, sans-serif;
  font-weight: 400;
  color: #000;
  line-height: 1.5rem;
  margin-bottom: 1.5rem;
  font-size: 1rem;
  letter-spacing: 0.0625rem;
}

p.medium:last-child {
  margin-bottom: 0;
}

p.small {
  font-family: lato_regular, Helvetica, sans-serif;
  font-weight: 400;
  color: #000;
  margin-bottom: 1.5rem;
  font-size: 1rem;
  letter-spacing: 0.0625rem;
  font-size: 0.875rem;
  line-height: 1.5rem;
}

p.small:last-child {
  margin-bottom: 0;
}

p.legal, span.help-block {
  font-family: lato_regular, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  color: #929292;
  line-height: 2;
  padding-bottom: 0.5rem;
  letter-spacing: 0.0625rem;
}

p.legal:last-child, span.help-block:last-child {
  margin-bottom: 0;
}

small {
  font-family: lato_regular, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  color: #929292;
  line-height: 2;
  padding-bottom: 0.5rem;
  letter-spacing: 0.0625rem;
}

button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  padding: 0;
}

form {
  max-width: 40rem;
}

.frame-1 form {
  margin: 0 auto;
}

form .form-group:not(:last-of-type) {
  margin-bottom: 1.25rem;
}

form .parsley-errors-list {
  font-family: lato_regular, Helvetica, sans-serif;
  font-weight: 400;
  color: #e20079;
  font-size: 0.875rem;
  margin-top: 0.3125rem;
}

form .row {
  margin-bottom: 1.5rem;
}

form .row .col-md-6 {
  width: auto;
  display: inline-block;
}

form .row .col-md-6:first-of-type {
  margin-right: 0.625rem;
}

form .row .col-md-6 .checkbox-label {
  margin: 0;
}

form .row .frame-type-text {
  padding: 0;
}

form .row .ce-text {
  padding: 0 0 0 2.1875rem;
}

form .clearfix > p {
  font-family: lato_regular, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  color: #929292;
  line-height: 2;
  padding-bottom: 0.5rem;
  letter-spacing: 0.0625rem;
  margin-top: 0 !important;
}

form .clearfix > p, form .clearfix > p:last-child {
  margin-bottom: 2.5rem;
}

form input[type=text]::-ms-clear, form input[type=text]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

form input[type=number] {
  -moz-appearance: textfield;
}

form input::-webkit-inner-spin-button, form input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

form ::-webkit-input-placeholder {
  color: #bdbdbd;
  font-family: lato_italic, Helvetica, sans-serif;
  font-style: italic;
  font-weight: 400;
  letter-spacing: 0.125rem;
}

form ::-moz-placeholder {
  color: #bdbdbd;
  font-family: lato_italic, Helvetica, sans-serif;
  font-style: italic;
  font-weight: 400;
  letter-spacing: 0.125rem;
}

form :-ms-input-placeholder {
  color: #bdbdbd;
  font-family: lato_italic, Helvetica, sans-serif;
  font-style: italic;
  font-weight: 400;
  letter-spacing: 0.125rem;
}

form :-moz-placeholder {
  color: #bdbdbd;
  font-family: lato_italic, Helvetica, sans-serif;
  font-style: italic;
  font-weight: 400;
  letter-spacing: 0.125rem;
}

input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #f1f1f1;
  padding: 1rem;
  color: #000;
  border: none;
  outline: none;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: background-color 0.3s, color 0.3s;
  transition: background-color 0.3s, color 0.3s;
  font-size: 0.875rem;
  width: 100%;
  font-family: lato_regular, Helvetica, sans-serif;
  font-weight: 400;
}

input:active, input:focus {
  -webkit-box-shadow: 0.125rem 0.125rem 0.625rem 0 rgba(54, 175, 253, 0.2);
  box-shadow: 0.125rem 0.125rem 0.625rem 0 rgba(54, 175, 253, 0.2);
  background-color: #fff;
}

input[type=radio] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: none;
}

input[type=radio]:checked + label:after {
  opacity: 1;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}

input[type=radio] + .radio-label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  padding-top: 0.3125rem;
}

input[type=radio] + .radio-label:after {
  position: absolute;
  content: "";
  opacity: 0;
  border-radius: 50%;
  background-color: #e20079;
  width: 0.5rem;
  height: 0.5rem;
  left: 0.3125rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

input[type=radio] + .radio-label:before {
  margin-right: 0.375rem;
  content: "";
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 50%;
  display: inline-block;
  background-color: #fff;
  -webkit-box-shadow: inset 0 0 0 0.125rem #e20079;
  box-shadow: inset 0 0 0 0.125rem #e20079;
}

.radio-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.form-check input {
  display: none;
}

.form-check input + span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  cursor: pointer;
}

.form-check input + span:before {
  margin-right: 0.5rem;
  content: "";
  -webkit-box-flex: 0;
  -ms-flex: 0 0 1.5rem;
  flex: 0 0 1.5rem;
  width: 1.5rem;
  height: 1.5rem;
  display: inline-block;
  background-color: #fff;
  -webkit-box-shadow: inset 0 0 0 0.125rem #929292;
  box-shadow: inset 0 0 0 0.125rem #929292;
  background-size: 1rem 1rem;
  background-position: 50% 100%;
  background-repeat: no-repeat;
  -webkit-transition: background-position 0.3s, background-color 0.3s;
  transition: background-position 0.3s, background-color 0.3s;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 16 16'%3E%3Cdefs%3E%3Cpath fill='%23fff' id='a' d='M14 2.8L6.8 14 2 9.2l1.485-2.012 3.046 3.046L11.6 2z'/%3E%3C/defs%3E%3Cuse xlink:href='%23a' fill-rule='evenodd'/%3E%3C/svg%3E");
}

.form-check input:checked + span:before {
  background-position: 50% 50%;
  background-color: #e20079;
  -webkit-box-shadow: inset 0 0 0 0.125rem #e20079;
  box-shadow: inset 0 0 0 0.125rem #e20079;
}

textarea {
  -moz-appearance: none;
  appearance: none;
  background-color: #f1f1f1;
  padding: 1rem;
  color: #000;
  border: none;
  outline: none;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: background-color 0.3s, color 0.3s;
  transition: background-color 0.3s, color 0.3s;
  font-size: 0.875rem;
  width: 100%;
  font-family: lato_regular, Helvetica, sans-serif;
  font-weight: 400;
  -webkit-appearance: none;
  min-height: 12.5rem;
  resize: vertical;
}

textarea:active, textarea:focus {
  -webkit-box-shadow: 0.125rem 0.125rem 0.625rem 0 rgba(54, 175, 253, 0.2);
  box-shadow: 0.125rem 0.125rem 0.625rem 0 rgba(54, 175, 253, 0.2);
  background-color: #fff;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #f1f1f1;
  padding: 1rem;
  color: #000;
  border: none;
  outline: none;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: background-color 0.3s, color 0.3s;
  transition: background-color 0.3s, color 0.3s;
  font-size: 0.875rem;
  width: 100%;
  font-family: lato_regular, Helvetica, sans-serif;
  font-weight: 400;
  cursor: pointer;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 16 16'%3E%3Cdefs%3E%3Cpath fill='%23E20079' id='a' d='M9.883 13a.216.216 0 01-.155-.066L5.064 8.158a.227.227 0 010-.317l4.664-4.775a.216.216 0 01.31 0l.897.919a.228.228 0 010 .317L7.324 8l3.612 3.698a.228.228 0 010 .317l-.898.92a.218.218 0 01-.155.065z'/%3E%3C/defs%3E%3Cuse transform='rotate(-90 8 8)' xlink:href='%23a'/%3E%3C/svg%3E");
  background-position: right 0.75rem center;
  background-size: 1.5rem 1.5rem;
  background-repeat: no-repeat;
}

select:active, select:focus {
  -webkit-box-shadow: 0.125rem 0.125rem 0.625rem 0 rgba(54, 175, 253, 0.2);
  box-shadow: 0.125rem 0.125rem 0.625rem 0 rgba(54, 175, 253, 0.2);
  background-color: #fff;
}

select::-ms-expand {
  display: none;
}

label {
  font-family: lato_black, Helvetica, sans-serif;
  font-weight: 900;
  color: #000;
  line-height: 1.7142857143;
  font-size: 0.875rem;
  letter-spacing: 0.125rem;
  padding-bottom: 0.3125rem;
  display: inline-block;
}

label.checkbox-label {
  font-family: lato_regular, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  color: #929292;
  line-height: 2;
  letter-spacing: 0.0625rem;
  display: inline-block;
  position: relative;
  padding-left: 2.125rem;
  margin-bottom: 0.625rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-bottom: 0;
}

label.checkbox-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

label.checkbox-label input:checked ~ .checkmark {
  background-color: #e20079;
  border: 0.125rem solid #e20079;
}

label.checkbox-label input:checked ~ .checkmark:after {
  display: block;
}

label.checkbox-label .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 1.5rem;
  width: 1.5rem;
  background-color: #fff;
  border: 0.125rem solid #bdbdbd;
}

label.checkbox-label .checkmark:after {
  content: "";
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 16 16'%3E%3Cdefs%3E%3Cpath fill='%23fff' id='a' d='M14 2.8L6.8 14 2 9.2l1.485-2.012 3.046 3.046L11.6 2z'/%3E%3C/defs%3E%3Cuse xlink:href='%23a' fill-rule='evenodd'/%3E%3C/svg%3E");
  background-size: 80%;
  background-position: 50%;
  background-repeat: no-repeat;
  position: absolute;
  width: 100%;
  height: 100%;
  display: none;
}

label.checkbox-label + .parsley-errors-list {
  margin: 0;
}

label.checkbox-label.parsley-error .checkmark {
  border-color: #e20079;
}

label.checkbox-label.parsley-error + .parsley-errors-list {
  display: none;
}

.help-block {
  font-family: lato_regular, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  color: #929292;
  line-height: 2;
  padding-bottom: 0.5rem;
  letter-spacing: 0.0625rem;
  display: block;
}

ul:not([class]) {
  margin: 0 0 1.25rem 0.625rem;
  padding: 0;
  line-height: 1.5rem;
}

ul:not([class]) li {
  position: relative;
  padding: 0 0 1.25rem 1.5625rem;
  letter-spacing: 0.0625rem;
}

ul:not([class]) li:before {
  background: #000;
  border-radius: 50%;
  content: "";
  height: 0.3125rem;
  left: 0;
  top: 0.625rem;
  position: absolute;
  width: 0.3125rem;
}

ul.unordered-list {
  font-family: lato_regular, Helvetica, sans-serif;
  font-weight: 400;
  color: #000;
  line-height: 1.5rem;
  margin: 0 0 1.5rem 0.625rem;
  font-size: 1rem;
  letter-spacing: 0.0625rem;
  padding: 0;
}

ul.unordered-list li {
  position: relative;
  padding: 0 0 1.25rem 1.5625rem;
  letter-spacing: 0.0625rem;
}

ul.unordered-list li:before {
  background: #000;
  border-radius: 50%;
  content: "";
  height: 0.3125rem;
  left: 0;
  top: 0.625rem;
  position: absolute;
  width: 0.3125rem;
}

ul.unordered-list-big {
  font-family: lato_regular, Helvetica, sans-serif;
  font-weight: 400;
  color: #000;
  line-height: 1.5rem;
  margin: 0 0 1.5rem 0.625rem;
  font-size: 1rem;
  letter-spacing: 0.0625rem;
  padding: 0;
}

ul.unordered-list-big li {
  position: relative;
  padding: 0 0 1.25rem 1.5625rem;
  letter-spacing: 0.0625rem;
}

ul.unordered-list-big li:before {
  background: #000;
  border-radius: 50%;
  content: "";
  height: 0.3125rem;
  left: 0;
  top: 0.625rem;
  position: absolute;
  width: 0.3125rem;
  top: 0.6875rem;
}

@media (min-width: 62rem) {
  ul.unordered-list-big {
    font-family: lato_regular, Helvetica, sans-serif;
    font-weight: 400;
    color: #000;
    line-height: 1.5rem;
    margin-bottom: 1.5rem;
    font-size: 1rem;
    letter-spacing: 0.0625rem;
    font-size: 1.125rem;
    line-height: 1.75rem;
    padding-bottom: 0;
  }
}
ul.unordered-list-medium {
  font-family: lato_regular, Helvetica, sans-serif;
  font-weight: 400;
  color: #000;
  line-height: 1.5rem;
  margin: 0 0 1.5rem 0.625rem;
  font-size: 1rem;
  letter-spacing: 0.0625rem;
  padding: 0;
}

ul.unordered-list-medium li {
  position: relative;
  padding: 0 0 1.25rem 1.5625rem;
  letter-spacing: 0.0625rem;
}

ul.unordered-list-medium li:before {
  background: #000;
  border-radius: 50%;
  content: "";
  height: 0.3125rem;
  left: 0;
  position: absolute;
  width: 0.3125rem;
  top: 0.625rem;
}

ul.unordered-list-small {
  font-family: lato_regular, Helvetica, sans-serif;
  font-weight: 400;
  color: #000;
  margin: 0 0 1.5rem 0.625rem;
  font-size: 1rem;
  letter-spacing: 0.0625rem;
  font-size: 0.875rem;
  line-height: 1.5rem;
  padding: 0;
}

ul.unordered-list-small li {
  position: relative;
  padding: 0 0 1.25rem 1.5625rem;
  letter-spacing: 0.0625rem;
}

ul.unordered-list-small li:before {
  background: #000;
  border-radius: 50%;
  content: "";
  height: 0.3125rem;
  left: 0;
  top: 0.625rem;
  position: absolute;
  width: 0.3125rem;
}

ol:not([class]) {
  margin-bottom: 1.25rem;
  padding: 0 0 0 1.875rem;
  line-height: 1.5rem;
  list-style: decimal;
  letter-spacing: 0.0625rem;
}

ol:not([class]) li {
  padding-bottom: 1.25rem;
  padding-left: 0.3125rem;
}

ol.ordered-list {
  list-style: decimal;
  font-family: lato_regular, Helvetica, sans-serif;
  font-weight: 400;
  color: #000;
  line-height: 1.5rem;
  margin-bottom: 1.5rem;
  font-size: 1rem;
  letter-spacing: 0.0625rem;
  padding: 0 0 0 1.875rem;
}

ol.ordered-list li {
  padding-bottom: 1.25rem;
  padding-left: 0.3125rem;
}

ol.ordered-list-big {
  list-style: decimal;
  font-family: lato_regular, Helvetica, sans-serif;
  font-weight: 400;
  color: #000;
  line-height: 1.5rem;
  margin-bottom: 1.5rem;
  font-size: 1rem;
  letter-spacing: 0.0625rem;
  padding: 0 0 0 1.875rem;
}

ol.ordered-list-big li {
  padding-bottom: 1.25rem;
  padding-left: 0.3125rem;
}

ol.ordered-list-big li:before {
  top: 0.6875rem;
}

@media (min-width: 62rem) {
  ol.ordered-list-big {
    font-family: lato_regular, Helvetica, sans-serif;
    font-weight: 400;
    color: #000;
    line-height: 1.5rem;
    margin-bottom: 1.5rem;
    font-size: 1rem;
    letter-spacing: 0.0625rem;
    font-size: 1.125rem;
    line-height: 1.75rem;
    padding-bottom: 0;
  }
}
ol.ordered-list-medium {
  list-style: decimal;
  font-family: lato_regular, Helvetica, sans-serif;
  font-weight: 400;
  color: #000;
  line-height: 1.5rem;
  margin-bottom: 1.5rem;
  font-size: 1rem;
  letter-spacing: 0.0625rem;
  padding: 0 0 0 1.875rem;
}

ol.ordered-list-medium li {
  padding-bottom: 1.25rem;
  padding-left: 0.3125rem;
}

ol.ordered-list-medium li:before {
  top: 0.5625rem;
}

ol.ordered-list-small {
  list-style: decimal;
  font-family: lato_regular, Helvetica, sans-serif;
  font-weight: 400;
  color: #000;
  margin-bottom: 1.5rem;
  font-size: 1rem;
  letter-spacing: 0.0625rem;
  font-size: 0.875rem;
  line-height: 1.5rem;
  padding: 0 0 0 1.875rem;
}

ol.ordered-list-small li {
  padding-bottom: 1.25rem;
  padding-left: 0.3125rem;
}

a.link, a:not([class]):not([id]) {
  font-family: lato_regular, Helvetica, sans-serif;
  font-weight: 400;
  color: #000;
  line-height: 1.5rem;
  font-size: 1rem;
  letter-spacing: 0.0625rem;
  font-family: lato_bold, Helvetica, sans-serif;
  font-weight: 700;
  color: #e20079;
  margin-bottom: 0;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  text-decoration: none;
  display: inline;
  font-size: inherit;
  line-height: inherit;
}

a.link:hover, a:not([class]):not([id]):hover {
  color: #000;
}

a.external-link {
  font-family: lato_regular, Helvetica, sans-serif;
  font-weight: 400;
  color: #000;
  line-height: 1.5rem;
  font-size: 1rem;
  letter-spacing: 0.0625rem;
  font-family: lato_bold, Helvetica, sans-serif;
  font-weight: 700;
  color: #e20079;
  margin-bottom: 0;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  text-decoration: none;
}

a.external-link:hover {
  color: #000;
}

a.external-link:before {
  content: "";
  display: inline-block;
  height: 1rem;
  margin: 0 0.25rem;
  width: 1rem;
  -webkit-transition: background-image 0.3s ease;
  transition: background-image 0.3s ease;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEgMTZjLS40ODEgMC0xLS41MTktMS0xVjIuODc1QzAgMi4zOTQuMzk0IDIgLjg3NSAySDh2MkgydjEwaDEwVjhoMnY3YzAgLjQ4MS0uNTE5IDEtMSAxSDF6bTYtOC4yNzlsNS44NzktNS44OTRIMTFWMGg1djVoLTEuODIzVjMuMTA3TDguMjc2IDkgNyA3LjcyMXoiIGZpbGw9IiNFMjAwNzkiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==");
  position: relative;
  top: 0.125rem;
}

a.external-link:hover:before {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTYiIGhlaWdodD0iMTYiPjxkZWZzPjxwYXRoIGlkPSJhIiBkPSJNMSAxNmMtLjQ4MSAwLTEtLjUxOS0xLTFWMi44NzVDMCAyLjM5NC4zOTQgMiAuODc1IDJIOHYySDJ2MTBoMTBWOGgydjdjMCAuNDgxLS41MTkgMS0xIDFIMXptNi04LjI4bDUuODc5LTUuODkzSDExVjBoNXY1aC0xLjgyM1YzLjEwN0w4LjI3NyA5IDcgNy43MnoiLz48L2RlZnM+PHVzZSB4bGluazpocmVmPSIjYSIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+");
}

a.internal-link {
  font-family: lato_regular, Helvetica, sans-serif;
  font-weight: 400;
  color: #000;
  line-height: 1.5rem;
  font-size: 1rem;
  letter-spacing: 0.0625rem;
  font-family: lato_bold, Helvetica, sans-serif;
  font-weight: 700;
  color: #e20079;
  margin-bottom: 0;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  text-decoration: none;
}

a.internal-link:hover {
  color: #000;
}

a.internal-link:before {
  content: "";
  display: inline-block;
  height: 0.75rem;
  margin: 0 0.125rem;
  width: 0.75rem;
  -webkit-transition: background-image 0.3s ease;
  transition: background-image 0.3s ease;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAAAXNSR0IB2cksfwAAAGNQTFRFAAAA/wCA4QB44QB5/wCA4QB44gB54gB5/wBV4gB34QB54gB4/wAA4QB44QB54QB54wB54gB44gB44gB44gB54gB54QB54gB44gB54wB54QB54QB54gB44gB54gB54QB5/wCAuGtZWgAAACF0Uk5TAAa7tQSb/68DPveoAUT5mkr8k1f9i13+fGVnbn1Q+6ICmS9UZwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAFhJREFUeJxdj0USgEAMBIM1Dou7/P+VFLdkc0tXjYmIBGEUi74E0kyDvICy0qRuoDUi10FvyDDCZMi8wGrIBuw+ODzJqf7LM3W3jfWL/dUfXf0f9+pEM/8D3wUDr7K4jO4AAAAASUVORK5CYII=");
  background-size: 0.75rem;
  background-repeat: no-repeat;
}

a.internal-link:hover:before {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAAAXNSR0IB2cksfwAAAGNQTFRFAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAZmh03gAAACF0Uk5TAAa7tQSb/68DPveoAUT5mkr8k1f9i13+fGVnbn1Q+6ICmS9UZwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAFhJREFUeJxdj0USgEAMBIM1Dou7/P+VFLdkc0tXjYmIBGEUi74E0kyDvICy0qRuoDUi10FvyDDCZMi8wGrIBuw+ODzJqf7LM3W3jfWL/dUfXf0f9+pEM/8D3wUDr7K4jO4AAAAASUVORK5CYII=");
}

a.download-link {
  font-family: lato_regular, Helvetica, sans-serif;
  font-weight: 400;
  color: #000;
  line-height: 1.5rem;
  font-size: 1rem;
  letter-spacing: 0.0625rem;
  font-family: lato_bold, Helvetica, sans-serif;
  font-weight: 700;
  color: #e20079;
  margin-bottom: 0;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  text-decoration: none;
}

a.download-link:hover {
  color: #000;
}

a.download-link:before {
  content: "";
  display: inline-block;
  height: 1rem;
  margin: 0 0.125rem;
  width: 1rem;
  -webkit-transition: background-image 0.4s ease;
  transition: background-image 0.4s ease;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTkuNjkgNS4yMTloNC4yODN2MTAuMDY3YzAgLjM3My0uMjgxLjY3OC0uNjI1LjY3OEgyLjYyNWMtLjM0NCAwLS42MjUtLjMwNS0uNjI1LS42NzhWLjY3OEMyIC4zMDUgMi4yODEgMCAyLjYyNSAwaDYuNDR2NC41NDFjMCAuMzczLjI4MS42NzguNjI1LjY3OHptLjYxNC0xLjM0OVYuMzQzTDE0IDMuODM3di4wMzNoLTMuNjk2eiIgZmlsbD0iI2UyMDA3OSIvPjwvc3ZnPg==");
  position: relative;
  top: 0.125rem;
}

a.download-link:hover:before {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTkuNjkgNS4yMTloNC4yODN2MTAuMDY3YzAgLjM3My0uMjgxLjY3OC0uNjI1LjY3OEgyLjYyNWMtLjM0NCAwLS42MjUtLjMwNS0uNjI1LS42NzhWLjY3OEMyIC4zMDUgMi4yODEgMCAyLjYyNSAwaDYuNDR2NC41NDFjMCAuMzczLjI4MS42NzguNjI1LjY3OHptLjYxNC0xLjM0OVYuMzQzTDE0IDMuODM3di4wMzNoLTMuNjk2eiIvPjwvc3ZnPg==");
}

.btn-primary {
  font-family: lato_bold, Helvetica, sans-serif;
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 1rem;
  color: #fff;
  line-height: 1.5;
  letter-spacing: 0.125rem;
  border: 0.1875rem solid #e20079;
  background-color: #e20079;
  padding: 0.5625rem 2.1875rem;
  -webkit-transition: color 0.3s, background-color 0.3s, border-color 0.3s;
  transition: color 0.3s, background-color 0.3s, border-color 0.3s;
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
}

.btn-primary.icon-left {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.btn-primary.icon-left svg {
  margin-right: 0.625rem;
}

.btn-primary.icon-right {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.btn-primary.icon-right svg {
  margin-left: 0.625rem;
}

.btn-primary.icon-only {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0.625rem;
}

.btn-primary svg {
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
  background-color: #fff;
  padding: 0.1875rem;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

.btn-primary svg use {
  fill: #e20079;
  -webkit-transition: fill 0.3s;
  transition: fill 0.3s;
}

.btn-primary:focus, .btn-primary:hover {
  background-color: transparent;
  color: #e20079;
  border-color: #e20079;
}

.btn-primary:focus svg, .btn-primary:hover svg {
  background-color: #e20079;
}

.btn-primary:focus svg use, .btn-primary:hover svg use {
  fill: #fff;
}

.btn-primary.disabled, .btn-primary[disabled] {
  background-color: #d7d7d7;
  color: #bdbdbd;
  border-color: #d7d7d7;
  cursor: not-allowed;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-primary.disabled span:first-child, .btn-primary[disabled] span:first-child {
  display: none;
}

.btn-primary.disabled span:last-child, .btn-primary[disabled] span:last-child {
  -webkit-transform: none;
  transform: none;
}

.btn-primary-inverted {
  font-family: lato_bold, Helvetica, sans-serif;
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 1rem;
  color: #e20079;
  line-height: 1.5;
  letter-spacing: 0.125rem;
  border: 0.1875rem solid #fff;
  background-color: #fff;
  padding: 0.5625rem 2.1875rem;
  -webkit-transition: color 0.3s, background-color 0.3s, border-color 0.3s;
  transition: color 0.3s, background-color 0.3s, border-color 0.3s;
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
}

.btn-primary-inverted.icon-left {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.btn-primary-inverted.icon-left svg {
  margin-right: 0.625rem;
}

.btn-primary-inverted.icon-right {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.btn-primary-inverted.icon-right svg {
  margin-left: 0.625rem;
}

.btn-primary-inverted.icon-only {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0.625rem;
}

.btn-primary-inverted svg {
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
  background-color: #e20079;
  padding: 0.1875rem;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

.btn-primary-inverted svg use {
  fill: #fff;
  -webkit-transition: fill 0.3s;
  transition: fill 0.3s;
}

.btn-primary-inverted:focus, .btn-primary-inverted:hover {
  background-color: transparent;
  color: #fff;
  border-color: #fff;
}

.btn-primary-inverted:focus svg, .btn-primary-inverted:hover svg {
  background-color: #fff;
}

.btn-primary-inverted:focus svg use, .btn-primary-inverted:hover svg use {
  fill: #000;
}

.btn-primary-inverted.disabled, .btn-primary-inverted[disabled] {
  background-color: #d7d7d7;
  color: #bdbdbd;
  border-color: #d7d7d7;
  cursor: not-allowed;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-primary-inverted.disabled span:first-child, .btn-primary-inverted[disabled] span:first-child {
  display: none;
}

.btn-primary-inverted.disabled span:last-child, .btn-primary-inverted[disabled] span:last-child {
  -webkit-transform: none;
  transform: none;
}

.btn-secondary {
  font-family: lato_bold, Helvetica, sans-serif;
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 1rem;
  color: #e20079;
  line-height: 1.5;
  letter-spacing: 0.125rem;
  border: 0.125rem solid #fff;
  background-color: #fff;
  padding: 0.9375rem 2.1875rem;
  -webkit-transition: color 0.3s, background-color 0.3s, border-color 0.3s;
  transition: color 0.3s, background-color 0.3s, border-color 0.3s;
  position: relative;
}

.btn-secondary svg {
  fill: #e20079;
  -webkit-transition: fill 0.3s;
  transition: fill 0.3s;
}

.btn-secondary:focus, .btn-secondary:hover {
  background-color: #000;
  color: #fff;
  border-color: #fff;
}

.btn-secondary:focus svg, .btn-secondary:hover svg {
  fill: #fff;
}

.btn-secondary:focus span:first-child, .btn-secondary:hover span:first-child {
  opacity: 1;
  fill: #d7d7d7;
  -webkit-transform: rotate(0);
  transform: rotate(0);
}

.btn-secondary:focus span:last-child, .btn-secondary:hover span:last-child {
  -webkit-transform: translateX(1.09375rem);
  transform: translateX(1.09375rem);
}

.btn-secondary[disabled] {
  background-color: transparent;
  color: #bdbdbd;
  border-color: #bdbdbd;
  cursor: not-allowed;
}

.btn-secondary[disabled] span:first-child {
  display: none;
}

.btn-secondary[disabled] span:last-child {
  -webkit-transform: none;
  transform: none;
}

.extbase-debugger-center pre {
  white-space: pre;
}

header h1.header, header h2.header {
  font-family: lato_black, Helvetica, sans-serif;
  font-weight: 900;
  color: #000;
  line-height: 1.1666666667;
  margin-bottom: 1.5rem;
  text-transform: uppercase;
  font-size: 1.625rem;
  letter-spacing: 0.25rem;
  -webkit-transition: font-size 0.6s cubic-bezier(0.68, -0.55, 0.27, 1.55);
  transition: font-size 0.6s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

@media (min-width: 36rem) {
  header h1.header, header h2.header {
    font-size: calc(2.546vw + .70833rem);
  }
}
@media (min-width: 90rem) {
  header h1.header, header h2.header {
    font-size: 3rem;
  }
}
@media (min-width: 125rem) {
  header h1.header, header h2.header {
    font-size: calc(2.857vw + -.57143rem);
  }
}
header h3.header + .subheader {
  line-height: 1.3333333333;
  font-size: 1.125rem;
}

header h3.header + .subheader, header h4.header + .subheader {
  font-family: lato_black, Helvetica, sans-serif;
  font-weight: 900;
  color: #000;
  margin-bottom: 0.5rem;
  letter-spacing: 0.125rem;
}

header h4.header + .subheader {
  line-height: 1.5;
  font-size: 1rem;
}

header h5.header + .subheader {
  line-height: 1.7142857143;
  font-size: 0.875rem;
}

header .subheader, header h5.header + .subheader {
  font-family: lato_black, Helvetica, sans-serif;
  font-weight: 900;
  color: #000;
  letter-spacing: 0.125rem;
}

header .subheader {
  line-height: 1.4545454545;
  margin-bottom: 1.25rem;
  font-size: 1.375rem;
  -webkit-transition: font-size 0.6s cubic-bezier(0.68, -0.55, 0.27, 1.55);
  transition: font-size 0.6s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

@media (min-width: 36rem) {
  header .subheader {
    font-size: calc(.231vw + 1.16667rem);
  }
}
@media (min-width: 90rem) {
  header .subheader {
    font-size: 1.375rem;
  }
}
@media (min-width: 125rem) {
  header .subheader {
    font-size: calc(1.786vw + -.85714rem);
  }
}
.frame-layout-1 .ce-right .ce-textpic-wrapper, .frame-layout-1 .ce-textpic-wrapper {
  padding: 0 1.5rem;
}

@media (min-width: 48rem) {
  .frame-layout-1 .ce-right .ce-textpic-wrapper {
    padding: 0 0 0 1.25rem;
  }
}
.frame-layout-1 .ce-left .ce-textpic-wrapper {
  padding: 0 1.5rem;
}

@media (min-width: 48rem) {
  .frame-layout-1 .ce-left .ce-textpic-wrapper {
    padding: 0 1.25rem 0 0;
  }
}
.cke_panel_listItem.cke_selected a h2, .cke_panel_listItem.cke_selected a h3, .cke_panel_listItem.cke_selected a h4, .cke_panel_listItem.cke_selected a h5, .cke_panel_listItem.cke_selected a h6, .cke_panel_listItem a h2, .cke_panel_listItem a h3, .cke_panel_listItem a h4, .cke_panel_listItem a h5, .cke_panel_listItem a h6 {
  color: #fff;
}

.cke_panel_listItem.cke_selected a:active h2, .cke_panel_listItem.cke_selected a:active h3, .cke_panel_listItem.cke_selected a:active h4, .cke_panel_listItem.cke_selected a:active h5, .cke_panel_listItem.cke_selected a:active h6, .cke_panel_listItem.cke_selected a:focus h2, .cke_panel_listItem.cke_selected a:focus h3, .cke_panel_listItem.cke_selected a:focus h4, .cke_panel_listItem.cke_selected a:focus h5, .cke_panel_listItem.cke_selected a:focus h6, .cke_panel_listItem.cke_selected a:hover h2, .cke_panel_listItem.cke_selected a:hover h3, .cke_panel_listItem.cke_selected a:hover h4, .cke_panel_listItem.cke_selected a:hover h5, .cke_panel_listItem.cke_selected a:hover h6, .cke_panel_listItem a:active h2, .cke_panel_listItem a:active h3, .cke_panel_listItem a:active h4, .cke_panel_listItem a:active h5, .cke_panel_listItem a:active h6, .cke_panel_listItem a:focus h2, .cke_panel_listItem a:focus h3, .cke_panel_listItem a:focus h4, .cke_panel_listItem a:focus h5, .cke_panel_listItem a:focus h6, .cke_panel_listItem a:hover h2, .cke_panel_listItem a:hover h3, .cke_panel_listItem a:hover h4, .cke_panel_listItem a:hover h5, .cke_panel_listItem a:hover h6 {
  color: #e9e9e9;
}

.cke_panel_listItem h2, .cke_panel_listItem h3, .cke_panel_listItem h4, .cke_panel_listItem h5, .cke_panel_listItem h6, .cke_panel_listItem p {
  margin-bottom: 0;
  padding-bottom: 0;
}

.cke_panel_listItem h2:before, .cke_panel_listItem h3:before, .cke_panel_listItem h4:before, .cke_panel_listItem h5:before, .cke_panel_listItem h6:before, .cke_panel_listItem p:before {
  color: #000;
}

.cke_panel_listItem h2:before {
  content: "H2";
}

.cke_panel_listItem h3:before {
  content: "H3";
}

.cke_panel_listItem h4:before {
  content: "H4";
}

.cke_panel_listItem h5:before {
  content: "H5";
}

.cke_panel_listItem h6:before {
  content: "H6";
}

.frame-type-text {
  padding-top: 0;
  padding-bottom: 0;
}

@media (min-width: 48rem) {
  .frame-type-text .ce-text, .frame-type-text header {
    padding-right: 33.3333333333%;
  }
}
@media (min-width: 48rem) {
  .frame-type-text.frame-1 {
    padding-left: 16.6666666667%;
    padding-right: 16.6666666667%;
  }
}
.frame-type-text.frame-1 > * {
  padding-right: 0;
}

@media (min-width: 48rem) {
  .frame-type-table.frame-101 {
    padding-right: 33.3333333333%;
  }
}
@media (min-width: 48rem) {
  .frame-type-form_formframework.frame-1, .frame-type-table.frame-102 {
    padding-left: 16.6666666667%;
    padding-right: 16.6666666667%;
  }
}
@media (min-width: 48rem) {
  .frame-type-accordion .accordion, .frame-type-accordion header, .frame-type-image_gallery.frame-103 .image-gallery, .frame-type-image_gallery header, .frame-type-table header {
    padding-right: 33.3333333333%;
  }
}
.frame-type-accordion, .frame-type-form_formframework, .frame-type-image_gallery, .frame-type-list, .frame-type-listteaser, .frame-type-slider, .frame-type-textmedia, .frame-type-textpic, .frame-type-video {
  padding-bottom: 1.25rem;
  padding-top: 1.25rem;
}

@media (min-width: 48rem) {
  .frame-type-accordion, .frame-type-form_formframework, .frame-type-image_gallery, .frame-type-list, .frame-type-listteaser, .frame-type-slider, .frame-type-textmedia, .frame-type-textpic, .frame-type-video {
    padding-bottom: 2.5rem;
    padding-top: 2.5rem;
  }
}
@media (min-width: 64rem) {
  .frame-type-accordion, .frame-type-form_formframework, .frame-type-image_gallery, .frame-type-list, .frame-type-listteaser, .frame-type-slider, .frame-type-textmedia, .frame-type-textpic, .frame-type-video {
    padding-bottom: 3.75rem;
    padding-top: 3.75rem;
  }
}
.stagecontainer .frame-type-accordion, .stagecontainer .frame-type-form_formframework, .stagecontainer .frame-type-image_gallery, .stagecontainer .frame-type-list, .stagecontainer .frame-type-listteaser, .stagecontainer .frame-type-slider, .stagecontainer .frame-type-textmedia, .stagecontainer .frame-type-textpic, .stagecontainer .frame-type-video {
  padding-bottom: 0;
  padding-top: 0;
}

.frame-type-heroeteaser + :not(.frame-type-heroeteaser):not(.frame-type-list) {
  padding-top: 1.25rem;
}

@media (min-width: 48rem) {
  .frame-type-heroeteaser + :not(.frame-type-heroeteaser):not(.frame-type-list) {
    padding-top: 2.5rem;
  }
}
@media (min-width: 64rem) {
  .frame-type-heroeteaser + :not(.frame-type-heroeteaser):not(.frame-type-list) {
    padding-top: 3.75rem;
  }
}
.frame-layout-0:not(.frame-type-heroeteaser) + .frame-type-heroeteaser, .frame-layout-1:not(.frame-type-heroeteaser) + .frame-type-heroeteaser {
  padding-top: 1.25rem;
}

@media (min-width: 48rem) {
  .frame-layout-0:not(.frame-type-heroeteaser) + .frame-type-heroeteaser, .frame-layout-1:not(.frame-type-heroeteaser) + .frame-type-heroeteaser {
    padding-top: 2.5rem;
  }
}
@media (min-width: 64rem) {
  .frame-layout-0:not(.frame-type-heroeteaser) + .frame-type-heroeteaser, .frame-layout-1:not(.frame-type-heroeteaser) + .frame-type-heroeteaser {
    padding-top: 3.75rem;
  }
}
.frame-type-image_gallery header, .frame-type-list header, .frame-type-listteaser header, .frame-type-table header, .frame-type-text header, .frame-type-textmedia header, .frame-type-textpic header {
  margin-bottom: 1.5rem;
}

.frame-type-image_gallery header .header, .frame-type-image_gallery header .subheader, .frame-type-list header .header, .frame-type-list header .subheader, .frame-type-listteaser header .header, .frame-type-listteaser header .subheader, .frame-type-table header .header, .frame-type-table header .subheader, .frame-type-text header .header, .frame-type-text header .subheader, .frame-type-textmedia header .header, .frame-type-textmedia header .subheader, .frame-type-textpic header .header, .frame-type-textpic header .subheader {
  margin-bottom: 0;
  padding-bottom: 0;
}

.breadcrumb-wrapper + .container .frame:first-child {
  padding-top: 0;
}

.news-detail-header .headline {
  margin-bottom: 0;
}

.accordion-content-text h2, .accordion-content-text h3, .accordion-content-text h4, .accordion-content-text h5, .accordion-content-text h6, .accordion-content-text ol, .accordion-content-text ul, .cke_editable h2, .cke_editable h3, .cke_editable h4, .cke_editable h5, .cke_editable h6, .cke_editable ol, .cke_editable ul, .frame-type-text h2, .frame-type-text h3, .frame-type-text h4, .frame-type-text h5, .frame-type-text h6, .frame-type-textmedia h2, .frame-type-textmedia h3, .frame-type-textmedia h4, .frame-type-textmedia h5, .frame-type-textmedia h6, .frame-type-textmedia ol, .frame-type-textmedia ul, .frame-type-text ol, .frame-type-textpic h2, .frame-type-textpic h3, .frame-type-textpic h4, .frame-type-textpic h5, .frame-type-textpic h6, .frame-type-textpic ol, .frame-type-textpic ul, .frame-type-text ul, .heroteaser-bodytext h2, .heroteaser-bodytext h3, .heroteaser-bodytext h4, .heroteaser-bodytext h5, .heroteaser-bodytext h6, .heroteaser-bodytext ol, .heroteaser-bodytext ul, .news-detail-bodytext h2, .news-detail-bodytext h3, .news-detail-bodytext h4, .news-detail-bodytext h5, .news-detail-bodytext h6, .news-detail-bodytext ol, .news-detail-bodytext ul {
  margin-bottom: 0.75rem;
  padding-bottom: 0;
}

.accordion-content-text ol li, .accordion-content-text ul li, .cke_editable ol li, .cke_editable ul li, .frame-type-textmedia ol li, .frame-type-textmedia ul li, .frame-type-text ol li, .frame-type-textpic ol li, .frame-type-textpic ul li, .frame-type-text ul li, .heroteaser-bodytext ol li, .heroteaser-bodytext ul li, .news-detail-bodytext ol li, .news-detail-bodytext ul li {
  margin-bottom: 0;
  padding-bottom: 0;
}

.accordion-content-text p, .cke_editable p, .frame-type-textmedia p, .frame-type-text p, .frame-type-textpic p, .heroteaser-bodytext p, .news-detail-bodytext p {
  margin-bottom: 0.75rem;
}

.frame-type-accordion header {
  margin-bottom: 1.5rem;
}

.frame-type-accordion header .header, .frame-type-accordion header .subheader {
  margin-bottom: 0;
  padding-bottom: 0;
}

.accordion-item {
  padding-bottom: 1.875rem;
}

.accordion-item:last-child {
  padding-bottom: 0;
}

.accordion-item .accordion-header .toggle-icon {
  width: 1.5rem;
  height: 1.5rem;
  display: inline-block;
  position: absolute;
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  cursor: pointer;
  left: 0;
  margin: 0;
  position: relative;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transition: -webkit-transform 0.2s ease;
  transition: -webkit-transform 0.2s ease;
  transition: transform 0.2s ease;
  transition: transform 0.2s ease, -webkit-transform 0.2s ease;
}

@media (min-width: 75rem) {
  .accordion-item .accordion-header .toggle-icon {
    display: none;
  }
}
.accordion-item .accordion-header .toggle-icon svg {
  width: 100%;
  height: 100%;
}

.accordion-item .accordion-header .toggle-icon svg use {
  fill: #fff;
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.accordion-item .accordion-header .toggle-icon:hover svg use {
  fill: #e20079;
}

@media (min-width: 75rem) {
  .accordion-item .accordion-header .toggle-icon {
    display: inline-block;
  }
}
.accordion-item .accordion-header .toggle-icon svg use {
  fill: #e20079;
}

.accordion-item .accordion-header .accordion-title {
  padding-bottom: 0;
  position: relative;
}

.accordion-item .accordion-header .accordion-title-link {
  font-family: lato_regular, Helvetica, sans-serif;
  font-weight: 400;
  line-height: 1.5rem;
  font-size: 1rem;
  letter-spacing: 0.0625rem;
  font-family: lato_bold, Helvetica, sans-serif;
  font-weight: 700;
  color: #e20079;
  margin-bottom: 0;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  text-decoration: none;
  color: #000;
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  width: 100%;
}

.accordion-item .accordion-header .accordion-title-link:hover {
  color: #000;
}

@media (min-width: 48rem) {
  .accordion-item .accordion-header .accordion-title-link {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}
.accordion-item .accordion-header .accordion-title-link-text {
  display: block;
  padding-left: 1rem;
  -webkit-transition: color 0.3s ease;
  transition: color 0.3s ease;
  width: calc(100% - 24px);
}

.accordion-item .accordion-header .accordion-title-link-text:hover {
  color: #e20079;
}

.accordion-item .accordion-body {
  padding-left: 2.5rem;
  max-height: 0;
  -webkit-transition: max-height 0.3s cubic-bezier(0, 1.05, 0, 1), opacity 0.3s;
  transition: max-height 0.3s cubic-bezier(0, 1.05, 0, 1), opacity 0.3s;
  overflow: hidden;
  opacity: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  background: #fff;
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

.accordion-item .accordion-body .accordion-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column wrap;
  flex-flow: column wrap;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  width: 100%;
}

@media (min-width: 36rem) {
  .accordion-item .accordion-body .accordion-content {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
  }
}
.accordion-item .accordion-body .accordion-content .accordion-content-media {
  width: 100%;
  min-width: unset;
  max-width: unset;
  margin: 0 0 1.25rem;
}

@media (min-width: 36rem) {
  .accordion-item .accordion-body .accordion-content .accordion-content-media {
    min-width: 30%;
    max-width: 30%;
    margin: 0 1.25rem 0 0;
  }
}
.accordion-item .accordion-body .accordion-content .accordion-content-media img {
  width: 100%;
  height: auto;
}

.accordion-item.active .toggle-icon {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.accordion-item.active .accordion-title-link-text {
  color: #e20079;
}

.accordion-item.active .accordion-body {
  max-height: none;
  opacity: 1;
  -webkit-transition: max-height 0.3s cubic-bezier(1, 0, 1, 0), opacity 0.3s;
  transition: max-height 0.3s cubic-bezier(1, 0, 1, 0), opacity 0.3s;
}

.breadcrumb {
  display: none;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (min-width: 48rem) {
  .breadcrumb {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
.breadcrumb-wrapper {
  max-width: 1440px;
  height: inherit;
  margin: 0 auto;
  position: relative;
  padding: 1.875rem 1.5rem;
}

@media (min-width: 62rem) {
  .breadcrumb-wrapper {
    padding: 1.875rem 4.861%;
  }
}
@media (min-width: 90rem) {
  .breadcrumb-wrapper {
    padding: 1.875rem 4.375rem;
  }
}
.breadcrumb-divider {
  width: 1rem;
  height: 1rem;
}

.breadcrumb-divider path {
  fill: #000;
}

.breadcrumb li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: lato_bold, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 0.8125rem;
  line-height: 1rem;
  color: #000;
  letter-spacing: 0.0625rem;
  -webkit-transition: color 0.3s ease;
  transition: color 0.3s ease;
}

.breadcrumb li:hover {
  color: #e20079;
}

.breadcrumb li.current {
  color: #656565;
  font-family: lato_regular, Helvetica, sans-serif;
  font-weight: 400;
}

.breadcrumb li:not(:last-of-type) {
  margin-right: 0.3125rem;
}

.frame-type-list {
  padding-top: 0;
}

.tx-px-contact-finder .contact-search-wrapper {
  margin-bottom: 5rem;
}

.tx-px-contact-finder .contact-search-form {
  max-width: none;
}

.tx-px-contact-finder .contact-search-content-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column wrap;
  flex-flow: column wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  -webkit-box-pack: left;
  -ms-flex-pack: left;
  justify-content: left;
}

@media (min-width: 62rem) {
  .tx-px-contact-finder .contact-search-content-wrapper {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
  }
}
.tx-px-contact-finder .contact-search-content-wrapper .form-label {
  padding-right: 0;
  margin-bottom: 1.25rem;
}

@media (min-width: 62rem) {
  .tx-px-contact-finder .contact-search-content-wrapper .form-label {
    margin-bottom: 0;
    padding-right: 1.25rem;
  }
}
.tx-px-contact-finder .contact-search-content-wrapper input {
  min-width: 12.5rem;
}

.tx-px-contact-finder .contact-search-content-wrapper input.contact-form-submit {
  margin: 1.25rem 0 0;
}

@media (min-width: 36rem) {
  .tx-px-contact-finder .contact-search-content-wrapper input.contact-form-submit {
    margin: 0 0 0 1.25rem;
  }
}
.tx-px-contact-finder .contact-search-content-wrapper .search-button-input-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column wrap;
  flex-flow: column wrap;
  width: 100%;
}

@media (min-width: 36rem) {
  .tx-px-contact-finder .contact-search-content-wrapper .search-button-input-wrapper {
    width: auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
  }
}
.tx-px-contact-finder .contact-search-content-wrapper .search-button-input-wrapper .btn-primary {
  display: block;
}

.tx-px-contact-finder .contact-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
}

@media (min-width: 62rem) {
  .tx-px-contact-finder .contact-wrapper {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
  }
}
.tx-px-contact-finder .contact-wrapper .contact-image {
  width: 100%;
}

@media (min-width: 62rem) {
  .tx-px-contact-finder .contact-wrapper .contact-image {
    width: 50%;
    min-width: 32.9375rem;
  }
}
.tx-px-contact-finder .contact-wrapper .contact-information {
  background: #f5f5f5;
  padding: 3.125rem 8.33333%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column wrap;
  flex-flow: column wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media (min-width: 62rem) {
  .tx-px-contact-finder .contact-wrapper .contact-information {
    width: 50%;
    padding: 1.5rem 8.33333% 1.5rem 1.5rem;
  }
}
@media (min-width: 75rem) {
  .tx-px-contact-finder .contact-wrapper .contact-information {
    width: 41.6666666667%;
  }
}
.tx-px-contact-finder .contact-wrapper .contact-information .contact-information-block {
  margin-bottom: 1.5rem;
}

@media (min-width: 62rem) {
  .tx-px-contact-finder .contact-wrapper .contact-information .contact-information-block {
    margin-bottom: 0;
  }
}
.tx-px-contact-finder .contact-wrapper .contact-information .btn-primary {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.tx-px-contact-finder .contact-wrapper .contact-information .contact-person-name {
  margin-bottom: 0;
}

.tx-px-contact-finder .contact-wrapper .contact-information span {
  font-size: 0.875rem;
  line-height: 1.5rem;
}

.tx-px-contact-finder .contact-wrapper .contact-information .label {
  font-family: lato_bold, Helvetica, sans-serif;
  font-weight: 700;
  width: 6.25rem;
  display: inline-block;
}

.donations-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -0.625rem;
}

.donations-grid-item {
  margin: 0 0.625rem;
  width: calc(100% - 20px);
  padding-bottom: 2.5rem;
}

@media (min-width: 48rem) {
  .donations-grid-item {
    width: calc(50% - 20px);
  }
}
@media (min-width: 62rem) {
  .donations-grid-item {
    width: calc(33.33333% - 20px);
  }
}
.donations-grid-item-label {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column wrap;
  flex-flow: column wrap;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  cursor: pointer;
  height: 100%;
}

.donations-grid-item-label:after, .donations-grid-item-label:before {
  display: none;
}

.donations-grid-item-label-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 0.5rem;
}

.donations-grid-item-input {
  display: none;
}

.donations-grid-item-input:checked ~ label .donations-selected-icon {
  background: #e20079;
  -webkit-box-shadow: inset 0 0 0 0.0625rem #e20079;
  box-shadow: inset 0 0 0 0.0625rem #e20079;
}

.donations-grid-item-input:checked ~ label .donations-selected-icon svg {
  opacity: 1;
  -webkit-transform: translateY(-125%) translateX(-50%);
  transform: translateY(-125%) translateX(-50%);
}

.donations-grid-item-input:checked ~ label .donations-selected-text-not-selected {
  opacity: 0;
  visibility: visible;
}

.donations-grid-item-input:checked ~ label .donations-selected-text-selected {
  color: #e20079;
  visibility: visible;
  opacity: 1;
}

.donations-headline {
  margin: 0;
}

.donations-donation-count {
  background-color: #e20079;
  padding: 0.625rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-family: lato_black, Helvetica, sans-serif;
  font-weight: 900;
  color: #fff;
  line-height: 1.5;
  font-size: 1rem;
  letter-spacing: 0.125rem;
  text-transform: uppercase;
  margin: 0;
}

.donations-description {
  font-family: lato_regular, Helvetica, sans-serif;
  font-weight: 400;
  color: #000;
  line-height: 1.5rem;
  font-size: 1rem;
  letter-spacing: 0.0625rem;
  padding: 1.25rem;
  display: inline-block;
  background-color: #f5f5f5;
  margin-bottom: 0;
}

.donations-selected {
  position: relative;
  height: 1.5rem;
  width: 100%;
  -ms-flex-item-align: end;
  align-self: flex-end;
}

.donations-selected-icon {
  width: 1.5rem;
  height: 1.5rem;
  background-color: #d7d7d7;
  display: inline-block;
  -webkit-box-shadow: inset 0 0 0 0.0625rem #bdbdbd;
  box-shadow: inset 0 0 0 0.0625rem #bdbdbd;
  margin-right: 0.625rem;
  -webkit-transition: background-color 0.3s, -webkit-box-shadow 0.3s;
  transition: background-color 0.3s, -webkit-box-shadow 0.3s;
  transition: background-color 0.3s, box-shadow 0.3s;
  transition: background-color 0.3s, box-shadow 0.3s, -webkit-box-shadow 0.3s;
  position: relative;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.donations-selected-icon svg {
  width: 1rem;
  height: 1rem;
  fill: #fff;
  opacity: 0;
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
  transition: opacity 0.3s, transform 0.3s, -webkit-transform 0.3s;
  position: absolute;
  top: 100%;
  left: 50%;
  -webkit-transform: translateY(0) translateX(-50%);
  transform: translateY(0) translateX(-50%);
}

.donations-selected-text {
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 2.125rem;
  font-family: lato_regular, Helvetica, sans-serif;
  font-weight: 400;
  color: #000;
  line-height: 1.5rem;
  margin-bottom: 1.5rem;
  font-size: 0.875rem;
  font-family: lato_bold, Helvetica, sans-serif;
  font-weight: 700;
  padding: 0;
  letter-spacing: 0.0625rem;
}

.donations-selected-text-wrapper {
  height: 100%;
  display: inline-block;
}

.donations-selected-text-not-selected {
  color: #bdbdbd;
}

.donations-selected-text-selected {
  visibility: hidden;
  opacity: 0;
}

.donations-image {
  background-color: #f5f5f5;
  height: 13.25rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  overflow: hidden;
}

.donations-image img {
  height: 100%;
  width: auto;
  max-height: 13.25rem;
}

.frame-type-slider {
  position: relative;
  padding-bottom: 0.75rem;
}

.main-carousel {
  overflow: hidden;
  position: relative;
}

.main-carousel:focus {
  outline: none;
}

.three-colored-border {
  background: #009ee0;
  height: 1rem;
  width: 100%;
  position: relative;
  position: absolute;
  bottom: 0;
}

.three-colored-border:before {
  left: 0;
  background: #003378;
}

.three-colored-border:after, .three-colored-border:before {
  content: "";
  width: 33%;
  height: 1rem;
  display: block;
  position: absolute;
  bottom: 0;
  z-index: 1;
}

.three-colored-border:after {
  right: 0;
  background: #e20079;
}

.flickity-slider {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: static;
  width: 100%;
}

.flickity-slider img, .slider-single-image {
  width: 100%;
  height: auto;
}

.carousel-cell {
  width: 100%;
  cursor: -webkit-grab;
  cursor: grab;
  background-color: transparent !important;
  position: static !important;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  margin-right: 1.25rem;
}

.flickity-prev-next-button {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 2.5rem;
  height: 2.5rem;
  display: none;
  background: #e20079;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
}

.flickity-prev-next-button:hover {
  background: #ff49aa;
}

@media (min-width: 48rem) {
  .flickity-prev-next-button {
    display: block;
  }
}
.flickity-prev-next-button.previous {
  left: 1.25rem;
}

.flickity-prev-next-button.next {
  right: 1.25rem;
}

.flickity-prev-next-button[disabled] {
  opacity: 0.3;
  cursor: default;
}

.flickity-prev-next-button .flickity-button-icon {
  width: 1rem;
  height: 1rem;
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.flickity-prev-next-button .flickity-button-icon path {
  width: 100%;
  height: 100%;
  fill: #fff;
  -webkit-transition: fill 0.3s;
  transition: fill 0.3s;
}

.flickity-page-dots {
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%);
  bottom: 2.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.flickity-page-dots li {
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 50%;
  background: #d7d7d7;
  cursor: pointer;
}

.flickity-page-dots li.is-selected {
  background: #e20079;
}

.flickity-page-dots li:not(:last-of-type) {
  margin-right: 0.625rem;
}

.footer-navigation {
  display: block;
}

@media (min-width: 75rem) {
  .footer-navigation {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 3.75rem;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem;
  }
}
.footer-navigation .footer-navigation-item.first-level {
  font-family: lato_black, Helvetica, sans-serif;
  font-weight: 900;
  text-transform: none;
  color: #fff;
  font-size: 1.125rem;
  letter-spacing: 0.125rem;
  text-align: center;
  margin-bottom: 2.5rem;
}

.footer-navigation .footer-navigation-item.first-level .link-wrapper {
  cursor: pointer;
}

.footer-navigation .footer-navigation-item.first-level .link-wrapper > a {
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}

.footer-navigation .footer-navigation-item.first-level .link-wrapper > a.mobile-only {
  display: inline-block;
}

@media (min-width: 75rem) {
  .footer-navigation .footer-navigation-item.first-level .link-wrapper > a.mobile-only {
    display: none;
  }
}
.footer-navigation .footer-navigation-item.first-level .link-wrapper > a.large-only {
  display: none;
}

@media (min-width: 75rem) {
  .footer-navigation .footer-navigation-item.first-level .link-wrapper > a.large-only {
    display: inline-block;
  }
}
.footer-navigation .footer-navigation-item.first-level .link-wrapper:hover > a {
  color: #e20079;
}

.footer-navigation .footer-navigation-item.first-level .link-wrapper:hover .toggle-icon svg use {
  fill: #e20079;
}

@media (min-width: 75rem) {
  .footer-navigation .footer-navigation-item.first-level {
    text-align: left;
  }
}
@media (-ms-high-contrast: none) and (min-width: 75rem) {
  .footer-navigation .footer-navigation-item.first-level {
    max-width: 13.375rem;
  }

  .footer-navigation .footer-navigation-item.first-level:first-of-type {
    -ms-grid-column: 1;
    -ms-grid-row: 1;
  }

  .footer-navigation .footer-navigation-item.first-level:nth-of-type(2) {
    -ms-grid-column: 2;
    -ms-grid-row: 1;
  }

  .footer-navigation .footer-navigation-item.first-level:nth-of-type(3) {
    -ms-grid-column: 3;
    -ms-grid-row: 1;
  }

  .footer-navigation .footer-navigation-item.first-level:nth-of-type(4) {
    -ms-grid-column: 1;
    -ms-grid-row: 2;
  }

  .footer-navigation .footer-navigation-item.first-level:nth-of-type(5) {
    -ms-grid-column: 2;
    -ms-grid-row: 2;
  }

  .footer-navigation .footer-navigation-item.first-level:nth-of-type(6) {
    -ms-grid-column: 3;
    -ms-grid-row: 2;
  }
}
.footer-navigation .footer-navigation-item.first-level.open .footer-navigation.level-2 {
  max-height: 50rem;
  -webkit-transition: max-height 0.3s ease-in;
  transition: max-height 0.3s ease-in;
}

.footer-navigation .footer-navigation-item.first-level.open .toggle-icon {
  -webkit-transform: rotateX(-180deg);
  transform: rotateX(-180deg);
}

.footer-navigation .footer-navigation-item.first-level .toggle-icon {
  width: 1.5rem;
  height: 1.5rem;
  display: inline-block;
  position: absolute;
  margin-left: 0.625rem;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  cursor: pointer;
}

@media (min-width: 75rem) {
  .footer-navigation .footer-navigation-item.first-level .toggle-icon {
    display: none;
  }
}
.footer-navigation .footer-navigation-item.first-level .toggle-icon svg {
  width: 100%;
  height: 100%;
}

.footer-navigation .footer-navigation-item.first-level .toggle-icon svg use {
  fill: #fff;
  -webkit-transition: fill 0.3s;
  transition: fill 0.3s;
}

.footer-navigation .footer-navigation-item.first-level .toggle-icon:hover svg use {
  fill: #e20079;
}

.footer-navigation .footer-navigation.level-2 {
  display: block;
  overflow: hidden;
  max-height: 0;
  -webkit-transition: max-height 0.3s ease-out;
  transition: max-height 0.3s ease-out;
}

@media (min-width: 75rem) {
  .footer-navigation .footer-navigation.level-2 {
    max-height: none;
    margin-top: 1.5rem;
  }
}
.footer-navigation .footer-navigation.level-2 .footer-navigation-item {
  font-family: lato_bold, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 0.8125rem;
  line-height: 1.375rem;
  width: 100%;
}

.footer-navigation .footer-navigation.level-2 .footer-navigation-item:first-of-type {
  margin-top: 1.25rem;
}

@media (min-width: 75rem) {
  .footer-navigation .footer-navigation.level-2 .footer-navigation-item:first-of-type {
    margin-top: 0;
  }
}
.footer-navigation .footer-navigation.level-2 .footer-navigation-item:not(:last-of-type) {
  margin-bottom: 0.875rem;
}

.footer-navigation .footer-navigation.level-2 .footer-navigation-item > a {
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}

.footer-navigation .footer-navigation.level-2 .footer-navigation-item > a:hover {
  color: #e20079;
}

.footer {
  background-color: #102036;
}

.footer .content-wrapper {
  max-width: 1440px;
  height: inherit;
  margin: 0 auto;
  position: relative;
  padding: 2.5rem 1.875rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column wrap;
  flex-flow: column wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (min-width: 62rem) {
  .footer .content-wrapper {
    padding: 3.75rem 4.375rem;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
}
.footer-left, .footer-middle {
  width: 100%;
  padding-bottom: 3.75rem;
}

@media (min-width: 62rem) {
  .footer-left, .footer-middle {
    width: 33%;
    padding: 0;
  }
}
.footer-left .link-container, .footer-middle .link-container {
  width: 100%;
  text-align: center;
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
}

@media (min-width: 62rem) {
  .footer-left .link-container, .footer-middle .link-container {
    width: auto;
    text-align: left;
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
}
.footer-left .link-container ul li, .footer-middle .link-container ul li {
  font-family: lato_regular, Helvetica, sans-serif;
  font-weight: 400;
  color: #000;
  line-height: 1.5rem;
  font-size: 1rem;
  letter-spacing: 0.0625rem;
  font-size: 0.8125rem;
  line-height: 1.375rem;
  letter-spacing: 0.125rem;
  margin-bottom: 0.5rem;
}

.footer-left .link-container ul li a, .footer-middle .link-container ul li a {
  font-family: lato_bold, Helvetica, sans-serif;
  font-weight: 700;
  color: #fff;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}

.footer-left .link-container ul li a:hover, .footer-middle .link-container ul li a:hover {
  color: #e20079;
}

.footer-left .link-container ul li:last-child, .footer-middle .link-container ul li:last-child {
  margin-bottom: 0;
}

.footer-left .link-container .za-link, .footer-middle .link-container .za-link {
  background-color: #e20079;
  text-transform: uppercase;
  font-family: lato_black, Helvetica, sans-serif;
  font-weight: 900;
  color: #000;
  line-height: 1.5;
  font-size: 1rem;
  color: #fff;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  margin: 1.875rem 0 0;
  display: inline-block;
  padding: 0;
  letter-spacing: 0.125rem;
}

.footer-left .link-container .za-link:hover, .footer-middle .link-container .za-link:hover {
  background-color: #ff49aa;
}

.footer-left .link-container .za-link a, .footer-middle .link-container .za-link a {
  display: inline-block;
  padding: 0.75rem 1.5rem;
}

.footer-right {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column wrap;
  flex-flow: column wrap;
  max-width: 33.125rem;
}

@media (min-width: 62rem) {
  .footer-right {
    padding: 0;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
}
.footer-right .social-container {
  width: 100%;
  text-align: center;
}

@media (min-width: 62rem) {
  .footer-right .social-container {
    width: auto;
    text-align: left;
  }
}
.footer-right .social-container h5 {
  color: #fff;
  letter-spacing: 0.125rem;
}

.footer-right .social-container .social-media-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 1.5rem;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@media (min-width: 62rem) {
  .footer-right .social-container .social-media-list {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
}
.footer-right .social-container .social-media-list li {
  cursor: pointer;
}

.footer-right .social-container .social-media-list li:not(:last-of-type) {
  margin-right: 1.875rem;
}

.footer-right .social-container .social-media-list li:hover svg use {
  fill: #e20079;
}

.footer-right .social-container .social-media-list li svg {
  width: 1rem;
  height: 1rem;
}

.footer-right .social-container .social-media-list li svg use {
  fill: #fff;
  -webkit-transition: fill 0.3s;
  transition: fill 0.3s;
}

.footer-right .newsletter {
  max-width: 31.25rem;
  text-align: center;
  margin-bottom: 3.75rem;
}

@media (min-width: 62rem) {
  .footer-right .newsletter {
    max-width: none;
    margin-bottom: 5rem;
    text-align: left;
  }
}
.footer-right .newsletter h4, .footer-right .newsletter p {
  color: #fff;
  letter-spacing: 0.125rem;
  margin-bottom: 1.5rem;
}

.footer-right .newsletter p {
  font-family: lato_bold, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 0.8125rem;
}

.footer-right .newsletter-input-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

@media (min-width: 62rem) {
  .footer-right .newsletter-input-wrapper :first-child {
    width: calc(100% - 220px);
  }
}
.footer-right .newsletter-input-wrapper :last-child {
  margin-top: 0.625rem;
}

@media (min-width: 62rem) {
  .footer-right .newsletter-input-wrapper :last-child {
    width: 13.75rem;
    margin-top: 0;
  }
}
.footer-right .newsletter-input {
  background-color: #334153;
  color: #fff;
  font-size: 0.8125rem;
  padding: 0.875rem;
  border: 0.125rem solid #334153;
  -webkit-transition: border 0.3s;
  transition: border 0.3s;
}

.footer-right .newsletter-input:active, .footer-right .newsletter-input:focus {
  border: 0.125rem solid #fff;
}

.footer-right .newsletter-input::-webkit-input-placeholder {
  font-family: lato_italic, Helvetica, sans-serif;
  font-style: italic;
  font-weight: 400;
  color: #fff;
  letter-spacing: 0.21875rem;
  opacity: 0.8;
}

.footer-right .newsletter-input::-moz-placeholder {
  font-family: lato_italic, Helvetica, sans-serif;
  font-style: italic;
  font-weight: 400;
  color: #fff;
  letter-spacing: 0.21875rem;
  opacity: 0.8;
}

.footer-right .newsletter-input:-ms-input-placeholder {
  font-family: lato_italic, Helvetica, sans-serif;
  font-style: italic;
  font-weight: 400;
  color: #fff;
  letter-spacing: 0.21875rem;
  opacity: 0.8;
}

.footer-right .newsletter-input:-moz-placeholder {
  font-family: lato_italic, Helvetica, sans-serif;
  font-style: italic;
  font-weight: 400;
  color: #fff;
  letter-spacing: 0.21875rem;
  opacity: 0.8;
}

.footer-right .newsletter-submit {
  background-color: #e20079;
  text-transform: uppercase;
  cursor: pointer;
  font-family: lato_black, Helvetica, sans-serif;
  font-weight: 900;
  color: #000;
  line-height: 1.5;
  font-size: 1rem;
  color: #fff;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  margin: 1.875rem 0 0;
  display: inline-block;
  padding: 0.75rem 0;
  letter-spacing: 0.125rem;
}

.footer-right .newsletter-submit:hover {
  background-color: #ff49aa;
}

.footer-right.full {
  margin: 0 auto;
  padding: 3.75rem 1rem;
}

.footer-right.full h4, .footer-right.full p {
  color: #000;
}

.footer-right.full .newsletter-input:active, .footer-right.full .newsletter-input:focus {
  border: 0.125rem solid #334153;
}

.footer-right.full .newsletter p {
  font-family: lato_regular, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.0625rem;
}

.footer-lower {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #0b1729;
  padding: 1.5rem 1.875rem;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.footer-lower .footer-copyright-container {
  color: #fff;
  font-family: lato_bold, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 0.6875rem;
  letter-spacing: 0.1875rem;
  width: 100%;
  text-align: center;
}

@media (min-width: 62rem) {
  .footer-lower .footer-copyright-container {
    width: auto;
    text-align: left;
  }
}
.newsletter-privacy {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 1.25rem;
}

.newsletter-privacy .newsletter-privacy-link {
  padding: 0 0.3125rem;
  font-family: lato_bold, Helvetica, sans-serif;
  font-weight: 700;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}

.newsletter-privacy .newsletter-privacy-link:hover {
  color: #e20079;
}

form[id*=rechnungskopie-] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
}

@media (min-width: 62rem) {
  form[id*=rechnungskopie-] {
    max-width: calc(640px + 33%);
    padding-right: 33%;
  }
}
form[id*=rechnungskopie-] .radio-label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

form[id*=rechnungskopie-] .radio-label:not(:last-of-type) {
  margin-right: 4.375rem;
}

form[id*=rechnungskopie-] h2 {
  margin-bottom: 0;
}

form[id*=rechnungskopie-] h2 + p:last-child:last-child:not(class) {
  margin-bottom: 1.875rem;
}

form[id*=rechnungskopie-] > div:first-of-type {
  display: none;
}

form[id*=rechnungskopie-] .row {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 100%;
  flex: 1 0 100%;
  margin-bottom: 0;
  max-width: 100%;
}

@media (min-width: 62rem) {
  form[id*=rechnungskopie-] .row {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 33%;
    flex: 1 0 33%;
  }
}
form[id*=rechnungskopie-] .row div > .form-group {
  padding-bottom: 1.25rem;
}

form[id*=rechnungskopie-] .row div .label-straße.type-text {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 60%;
  flex: 1 0 60%;
}

form[id*=rechnungskopie-] .row div .label-plz.type-number, form[id*=rechnungskopie-] .row div .label-straße.type-text + .type-text {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
}

form[id*=rechnungskopie-] .row div .label-plz.type-number + .label-ort.type-text {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 60%;
  flex: 1 0 60%;
}

form[id*=rechnungskopie-] .row .row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

form[id*=rechnungskopie-] .row ~ .row div:nth-of-type(4) .row div:first-of-type {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 60%;
  flex: 1 0 60%;
}

form[id*=rechnungskopie-] .row ~ .row div:nth-of-type(4) .row div:last-of-type, form[id*=rechnungskopie-] .row ~ .row div:nth-of-type(5) .row div:first-of-type {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
}

form[id*=rechnungskopie-] .row ~ .row div:nth-of-type(5) .row div:last-of-type {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 60%;
  flex: 1 0 60%;
}

@media (min-width: 62rem) {
  form[id*=rechnungskopie-] .row ~ .row {
    margin-left: 8.125rem;
  }
}
form[id*=rechnungskopie-] .row .col-lg-1, form[id*=rechnungskopie-] .row .col-md-1, form[id*=rechnungskopie-] .row .col-sm-1, form[id*=rechnungskopie-] .row .col-xs-1 {
  position: relative;
}

form[id*=rechnungskopie-] .row .label-art.type-radiobutton, form[id*=rechnungskopie-] .row .label-geburtstag.type-date, form[id*=rechnungskopie-] .row .label-vorname.type-text, form[id*=rechnungskopie-] .row .type-gridrow {
  width: 100%;
  -webkit-box-flex: 1 !important;
  -ms-flex: 1 0 100% !important;
  flex: 1 0 100% !important;
  margin-right: 0;
  z-index: 1;
}

form[id*=rechnungskopie-] .row .label-art.type-radiobutton + .label-content.type-contentelement, form[id*=rechnungskopie-] .row .label-geburtstag.type-date + .label-content.type-contentelement, form[id*=rechnungskopie-] .row .label-vorname.type-text + .label-content.type-contentelement, form[id*=rechnungskopie-] .row .type-gridrow + .label-content.type-contentelement {
  width: 100%;
}

@media (min-width: 62rem) {
  form[id*=rechnungskopie-] .row .label-art.type-radiobutton + .label-content.type-contentelement, form[id*=rechnungskopie-] .row .label-geburtstag.type-date + .label-content.type-contentelement, form[id*=rechnungskopie-] .row .label-vorname.type-text + .label-content.type-contentelement, form[id*=rechnungskopie-] .row .type-gridrow + .label-content.type-contentelement {
    position: absolute;
    height: 100%;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}
@media (min-width: 62rem) {
  form[id*=rechnungskopie-] .row .label-art.type-radiobutton + .label-contentelement.type-contentelement .frame-type-accordion {
    top: 0.3125rem;
  }
}
form[id*=rechnungskopie-] .actions {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
}

form[id*=rechnungskopie-] button[type=submit] {
  width: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

form[id*=rechnungskopie-] input[type=checkbox] + span {
  font-family: lato_regular, Helvetica, sans-serif;
  font-weight: 400;
  color: #929292;
  margin-bottom: 1.5rem;
  font-size: 0.75rem;
  letter-spacing: 0.0625rem;
  line-height: 1.5rem;
}

form[id*=rechnungskopie-] input[type=date].hidden {
  display: none;
}

form[id*=rechnungskopie-] p {
  font-family: lato_regular, Helvetica, sans-serif;
  font-weight: 400;
  color: #929292;
  margin-bottom: 1.5rem;
  font-size: 0.75rem;
  letter-spacing: 0.0625rem;
  line-height: 1.5rem;
  margin-top: 0.9375rem;
}

form[id*=rechnungskopie-] .frame-type-accordion {
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  z-index: 1;
}

@media (min-width: 62rem) {
  form[id*=rechnungskopie-] .frame-type-accordion {
    width: 50%;
    padding: 0;
    position: absolute;
    left: calc(100% + 20px);
    top: 2.5rem;
  }
}
form[id*=rechnungskopie-] .frame-type-accordion .accordion {
  padding: 0;
}

form[id*=rechnungskopie-] .frame-type-accordion .accordion-item .accordion-body {
  overflow: hidden;
}

form[id*=rechnungskopie-] .frame-type-accordion .accordion-item.active .accordion-body {
  overflow: visible;
}

form[id*=rechnungskopie-] .frame-type-accordion .accordion .toggle-icon {
  -webkit-transform: none !important;
  transform: none !important;
}

form[id*=rechnungskopie-] .frame-type-accordion .accordion .toggle-icon svg {
  display: none;
}

form[id*=rechnungskopie-] .frame-type-accordion .accordion .toggle-icon:before {
  content: "?";
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #f1f1f1;
  color: #656565;
  width: 1.5rem;
  height: 1.5rem;
  top: 0;
  left: 0;
}

form[id*=rechnungskopie-] .frame-type-accordion .accordion-body {
  background-color: transparent;
  padding-left: 0;
  -webkit-transition: none;
  transition: none;
}

@media (min-width: 62rem) {
  form[id*=rechnungskopie-] .frame-type-accordion .accordion-body {
    margin-top: -2.5rem;
    padding-left: 2.5rem;
    -webkit-transition: opacity 0.3s;
    transition: opacity 0.3s;
  }
}
form[id*=rechnungskopie-] .frame-type-accordion .accordion-content {
  padding: 1.25rem;
  -webkit-box-shadow: 0 0.625rem 2.5rem 0 rgba(0, 51, 120, 0.1);
  box-shadow: 0 0.625rem 2.5rem 0 rgba(0, 51, 120, 0.1);
  background-color: #fff;
}

form[id*=rechnungskopie-] .frame-type-accordion .accordion-body, form[id*=rechnungskopie-] .frame-type-accordion .accordion-content {
  overflow: visible;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

form[id*=rechnungskopie-] .frame-type-accordion .accordion-body-media, form[id*=rechnungskopie-] .frame-type-accordion .accordion-content-media {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
  max-width: none !important;
  width: 100%;
  margin-right: 0 !important;
  margin-top: 1.25rem !important;
}

form[id*=rechnungskopie-] .frame-type-accordion .accordion-body-text, form[id*=rechnungskopie-] .frame-type-accordion .accordion-content-text {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}

form[id*=rechnungskopie-] .frame-type-accordion .accordion-title-link-text {
  display: none;
}

form[id*=karriere-] div:nth-of-type(6) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
}

@media (min-width: 36rem) {
  form[id*=karriere-] div:nth-of-type(6) {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }
}
form[id*=karriere-] div:nth-of-type(6) div:first-of-type {
  -webkit-box-flex: 100%;
  -ms-flex: 100%;
  flex: 100%;
  margin: 0;
}

@media (min-width: 36rem) {
  form[id*=karriere-] div:nth-of-type(6) div:first-of-type {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 60%;
    flex: 1 0 60%;
  }
}
form[id*=karriere-] div:nth-of-type(6) div:first-of-type .form-group {
  margin: 0 0 1.5rem;
}

@media (min-width: 36rem) {
  form[id*=karriere-] div:nth-of-type(6) div:first-of-type .form-group {
    margin: 0 0.625rem 0 0;
  }
}
form[id*=karriere-] div:nth-of-type(6) div:last-of-type {
  -webkit-box-flex: 100%;
  -ms-flex: 100%;
  flex: 100%;
}

@media (min-width: 36rem) {
  form[id*=karriere-] div:nth-of-type(6) div:last-of-type {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
  }
}
form[id*=karriere-] div:nth-of-type(7) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
}

@media (min-width: 36rem) {
  form[id*=karriere-] div:nth-of-type(7) {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }
}
form[id*=karriere-] div:nth-of-type(7) div:first-of-type {
  -webkit-box-flex: 100%;
  -ms-flex: 100%;
  flex: 100%;
  margin: 0;
}

@media (min-width: 36rem) {
  form[id*=karriere-] div:nth-of-type(7) div:first-of-type {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
  }
}
form[id*=karriere-] div:nth-of-type(7) div:first-of-type .form-group {
  margin: 0 0 1.5rem;
}

@media (min-width: 36rem) {
  form[id*=karriere-] div:nth-of-type(7) div:first-of-type .form-group {
    margin: 0 0.625rem 0 0;
  }
}
form[id*=karriere-] div:nth-of-type(7) div:last-of-type {
  -webkit-box-flex: 100%;
  -ms-flex: 100%;
  flex: 100%;
}

@media (min-width: 36rem) {
  form[id*=karriere-] div:nth-of-type(7) div:last-of-type {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 60%;
    flex: 1 0 60%;
  }
}
form[id*=donations-] .row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 100%;
  flex: 1 0 100%;
  margin-bottom: 0;
  max-width: 100%;
}

@media (min-width: 62rem) {
  form[id*=donations-] .row {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 33%;
    flex: 1 0 33%;
  }
}
form[id*=donations-] .row div > .form-group {
  padding-bottom: 1.25rem;
}

form[id*=donations-] .row div.label-straße.type-text {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 60%;
  flex: 1 0 60%;
}

form[id*=donations-] .row div.label-plz.type-number, form[id*=donations-] .row div.label-straße.type-text + .type-text {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
}

form[id*=donations-] .row div.label-plz.type-number + .label-ort.type-text {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 60%;
  flex: 1 0 60%;
}

form[id*=rewards-] {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  max-width: 60rem;
  margin-left: 0;
}

form[id*=rewards-], form[id*=rewards-] .radio-label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

form[id*=rewards-] .radio-label {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

form[id*=rewards-] .radio-label:not(:last-of-type) {
  margin-right: 4.375rem;
}

form[id*=rewards-] > div:first-of-type {
  display: none;
}

form[id*=rewards-] .row {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 100%;
  flex: 1 0 100%;
}

@media (min-width: 62rem) {
  form[id*=rewards-] .row {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 33%;
    flex: 1 0 33%;
  }
}
form[id*=rewards-] .row div > .form-group {
  padding-bottom: 1.25rem;
}

form[id*=rewards-] .row div:nth-of-type(7) .row div:first-of-type {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 60%;
  flex: 1 0 60%;
}

form[id*=rewards-] .row div:nth-of-type(7) .row div:last-of-type, form[id*=rewards-] .row div:nth-of-type(8) .row div:first-of-type {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
}

form[id*=rewards-] .row div:nth-of-type(8) .row div:last-of-type {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 60%;
  flex: 1 0 60%;
}

form[id*=rewards-] .row .row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

form[id*=rewards-] .row ~ .row div:nth-of-type(6) .row div:first-of-type {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 60%;
  flex: 1 0 60%;
}

form[id*=rewards-] .row ~ .row div:nth-of-type(6) .row div:last-of-type, form[id*=rewards-] .row ~ .row div:nth-of-type(7) .row div:first-of-type {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
}

form[id*=rewards-] .row ~ .row div:nth-of-type(7) .row div:last-of-type {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 60%;
  flex: 1 0 60%;
}

@media (min-width: 62rem) {
  form[id*=rewards-] .row ~ .row {
    margin-left: 8.125rem;
  }
}
form[id*=rewards-] .actions {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
}

form[id*=rewards-] button[type=submit] {
  width: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 1.875rem;
}

form[id*=rewards-] input[type=checkbox] + span, form[id*=rewards-] p {
  font-family: lato_regular, Helvetica, sans-serif;
  font-weight: 400;
  color: #929292;
  margin-bottom: 1.5rem;
  font-size: 0.75rem;
  letter-spacing: 0.0625rem;
  line-height: 1.5rem;
}

form[id*=rewards-] p {
  margin-top: 0.9375rem;
}

.main-header {
  z-index: 999;
  overflow: visible;
  position: fixed;
  top: 0;
  width: 100%;
}

.main-header .upper-header-wrapper {
  background-color: #000;
  z-index: 1;
}

.main-header .upper-header-wrapper .content-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.main-header .upper-header-wrapper .content-wrapper .meta-navigation-wrapper {
  display: none;
}

@media (min-width: 62rem) {
  .main-header .upper-header-wrapper .content-wrapper .meta-navigation-wrapper {
    display: block;
  }
}
.main-header .lower-header-wrapper {
  background-color: #fff;
  overflow: visible;
  position: relative;
  z-index: 1;
  height: 2.8125rem;
}

.main-header .lower-header-wrapper:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  -webkit-box-shadow: 0 0.625rem 2.5rem 0 rgba(0, 51, 120, 0.1);
  box-shadow: 0 0.625rem 2.5rem 0 rgba(0, 51, 120, 0.1);
}

@media (min-width: 62rem) {
  .main-header .lower-header-wrapper {
    height: auto;
  }
}
.main-header .lower-header-wrapper .mobile-menu-search-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (min-width: 62rem) {
  .main-header .lower-header-wrapper .mobile-menu-search-wrapper {
    display: none;
  }
}
.main-header .lower-header-wrapper .mobile-menu-search-wrapper .mobile-search-trigger {
  margin-right: 0.625rem;
  cursor: pointer;
  height: 2.875rem;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 0.625rem;
}

.main-header .lower-header-wrapper .mobile-menu-search-wrapper .mobile-search-trigger:after {
  content: "";
  height: 0.25rem;
  background-color: #e20079;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  -webkit-transition: width 0.3s;
  transition: width 0.3s;
}

.main-header .lower-header-wrapper .mobile-menu-search-wrapper .mobile-search-trigger .icon {
  width: 1rem;
  height: 1rem;
  -webkit-transition: fill 0.3s;
  transition: fill 0.3s;
}

.main-header .lower-header-wrapper .mobile-menu-search-wrapper .mobile-search-trigger.active:after {
  width: 100%;
}

.main-header .lower-header-wrapper .mobile-menu-search-wrapper .mobile-search-trigger.active .icon {
  fill: #e20079;
}

.main-header .lower-header-wrapper .mobile-menu-search-wrapper .mobile-menu-wrapper {
  height: 2.875rem;
  width: 2.875rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.main-header .lower-header-wrapper .mobile-menu-search-wrapper .mobile-menu-wrapper .mobile-menu {
  height: 2.5rem;
  width: 2.5rem;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.main-header .lower-header-wrapper .mobile-menu-search-wrapper .mobile-menu-wrapper .mobile-menu span {
  display: block;
  position: absolute;
  height: 0.25rem;
  background: #d3531a;
  opacity: 1;
  left: 0.1875rem;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

.main-header .lower-header-wrapper .mobile-menu-search-wrapper .mobile-menu-wrapper .mobile-menu span:first-child {
  top: 0.5625rem;
  width: 1.875rem;
  background: #003378;
}

.main-header .lower-header-wrapper .mobile-menu-search-wrapper .mobile-menu-wrapper .mobile-menu span:nth-child(2), .main-header .lower-header-wrapper .mobile-menu-search-wrapper .mobile-menu-wrapper .mobile-menu span:nth-child(3) {
  top: 1.125rem;
  width: 1.4375rem;
  background: #009ee0;
}

.main-header .lower-header-wrapper .mobile-menu-search-wrapper .mobile-menu-wrapper .mobile-menu span:nth-child(4) {
  top: 1.6875rem;
  width: 2.125rem;
  background: #e20079;
}

.main-header .lower-header-wrapper .mobile-menu-search-wrapper .mobile-menu-wrapper .mobile-menu.open span:first-child {
  top: 18px;
  width: 0;
  left: 50%;
}

.main-header .lower-header-wrapper .mobile-menu-search-wrapper .mobile-menu-wrapper .mobile-menu.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 2.125rem;
  background: #e20079;
}

.main-header .lower-header-wrapper .mobile-menu-search-wrapper .mobile-menu-wrapper .mobile-menu.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  width: 2.125rem;
  background: #e20079;
}

.main-header .lower-header-wrapper .mobile-menu-search-wrapper .mobile-menu-wrapper .mobile-menu.open span:nth-child(4) {
  top: 18px;
  width: 0;
  left: 50%;
}

.main-header .lower-header-wrapper .content-wrapper {
  overflow: visible;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 1.5rem;
  position: relative;
  z-index: -1;
}

@media (min-width: 62rem) {
  .main-header .lower-header-wrapper .content-wrapper {
    padding: 0 1.875rem;
  }
}
.main-header .lower-header-wrapper .content-wrapper .logo {
  width: 2rem;
  height: 2rem;
}

@media (min-width: 62rem) {
  .main-header .lower-header-wrapper .content-wrapper .logo {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 3.75rem;
    height: 3.75rem;
  }
}
.main-header .lower-header-wrapper .content-wrapper .logo-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.main-header .lower-header-wrapper .content-wrapper .logo svg {
  width: 100%;
  height: 100%;
}

.main-header .lower-header-wrapper .content-wrapper .navi-search-wrapper {
  display: none;
}

@media (min-width: 62rem) {
  .main-header .lower-header-wrapper .content-wrapper .navi-search-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
.main-header .lower-header-wrapper .content-wrapper .main-navigation-wrapper {
  margin-right: 1.25rem;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.main-header .lower-header-wrapper .content-wrapper .search-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 10rem;
}

.main-header .mobile-search-container {
  position: fixed;
  top: -1.25rem;
  -webkit-transition: top 0.3s, opacity 0.3s;
  transition: top 0.3s, opacity 0.3s;
  width: 100%;
  padding: 1.25rem;
  background-color: #fff;
  opacity: 0;
}

@media (min-width: 62rem) {
  .main-header .mobile-search-container {
    display: none;
  }
}
.main-header .mobile-search-container.active {
  top: 2.8125rem;
  opacity: 1;
}

.main-header .mobile-search-container .main-search {
  max-width: none;
}

.main-header .mobile-search-container .main-search .tx-indexedsearch-searchbox-sword {
  margin: 0;
  background-image: none;
}

.main-header .mobile-search-container .main-search #tx-indexedsearch-searchbox-button-submit {
  background-color: #e20079;
  opacity: 1;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 3.75rem;
  font-family: lato_bold, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 0.8125rem;
  line-height: 0;
  text-transform: uppercase;
  letter-spacing: 0.0625rem;
}

.main-header .mobile-search-container .main-search #tx-indexedsearch-searchbox-button-submit:hover {
  background-color: #ff49aa;
}

.heroteaser {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-bottom: 0 !important;
}

@media (min-width: 48rem) {
  .heroteaser {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }
}
.heroteaser-image-container {
  width: 100%;
  border: 0;
}

@media (min-width: 48rem) {
  .heroteaser-image-container {
    width: 50%;
    border-left: 0;
    border-right: 0.25rem solid #e20079;
  }
}
.heroteaser-text-container {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: 0;
  padding: 3.125rem 1.25rem;
}

@media (min-width: 48rem) {
  .heroteaser-text-container {
    width: 50%;
    border-right: 0;
    border-left: 0.25rem solid #e20079;
    padding: 0.625rem 1.25rem;
  }
}
@media (min-width: 62rem) {
  .heroteaser-text-container {
    padding: 1.25rem 2.5rem;
  }
}
.heroteaser-text-container-wrapper {
  -webkit-transition: max-width 0.3s ease;
  transition: max-width 0.3s ease;
  width: 100%;
}

@media (min-width: 48rem) {
  .heroteaser-text-container-wrapper {
    max-width: 100%;
    width: auto;
  }
}
@media (min-width: 120rem) {
  .heroteaser-text-container-wrapper {
    max-width: 70%;
  }
}
.heroteaser-text-container .btn-primary, .heroteaser-text-container .btn-primary-inverted {
  margin-top: 1.875rem;
}

@media (min-width: 36rem) {
  .heroteaser-text-container .btn-primary, .heroteaser-text-container .btn-primary-inverted {
    font-size: calc(.231vw + .91667rem);
  }
}
@media (min-width: 90rem) {
  .heroteaser-text-container .btn-primary, .heroteaser-text-container .btn-primary-inverted {
    font-size: 1.125rem;
  }
}
@media (min-width: 125rem) {
  .heroteaser-text-container .btn-primary, .heroteaser-text-container .btn-primary-inverted {
    font-size: calc(1.071vw + -.21429rem);
  }
}
.heroteaser-text-container .heroteaser-headline {
  font-family: lato_black, Helvetica, sans-serif;
  font-weight: 900;
  font-size: 1.375rem;
  letter-spacing: 0.25rem;
  margin-bottom: 1.25rem;
  -webkit-transition: font-size 0.6s cubic-bezier(0.68, -0.55, 0.27, 1.55), margin-bottom 0.3s ease;
  transition: font-size 0.6s cubic-bezier(0.68, -0.55, 0.27, 1.55), margin-bottom 0.3s ease;
}

@media (min-width: 36rem) {
  .heroteaser-text-container .heroteaser-headline {
    font-size: calc(3.009vw + .29167rem);
  }
}
@media (min-width: 75rem) {
  .heroteaser-text-container .heroteaser-headline {
    margin-bottom: 2.5rem;
  }
}
@media (min-width: 90rem) {
  .heroteaser-text-container .heroteaser-headline {
    font-size: 3rem;
  }
}
@media (min-width: 125rem) {
  .heroteaser-text-container .heroteaser-headline {
    font-size: calc(2.857vw + -.57143rem);
  }
}
.heroteaser-text-container .heroteaser-bodytext p {
  font-size: 0.8125rem;
  -webkit-transition: font-size 0.6s cubic-bezier(0.68, -0.55, 0.27, 1.55);
  transition: font-size 0.6s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

@media (min-width: 36rem) {
  .heroteaser-text-container .heroteaser-bodytext p {
    font-size: calc(.347vw + .6875rem);
  }
}
@media (min-width: 90rem) {
  .heroteaser-text-container .heroteaser-bodytext p {
    font-size: 1rem;
  }
}
@media (min-width: 125rem) {
  .heroteaser-text-container .heroteaser-bodytext p {
    font-size: calc(1.429vw + -.78571rem);
  }
}
.heroteaser-text-container.magenta {
  background: #e20079;
}

.heroteaser-text-container.magenta .heroteaser-headline, .heroteaser-text-container.magenta .heroteaser-subheadline, .heroteaser-text-container.magenta p {
  color: #fff;
}

.heroteaser-text-container.magenta .toggle-icon svg use {
  fill: #fff;
}

.heroteaser-text-container.magenta a:hover .toggle-icon svg use {
  fill: #000;
}

.heroteaser-text-container.magenta a:hover .heroteaser-subheadline, .heroteaser-text-container.magenta a:hover p {
  color: #000;
}

.heroteaser.image-right .heroteaser-image-container {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
  border: 0;
}

@media (min-width: 48rem) {
  .heroteaser.image-right .heroteaser-image-container {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    border-right: 0;
    border-left: 0.25rem solid #e20079;
  }
}
.heroteaser.image-right .heroteaser-text-container {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
  border: 0;
}

@media (min-width: 48rem) {
  .heroteaser.image-right .heroteaser-text-container {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    border-left: 0;
    border-right: 0.25rem solid #e20079;
  }
}
.heroteaser.full-bg .heroteaser-image-container, .heroteaser.full-bg .heroteaser-text-container {
  border-left: none;
  border-right: none;
}

.heroteaser.full-bg .heroteaser-text-container {
  background-image: none;
}

@media (min-width: 48rem) {
  .heroteaser.full-bg .heroteaser-text-container {
    background-image: url(/typo3conf/ext/px_basis_config/Resources/Public/img/Heroteaser-bg-desktop.jpg);
    background-position: 0 0;
    background-size: cover;
    background-repeat: no-repeat;
  }
}
.heroteaser.full-bg .heroteaser-image-container {
  background-image: url(/typo3conf/ext/px_basis_config/Resources/Public/img/Heroteaser-bg-desktop.jpg);
  background-position: 0 0;
  background-size: cover;
  background-repeat: no-repeat;
}

.heroteaser-text-container-listelement, .listteaser-item {
  padding-bottom: 1.875rem;
  position: relative;
  -webkit-transition: padding 0.3s ease;
  transition: padding 0.3s ease;
}

@media (min-width: 48rem) {
  .heroteaser-text-container-listelement, .listteaser-item {
    padding-bottom: 0.9375rem;
  }
}
@media (min-width: 62rem) {
  .heroteaser-text-container-listelement, .listteaser-item {
    padding-bottom: 1.25rem;
  }
}
@media (min-width: 75rem) {
  .heroteaser-text-container-listelement, .listteaser-item {
    padding-bottom: 2.5rem;
  }
}
.heroteaser-text-container-listelement:last-child, .listteaser-item:last-child {
  padding-bottom: 0;
}

.heroteaser-text-container-listelement p, .listteaser-item p {
  font-size: 0.8125rem;
  margin-left: 1.625rem;
  -webkit-transition: font-size 0.6s cubic-bezier(0.68, -0.55, 0.27, 1.55), color 0.3s;
  transition: font-size 0.6s cubic-bezier(0.68, -0.55, 0.27, 1.55), color 0.3s;
}

@media (min-width: 36rem) {
  .heroteaser-text-container-listelement p, .listteaser-item p {
    font-size: calc(.347vw + .6875rem);
  }
}
@media (min-width: 62rem) {
  .heroteaser-text-container-listelement p, .listteaser-item p {
    margin-left: 2.125rem;
  }
}
@media (min-width: 90rem) {
  .heroteaser-text-container-listelement p, .listteaser-item p {
    font-size: 1rem;
  }
}
@media (min-width: 125rem) {
  .heroteaser-text-container-listelement p, .listteaser-item p {
    font-size: calc(1.429vw + -.78571rem);
  }
}
.heroteaser-text-container-listelement .toggle-icon, .listteaser-item .toggle-icon {
  width: 1.5rem;
  height: 1.5rem;
  display: inline-block;
  position: absolute;
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  cursor: pointer;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  margin: 0;
  width: 1rem;
  height: 1rem;
  position: relative;
  -webkit-transition: height 0.6s cubic-bezier(0.68, -0.55, 0.27, 1.55), width 0.6s cubic-bezier(0.68, -0.55, 0.27, 1.55);
  transition: height 0.6s cubic-bezier(0.68, -0.55, 0.27, 1.55), width 0.6s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

@media (min-width: 75rem) {
  .heroteaser-text-container-listelement .toggle-icon, .listteaser-item .toggle-icon {
    display: none;
  }
}
.heroteaser-text-container-listelement .toggle-icon svg, .listteaser-item .toggle-icon svg {
  width: 100%;
  height: 100%;
}

.heroteaser-text-container-listelement .toggle-icon svg use, .listteaser-item .toggle-icon svg use {
  fill: #fff;
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.heroteaser-text-container-listelement .toggle-icon:hover svg use, .listteaser-item .toggle-icon:hover svg use {
  fill: #e20079;
}

@media (min-width: 75rem) {
  .heroteaser-text-container-listelement .toggle-icon, .listteaser-item .toggle-icon {
    display: inline-block;
  }
}
@media (min-width: 62rem) {
  .heroteaser-text-container-listelement .toggle-icon, .listteaser-item .toggle-icon {
    width: 1.5rem;
    height: 1.5rem;
  }
}
.heroteaser-text-container-listelement .toggle-icon svg use, .listteaser-item .toggle-icon svg use {
  fill: #e20079;
}

.heroteaser-text-container-listelement a:hover .heroteaser-subheadline, .heroteaser-text-container-listelement a:hover .listteaser-item-headline, .heroteaser-text-container-listelement a:hover p, .listteaser-item a:hover .heroteaser-subheadline, .listteaser-item a:hover .listteaser-item-headline, .listteaser-item a:hover p {
  color: #e20079;
}

.heroteaser-text-container-listelement > .listteaser-item-headline-wrapper .toggle-icon, .listteaser-item > .listteaser-item-headline-wrapper .toggle-icon {
  cursor: default;
}

.heroteaser-subheadline, .listteaser-item-headline {
  margin-bottom: 0;
  padding-bottom: 0;
  font-size: 1rem;
  width: calc(100% - 26px);
  -webkit-transition: font-size 0.6s cubic-bezier(0.68, -0.55, 0.27, 1.55), color 0.3s;
  transition: font-size 0.6s cubic-bezier(0.68, -0.55, 0.27, 1.55), color 0.3s;
}

@media (min-width: 36rem) {
  .heroteaser-subheadline, .listteaser-item-headline {
    font-size: calc(.694vw + .75rem);
  }
}
@media (min-width: 62rem) {
  .heroteaser-subheadline, .listteaser-item-headline {
    width: calc(100% - 34px);
  }
}
@media (min-width: 90rem) {
  .heroteaser-subheadline, .listteaser-item-headline {
    font-size: 1.375rem;
  }
}
@media (min-width: 125rem) {
  .heroteaser-subheadline, .listteaser-item-headline {
    font-size: calc(1.786vw + -.85714rem);
  }
}
.heroteaser-subheadline-wrapper, .listteaser-item-headline-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.frame-type-image_gallery.frame-layout-1 header {
  padding: 0 1.25rem;
}

@media (min-width: 48rem) {
  .frame-type-image_gallery.frame-layout-1 header {
    padding: 0 33.33333% 0 1.25rem;
  }
}
.frame-type-image_gallery.frame-layout-1.frame-slider-both, .frame-type-image_gallery.frame-layout-1.frame-slider-mobile {
  padding-left: 1.25rem;
  padding-right: 0;
}

@media (min-width: 48rem) {
  .frame-type-image_gallery.frame-layout-1.frame-slider-both, .frame-type-image_gallery.frame-layout-1.frame-slider-mobile {
    padding-left: 0;
  }
}
.frame-type-image_gallery.frame-layout-1.frame-slider-both header, .frame-type-image_gallery.frame-layout-1.frame-slider-mobile header {
  padding-left: 0;
}

@media (min-width: 48rem) {
  .frame-type-image_gallery.frame-layout-1.frame-slider-both header, .frame-type-image_gallery.frame-layout-1.frame-slider-mobile header {
    padding-left: 1.25rem;
  }
}
.frame-type-image_gallery.frame-layout-0 header {
  padding: 0 1.25rem 0 0;
}

@media (min-width: 48rem) {
  .frame-type-image_gallery.frame-layout-0 header {
    padding: 0;
  }
}
.frame-type-image_gallery.frame-layout-0.frame-slider-both, .frame-type-image_gallery.frame-layout-0.frame-slider-mobile {
  padding-right: 0;
}

@media (min-width: 48rem) {
  .frame-type-image_gallery.frame-layout-0.frame-slider-both, .frame-type-image_gallery.frame-layout-0.frame-slider-mobile {
    padding-right: 1.25rem;
  }
}
.frame-type-image_gallery.frame-layout-0.frame-slider-none header {
  padding: 0;
}

@media (min-width: 48rem) {
  .frame-type-image_gallery.frame-layout-0.frame-slider-none header {
    padding: 0 33.3333333333% 0 0;
  }
}
empty {
  content: 1;
}

.main-navigation-wrapper .navigation {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
  overflow: visible;
}

.main-navigation-wrapper .navigation > .navigation-item {
  position: relative;
  cursor: pointer;
  z-index: 1;
  margin: 0 1.25rem;
  font-family: lato_bold, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 0.8125rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.main-navigation-wrapper .navigation > .navigation-item > a {
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 0.125rem;
  padding: 2.3125rem 0;
  white-space: nowrap;
}

.main-navigation-wrapper .navigation > .navigation-item:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 0.4375rem;
  width: 0;
  background-color: #e20079;
  -webkit-transition: width 0.3s;
  transition: width 0.3s;
}

.main-navigation-wrapper .navigation > .navigation-item.hover, .main-navigation-wrapper .navigation > .navigation-item:hover {
  color: #e20079;
}

.main-navigation-wrapper .navigation > .navigation-item.hover:after, .main-navigation-wrapper .navigation > .navigation-item:hover:after {
  width: 100%;
}

.main-navigation-wrapper .navigation > .navigation-item.hover .navigation-lvl2, .main-navigation-wrapper .navigation > .navigation-item:hover .navigation-lvl2 {
  visibility: visible;
}

.main-navigation-wrapper .navigation-lvl2 {
  visibility: hidden;
  background-color: #fff;
  position: absolute;
  top: 100%;
  left: 0;
  -webkit-box-shadow: 0 0.625rem 2.5rem 0 rgba(0, 51, 120, 0.1);
  box-shadow: 0 0.625rem 2.5rem 0 rgba(0, 51, 120, 0.1);
}

.main-navigation-wrapper .navigation-lvl2 .navigation-item {
  background: #fff;
  text-transform: none;
  -webkit-transition: background 0.3s;
  transition: background 0.3s;
  white-space: nowrap;
}

.main-navigation-wrapper .navigation-lvl2 .navigation-item.has-sub {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 16 16'%3E%3Cdefs%3E%3Cpath fill='%23E20079' id='a' d='M9.883 13a.216.216 0 01-.155-.066L5.064 8.158a.227.227 0 010-.317l4.664-4.775a.216.216 0 01.31 0l.897.919a.228.228 0 010 .317L7.324 8l3.612 3.698a.228.228 0 010 .317l-.898.92a.218.218 0 01-.155.065z'/%3E%3C/defs%3E%3Cuse transform='matrix(-1 0 0 1 16 0)' xlink:href='%23a'/%3E%3C/svg%3E");
  background-color: #fff;
  background-position: right 1.5rem center;
  background-size: 1rem 1rem;
  background-repeat: no-repeat;
  position: relative;
}

.main-navigation-wrapper .navigation-lvl2 .navigation-item > a {
  display: inline-block;
  padding: 1.5rem 3rem 1.5rem 1.5rem;
  color: #000;
  letter-spacing: 0.125rem;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}

.main-navigation-wrapper .navigation-lvl2 .navigation-item.hover, .main-navigation-wrapper .navigation-lvl2 .navigation-item:hover {
  background: #e20079;
}

.main-navigation-wrapper .navigation-lvl2 .navigation-item.hover.has-sub, .main-navigation-wrapper .navigation-lvl2 .navigation-item:hover.has-sub {
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 16 16'%3E%3Cdefs%3E%3Cpath fill='%23fff' id='a' d='M9.883 13a.216.216 0 01-.155-.066L5.064 8.158a.227.227 0 010-.317l4.664-4.775a.216.216 0 01.31 0l.897.919a.228.228 0 010 .317L7.324 8l3.612 3.698a.228.228 0 010 .317l-.898.92a.218.218 0 01-.155.065z'/%3E%3C/defs%3E%3Cuse transform='matrix(-1 0 0 1 16 0)' xlink:href='%23a'/%3E%3C/svg%3E");
  background-color: #e20079;
  background-position: right 1.5rem center;
  background-size: 1rem 1rem;
  background-repeat: no-repeat;
}

.main-navigation-wrapper .navigation-lvl2 .navigation-item.hover > a, .main-navigation-wrapper .navigation-lvl2 .navigation-item:hover > a {
  color: #fff;
}

.main-navigation-wrapper .navigation-lvl2 .navigation-item.hover .navigation-lvl-3, .main-navigation-wrapper .navigation-lvl2 .navigation-item:hover .navigation-lvl-3 {
  visibility: visible;
}

.main-navigation-wrapper .navigation-lvl-3 {
  visibility: hidden;
  z-index: -1;
  position: absolute;
  left: 100%;
  top: 0;
  -webkit-box-shadow: 0 0.625rem 2.5rem 0 rgba(0, 51, 120, 0.1);
  box-shadow: 0 0.625rem 2.5rem 0 rgba(0, 51, 120, 0.1);
}

.main-navigation-wrapper .navigation-lvl-3 .navigation-item {
  background: #fff;
}

.main-navigation-wrapper .navigation-lvl-3 .navigation-item.hover, .main-navigation-wrapper .navigation-lvl-3 .navigation-item:hover {
  background: #e20079;
}

.main-navigation-wrapper .navigation-lvl-3 .navigation-item > a {
  letter-spacing: 0.125rem;
}

.main-navigation-wrapper .navigation .navigation-item .navigation-lvl2 .navigation-lvl-3.left {
  left: auto;
  right: 100%;
}

.meta-navigation-wrapper .meta-navigation {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
}

.meta-navigation-wrapper .meta-navigation-item {
  color: #fff;
  font-family: lato_regular, Helvetica, sans-serif;
  font-weight: 400;
  letter-spacing: 0.125rem;
  font-size: 0.75rem;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}

.meta-navigation-wrapper .meta-navigation-item > a {
  padding: 0.9375rem;
  display: inline-block;
}

.meta-navigation-wrapper .meta-navigation-item:hover {
  color: #e20079;
}

.meta-navigation-wrapper .meta-navigation-item.pink {
  background-color: #e20079;
  margin-left: 0.9375rem;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

.meta-navigation-wrapper .meta-navigation-item.pink:hover {
  background-color: #ff49aa;
  color: #fff;
}

.mobile-navigation-wrapper {
  display: block;
  z-index: 999;
  position: fixed;
  top: 2.8125rem;
  right: 100%;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  height: calc(100% - 45px);
  background-color: #fff;
}

.mobile-navigation-wrapper.open {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

@media (min-width: 62rem) {
  .mobile-navigation-wrapper {
    display: none;
  }
}
.mobile-navigation-wrapper .navigation {
  display: block;
  width: 100%;
}

.mobile-navigation-wrapper .navigation-item {
  margin: 0;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.mobile-navigation-wrapper .navigation-item > a {
  padding: 1.25rem 0.625rem 1.25rem 1.5rem;
  width: 100%;
  font-family: lato_bold, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 0.75rem;
  letter-spacing: 0.125rem;
}

.mobile-navigation-wrapper .navigation-item > a:hover {
  background-color: #e20079;
  color: #fff;
  -webkit-transition: background-color 0.3s, color 0.3s;
  transition: background-color 0.3s, color 0.3s;
}

.mobile-navigation-wrapper .navigation-item.has-sub > a {
  width: calc(100% - 53px);
}

.mobile-navigation-wrapper .navigation-item .submenu-trigger {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 3.4375rem;
  width: 3.375rem;
  background-color: #fff;
  cursor: pointer;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  position: relative;
}

.mobile-navigation-wrapper .navigation-item .submenu-trigger svg {
  height: 1.25rem;
  width: 1.25rem;
}

.mobile-navigation-wrapper .navigation-item .submenu-trigger svg use {
  -webkit-transition: fill 0.3s;
  transition: fill 0.3s;
  fill: #e20079;
}

.mobile-navigation-wrapper .navigation-item .submenu-trigger:hover {
  background-color: #e20079;
}

.mobile-navigation-wrapper .navigation-item .submenu-trigger:hover svg use {
  fill: #fff;
}

.mobile-navigation-wrapper .navigation-item .submenu-trigger:hover:before {
  background-color: #e20079;
}

.mobile-navigation-wrapper .navigation-item .submenu-trigger:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 60%;
  width: 0.0625rem;
  background-color: #d7d7d7;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

.mobile-navigation-wrapper .navigation-item ul {
  display: block;
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 100%;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  z-index: 1;
}

.mobile-navigation-wrapper .navigation-item ul.open {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.mobile-navigation-wrapper .navigation-item ul .back {
  width: 100%;
  border-bottom: 0.0625rem solid #d7d7d7;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

.mobile-navigation-wrapper .navigation-item ul .back span {
  font-family: lato_bold, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 0.75rem;
  letter-spacing: 0.125rem;
  margin-left: 0.625rem;
}

.mobile-navigation-wrapper .navigation-item ul .back .logo-wrapper {
  height: 3.4375rem;
  width: 3.4375rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #e20079;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

.mobile-navigation-wrapper .navigation-item ul .back .logo-wrapper svg {
  height: 1.25rem;
  width: 1.25rem;
}

.mobile-navigation-wrapper .navigation-item ul .back .logo-wrapper svg use {
  fill: #fff;
}

.mobile-navigation-wrapper .navigation-item ul .back:hover .logo-wrapper {
  background-color: #ff49aa;
}

.mobile-navigation-wrapper .navigation-item ul .navigation-item a > {
  color: #000;
}

.mobile-navigation-wrapper .navigation-item ul .navigation-item a > :hover {
  color: #fff;
}

.mobile-navigation-wrapper .meta-navigation {
  margin-top: 3.75rem;
}

.mobile-navigation-wrapper .meta-navigation-item > a {
  padding: 0.9375rem 1.25rem;
}

.mobile-navigation-wrapper .meta-navigation-item:not(.pink) {
  color: #000;
}

.mobile-navigation-wrapper .meta-navigation-item:not(.pink):hover {
  color: #e20079;
}

.news-list-view-items-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
}

@media (min-width: 36rem) {
  .news-list-view-items-wrapper {
    margin: -0.625rem;
  }
}
.news-list-view-items-wrapper .article {
  margin: 0.625rem 0;
  padding-bottom: 0;
  width: 100%;
  -webkit-transition: min-height 0.6s ease, width 0.3s ease;
  transition: min-height 0.6s ease, width 0.3s ease;
}

@media (min-width: 36rem) {
  .news-list-view-items-wrapper .article {
    margin: 0.625rem;
    min-height: 15.625rem;
    width: calc(50% - 20px);
  }
}
@media (min-width: 62rem) {
  .news-list-view-items-wrapper .article {
    min-height: 19.375rem;
    width: calc(33.33333% - 20px);
  }
}
@media (min-width: 75rem) {
  .news-list-view-items-wrapper .article {
    width: calc(25% - 20px);
  }
}
@media (min-width: 36rem) {
  .news-list-view-items-wrapper .article:last-child {
    margin-bottom: 0.625rem;
  }
}
.news-item-link-wrapper {
  background: #f5f5f5;
  display: block;
  height: 100%;
  padding: 1.5625rem 1.25rem;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
}

.news-item-link-wrapper:hover {
  background: #e20079;
}

.news-content-wrapper {
  height: 100%;
  position: relative;
  padding-bottom: 3.9375rem;
}

.news-list-date {
  font-family: lato_regular, Helvetica, sans-serif;
  font-weight: 400;
  color: #000;
  font-size: 1rem;
  letter-spacing: 0.0625rem;
  font-size: 0.875rem;
  line-height: 1.5rem;
  display: inline-block;
  margin-bottom: 0.5rem;
  -webkit-transition: color 0.3s ease;
  transition: color 0.3s ease;
}

.news-item-link-wrapper:hover .news-list-date {
  color: #fff;
}

.news-headline {
  margin-bottom: 0.5rem;
}

.news-headline h3 {
  line-height: 2rem;
  -webkit-transition: color 0.3s ease;
  transition: color 0.3s ease;
}

.news-item-link-wrapper:hover .news-headline h3 {
  color: #fff;
}

.news-teaser-text div * {
  font-size: 0.875rem;
  line-height: 1.5rem;
  -webkit-transition: color 0.3s ease;
  transition: color 0.3s ease;
}

.news-item-link-wrapper:hover .news-teaser-text div * {
  color: #fff;
}

.news-read-more-link {
  bottom: 0;
  height: 3rem;
  left: 0;
  line-height: 0;
  position: absolute;
  width: 3rem;
}

.news-read-more-link use {
  fill: #e20079;
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.news-item-link-wrapper:hover .news-read-more-link use {
  fill: #fff;
}

.news-list-button-wrapper {
  text-align: center;
}

.news-single .frame-layout-0 {
  padding-left: 0;
  padding-right: 0;
}

.news-detail-wrapper {
  padding-bottom: 1.25rem;
}

@media (min-width: 48rem) {
  .news-detail-wrapper {
    padding-bottom: 2.5rem;
  }
}
@media (min-width: 64rem) {
  .news-detail-wrapper {
    padding-bottom: 3.75rem;
  }
}
@media (min-width: 48rem) {
  .news-detail-bodytext, .news-detail-header {
    padding-right: 33.3333333333%;
  }
}
.news-detail-additional-infos {
  font-family: lato_regular, Helvetica, sans-serif;
  font-weight: 400;
  color: #000;
  font-size: 1rem;
  letter-spacing: 0.0625rem;
  font-size: 0.875rem;
  line-height: 1.5rem;
  margin-bottom: 0;
}

.f3-widget-paginator, .tx-indexedsearch-browsebox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 2.5rem 0;
}

@media (min-width: 48rem) {
  .f3-widget-paginator, .tx-indexedsearch-browsebox {
    margin: 3.75rem 0;
  }
}
.f3-widget-paginator li, .tx-indexedsearch-browsebox li {
  line-height: 1.5rem;
  display: none;
}

@media (min-width: 48rem) {
  .f3-widget-paginator li, .tx-indexedsearch-browsebox li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
.f3-widget-paginator li a:not([class]), .tx-indexedsearch-browsebox li a:not([class]) {
  padding-bottom: 0;
  color: #1c1c1c;
  font-size: 0.875rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-decoration: underline;
}

.f3-widget-paginator li a:not([class]):hover, .tx-indexedsearch-browsebox li a:not([class]):hover {
  color: #e20079;
}

.f3-widget-paginator li:not(:last-of-type), .tx-indexedsearch-browsebox li:not(:last-of-type) {
  margin-right: 1.25rem;
}

.f3-widget-paginator li.current, .f3-widget-paginator li.tx-indexedsearch-browselist-currentPage, .tx-indexedsearch-browsebox li.current, .tx-indexedsearch-browsebox li.tx-indexedsearch-browselist-currentPage {
  font-family: lato_bold, Helvetica, sans-serif;
  font-weight: 700;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0.25rem;
  background-color: #e20079;
  color: #fff;
  font-size: 0.875rem;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 2rem;
}

.f3-widget-paginator li.first, .f3-widget-paginator li.last, .tx-indexedsearch-browsebox li.first, .tx-indexedsearch-browsebox li.last {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.f3-widget-paginator li.next, .f3-widget-paginator li.previous, .tx-indexedsearch-browsebox li.next, .tx-indexedsearch-browsebox li.previous {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border: 0.1875rem solid #e20079;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
}

.f3-widget-paginator li.next a, .f3-widget-paginator li.previous a, .tx-indexedsearch-browsebox li.next a, .tx-indexedsearch-browsebox li.previous a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 0;
  text-indent: -9999px;
  height: 1.625rem;
  width: 1.625rem;
}

.f3-widget-paginator li.next a:after, .f3-widget-paginator li.previous a:after, .tx-indexedsearch-browsebox li.next a:after, .tx-indexedsearch-browsebox li.previous a:after {
  content: "";
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTYiIGhlaWdodD0iMTYiPjxkZWZzPjxwYXRoIGlkPSJhIiBkPSJNOC44ODMgMTNhLjIxNi4yMTYgMCAwMS0uMTU1LS4wNjZMNC4wNjQgOC4xNThhLjIyNy4yMjcgMCAwMTAtLjMxN2w0LjY2NC00Ljc3NWEuMjE2LjIxNiAwIDAxLjMxIDBsLjg5Ny45MTlhLjIyOC4yMjggMCAwMTAgLjMxN0w2LjMyNCA4bDMuNjEyIDMuNjk4YS4yMjguMjI4IDAgMDEwIC4zMTdsLS44OTguOTJhLjIxOC4yMTggMCAwMS0uMTU1LjA2NXoiLz48L2RlZnM+PHVzZSBmaWxsPSIjZTIwMDc5IiB4bGluazpocmVmPSIjYSIvPjwvc3ZnPg==");
  width: 1rem;
  height: 1rem;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
}

.f3-widget-paginator li.next:hover, .f3-widget-paginator li.previous:hover, .tx-indexedsearch-browsebox li.next:hover, .tx-indexedsearch-browsebox li.previous:hover {
  background: #e20079;
}

.f3-widget-paginator li.next:hover a:after, .f3-widget-paginator li.previous:hover a:after, .tx-indexedsearch-browsebox li.next:hover a:after, .tx-indexedsearch-browsebox li.previous:hover a:after {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQBAMAAADt3eJSAAAAAXNSR0IB2cksfwAAADBQTFRFAAAA////////////////////////////////////////////////////////////O00EMAAAABB0Uk5TAAi9ewb/rLi1BLvBq8cKw5417qoAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAAySURBVHicY2DACxiVoQynMAjNUtoJYUyPcoAwUjdClRy9B2VMj3ZAU8zgFAMz8DN+CwGHLglv8bSV6AAAAABJRU5ErkJggg==");
}

.f3-widget-paginator li.next a:after, .tx-indexedsearch-browsebox li.next a:after {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTYiIGhlaWdodD0iMTYiPjxkZWZzPjxwYXRoIGlkPSJhIiBkPSJNOS44ODMgMTNhLjIxNi4yMTYgMCAwMS0uMTU1LS4wNjZMNS4wNjQgOC4xNThhLjIyNy4yMjcgMCAwMTAtLjMxN2w0LjY2NC00Ljc3NWEuMjE2LjIxNiAwIDAxLjMxIDBsLjg5Ny45MTlhLjIyOC4yMjggMCAwMTAgLjMxN0w3LjMyNCA4bDMuNjEyIDMuNjk4YS4yMjguMjI4IDAgMDEwIC4zMTdsLS44OTguOTJhLjIxOC4yMTggMCAwMS0uMTU1LjA2NXoiLz48L2RlZnM+PHVzZSBmaWxsPSIjZTIwMDc5IiB0cmFuc2Zvcm09Im1hdHJpeCgtMSAwIDAgMSAxNiAwKSIgeGxpbms6aHJlZj0iI2EiLz48L3N2Zz4=");
}

.f3-widget-paginator li.next:hover a:after, .tx-indexedsearch-browsebox li.next:hover a:after {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAAAXNSR0IB2cksfwAAADNQTFRFAAAA////////////////////////////////////////////////////////////////t5XiggAAABF0Uk5TAH69Bqz/uAS7CMGxCsepqq5ZLBorAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAN0lEQVR4nGNgIBMwMjGjCrCwsqGKsHOwsrGjiHBysXKjivDwsvKhC/Dj08KMbqgAurUYDiMJAACbkAEoVUqTqgAAAABJRU5ErkJggg==");
}

.pxlatest {
  padding: 2.5rem 0;
  background-image: url(/typo3conf/ext/px_basis_config/Resources/Public/img/px_latest-background.jpg);
  background-position: 0 0;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
}

@media (min-width: 48rem) {
  .pxlatest {
    padding: 5rem 0;
  }
}
.pxlatest-filter:not(.pxlatest-list-filter-item) {
  width: 100%;
  font-weight: 700;
  font-size: 0.875rem;
  letter-spacing: 0.125rem;
  line-height: 1.5rem;
  padding: 0.75rem 0;
  text-align: center;
  background-color: #fff;
  -webkit-transition: color 0.3s, background-color 0.3s;
  transition: color 0.3s, background-color 0.3s;
}

@media (min-width: 48rem) {
  .pxlatest-filter:not(.pxlatest-list-filter-item) {
    width: auto;
    min-width: 19.25rem;
  }
}
.pxlatest-filter:not(.pxlatest-list-filter-item):first-of-type {
  margin: 0 0 0.625rem;
}

@media (min-width: 48rem) {
  .pxlatest-filter:not(.pxlatest-list-filter-item):first-of-type {
    margin: 0 0.25rem 0 0;
  }
}
.pxlatest-filter:not(.pxlatest-list-filter-item).active, .pxlatest-filter:not(.pxlatest-list-filter-item):hover {
  background-color: #e20079;
  color: #fff;
}

.pxlatest-filter:not(.pxlatest-list-filter-item).active:hover {
  background-color: #ff49aa;
}

.pxlatest-filter-wrapper {
  display: none;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0 1.5rem;
}

@media (min-width: 48rem) {
  .pxlatest-filter-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
.pxlatest-content {
  opacity: 0;
  visibility: hidden;
  height: 0;
  -webkit-transition: visibility 0s, opacity 0.3s linear;
  transition: visibility 0s, opacity 0.3s linear;
  overflow: hidden;
}

.pxlatest-content.visible {
  opacity: 1;
  visibility: visible;
  height: auto;
}

.pxlatest-content-wrapper {
  max-width: 1440px;
  height: inherit;
  margin: 0 auto;
  position: relative;
  padding: 2.5rem 1.5rem 0;
}

@media (min-width: 62rem) {
  .pxlatest-content-wrapper {
    padding: 2.5rem 4.861% 0;
  }
}
@media (min-width: 90rem) {
  .pxlatest-content-wrapper {
    padding: 2.5rem 4.375rem 0;
  }
}
.pxlatest-list-button {
  text-align: center;
}

@media (min-width: 48rem) {
  .pxlatest-list-button {
    min-width: 25rem;
  }
}
.pxlatest-list-button:active, .pxlatest-list-button:focus, .pxlatest-list-button:hover {
  background-color: #ff49aa;
  border: 0.1875rem solid #ff49aa;
  color: #fff;
}

.pxlatest-list-button-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 2.5rem;
}

.pxlatest .news-item-link-wrapper, .pxlatest .seminar-list-container {
  background-color: #fff;
}

.pxlatest .news-item-link-wrapper:hover, .pxlatest .seminar-list-container:hover {
  background-color: #e20079;
}

.pxlatest-list-filter {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  background-color: #fff;
  top: 100%;
  z-index: 1;
  width: 100%;
  cursor: pointer;
}

.pxlatest-list-filter, .pxlatest-list-filter.visible {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.pxlatest-list-filter-box {
  display: none;
}

.pxlatest-list-filter-wrapper {
  margin: auto;
  width: 100%;
  padding: 0 1.5rem;
}

@media (min-width: 48rem) {
  .pxlatest-list-filter-wrapper {
    display: none;
  }
}
.pxlatest-list-filter-button {
  display: block;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #f1f1f1;
  padding: 1rem;
  color: #000;
  border: none;
  outline: none;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: background-color 0.3s, color 0.3s;
  transition: background-color 0.3s, color 0.3s;
  font-size: 0.875rem;
  width: 100%;
  font-family: lato_regular, Helvetica, sans-serif;
  font-weight: 400;
  cursor: pointer;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 16 16'%3E%3Cdefs%3E%3Cpath fill='%23E20079' id='a' d='M9.883 13a.216.216 0 01-.155-.066L5.064 8.158a.227.227 0 010-.317l4.664-4.775a.216.216 0 01.31 0l.897.919a.228.228 0 010 .317L7.324 8l3.612 3.698a.228.228 0 010 .317l-.898.92a.218.218 0 01-.155.065z'/%3E%3C/defs%3E%3Cuse transform='rotate(-90 8 8)' xlink:href='%23a'/%3E%3C/svg%3E");
  background-position: right 0.75rem center;
  background-size: 1.5rem 1.5rem;
  background-repeat: no-repeat;
  background-color: #fff;
}

.pxlatest-list-filter-button:active, .pxlatest-list-filter-button:focus {
  -webkit-box-shadow: 0.125rem 0.125rem 0.625rem 0 rgba(54, 175, 253, 0.2);
  box-shadow: 0.125rem 0.125rem 0.625rem 0 rgba(54, 175, 253, 0.2);
  background-color: #fff;
}

.pxlatest-list-filter-button::-ms-expand {
  display: none;
}

.pxlatest-list-filter-button.active {
  color: #fff;
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 16 16'%3E%3Cdefs%3E%3Cpath fill='%23fff' id='a' d='M9.883 13a.216.216 0 01-.155-.066L5.064 8.158a.227.227 0 010-.317l4.664-4.775a.216.216 0 01.31 0l.897.919a.228.228 0 010 .317L7.324 8l3.612 3.698a.228.228 0 010 .317l-.898.92a.218.218 0 01-.155.065z'/%3E%3C/defs%3E%3Cuse fill='%23fff' transform='rotate(-90 8 8)' xlink:href='%23a'/%3E%3C/svg%3E") calc(100% - 12px)/24px no-repeat;
  background-color: #e20079;
}

.pxlatest-list-filter-item {
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  width: 100%;
  font-size: 0.875rem;
  padding: 1rem;
}

.pxlatest-list-filter-item:hover {
  background-color: #e20079;
  color: #fff;
  -webkit-transition: color 0.3s, background-color 0.3s;
  transition: color 0.3s, background-color 0.3s;
}

.quickentry {
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

@media (min-width: 48rem) {
  .quickentry {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
}
.quickentry-left, .quickentry-right {
  width: 100%;
}

@media (min-width: 48rem) {
  .quickentry-left, .quickentry-right {
    width: 50%;
  }
}
.quickentry-left {
  background: #000;
  display: none;
}

@media (min-width: 48rem) {
  .quickentry-left {
    display: block;
  }
}
.quickentry-right {
  background: #fff;
  padding: 2rem 1rem;
  -webkit-transition: padding 0.3s ease;
  transition: padding 0.3s ease;
}

@media (min-width: 36rem) {
  .quickentry-right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 3.75rem 2.5rem;
  }
}
@media (min-width: 48rem) {
  .quickentry-right {
    padding: 2.5rem;
  }
}
.quickentry-right-wrapper {
  -webkit-transition: max-width 0.3s ease;
  transition: max-width 0.3s ease;
}

@media (min-width: 90rem) {
  .quickentry-right-wrapper {
    max-width: 33.125rem;
  }
}
@media (min-width: 120rem) {
  .quickentry-right-wrapper {
    max-width: 100%;
  }
}
.quickentry-video-wrapper {
  position: relative;
  padding-top: 100%;
  width: 100%;
}

.quickentry-video-wrapper video {
  left: 0;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
}

.quickentry-subheadline {
  font-size: 0.875rem;
  line-height: 1.3;
  letter-spacing: 0.125rem;
  margin-bottom: 1.5rem;
  padding-bottom: 0;
  -webkit-transition: font-size 0.6s cubic-bezier(0.68, -0.55, 0.27, 1.55), margin-bottom 0.3s ease;
  transition: font-size 0.6s cubic-bezier(0.68, -0.55, 0.27, 1.55), margin-bottom 0.3s ease;
}

@media (min-width: 36rem) {
  .quickentry-subheadline {
    font-size: calc(.463vw + .70833rem);
  }
}
@media (min-width: 48rem) {
  .quickentry-subheadline {
    max-width: 33.125rem;
    margin-bottom: 1rem;
  }
}
@media (min-width: 62rem) {
  .quickentry-subheadline {
    margin-bottom: 1.5rem;
  }
}
@media (min-width: 75rem) {
  .quickentry-subheadline {
    margin-bottom: 2.5rem;
  }
}
@media (min-width: 90rem) {
  .quickentry-subheadline {
    font-size: 1.125rem;
  }
}
@media (min-width: 125rem) {
  .quickentry-subheadline {
    font-size: calc(1.071vw + -.21429rem);
  }
}
.quickentry-headline {
  color: #e20079;
  display: inline-block;
  font-size: 2rem;
  margin-bottom: 1.5rem;
  -webkit-transition: font-size 0.6s cubic-bezier(0.68, -0.55, 0.27, 1.55), margin-bottom 0.3s ease;
  transition: font-size 0.6s cubic-bezier(0.68, -0.55, 0.27, 1.55), margin-bottom 0.3s ease;
}

@media (min-width: 36rem) {
  .quickentry-headline {
    font-size: calc(3.704vw + .66667rem);
  }
}
@media (min-width: 48rem) {
  .quickentry-headline {
    letter-spacing: 0.5vw;
    line-height: 1.125;
    margin-bottom: 1rem;
  }
}
@media (min-width: 62rem) {
  .quickentry-headline {
    margin-bottom: 1.5rem;
  }
}
@media (min-width: 75rem) {
  .quickentry-headline {
    letter-spacing: 0.375rem;
    margin-bottom: 2.5rem;
  }
}
@media (min-width: 90rem) {
  .quickentry-headline {
    font-size: 4rem;
  }
}
@media (min-width: 125rem) {
  .quickentry-headline {
    font-size: calc(2.857vw + .42857rem);
  }
}
.quickentry-records {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.quickentry-records, .quickentry-records-request, .quickentry-records-target {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
}

.quickentry-records-request, .quickentry-records-target {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  -webkit-transition: margin-bottom 0.3s ease;
  transition: margin-bottom 0.3s ease;
}

@media (min-width: 36rem) {
  .quickentry-records-request, .quickentry-records-target {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}
@media (min-width: 48rem) {
  .quickentry-records-request, .quickentry-records-target {
    margin-bottom: 1rem;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column wrap;
    flex-flow: column wrap;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    width: 100%;
  }
}
@media (min-width: 62rem) {
  .quickentry-records-request, .quickentry-records-target {
    margin-bottom: 1.5rem;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}
@media (min-width: 120rem) {
  .quickentry-records-request, .quickentry-records-target {
    margin-bottom: 2.5rem;
  }
}
.quickentry-records-request label, .quickentry-records-target label {
  font-family: lato_black, Helvetica, sans-serif;
  font-weight: 900;
  color: #000;
  line-height: 1.3333333333;
  font-size: 1.125rem;
  letter-spacing: 0.125rem;
  font-size: 0.875rem;
  line-height: 1.3;
  margin-bottom: 0.5rem;
  padding-bottom: 0;
  -webkit-transition: font-size 0.6s cubic-bezier(0.68, -0.55, 0.27, 1.55), margin-bottom 0.3s ease;
  transition: font-size 0.6s cubic-bezier(0.68, -0.55, 0.27, 1.55), margin-bottom 0.3s ease;
}

@media (min-width: 36rem) {
  .quickentry-records-request label, .quickentry-records-target label {
    font-size: calc(.463vw + .70833rem);
    margin-bottom: 0;
  }
}
@media (min-width: 48rem) {
  .quickentry-records-request label, .quickentry-records-target label {
    margin-bottom: 0.5rem;
  }
}
@media (min-width: 62rem) {
  .quickentry-records-request label, .quickentry-records-target label {
    margin-bottom: 0;
  }
}
@media (min-width: 90rem) {
  .quickentry-records-request label, .quickentry-records-target label {
    font-size: 1.125rem;
  }
}
@media (min-width: 125rem) {
  .quickentry-records-request label, .quickentry-records-target label {
    font-size: calc(1.071vw + -.21429rem);
  }
}
.quickentry-records select {
  background-color: #fff;
  border-bottom: 0.25rem solid #000;
  font-size: 1rem;
  letter-spacing: 0.125rem;
  line-height: 2;
  padding: 0 2.25rem 0.125rem 0;
  -webkit-transition: width 0.3s ease;
  transition: width 0.3s ease;
  width: 100%;
}

@media (min-width: 36rem) {
  .quickentry-records select {
    font-size: calc(.463vw + .70833rem);
    width: calc(100% - 120px);
  }
}
@media (min-width: 48rem) {
  .quickentry-records select {
    width: 100%;
  }
}
@media (min-width: 62rem) {
  .quickentry-records select {
    width: calc(100% - 160px);
  }
}
@media (min-width: 120rem) {
  .quickentry-records select {
    width: 70%;
  }
}
@media (min-width: 90rem) {
  .quickentry-records select {
    font-size: 1.125rem;
  }
}
@media (min-width: 125rem) {
  .quickentry-records select {
    font-size: calc(1.071vw + -.21429rem);
  }
}
.quickentry-records select:invalid, .quickentry-records select[disabled] {
  color: #bdbdbd;
  font-family: lato_regular, Helvetica, sans-serif;
  font-weight: 400;
  letter-spacing: 0.125rem;
}

.quickentry-records select:active, .quickentry-records select:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.quickentry-records select option {
  color: #000;
  font-style: normal;
}

.quickentry-records select option:first-child {
  display: none;
}

.quickentry-records select[disabled] {
  cursor: default;
}

.quickentry-records select[disabled]:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.quickentry-records-headline {
  font-family: lato_black, Helvetica, sans-serif;
  font-weight: 900;
  margin-bottom: 0.5rem;
  padding-bottom: 1.5rem;
}

.quickentry-records-headline, .quickentry-records-submit {
  color: #fff;
  line-height: 1.5;
  font-size: 1rem;
  letter-spacing: 0.125rem;
}

.quickentry-records-submit {
  font-family: lato_bold, Helvetica, sans-serif;
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase;
  border: 0.1875rem solid #e20079;
  background-color: #e20079;
  padding: 0.5625rem 1.25rem;
  -webkit-transition: color 0.3s, background-color 0.3s, border-color 0.3s;
  transition: color 0.3s, background-color 0.3s, border-color 0.3s;
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
  width: 100%;
}

.quickentry-records-submit.icon-left {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.quickentry-records-submit.icon-left svg {
  margin-right: 0.625rem;
}

.quickentry-records-submit.icon-right {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.quickentry-records-submit.icon-right svg {
  margin-left: 0.625rem;
}

.quickentry-records-submit.icon-only {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0.625rem;
}

.quickentry-records-submit svg {
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
  background-color: #fff;
  padding: 0.1875rem;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

.quickentry-records-submit svg use {
  fill: #e20079;
  -webkit-transition: fill 0.3s;
  transition: fill 0.3s;
}

.quickentry-records-submit:focus, .quickentry-records-submit:hover {
  background-color: transparent;
  color: #e20079;
  border-color: #e20079;
}

.quickentry-records-submit:focus svg, .quickentry-records-submit:hover svg {
  background-color: #e20079;
}

.quickentry-records-submit:focus svg use, .quickentry-records-submit:hover svg use {
  fill: #fff;
}

.quickentry-records-submit.disabled, .quickentry-records-submit[disabled] {
  background-color: #d7d7d7;
  color: #bdbdbd;
  border-color: #d7d7d7;
  cursor: not-allowed;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.quickentry-records-submit.disabled span:first-child, .quickentry-records-submit[disabled] span:first-child {
  display: none;
}

.quickentry-records-submit.disabled span:last-child, .quickentry-records-submit[disabled] span:last-child {
  -webkit-transform: none;
  transform: none;
}

@media (min-width: 36rem) {
  .quickentry-records-submit {
    font-size: calc(.231vw + .91667rem);
    width: auto;
  }
}
@media (min-width: 90rem) {
  .quickentry-records-submit {
    font-size: 1.125rem;
  }
}
@media (min-width: 125rem) {
  .quickentry-records-submit {
    font-size: calc(1.071vw + -.21429rem);
  }
}
.quickentry .more-info-link {
  color: #fff !important;
  margin-top: 1.5rem;
}

.quickentry .request-data-wrapper {
  display: none;
}

.quickentry-three-colored-border {
  background: #009ee0;
  height: 1rem;
  width: 100%;
  position: relative;
}

.quickentry-three-colored-border:before {
  left: 0;
  background: #003378;
}

.quickentry-three-colored-border:after, .quickentry-three-colored-border:before {
  content: "";
  width: 33%;
  height: 1rem;
  display: block;
  position: absolute;
  bottom: 0;
  z-index: 1;
}

.quickentry-three-colored-border:after {
  right: 0;
  background: #e20079;
}

.tx-px-rates-calculator .rates-calculator form {
  max-width: none;
}

@media (min-width: 48rem) {
  .tx-px-rates-calculator .rates-calculator form {
    max-width: 40rem;
  }
}
.tx-px-rates-calculator .rates-calculator form .form-group {
  position: relative;
}

.tx-px-rates-calculator .rates-calculator .rates-calculator-invoice {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  max-width: 90rem;
  margin: 0 auto;
  padding: 0 1.5rem 3.75rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
}

@media (min-width: 48rem) {
  .tx-px-rates-calculator .rates-calculator .rates-calculator-invoice {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }
}
@media (min-width: 62rem) {
  .tx-px-rates-calculator .rates-calculator .rates-calculator-invoice {
    padding: 0 4.861% 3.75rem;
  }
}
@media (min-width: 90rem) {
  .tx-px-rates-calculator .rates-calculator .rates-calculator-invoice {
    padding: 0 4.375rem 3.75rem;
  }
}
.tx-px-rates-calculator .rates-calculator .rates-calculator-invoice-form {
  width: 100%;
}

@media (min-width: 48rem) {
  .tx-px-rates-calculator .rates-calculator .rates-calculator-invoice-form {
    width: 58.3333%;
  }
}
.tx-px-rates-calculator .rates-calculator .rates-calculator-invoice-result {
  width: 100%;
  -webkit-transition: width 0.3s ease;
  transition: width 0.3s ease;
  padding-top: 3.75rem;
}

@media (min-width: 48rem) {
  .tx-px-rates-calculator .rates-calculator .rates-calculator-invoice-result {
    width: 38%;
    padding-top: 0;
  }
}
@media (min-width: 62rem) {
  .tx-px-rates-calculator .rates-calculator .rates-calculator-invoice-result {
    width: 33%;
  }
}
.tx-px-rates-calculator .rates-calculator .rates-calculator-invoice-result-list {
  border: 0.1875rem solid #e20079;
}

.tx-px-rates-calculator .rates-calculator .rates-calculator-invoice-result-list-container {
  padding: 1.25rem;
}

.tx-px-rates-calculator .rates-calculator .rates-calculator-invoice-result-list .result-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-bottom: 1.25rem;
}

.tx-px-rates-calculator .rates-calculator .rates-calculator-invoice-result-list .result-monthly-rate {
  background-color: #e20079;
  width: 100%;
  padding: 1.25rem 1.875rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  color: #fff;
}

.tx-px-rates-calculator .rates-calculator .rates-calculator-contact {
  background: #f5f5f5;
}

.tx-px-rates-calculator .rates-calculator .rates-calculator-contact-container {
  padding: 3.75rem 1.5rem;
  max-width: 90rem;
  margin: 0 auto;
}

@media (min-width: 62rem) {
  .tx-px-rates-calculator .rates-calculator .rates-calculator-contact-container {
    padding: 3.75rem 4.861%;
  }
}
@media (min-width: 90rem) {
  .tx-px-rates-calculator .rates-calculator .rates-calculator-contact-container {
    padding: 3.75rem 4.375rem;
  }
}
.tx-px-rates-calculator .rates-calculator .rates-calculator-contact-container input:not(.btn-primary) {
  background-color: #fff;
}

.tx-px-rates-calculator .rates-calculator .rates-calculator-contact-container > form {
  max-width: none;
}

.tx-px-rates-calculator .rates-calculator .rates-calculator-contact-container > form .two-cols-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media (min-width: 48rem) {
  .tx-px-rates-calculator .rates-calculator .rates-calculator-contact-container > form .two-cols-group {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }
}
.tx-px-rates-calculator .rates-calculator .rates-calculator-contact-container > form .two-cols-group .form-group {
  width: 100%;
}

@media (min-width: 48rem) {
  .tx-px-rates-calculator .rates-calculator .rates-calculator-contact-container > form .two-cols-group .form-group {
    width: 50%;
  }
}
.tx-px-rates-calculator .rates-calculator .rates-calculator-contact-container > form .two-cols-group .form-group:first-child {
  padding-right: 0;
}

@media (min-width: 48rem) {
  .tx-px-rates-calculator .rates-calculator .rates-calculator-contact-container > form .two-cols-group .form-group:first-child {
    padding-right: 1.25rem;
  }
}
.tx-px-rates-calculator .rates-calculator .rates-calculator-contact-container > form .btn-primary {
  width: auto;
}

.tx-px-rates-calculator .rates-calculator .form-group {
  margin-bottom: 1.5rem;
}

.tx-px-rates-calculator .rates-calculator .form-group.disabled {
  opacity: 0.4;
}

.tx-px-rates-calculator .rates-calculator .form-group.disabled select {
  cursor: default;
}

.hint-wrapper {
  display: inline-block;
  cursor: pointer;
}

@media (min-width: 48rem) {
  .hint-wrapper {
    position: relative;
  }
}
.hint-wrapper .hint-click {
  width: 1.125rem;
  height: 1.125rem;
  display: block;
  position: relative;
}

.hint-wrapper .hint-click > svg {
  position: absolute;
  top: 0.125rem;
}

.hint-wrapper .hint-text {
  display: none;
  position: absolute;
  background: #e20079;
  width: 15.625rem;
  padding: 1.25rem;
  z-index: 2;
  -webkit-box-shadow: 0 0.625rem 2.5rem 0 rgba(0, 51, 120, 0.1);
  box-shadow: 0 0.625rem 2.5rem 0 rgba(0, 51, 120, 0.1);
  -webkit-transform-origin: 0 1.875rem;
  transform-origin: 0 1.875rem;
  top: 1.875rem;
  left: 0;
  max-width: 100%;
}

@media (min-width: 48rem) {
  .hint-wrapper .hint-text {
    top: 0.125rem;
    left: 2.1875rem;
    max-width: none;
  }

  .hint-wrapper .hint-text:before {
    content: "";
    width: 0.875rem;
    height: 0.875rem;
    left: -0.4375rem;
    top: 0.1875rem;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    display: block;
    background: #e20079;
    position: absolute;
  }
}
.hint-wrapper .hint-text > p {
  font-size: 0.875rem;
  color: #fff;
}

.hint-wrapper .hint-text.hidden {
  -webkit-animation-name: shrink;
  animation-name: shrink;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
}

.hint-wrapper .hint-text.show {
  display: block;
  -webkit-animation-name: expand-bounce;
  animation-name: expand-bounce;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
}

.rewards-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-bottom: 2.5rem;
  margin: 0 -0.625rem;
}

.rewards-grid-item {
  margin: 0 0.625rem;
  width: calc(100% - 20px);
  padding-bottom: 2.5rem;
}

@media (min-width: 48rem) {
  .rewards-grid-item {
    width: calc(50% - 20px);
  }
}
@media (min-width: 62rem) {
  .rewards-grid-item {
    width: calc(25% - 20px);
  }
}
.rewards-grid-item-label {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column wrap;
  flex-flow: column wrap;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  cursor: pointer;
  height: 100%;
}

.rewards-grid-item-input, .rewards-grid-item-label:after, .rewards-grid-item-label:before {
  display: none;
}

.rewards-grid-item-input:checked ~ label .rewards-selected-icon {
  background: #e20079;
  -webkit-box-shadow: inset 0 0 0 0.0625rem #e20079;
  box-shadow: inset 0 0 0 0.0625rem #e20079;
}

.rewards-grid-item-input:checked ~ label .rewards-selected-icon svg {
  opacity: 1;
  -webkit-transform: translateY(-125%) translateX(-50%);
  transform: translateY(-125%) translateX(-50%);
}

.rewards-grid-item-input:checked ~ label .rewards-selected-text-not-selected {
  opacity: 0;
  visibility: visible;
}

.rewards-grid-item-input:checked ~ label .rewards-selected-text-selected {
  color: #e20079;
  visibility: visible;
  opacity: 1;
}

.rewards-headline {
  margin-top: 0.75rem;
}

.rewards-description {
  font-family: lato_regular, Helvetica, sans-serif;
  font-weight: 400;
  color: #000;
  line-height: 1.5rem;
  font-size: 1rem;
  letter-spacing: 0.0625rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: inline-block;
}

.rewards-selected {
  position: relative;
  height: 1.5rem;
  width: 100%;
  -ms-flex-item-align: end;
  align-self: flex-end;
}

.rewards-selected-icon {
  width: 1.5rem;
  height: 1.5rem;
  background-color: #d7d7d7;
  display: inline-block;
  -webkit-box-shadow: inset 0 0 0 0.0625rem #bdbdbd;
  box-shadow: inset 0 0 0 0.0625rem #bdbdbd;
  margin-right: 0.625rem;
  -webkit-transition: background-color 0.3s, -webkit-box-shadow 0.3s;
  transition: background-color 0.3s, -webkit-box-shadow 0.3s;
  transition: background-color 0.3s, box-shadow 0.3s;
  transition: background-color 0.3s, box-shadow 0.3s, -webkit-box-shadow 0.3s;
  position: relative;
}

.rewards-selected-icon svg {
  width: 1rem;
  height: 1rem;
  fill: #fff;
  opacity: 0;
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
  transition: opacity 0.3s, transform 0.3s, -webkit-transform 0.3s;
  position: absolute;
  top: 100%;
  left: 50%;
  -webkit-transform: translateY(0) translateX(-50%);
  transform: translateY(0) translateX(-50%);
}

.rewards-selected-text {
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 2.125rem;
  font-family: lato_regular, Helvetica, sans-serif;
  font-weight: 400;
  color: #000;
  line-height: 1.5rem;
  margin-bottom: 1.5rem;
  font-size: 0.875rem;
  font-family: lato_bold, Helvetica, sans-serif;
  font-weight: 700;
  padding: 0;
  letter-spacing: 0.0625rem;
}

.rewards-selected-text-wrapper {
  height: 100%;
  display: inline-block;
}

.rewards-selected-text-not-selected {
  color: #bdbdbd;
}

.rewards-selected-text-selected {
  visibility: hidden;
  opacity: 0;
}

.rewards-text {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.rewards-image {
  background-color: #f5f5f5;
  max-height: 13.25rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
}

.rewards-image img {
  height: 100%;
  width: auto;
  max-height: 13.25rem;
}

.main-search, .tx-indexedsearch-searchbox {
  margin: auto 0;
  position: relative;
}

.main-search #tx-indexedsearch-searchbox-button-submit, .tx-indexedsearch-searchbox #tx-indexedsearch-searchbox-button-submit {
  opacity: 0;
  position: absolute;
  height: 100%;
  width: 2.8125rem;
  top: 0;
  right: 0;
  cursor: pointer;
}

.main-search .tx-indexedsearch-searchbox-sword, .tx-indexedsearch-searchbox .tx-indexedsearch-searchbox-sword {
  color: #999;
  font-size: 0.8125rem;
  padding: 0.875rem 2.8125rem 0.875rem 1.25rem;
  margin: 0.625rem 0;
  position: relative;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M5.586 11.69L2.15 15.108a1 1 0 01-1.412 0l-.03-.032a1 1 0 010-1.417l3.458-3.442a6.5 6.5 0 111.418 1.472zM9.5 11a4.5 4.5 0 100-9 4.5 4.5 0 000 9z'/%3E%3C/svg%3E");
  background-position: right 0.9375rem center;
  background-repeat: no-repeat;
  background-size: 1rem 1rem;
}

.tx-indexedsearch-searchbox form {
  margin: 0;
  width: 100%;
}

@media (min-width: 36rem) {
  .tx-indexedsearch-searchbox form {
    width: 50%;
  }
}
@media (min-width: 48rem) {
  .tx-indexedsearch-searchbox form {
    width: 30%;
  }
}
.search-count-wrapper {
  margin-bottom: 1.25rem;
}

.search-result-list-wrapper {
  display: block;
}

.search-result-list-wrapper .search-list-item {
  margin-bottom: 1.25rem;
  padding-bottom: 0;
  width: 100%;
  -webkit-transition: min-height 0.6s ease, width 0.3s ease;
  transition: min-height 0.6s ease, width 0.3s ease;
}

.search-result-list-wrapper .search-list-item .search-list-item-content-wrapper {
  display: block;
  background-color: #f5f5f5;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
  padding: 1.25rem 1.25rem 5rem;
  width: 100%;
  position: relative;
}

@media (min-width: 48rem) {
  .search-result-list-wrapper .search-list-item .search-list-item-content-wrapper {
    padding: 1.5625rem 6.25rem 1.5625rem 2.5rem;
  }
}
.search-result-list-wrapper .search-list-item .search-list-item-content-wrapper .search-list-item-description {
  -webkit-transition: color 0.3s ease;
  transition: color 0.3s ease;
  margin-bottom: 0;
}

.search-result-list-wrapper .search-list-item .search-list-item-content-wrapper .search-list-item-description .tx-indexedsearch-redMarkup {
  color: #e20079;
  -webkit-transition: color 0.3s ease;
  transition: color 0.3s ease;
}

.search-result-list-wrapper .search-list-item .search-list-item-content-wrapper .search-list-item-title {
  -webkit-transition: color 0.3s ease;
  transition: color 0.3s ease;
}

.search-result-list-wrapper .search-list-item .search-list-item-content-wrapper:hover {
  background-color: #e20079;
}

.search-result-list-wrapper .search-list-item .search-list-item-content-wrapper:hover .search-list-item-description {
  color: #fff;
}

.search-result-list-wrapper .search-list-item .search-list-item-content-wrapper:hover .search-list-item-description .tx-indexedsearch-redMarkup {
  color: #000;
}

.search-result-list-wrapper .search-list-item .search-list-item-content-wrapper:hover .search-list-item-title {
  color: #fff;
}

.search-result-list-wrapper .search-list-item .search-list-item-content-wrapper:hover .search-list-more-link use {
  fill: #fff;
}

.search-result-list-wrapper .search-list-item .search-list-item-content-wrapper .search-list-more-link {
  width: 2.5rem;
  height: 2.5rem;
  line-height: 0;
  position: absolute;
  left: 0;
  bottom: 1.25rem;
  -webkit-transform: translateX(50%);
  transform: translateX(50%);
}

@media (min-width: 48rem) {
  .search-result-list-wrapper .search-list-item .search-list-item-content-wrapper .search-list-more-link {
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 1.875rem;
    left: auto;
  }
}
.search-result-list-wrapper .search-list-item .search-list-item-content-wrapper .search-list-more-link use {
  fill: #e20079;
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.news-item-link-wrapper:hover .search-result-list-wrapper .search-list-item .search-list-item-content-wrapper .search-list-more-link use {
  fill: #fff;
}

.tx-px-seminars {
  margin-bottom: 2.5rem;
}

.seminar-list-filter {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  background-color: #f5f5f5;
  top: 100%;
  z-index: 1;
  width: 100%;
}

.seminar-list-filter, .seminar-list-filter.visible {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.seminar-list-filter-box {
  display: none;
}

.seminar-list-filter-wrapper {
  margin-bottom: 1.25rem;
  max-width: 100%;
}

@media (min-width: 48rem) {
  .seminar-list-filter-wrapper {
    margin-left: auto;
    max-width: 18.75rem;
  }
}
.seminar-list-filter-button {
  display: block;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #f1f1f1;
  padding: 1rem;
  color: #000;
  border: none;
  outline: none;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: background-color 0.3s, color 0.3s;
  transition: background-color 0.3s, color 0.3s;
  font-size: 0.875rem;
  width: 100%;
  font-family: lato_regular, Helvetica, sans-serif;
  font-weight: 400;
  cursor: pointer;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 16 16'%3E%3Cdefs%3E%3Cpath fill='%23E20079' id='a' d='M9.883 13a.216.216 0 01-.155-.066L5.064 8.158a.227.227 0 010-.317l4.664-4.775a.216.216 0 01.31 0l.897.919a.228.228 0 010 .317L7.324 8l3.612 3.698a.228.228 0 010 .317l-.898.92a.218.218 0 01-.155.065z'/%3E%3C/defs%3E%3Cuse transform='rotate(-90 8 8)' xlink:href='%23a'/%3E%3C/svg%3E");
  background-position: right 0.75rem center;
  background-size: 1.5rem 1.5rem;
  background-repeat: no-repeat;
}

.seminar-list-filter-button:active, .seminar-list-filter-button:focus {
  -webkit-box-shadow: 0.125rem 0.125rem 0.625rem 0 rgba(54, 175, 253, 0.2);
  box-shadow: 0.125rem 0.125rem 0.625rem 0 rgba(54, 175, 253, 0.2);
  background-color: #fff;
}

.seminar-list-filter-button::-ms-expand {
  display: none;
}

.seminar-list-filter-button.active {
  color: #fff;
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 16 16'%3E%3Cdefs%3E%3Cpath fill='%23fff' id='a' d='M9.883 13a.216.216 0 01-.155-.066L5.064 8.158a.227.227 0 010-.317l4.664-4.775a.216.216 0 01.31 0l.897.919a.228.228 0 010 .317L7.324 8l3.612 3.698a.228.228 0 010 .317l-.898.92a.218.218 0 01-.155.065z'/%3E%3C/defs%3E%3Cuse fill='%23fff' transform='rotate(-90 8 8)' xlink:href='%23a'/%3E%3C/svg%3E") calc(100% - 12px)/24px no-repeat;
  background-color: #e20079;
}

.seminar-list-filter-item {
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  width: 100%;
  font-size: 0.875rem;
  padding: 1rem;
}

.seminar-list-filter-item:hover {
  background-color: #e20079;
  color: #fff;
  -webkit-transition: color 0.3s, background-color 0.3s;
  transition: color 0.3s, background-color 0.3s;
}

.seminar-list-container {
  background-color: #f5f5f5;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1.25rem;
  margin-bottom: 1.25rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (min-width: 48rem) {
  .seminar-list-container {
    padding: 1.5625rem 1.25rem;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}
.seminar-list-container:hover {
  background-color: #e20079;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

.seminar-list-container:hover .seminar-list-date, .seminar-list-container:hover .seminar-list-name, .seminar-list-container:hover .seminar-list-speaker {
  color: #fff;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}

.seminar-list-container:hover .seminar-list-icon svg use {
  -webkit-transition: fill 0.3s;
  transition: fill 0.3s;
  fill: #fff !important;
}

.seminar-list-details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

@media (min-width: 48rem) {
  .seminar-list-details {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}
@media (min-width: 48rem) {
  .seminar-list-name {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 55%;
    flex: 0 0 55%;
    padding: 0 0.625rem;
    margin-bottom: 0;
  }
}
@media (min-width: 48rem) {
  .seminar-list-date, .seminar-list-speaker {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 22.5%;
    flex: 0 0 22.5%;
    padding: 0 0.625rem;
    margin-bottom: 0;
  }
}
.seminar-list-date span {
  padding-left: 0.625rem;
}

@media (min-width: 48rem) {
  .seminar-list-date span {
    padding: 0;
  }
}
.seminar-list-date span:before {
  content: "|";
  white-space: pre;
  padding-right: 0.625rem;
}

@media (min-width: 48rem) {
  .seminar-list-date span:before {
    content: "\a";
    white-space: pre;
  }
}
.seminar-list-link {
  padding-bottom: 0 !important;
  line-height: 0 !important;
}

.seminar-list-icon {
  width: 1.5rem;
  height: 1.5rem;
  display: inline-block;
  position: absolute;
  margin-left: 0.625rem;
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  cursor: pointer;
  position: relative;
  height: 2.5rem;
  width: 2.5rem;
  display: inline-block !important;
}

@media (min-width: 75rem) {
  .seminar-list-icon {
    display: none;
  }
}
.seminar-list-icon svg {
  width: 100%;
  height: 100%;
}

.seminar-list-icon svg use {
  fill: #fff;
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.seminar-list-icon:hover svg use {
  fill: #e20079;
}

.seminar-list-icon svg use {
  fill: #e20079 !important;
}

.seminar-detail-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.seminar-detail-wrapper-left {
  width: 100%;
}

@media (min-width: 62rem) {
  .seminar-detail-wrapper-left {
    width: 58.3333%;
    min-width: 40.625rem;
  }
}
@media (min-width: 62rem) {
  .seminar-detail-wrapper {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
}
.seminar-detail-name {
  padding-bottom: 0.3125rem;
}

.seminar-detail-headline {
  padding-bottom: 2.5rem;
}

.seminar-detail-date-container {
  background-color: #f5f5f5;
  padding: 0.9375rem;
  width: 100%;
  position: relative;
}

@media (min-width: 62rem) {
  .seminar-detail-date-container {
    padding: 1.875rem;
  }
}
.seminar-detail-date-headline {
  padding-bottom: 0.625rem;
}

.seminar-detail-date-table {
  width: auto;
  font-size: 1rem;
  letter-spacing: 0.0625rem;
}

.seminar-detail-date-table td {
  padding: 0 1.875rem 0.625rem 0;
}

.seminar-detail-date-price {
  padding-bottom: 0;
}

.seminar-detail-date-button {
  padding-top: 0.9375rem;
}

@media (min-width: 62rem) {
  .seminar-detail-date-button {
    position: absolute;
    right: 1.875rem;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    padding-top: 0;
  }
}
.seminar-detail-date-button a {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.seminar-detail-speaker-container {
  margin-bottom: 2.5rem;
}

@media (min-width: 62rem) {
  .seminar-detail-speaker-container {
    padding-left: 1.25rem;
    width: 33%;
  }
}
.seminar-detail-speaker-details {
  margin: 1.5625rem 0;
}

.seminar-detail-speaker-details h3, .seminar-detail-speaker-details p {
  padding-bottom: 0;
}

.seminar-detail-speaker-link {
  width: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.seminar-detail-content {
  max-width: 46.875rem;
}

.seminar-detail-description, .seminar-detail-details {
  margin: 2.5rem 0;
}

.seminar-detail-details h3 {
  padding-bottom: 0.3125rem;
}

.seminar-detail-details-place-label, .seminar-detail-details p:last-of-type {
  padding-bottom: 0;
}

.seminar-detail-details-additionalInformation, .seminar-detail-details-place {
  padding-bottom: 0.625rem;
}

.seminar-detail-form form {
  margin: 0;
  max-width: 26.25rem;
}

.seminar-detail-form .radio-label:not(:last-of-type) {
  padding-right: 1.5625rem;
}

.seminar-detail-form .radio-wrapper {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.seminar-detail-form-submit {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  max-width: 100%;
}

.seminar-detail-form .row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 100%;
  flex: 1 0 100%;
}

.seminar-detail-form .row div.big {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 60%;
  flex: 1 0 60%;
}

.seminar-detail-form .row div.small {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
}

.seminar-detail-form .ce-text, .seminar-detail-form .frame-layout-0 {
  padding: 0;
}

.table-container {
  overflow-x: auto;
  width: 100%;
}

.ce-table {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
  zoom: 1;
}

.ce-table tbody tr:nth-child(odd) {
  background: #fff;
}

.ce-table tbody tr:nth-child(2n) {
  background: #f1f1f1;
}

.ce-table tbody tr:nth-child(2n) td {
  border-color: #fff;
}

.ce-table th {
  font-family: lato_black, Helvetica, sans-serif;
  font-weight: 900;
  color: #000;
  line-height: 1.7142857143;
  font-size: 0.875rem;
  letter-spacing: 0.125rem;
  border-right: 0.0625rem solid #fff;
  color: #fff;
  background: #e20079;
  font-size: 1rem;
  padding: 1rem 0.75rem;
}

@media (min-width: 62rem) {
  .ce-table th {
    border: none;
  }
}
.ce-table th:last-child {
  border: none;
}

.ce-table td {
  font-family: lato_regular, Helvetica, sans-serif;
  font-weight: 400;
  color: #000;
  line-height: 1.5rem;
  font-size: 1rem;
  letter-spacing: 0.0625rem;
  border-right: 0.0625rem solid #f1f1f1;
  margin-bottom: 0;
  min-width: 9.375rem;
  padding: 1rem 0.75rem;
}

@media (min-width: 36rem) {
  .ce-table td {
    min-width: 12.5rem;
  }
}
@media (min-width: 62rem) {
  .ce-table td {
    border: none;
    min-width: auto;
  }
}
.ce-table td:last-child {
  border: none;
}

.teaser-container {
  width: 100%;
}

.teaser-container .main-carousel {
  padding-bottom: 1.875rem;
}

.teaser-container .main-carousel .flickity-button-icon path {
  fill: #fff;
}

.teaser-container .main-carousel .flickity-button-icon:hover path {
  background: #ff49aa;
}

.teaser-container .main-carousel .flickity-button[disabled] .flickity-button-icon:hover path {
  fill: #e20079;
}

.teaser-container .main-carousel .flickity-page-dots {
  bottom: 0.9375rem;
  -webkit-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%);
}

.teaser-container .main-carousel.slider-option-none {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
}

.teaser-container .main-carousel.slider-option-none .carousel-cell {
  margin: 0 0 1.25rem;
  cursor: auto;
}

.teaser-container .main-carousel.slider-option-none.teaser-column-1 .carousel-cell {
  width: 100%;
}

@media (min-width: 36rem) {
  .teaser-container .main-carousel.slider-option-none.teaser-column-2 .carousel-cell {
    width: calc(50% - 10px);
  }

  .teaser-container .main-carousel.slider-option-none.teaser-column-2 .carousel-cell:nth-of-type(odd) {
    margin-right: 1.25rem;
  }
}
.teaser-container .main-carousel.slider-option-none:after {
  content: "";
}

@media (min-width: 36rem) {
  .teaser-container .main-carousel.slider-option-none {
    margin-bottom: 0.625rem;
  }
}
@media (min-width: 48rem) {
  .teaser-container .main-carousel.slider-option-none {
    margin-bottom: 1.875rem;
  }
}
@media (max-width: 35.9375rem) {
  .teaser-container .main-carousel.slider-option-none .carousel-cell:last-child {
    margin-bottom: 0.625rem;
  }
}
.teaser-container .main-carousel.slider-option-mobile {
  margin-bottom: 0.625rem;
}

.teaser-container .main-carousel.slider-option-mobile.teaser-column-1 .carousel-cell {
  width: calc(90% - 10px);
}

@media (min-width: 36rem) {
  .teaser-container .main-carousel.slider-option-mobile.teaser-column-1 .carousel-cell {
    width: calc(90% - 20px);
  }
}
@media (min-width: 48rem) {
  .teaser-container .main-carousel.slider-option-mobile.teaser-column-1 .carousel-cell {
    width: calc(95% - 20px);
  }
}
@media (min-width: 75rem) {
  .teaser-container .main-carousel.slider-option-mobile.teaser-column-1 .carousel-cell {
    width: 100%;
  }
}
.teaser-container .main-carousel.slider-option-mobile.teaser-column-2 {
  width: 100%;
}

.teaser-container .main-carousel.slider-option-mobile.teaser-column-2 .carousel-cell {
  width: calc(90% - 10px);
}

@media (min-width: 36rem) {
  .teaser-container .main-carousel.slider-option-mobile.teaser-column-2 .carousel-cell {
    width: calc(90% - 20px);
  }
}
@media (min-width: 48rem) {
  .teaser-container .main-carousel.slider-option-mobile.teaser-column-2 .carousel-cell {
    width: calc(44% - 10px);
  }
}
@media (min-width: 75rem) {
  .teaser-container .main-carousel.slider-option-mobile.teaser-column-2 .carousel-cell {
    width: calc(50% - 10px);
  }
}
.teaser-container .main-carousel.slider-option-mobile:after {
  content: "flickity";
  display: none;
}

@media (min-width: 48rem) {
  .teaser-container .main-carousel.slider-option-mobile {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 1.875rem;
  }

  .teaser-container .main-carousel.slider-option-mobile .carousel-cell {
    margin: 0 0 1.25rem;
    cursor: auto;
  }

  .teaser-container .main-carousel.slider-option-mobile.teaser-column-1 .carousel-cell {
    width: 100%;
  }
}
@media (min-width: 48rem) and (min-width: 36rem) {
  .teaser-container .main-carousel.slider-option-mobile.teaser-column-2 .carousel-cell {
    width: calc(50% - 10px);
  }

  .teaser-container .main-carousel.slider-option-mobile.teaser-column-2 .carousel-cell:nth-of-type(odd) {
    margin-right: 1.25rem;
  }
}
@media (min-width: 48rem) {
  .teaser-container .main-carousel.slider-option-mobile:after {
    content: "";
  }
}
.teaser-container .main-carousel.slider-option-both {
  margin-bottom: 1.875rem;
}

.teaser-container .main-carousel.slider-option-both.teaser-column-1 .carousel-cell {
  width: calc(90% - 10px);
}

@media (min-width: 36rem) {
  .teaser-container .main-carousel.slider-option-both.teaser-column-1 .carousel-cell {
    width: calc(90% - 20px);
  }
}
@media (min-width: 48rem) {
  .teaser-container .main-carousel.slider-option-both.teaser-column-1 .carousel-cell {
    width: calc(95% - 20px);
  }
}
@media (min-width: 75rem) {
  .teaser-container .main-carousel.slider-option-both.teaser-column-1 .carousel-cell {
    width: 100%;
  }
}
.teaser-container .main-carousel.slider-option-both.teaser-column-2 {
  width: 100%;
}

.teaser-container .main-carousel.slider-option-both.teaser-column-2 .carousel-cell {
  width: calc(90% - 10px);
}

@media (min-width: 36rem) {
  .teaser-container .main-carousel.slider-option-both.teaser-column-2 .carousel-cell {
    width: calc(90% - 20px);
  }
}
@media (min-width: 48rem) {
  .teaser-container .main-carousel.slider-option-both.teaser-column-2 .carousel-cell {
    width: calc(44% - 10px);
  }
}
@media (min-width: 75rem) {
  .teaser-container .main-carousel.slider-option-both.teaser-column-2 .carousel-cell {
    width: calc(50% - 10px);
  }
}
.teaser-container .main-carousel.slider-option-both:after {
  content: "flickity";
  display: none;
}

@media (min-width: 48rem) {
  .teaser-container .main-carousel.slider-option-both {
    margin-bottom: 3.125rem;
  }
}
.teaser-container .main-carousel .carousel-cell {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.teaser-container .main-carousel .teaser {
  position: relative;
  width: 100%;
}

.teaser-container .main-carousel .teaser-image {
  width: 100%;
  height: auto;
}

.teaser-container .main-carousel .teaser-image-wrapper {
  width: 100%;
  line-height: 0;
}

.frame-type-text .ce-headline-101 {
  text-align: left;
}

.frame-type-text .ce-headline-102 {
  text-align: center;
}

.frame-type-text .ce-headline-103 {
  text-align: right;
}

.ce-image.ce-intext.ce-left, .ce-image.ce-intext.ce-right, .ce-textpic.ce-intext.ce-left, .ce-textpic.ce-intext.ce-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
}

@media (min-width: 48rem) {
  .ce-image.ce-intext.ce-left, .ce-image.ce-intext.ce-right, .ce-textpic.ce-intext.ce-left, .ce-textpic.ce-intext.ce-right {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
  }
}
@media (min-width: 48rem) {
  .ce-image.ce-intext.ce-left .ce-textpic-wrapper, .ce-image.ce-intext.ce-right .ce-textpic-wrapper, .ce-textpic.ce-intext.ce-left .ce-textpic-wrapper, .ce-textpic.ce-intext.ce-right .ce-textpic-wrapper {
    width: 50%;
  }
}
.ce-image.ce-intext.ce-left .gallery-row, .ce-image.ce-intext.ce-right .gallery-row, .ce-textpic.ce-intext.ce-left .gallery-row, .ce-textpic.ce-intext.ce-right .gallery-row {
  display: inline-block;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  margin-bottom: 1.25rem;
}

@media (min-width: 48rem) {
  .ce-image.ce-intext.ce-left .gallery-row, .ce-image.ce-intext.ce-right .gallery-row, .ce-textpic.ce-intext.ce-left .gallery-row, .ce-textpic.ce-intext.ce-right .gallery-row {
    margin-bottom: 0.625rem;
    margin-right: 8.3333333333%;
    width: 41.6666666667%;
  }
}
@media (min-width: 48rem) {
  .ce-image.ce-intext.ce-right .gallery-row, .ce-textpic.ce-intext.ce-right .gallery-row {
    margin-left: 8.3333333333%;
    margin-right: 0;
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    text-align: right;
  }
}
@media (min-width: 48rem) {
  .ce-image.ce-intext.ce-right .gallery-row .gallery-item, .ce-textpic.ce-intext.ce-right .gallery-row .gallery-item {
    display: inline-block;
  }
}
.ce-image .gallery-item, .ce-textpic .gallery-item {
  margin-bottom: 1.25rem;
  max-width: 33.125rem;
  width: 100%;
}

.ce-image .gallery-item:last-child, .ce-textpic .gallery-item:last-child {
  margin-bottom: 0;
}

.ce-image .gallery-item .image-embed-item, .ce-textpic .gallery-item .image-embed-item {
  display: block;
  height: auto;
  width: auto;
  max-width: 100%;
}

@media (min-width: 48rem) {
  .ce-image .gallery-item .image-embed-item, .ce-textpic .gallery-item .image-embed-item {
    margin: 0 auto;
  }
}
.ce-image .gallery-item .video-embed, .ce-textpic .gallery-item .video-embed {
  position: relative;
  padding-bottom: 56.25%;
}

.ce-image .gallery-item .video-embed iframe, .ce-image .gallery-item .video-embed video, .ce-textpic .gallery-item .video-embed iframe, .ce-textpic .gallery-item .video-embed video {
  border: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-playlist-tabs {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.video-playlist-tabs-upper-content {
  margin-bottom: 2.5rem;
}

.video-playlist-tabs-upper-content .video-playlist-tabs-headline {
  font-family: lato_black, Helvetica, sans-serif;
  font-weight: 900;
  color: #000;
  line-height: 1.1666666667;
  margin-bottom: 1.5rem;
  text-transform: uppercase;
  font-size: 1.625rem;
  letter-spacing: 0.25rem;
  -webkit-transition: font-size 0.6s cubic-bezier(0.68, -0.55, 0.27, 1.55);
  transition: font-size 0.6s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

@media (min-width: 36rem) {
  .video-playlist-tabs-upper-content .video-playlist-tabs-headline {
    font-size: calc(2.546vw + .70833rem);
  }
}
@media (min-width: 90rem) {
  .video-playlist-tabs-upper-content .video-playlist-tabs-headline {
    font-size: 3rem;
  }
}
@media (min-width: 125rem) {
  .video-playlist-tabs-upper-content .video-playlist-tabs-headline {
    font-size: calc(2.857vw + -.57143rem);
  }
}
.video-playlist-tabs-upper-content .video-playlist-tabs-subheadline {
  font-family: lato_black, Helvetica, sans-serif;
  font-weight: 900;
  color: #000;
  line-height: 1.3333333333;
  margin-bottom: 2rem;
  text-transform: uppercase;
  font-size: 1.5rem;
  letter-spacing: 0.125rem;
  -webkit-transition: font-size 0.6s cubic-bezier(0.68, -0.55, 0.27, 1.55);
  transition: font-size 0.6s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

@media (min-width: 36rem) {
  .video-playlist-tabs-upper-content .video-playlist-tabs-subheadline {
    font-size: calc(.231vw + 1.29167rem);
  }
}
@media (min-width: 90rem) {
  .video-playlist-tabs-upper-content .video-playlist-tabs-subheadline {
    font-size: 1.5rem;
  }
}
@media (min-width: 125rem) {
  .video-playlist-tabs-upper-content .video-playlist-tabs-subheadline {
    font-size: calc(2.857vw + -2.07143rem);
  }
}
.video-playlist-tabs-upper-content .video-playlist-tabs-headline + .video-playlist-tabs-rte-content, .video-playlist-tabs-upper-content .video-playlist-tabs-subheadline + .video-playlist-tabs-rte-content {
  margin-top: 1.5rem;
}

.video-playlist-tabs-upper-content .video-playlist-tabs-rte-content p {
  line-height: 2rem;
}

.video-playlist-tabs-lower-content {
  display: block;
}

@media (min-width: 48rem) {
  .video-playlist-tabs-lower-content {
    height: 100%;
  }
}
.video-playlist-tabs-lower-content .accordion {
  padding: 0;
}

.video-playlist-tabs-lower-content .accordion-body {
  padding-left: 0;
}

.video-playlist-tabs-lower-content .accordion-content {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}

.video-playlist-tabs-lower-content .accordion .arrow-right {
  display: none;
}

@media (min-width: 48rem) {
  .video-playlist-tabs-lower-content .accordion {
    display: none;
  }

  .video-playlist-tabs-lower-content .accordion .arrow-right {
    display: inline;
  }
}
.video-playlist-tabs-lower-content .accordion .tab-image {
  margin: 0 auto;
  max-width: 19.375rem;
  width: 100%;
}

@media (min-width: 36rem) {
  .video-playlist-tabs-lower-content .accordion-content-media + .accordion-content-text {
    width: calc(70% - 20px);
  }
}
.video-playlist-tabs-lower-content .accordion-content-text {
  width: 100%;
}

.video-playlist-tabs-lower-content .accordion .tab-button-text {
  text-transform: uppercase;
}

.video-playlist-tabs-lower-content .accordion-item:last-of-type {
  padding-bottom: 0;
}

.video-playlist-tabs-lower-content .accordion .tab-content-rte-content-main-content {
  padding-bottom: 5rem;
  position: relative;
}

@media (min-width: 48rem) {
  .video-playlist-tabs-lower-content .accordion .tab-content-rte-content-main-content {
    padding-bottom: 0;
  }
}
.video-playlist-tabs-lower-content .accordion .tab-content-rte-content-main-content .video-metadata-wrapper h2 {
  font-size: 1rem;
}

.video-playlist-tabs-lower-content .accordion .tab-content-rte-content-sidebar-content {
  background-color: #f5f5f5;
  padding: 1.25rem 0;
}

.video-playlist-tabs-lower-content .video-playlist-tabs-buttons {
  display: none;
  position: relative;
}

@media (min-width: 48rem) {
  .video-playlist-tabs-lower-content .video-playlist-tabs-buttons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-bottom: 5.5rem;
  }
}
.video-playlist-tabs-lower-content .video-playlist-tabs-buttons .tab-button {
  cursor: pointer;
  display: inline-block;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  white-space: nowrap;
  background-color: #f5f5f5;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  padding: 0.75rem;
  text-align: center;
}

.video-playlist-tabs-lower-content .video-playlist-tabs-buttons .tab-button:not(:last-of-type) {
  margin-right: 0.25rem;
}

.video-playlist-tabs-lower-content .video-playlist-tabs-buttons .tab-button-icon {
  display: inline-block;
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 0.5rem;
}

.video-playlist-tabs-lower-content .video-playlist-tabs-buttons .tab-button-text {
  display: inline-block;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.5rem;
  color: #000;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  letter-spacing: 2px;
}

.video-playlist-tabs-lower-content .video-playlist-tabs-buttons .tab-button .arrow-right {
  height: 100%;
  width: 100%;
}

.video-playlist-tabs-lower-content .video-playlist-tabs-buttons .tab-button .arrow-right path {
  -webkit-transition: fill 0.3s;
  transition: fill 0.3s;
  fill: #003378;
}

.video-playlist-tabs-lower-content .video-playlist-tabs-buttons .tab-button:hover {
  background-color: #e20079;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

.video-playlist-tabs-lower-content .video-playlist-tabs-buttons .tab-button:hover .tab-button-text {
  color: #fff;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}

.video-playlist-tabs-lower-content .video-playlist-tabs-buttons .tab-button:hover .arrow-right path {
  -webkit-transition: fill 0.3s;
  transition: fill 0.3s;
  fill: #fff;
}

.video-playlist-tabs-lower-content .video-playlist-tabs-buttons .tab-button.active {
  background-color: #e20079;
}

.video-playlist-tabs-lower-content .video-playlist-tabs-buttons .tab-button.active:hover {
  background-color: #ff49aa;
}

.video-playlist-tabs-lower-content .video-playlist-tabs-buttons .tab-button.active .tab-button-text {
  color: #fff;
}

.video-playlist-tabs-lower-content .video-playlist-tabs-content-wrapper {
  display: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (min-width: 48rem) {
  .video-playlist-tabs-lower-content .video-playlist-tabs-content-wrapper {
    display: inline-block;
    width: 100%;
  }
}
.video-playlist-tabs-lower-content .video-playlist-tabs-content-wrapper .tab-content {
  display: none;
  margin-left: 0;
  margin-right: 0;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

@media (min-width: 64rem) {
  .video-playlist-tabs-lower-content .video-playlist-tabs-content-wrapper .tab-content {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}
.video-playlist-tabs-lower-content .video-playlist-tabs-content-wrapper .tab-content.active {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-animation-name: tabAnimation;
  animation-name: tabAnimation;
  -webkit-animation-duration: 0.6s;
  animation-duration: 0.6s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.video-playlist-tabs-lower-content .video-playlist-tabs-content-wrapper .tab-content-rte-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  max-height: 38.75rem;
  padding-bottom: 5rem;
  position: relative;
  height: 100%;
}

.video-playlist-tabs-lower-content .video-playlist-tabs-content-wrapper .tab-content-rte-content h2, .video-playlist-tabs-lower-content .video-playlist-tabs-content-wrapper .tab-content-rte-content h3, .video-playlist-tabs-lower-content .video-playlist-tabs-content-wrapper .tab-content-rte-content h4, .video-playlist-tabs-lower-content .video-playlist-tabs-content-wrapper .tab-content-rte-content h5, .video-playlist-tabs-lower-content .video-playlist-tabs-content-wrapper .tab-content-rte-content h6 {
  margin-bottom: 1.375rem;
}

.video-playlist-tabs-lower-content .video-playlist-tabs-content-wrapper .tab-content-rte-content-main-content {
  width: calc(66.6666% - 10px);
  background-color: #f5f5f5;
}

.video-playlist-tabs-lower-content .video-playlist-tabs-content-wrapper .tab-content-rte-content-sidebar-content {
  background-color: #f5f5f5;
  width: calc(33.33333% - 10px);
  padding: 1.5rem;
  overflow-y: scroll;
  max-height: 32.5rem;
}

.video-playlist-tabs-lower-content .video-playlist-tabs-content-wrapper .tab-content-rte-content-link {
  font-family: lato_regular, Helvetica, sans-serif;
  font-weight: 400;
  color: #000;
  line-height: 1.5rem;
  font-size: 1rem;
  letter-spacing: 0.0625rem;
  font-family: lato_bold, Helvetica, sans-serif;
  font-weight: 700;
  color: #e20079;
  margin-bottom: 0;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  text-decoration: none;
  font-size: 0.875rem;
  line-height: 1.75rem;
}

.video-playlist-tabs-lower-content .video-playlist-tabs-content-wrapper .tab-content-rte-content-link:hover {
  color: #000;
}

.video-playlist-tabs-lower-content .video-playlist-tabs-content-wrapper .tab-content .tab-image {
  width: 100%;
  max-width: 19.375rem;
  margin-bottom: 1.25rem;
}

@media (min-width: 64rem) {
  .video-playlist-tabs-lower-content .video-playlist-tabs-content-wrapper .tab-content .tab-image {
    margin-bottom: 0;
    margin-right: 1.25rem;
  }
}
.video-playlist-tabs-lower-content .video-playlist-tabs-content-wrapper .tab-content .tab-image + .tab-content-rte-content {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

@media (min-width: 75rem) {
  .video-playlist-tabs-lower-content .video-playlist-tabs-content-wrapper .tab-content .tab-image + .tab-content-rte-content {
    width: calc(100% - 330px);
  }
}
.video-playlist-tabs-lower-content .tab-video-wrapper {
  width: 100%;
  display: block;
}

.video-playlist-tabs .video-thumbnail-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column wrap;
  flex-flow: column wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  cursor: pointer;
  z-index: 9999;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}

@media (min-width: 62rem) {
  .video-playlist-tabs .video-thumbnail-wrapper {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
  }
}
.video-playlist-tabs .video-thumbnail-wrapper.active, .video-playlist-tabs .video-thumbnail-wrapper:hover {
  color: #e20079;
}

.video-playlist-tabs .video-thumbnail-wrapper:not(:last-of-type) {
  padding-bottom: 1.5rem;
}

.video-playlist-tabs .video-thumbnail-wrapper .video-thumbnail-wrapper-image {
  width: 75%;
}

@media (min-width: 62rem) {
  .video-playlist-tabs .video-thumbnail-wrapper .video-thumbnail-wrapper-image {
    width: 33%;
  }
}
.video-playlist-tabs .video-thumbnail-wrapper .video-thumbnail-wrapper-text {
  width: 75%;
  padding: 0.3125rem 0 0;
}

@media (min-width: 62rem) {
  .video-playlist-tabs .video-thumbnail-wrapper .video-thumbnail-wrapper-text {
    padding: 0 0 0 1.5rem;
  }
}
.video-playlist-tabs .video-thumbnail-wrapper .video-thumbnail-wrapper-text p {
  font-size: 0.875rem;
  line-height: 1.5rem;
  letter-spacing: 0.0625rem;
}

.video-playlist-tabs .video-thumbnail-wrapper .hidden-youtube-link, .video-playlist-tabs .video-thumbnail-wrapper .video-metadata-hidden {
  visibility: hidden !important;
  display: none !important;
}

.video-playlist-tabs .video-metadata-wrapper {
  position: absolute;
  bottom: 0;
}

.video-playlist-tabs .video-metadata-wrapper h2 {
  text-transform: none;
}

.youtube-video-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
}

.youtube-video-wrapper embed, .youtube-video-wrapper iframe, .youtube-video-wrapper object {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@-webkit-keyframes tabAnimation {
  0% {
    opacity: 0;
    display: none;
  }
  1% {
    display: block;
  }
  to {
    opacity: 1;
  }
}
@keyframes tabAnimation {
  0% {
    opacity: 0;
    display: none;
  }
  1% {
    display: block;
  }
  to {
    opacity: 1;
  }
}
.content-type-video .video-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  width: 100%;
  height: auto;
}

.content-type-video .video-wrapper iframe, .content-type-video .video-wrapper video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.tx-px-seminars .filter {
  border: 0px;
  float: left;
}

.tx-px-seminars .speaker {
  width: 33%;
  padding-right: 0;
}

.tx-px-seminars .city {
  width: 34%;
}

.tx-px-seminars .category {
  width: 33%;
  padding-left: 0;
}

.tx-px-seminars #caleandar {
  min-height: 800px;
}

.cld-main {
  width: 100%;
}

.cld-main a {
  color: #7B00FF;
}

.cld-datetime {
  position: relative;
  width: 66%;
  min-width: 100px;
  max-width: 300px;
  margin: auto;
  overflow: hidden;
  margin-bottom: 50px;
}

.cld-datetime .today {
  position: relative;
  float: left;
  width: calc(100% - 40px);
  margin: auto;
  text-align: center;
}

.cld-nav {
  position: relative;
  width: 20px;
  height: 20px;
  margin-top: 2px;
}

.cld-nav:hover {
  cursor: pointer;
}

.cld-nav:hover svg {
  fill: #666;
}

.cld-rwd {
  float: left;
}

.cld-fwd {
  float: right;
}

.cld-labels, .cld-days {
  padding-left: 0;
}

.cld-label, .cld-day {
  box-sizing: border-box;
  display: inline-block;
  width: 14.28%;
  text-align: center;
}

.cld-day {
  display: block;
  float: left;
  position: relative;
  margin: 0;
  padding: 5px;
  height: 120px;
  border: 1px solid #ddd;
  overflow-y: auto;
}

.cld-day.clickable:hover {
  cursor: pointer;
}

.cld-day.today {
  border: 1px solid #7B00FF;
}

.cld-day.disableDay {
  opacity: 0.5;
}

.cld-day.nextMonth, .cld-day.prevMonth {
  opacity: 0.33;
}

.cld-number {
  margin: 0;
  text-align: left;
}

.cld-title {
  font-size: 10px;
  display: block;
  margin: 0;
  font-weight: normal;
}

.cld-day:hover {
  background: #eee;
}

.cld-number.eventday {
  font-weight: bold;
}

.cld-number.eventday:hover {
  background: #eee;
}

.bootstrap {
  /*!
   * Bootstrap v4.4.1 (https://getbootstrap.com/)
   * Copyright 2011-2019 The Bootstrap Authors
   * Copyright 2011-2019 Twitter, Inc.
   * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
   */
}
.bootstrap .modal-open {
  overflow: hidden;
}
.bootstrap .modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.bootstrap .modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}
.bootstrap .modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .bootstrap .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .bootstrap .modal-dialog {
    transition: none;
  }
}
.modal.show .bootstrap .modal-dialog {
  transform: none;
}
.modal.modal-static .bootstrap .modal-dialog {
  transform: scale(1.02);
}
.bootstrap .modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.bootstrap .modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.bootstrap .modal-dialog-scrollable .modal-header,
.bootstrap .modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.bootstrap .modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}
.bootstrap .modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.bootstrap .modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  content: "";
}
.bootstrap .modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.bootstrap .modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.bootstrap .modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}
.bootstrap .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}
.bootstrap .modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.bootstrap .modal-backdrop.fade {
  opacity: 0;
}
.bootstrap .modal-backdrop.show {
  opacity: 0.5;
}
.bootstrap .modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.bootstrap .modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}
.bootstrap .modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}
.bootstrap .modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}
.bootstrap .modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.bootstrap .modal-footer > * {
  margin: 0.25rem;
}
.bootstrap .modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
@media (min-width: 576px) {
  .bootstrap .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .bootstrap .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .bootstrap .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .bootstrap .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .bootstrap .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
  }
  .bootstrap .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .bootstrap .modal-lg,
.bootstrap .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .bootstrap .modal-xl {
    max-width: 1140px;
  }
}
.bootstrap .container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .bootstrap .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .bootstrap .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .bootstrap .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .bootstrap .container {
    max-width: 1140px;
  }
}
.bootstrap .container-fluid, .bootstrap .container-xl, .bootstrap .container-lg, .bootstrap .container-md, .bootstrap .container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .bootstrap .container-sm, .bootstrap .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .bootstrap .container-md, .bootstrap .container-sm, .bootstrap .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .bootstrap .container-lg, .bootstrap .container-md, .bootstrap .container-sm, .bootstrap .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .bootstrap .container-xl, .bootstrap .container-lg, .bootstrap .container-md, .bootstrap .container-sm, .bootstrap .container {
    max-width: 1140px;
  }
}
.bootstrap .row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.bootstrap .no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.bootstrap .no-gutters > .col,
.bootstrap .no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}
.bootstrap .col-xl,
.bootstrap .col-xl-auto, .bootstrap .col-xl-12, .bootstrap .col-xl-11, .bootstrap .col-xl-10, .bootstrap .col-xl-9, .bootstrap .col-xl-8, .bootstrap .col-xl-7, .bootstrap .col-xl-6, .bootstrap .col-xl-5, .bootstrap .col-xl-4, .bootstrap .col-xl-3, .bootstrap .col-xl-2, .bootstrap .col-xl-1, .bootstrap .col-lg,
.bootstrap .col-lg-auto, .bootstrap .col-lg-12, .bootstrap .col-lg-11, .bootstrap .col-lg-10, .bootstrap .col-lg-9, .bootstrap .col-lg-8, .bootstrap .col-lg-7, .bootstrap .col-lg-6, .bootstrap .col-lg-5, .bootstrap .col-lg-4, .bootstrap .col-lg-3, .bootstrap .col-lg-2, .bootstrap .col-lg-1, .bootstrap .col-md,
.bootstrap .col-md-auto, .bootstrap .col-md-12, .bootstrap .col-md-11, .bootstrap .col-md-10, .bootstrap .col-md-9, .bootstrap .col-md-8, .bootstrap .col-md-7, .bootstrap .col-md-6, .bootstrap .col-md-5, .bootstrap .col-md-4, .bootstrap .col-md-3, .bootstrap .col-md-2, .bootstrap .col-md-1, .bootstrap .col-sm,
.bootstrap .col-sm-auto, .bootstrap .col-sm-12, .bootstrap .col-sm-11, .bootstrap .col-sm-10, .bootstrap .col-sm-9, .bootstrap .col-sm-8, .bootstrap .col-sm-7, .bootstrap .col-sm-6, .bootstrap .col-sm-5, .bootstrap .col-sm-4, .bootstrap .col-sm-3, .bootstrap .col-sm-2, .bootstrap .col-sm-1, .bootstrap .col,
.bootstrap .col-auto, .bootstrap .col-12, .bootstrap .col-11, .bootstrap .col-10, .bootstrap .col-9, .bootstrap .col-8, .bootstrap .col-7, .bootstrap .col-6, .bootstrap .col-5, .bootstrap .col-4, .bootstrap .col-3, .bootstrap .col-2, .bootstrap .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.bootstrap .col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}
.bootstrap .row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}
.bootstrap .row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}
.bootstrap .row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}
.bootstrap .row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}
.bootstrap .row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}
.bootstrap .row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}
.bootstrap .col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}
.bootstrap .col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}
.bootstrap .col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}
.bootstrap .col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}
.bootstrap .col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}
.bootstrap .col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}
.bootstrap .col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}
.bootstrap .col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}
.bootstrap .col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}
.bootstrap .col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}
.bootstrap .col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}
.bootstrap .col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}
.bootstrap .col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}
.bootstrap .order-first {
  order: -1;
}
.bootstrap .order-last {
  order: 13;
}
.bootstrap .order-0 {
  order: 0;
}
.bootstrap .order-1 {
  order: 1;
}
.bootstrap .order-2 {
  order: 2;
}
.bootstrap .order-3 {
  order: 3;
}
.bootstrap .order-4 {
  order: 4;
}
.bootstrap .order-5 {
  order: 5;
}
.bootstrap .order-6 {
  order: 6;
}
.bootstrap .order-7 {
  order: 7;
}
.bootstrap .order-8 {
  order: 8;
}
.bootstrap .order-9 {
  order: 9;
}
.bootstrap .order-10 {
  order: 10;
}
.bootstrap .order-11 {
  order: 11;
}
.bootstrap .order-12 {
  order: 12;
}
.bootstrap .offset-1 {
  margin-left: 8.3333333333%;
}
.bootstrap .offset-2 {
  margin-left: 16.6666666667%;
}
.bootstrap .offset-3 {
  margin-left: 25%;
}
.bootstrap .offset-4 {
  margin-left: 33.3333333333%;
}
.bootstrap .offset-5 {
  margin-left: 41.6666666667%;
}
.bootstrap .offset-6 {
  margin-left: 50%;
}
.bootstrap .offset-7 {
  margin-left: 58.3333333333%;
}
.bootstrap .offset-8 {
  margin-left: 66.6666666667%;
}
.bootstrap .offset-9 {
  margin-left: 75%;
}
.bootstrap .offset-10 {
  margin-left: 83.3333333333%;
}
.bootstrap .offset-11 {
  margin-left: 91.6666666667%;
}
@media (min-width: 576px) {
  .bootstrap .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .bootstrap .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .bootstrap .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .bootstrap .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .bootstrap .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .bootstrap .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .bootstrap .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .bootstrap .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .bootstrap .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .bootstrap .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .bootstrap .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .bootstrap .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .bootstrap .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .bootstrap .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .bootstrap .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .bootstrap .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .bootstrap .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .bootstrap .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .bootstrap .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .bootstrap .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .bootstrap .order-sm-first {
    order: -1;
  }
  .bootstrap .order-sm-last {
    order: 13;
  }
  .bootstrap .order-sm-0 {
    order: 0;
  }
  .bootstrap .order-sm-1 {
    order: 1;
  }
  .bootstrap .order-sm-2 {
    order: 2;
  }
  .bootstrap .order-sm-3 {
    order: 3;
  }
  .bootstrap .order-sm-4 {
    order: 4;
  }
  .bootstrap .order-sm-5 {
    order: 5;
  }
  .bootstrap .order-sm-6 {
    order: 6;
  }
  .bootstrap .order-sm-7 {
    order: 7;
  }
  .bootstrap .order-sm-8 {
    order: 8;
  }
  .bootstrap .order-sm-9 {
    order: 9;
  }
  .bootstrap .order-sm-10 {
    order: 10;
  }
  .bootstrap .order-sm-11 {
    order: 11;
  }
  .bootstrap .order-sm-12 {
    order: 12;
  }
  .bootstrap .offset-sm-0 {
    margin-left: 0;
  }
  .bootstrap .offset-sm-1 {
    margin-left: 8.3333333333%;
  }
  .bootstrap .offset-sm-2 {
    margin-left: 16.6666666667%;
  }
  .bootstrap .offset-sm-3 {
    margin-left: 25%;
  }
  .bootstrap .offset-sm-4 {
    margin-left: 33.3333333333%;
  }
  .bootstrap .offset-sm-5 {
    margin-left: 41.6666666667%;
  }
  .bootstrap .offset-sm-6 {
    margin-left: 50%;
  }
  .bootstrap .offset-sm-7 {
    margin-left: 58.3333333333%;
  }
  .bootstrap .offset-sm-8 {
    margin-left: 66.6666666667%;
  }
  .bootstrap .offset-sm-9 {
    margin-left: 75%;
  }
  .bootstrap .offset-sm-10 {
    margin-left: 83.3333333333%;
  }
  .bootstrap .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .bootstrap .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .bootstrap .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .bootstrap .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .bootstrap .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .bootstrap .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .bootstrap .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .bootstrap .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .bootstrap .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .bootstrap .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .bootstrap .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .bootstrap .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .bootstrap .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .bootstrap .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .bootstrap .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .bootstrap .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .bootstrap .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .bootstrap .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .bootstrap .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .bootstrap .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .bootstrap .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .bootstrap .order-md-first {
    order: -1;
  }
  .bootstrap .order-md-last {
    order: 13;
  }
  .bootstrap .order-md-0 {
    order: 0;
  }
  .bootstrap .order-md-1 {
    order: 1;
  }
  .bootstrap .order-md-2 {
    order: 2;
  }
  .bootstrap .order-md-3 {
    order: 3;
  }
  .bootstrap .order-md-4 {
    order: 4;
  }
  .bootstrap .order-md-5 {
    order: 5;
  }
  .bootstrap .order-md-6 {
    order: 6;
  }
  .bootstrap .order-md-7 {
    order: 7;
  }
  .bootstrap .order-md-8 {
    order: 8;
  }
  .bootstrap .order-md-9 {
    order: 9;
  }
  .bootstrap .order-md-10 {
    order: 10;
  }
  .bootstrap .order-md-11 {
    order: 11;
  }
  .bootstrap .order-md-12 {
    order: 12;
  }
  .bootstrap .offset-md-0 {
    margin-left: 0;
  }
  .bootstrap .offset-md-1 {
    margin-left: 8.3333333333%;
  }
  .bootstrap .offset-md-2 {
    margin-left: 16.6666666667%;
  }
  .bootstrap .offset-md-3 {
    margin-left: 25%;
  }
  .bootstrap .offset-md-4 {
    margin-left: 33.3333333333%;
  }
  .bootstrap .offset-md-5 {
    margin-left: 41.6666666667%;
  }
  .bootstrap .offset-md-6 {
    margin-left: 50%;
  }
  .bootstrap .offset-md-7 {
    margin-left: 58.3333333333%;
  }
  .bootstrap .offset-md-8 {
    margin-left: 66.6666666667%;
  }
  .bootstrap .offset-md-9 {
    margin-left: 75%;
  }
  .bootstrap .offset-md-10 {
    margin-left: 83.3333333333%;
  }
  .bootstrap .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .bootstrap .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .bootstrap .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .bootstrap .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .bootstrap .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .bootstrap .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .bootstrap .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .bootstrap .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .bootstrap .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .bootstrap .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .bootstrap .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .bootstrap .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .bootstrap .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .bootstrap .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .bootstrap .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .bootstrap .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .bootstrap .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .bootstrap .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .bootstrap .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .bootstrap .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .bootstrap .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .bootstrap .order-lg-first {
    order: -1;
  }
  .bootstrap .order-lg-last {
    order: 13;
  }
  .bootstrap .order-lg-0 {
    order: 0;
  }
  .bootstrap .order-lg-1 {
    order: 1;
  }
  .bootstrap .order-lg-2 {
    order: 2;
  }
  .bootstrap .order-lg-3 {
    order: 3;
  }
  .bootstrap .order-lg-4 {
    order: 4;
  }
  .bootstrap .order-lg-5 {
    order: 5;
  }
  .bootstrap .order-lg-6 {
    order: 6;
  }
  .bootstrap .order-lg-7 {
    order: 7;
  }
  .bootstrap .order-lg-8 {
    order: 8;
  }
  .bootstrap .order-lg-9 {
    order: 9;
  }
  .bootstrap .order-lg-10 {
    order: 10;
  }
  .bootstrap .order-lg-11 {
    order: 11;
  }
  .bootstrap .order-lg-12 {
    order: 12;
  }
  .bootstrap .offset-lg-0 {
    margin-left: 0;
  }
  .bootstrap .offset-lg-1 {
    margin-left: 8.3333333333%;
  }
  .bootstrap .offset-lg-2 {
    margin-left: 16.6666666667%;
  }
  .bootstrap .offset-lg-3 {
    margin-left: 25%;
  }
  .bootstrap .offset-lg-4 {
    margin-left: 33.3333333333%;
  }
  .bootstrap .offset-lg-5 {
    margin-left: 41.6666666667%;
  }
  .bootstrap .offset-lg-6 {
    margin-left: 50%;
  }
  .bootstrap .offset-lg-7 {
    margin-left: 58.3333333333%;
  }
  .bootstrap .offset-lg-8 {
    margin-left: 66.6666666667%;
  }
  .bootstrap .offset-lg-9 {
    margin-left: 75%;
  }
  .bootstrap .offset-lg-10 {
    margin-left: 83.3333333333%;
  }
  .bootstrap .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .bootstrap .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .bootstrap .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .bootstrap .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .bootstrap .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .bootstrap .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .bootstrap .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .bootstrap .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .bootstrap .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .bootstrap .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .bootstrap .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .bootstrap .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .bootstrap .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .bootstrap .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .bootstrap .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .bootstrap .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .bootstrap .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .bootstrap .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .bootstrap .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .bootstrap .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .bootstrap .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .bootstrap .order-xl-first {
    order: -1;
  }
  .bootstrap .order-xl-last {
    order: 13;
  }
  .bootstrap .order-xl-0 {
    order: 0;
  }
  .bootstrap .order-xl-1 {
    order: 1;
  }
  .bootstrap .order-xl-2 {
    order: 2;
  }
  .bootstrap .order-xl-3 {
    order: 3;
  }
  .bootstrap .order-xl-4 {
    order: 4;
  }
  .bootstrap .order-xl-5 {
    order: 5;
  }
  .bootstrap .order-xl-6 {
    order: 6;
  }
  .bootstrap .order-xl-7 {
    order: 7;
  }
  .bootstrap .order-xl-8 {
    order: 8;
  }
  .bootstrap .order-xl-9 {
    order: 9;
  }
  .bootstrap .order-xl-10 {
    order: 10;
  }
  .bootstrap .order-xl-11 {
    order: 11;
  }
  .bootstrap .order-xl-12 {
    order: 12;
  }
  .bootstrap .offset-xl-0 {
    margin-left: 0;
  }
  .bootstrap .offset-xl-1 {
    margin-left: 8.3333333333%;
  }
  .bootstrap .offset-xl-2 {
    margin-left: 16.6666666667%;
  }
  .bootstrap .offset-xl-3 {
    margin-left: 25%;
  }
  .bootstrap .offset-xl-4 {
    margin-left: 33.3333333333%;
  }
  .bootstrap .offset-xl-5 {
    margin-left: 41.6666666667%;
  }
  .bootstrap .offset-xl-6 {
    margin-left: 50%;
  }
  .bootstrap .offset-xl-7 {
    margin-left: 58.3333333333%;
  }
  .bootstrap .offset-xl-8 {
    margin-left: 66.6666666667%;
  }
  .bootstrap .offset-xl-9 {
    margin-left: 75%;
  }
  .bootstrap .offset-xl-10 {
    margin-left: 83.3333333333%;
  }
  .bootstrap .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
.bootstrap .align-baseline {
  vertical-align: baseline !important;
}
.bootstrap .align-top {
  vertical-align: top !important;
}
.bootstrap .align-middle {
  vertical-align: middle !important;
}
.bootstrap .align-bottom {
  vertical-align: bottom !important;
}
.bootstrap .align-text-bottom {
  vertical-align: text-bottom !important;
}
.bootstrap .align-text-top {
  vertical-align: text-top !important;
}
.bootstrap .bg-primary {
  background-color: #e20079 !important;
}
.bootstrap a.bg-primary:hover, .bootstrap a.bg-primary:focus,
.bootstrap button.bg-primary:hover,
.bootstrap button.bg-primary:focus {
  background-color: #af005e !important;
}
.bootstrap .bg-secondary {
  background-color: #6c757d !important;
}
.bootstrap a.bg-secondary:hover, .bootstrap a.bg-secondary:focus,
.bootstrap button.bg-secondary:hover,
.bootstrap button.bg-secondary:focus {
  background-color: #545b62 !important;
}
.bootstrap .bg-success {
  background-color: #28a745 !important;
}
.bootstrap a.bg-success:hover, .bootstrap a.bg-success:focus,
.bootstrap button.bg-success:hover,
.bootstrap button.bg-success:focus {
  background-color: #1e7e34 !important;
}
.bootstrap .bg-info {
  background-color: #17a2b8 !important;
}
.bootstrap a.bg-info:hover, .bootstrap a.bg-info:focus,
.bootstrap button.bg-info:hover,
.bootstrap button.bg-info:focus {
  background-color: #117a8b !important;
}
.bootstrap .bg-warning {
  background-color: #ffc107 !important;
}
.bootstrap a.bg-warning:hover, .bootstrap a.bg-warning:focus,
.bootstrap button.bg-warning:hover,
.bootstrap button.bg-warning:focus {
  background-color: #d39e00 !important;
}
.bootstrap .bg-danger {
  background-color: #dc3545 !important;
}
.bootstrap a.bg-danger:hover, .bootstrap a.bg-danger:focus,
.bootstrap button.bg-danger:hover,
.bootstrap button.bg-danger:focus {
  background-color: #bd2130 !important;
}
.bootstrap .bg-light {
  background-color: #f8f9fa !important;
}
.bootstrap a.bg-light:hover, .bootstrap a.bg-light:focus,
.bootstrap button.bg-light:hover,
.bootstrap button.bg-light:focus {
  background-color: #dae0e5 !important;
}
.bootstrap .bg-dark {
  background-color: #343a40 !important;
}
.bootstrap a.bg-dark:hover, .bootstrap a.bg-dark:focus,
.bootstrap button.bg-dark:hover,
.bootstrap button.bg-dark:focus {
  background-color: #1d2124 !important;
}
.bootstrap .bg-white {
  background-color: #fff !important;
}
.bootstrap .bg-transparent {
  background-color: transparent !important;
}
.bootstrap .border {
  border: 1px solid #dee2e6 !important;
}
.bootstrap .border-top {
  border-top: 1px solid #dee2e6 !important;
}
.bootstrap .border-right {
  border-right: 1px solid #dee2e6 !important;
}
.bootstrap .border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}
.bootstrap .border-left {
  border-left: 1px solid #dee2e6 !important;
}
.bootstrap .border-0 {
  border: 0 !important;
}
.bootstrap .border-top-0 {
  border-top: 0 !important;
}
.bootstrap .border-right-0 {
  border-right: 0 !important;
}
.bootstrap .border-bottom-0 {
  border-bottom: 0 !important;
}
.bootstrap .border-left-0 {
  border-left: 0 !important;
}
.bootstrap .border-primary {
  border-color: #e20079 !important;
}
.bootstrap .border-secondary {
  border-color: #6c757d !important;
}
.bootstrap .border-success {
  border-color: #28a745 !important;
}
.bootstrap .border-info {
  border-color: #17a2b8 !important;
}
.bootstrap .border-warning {
  border-color: #ffc107 !important;
}
.bootstrap .border-danger {
  border-color: #dc3545 !important;
}
.bootstrap .border-light {
  border-color: #f8f9fa !important;
}
.bootstrap .border-dark {
  border-color: #343a40 !important;
}
.bootstrap .border-white {
  border-color: #fff !important;
}
.bootstrap .rounded-sm {
  border-radius: 0.2rem !important;
}
.bootstrap .rounded {
  border-radius: 0.25rem !important;
}
.bootstrap .rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}
.bootstrap .rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
.bootstrap .rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}
.bootstrap .rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}
.bootstrap .rounded-lg {
  border-radius: 0.3rem !important;
}
.bootstrap .rounded-circle {
  border-radius: 50% !important;
}
.bootstrap .rounded-pill {
  border-radius: 50rem !important;
}
.bootstrap .rounded-0 {
  border-radius: 0 !important;
}
.bootstrap .clearfix::after {
  display: block;
  clear: both;
  content: "";
}
.bootstrap .d-none {
  display: none !important;
}
.bootstrap .d-inline {
  display: inline !important;
}
.bootstrap .d-inline-block {
  display: inline-block !important;
}
.bootstrap .d-block {
  display: block !important;
}
.bootstrap .d-table {
  display: table !important;
}
.bootstrap .d-table-row {
  display: table-row !important;
}
.bootstrap .d-table-cell {
  display: table-cell !important;
}
.bootstrap .d-flex {
  display: flex !important;
}
.bootstrap .d-inline-flex {
  display: inline-flex !important;
}
@media (min-width: 576px) {
  .bootstrap .d-sm-none {
    display: none !important;
  }
  .bootstrap .d-sm-inline {
    display: inline !important;
  }
  .bootstrap .d-sm-inline-block {
    display: inline-block !important;
  }
  .bootstrap .d-sm-block {
    display: block !important;
  }
  .bootstrap .d-sm-table {
    display: table !important;
  }
  .bootstrap .d-sm-table-row {
    display: table-row !important;
  }
  .bootstrap .d-sm-table-cell {
    display: table-cell !important;
  }
  .bootstrap .d-sm-flex {
    display: flex !important;
  }
  .bootstrap .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .bootstrap .d-md-none {
    display: none !important;
  }
  .bootstrap .d-md-inline {
    display: inline !important;
  }
  .bootstrap .d-md-inline-block {
    display: inline-block !important;
  }
  .bootstrap .d-md-block {
    display: block !important;
  }
  .bootstrap .d-md-table {
    display: table !important;
  }
  .bootstrap .d-md-table-row {
    display: table-row !important;
  }
  .bootstrap .d-md-table-cell {
    display: table-cell !important;
  }
  .bootstrap .d-md-flex {
    display: flex !important;
  }
  .bootstrap .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .bootstrap .d-lg-none {
    display: none !important;
  }
  .bootstrap .d-lg-inline {
    display: inline !important;
  }
  .bootstrap .d-lg-inline-block {
    display: inline-block !important;
  }
  .bootstrap .d-lg-block {
    display: block !important;
  }
  .bootstrap .d-lg-table {
    display: table !important;
  }
  .bootstrap .d-lg-table-row {
    display: table-row !important;
  }
  .bootstrap .d-lg-table-cell {
    display: table-cell !important;
  }
  .bootstrap .d-lg-flex {
    display: flex !important;
  }
  .bootstrap .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .bootstrap .d-xl-none {
    display: none !important;
  }
  .bootstrap .d-xl-inline {
    display: inline !important;
  }
  .bootstrap .d-xl-inline-block {
    display: inline-block !important;
  }
  .bootstrap .d-xl-block {
    display: block !important;
  }
  .bootstrap .d-xl-table {
    display: table !important;
  }
  .bootstrap .d-xl-table-row {
    display: table-row !important;
  }
  .bootstrap .d-xl-table-cell {
    display: table-cell !important;
  }
  .bootstrap .d-xl-flex {
    display: flex !important;
  }
  .bootstrap .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .bootstrap .d-print-none {
    display: none !important;
  }
  .bootstrap .d-print-inline {
    display: inline !important;
  }
  .bootstrap .d-print-inline-block {
    display: inline-block !important;
  }
  .bootstrap .d-print-block {
    display: block !important;
  }
  .bootstrap .d-print-table {
    display: table !important;
  }
  .bootstrap .d-print-table-row {
    display: table-row !important;
  }
  .bootstrap .d-print-table-cell {
    display: table-cell !important;
  }
  .bootstrap .d-print-flex {
    display: flex !important;
  }
  .bootstrap .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.bootstrap .embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.bootstrap .embed-responsive::before {
  display: block;
  content: "";
}
.bootstrap .embed-responsive .embed-responsive-item,
.bootstrap .embed-responsive iframe,
.bootstrap .embed-responsive embed,
.bootstrap .embed-responsive object,
.bootstrap .embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.bootstrap .embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}
.bootstrap .embed-responsive-16by9::before {
  padding-top: 56.25%;
}
.bootstrap .embed-responsive-4by3::before {
  padding-top: 75%;
}
.bootstrap .embed-responsive-1by1::before {
  padding-top: 100%;
}
.bootstrap .flex-row {
  flex-direction: row !important;
}
.bootstrap .flex-column {
  flex-direction: column !important;
}
.bootstrap .flex-row-reverse {
  flex-direction: row-reverse !important;
}
.bootstrap .flex-column-reverse {
  flex-direction: column-reverse !important;
}
.bootstrap .flex-wrap {
  flex-wrap: wrap !important;
}
.bootstrap .flex-nowrap {
  flex-wrap: nowrap !important;
}
.bootstrap .flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}
.bootstrap .flex-fill {
  flex: 1 1 auto !important;
}
.bootstrap .flex-grow-0 {
  flex-grow: 0 !important;
}
.bootstrap .flex-grow-1 {
  flex-grow: 1 !important;
}
.bootstrap .flex-shrink-0 {
  flex-shrink: 0 !important;
}
.bootstrap .flex-shrink-1 {
  flex-shrink: 1 !important;
}
.bootstrap .justify-content-start {
  justify-content: flex-start !important;
}
.bootstrap .justify-content-end {
  justify-content: flex-end !important;
}
.bootstrap .justify-content-center {
  justify-content: center !important;
}
.bootstrap .justify-content-between {
  justify-content: space-between !important;
}
.bootstrap .justify-content-around {
  justify-content: space-around !important;
}
.bootstrap .align-items-start {
  align-items: flex-start !important;
}
.bootstrap .align-items-end {
  align-items: flex-end !important;
}
.bootstrap .align-items-center {
  align-items: center !important;
}
.bootstrap .align-items-baseline {
  align-items: baseline !important;
}
.bootstrap .align-items-stretch {
  align-items: stretch !important;
}
.bootstrap .align-content-start {
  align-content: flex-start !important;
}
.bootstrap .align-content-end {
  align-content: flex-end !important;
}
.bootstrap .align-content-center {
  align-content: center !important;
}
.bootstrap .align-content-between {
  align-content: space-between !important;
}
.bootstrap .align-content-around {
  align-content: space-around !important;
}
.bootstrap .align-content-stretch {
  align-content: stretch !important;
}
.bootstrap .align-self-auto {
  align-self: auto !important;
}
.bootstrap .align-self-start {
  align-self: flex-start !important;
}
.bootstrap .align-self-end {
  align-self: flex-end !important;
}
.bootstrap .align-self-center {
  align-self: center !important;
}
.bootstrap .align-self-baseline {
  align-self: baseline !important;
}
.bootstrap .align-self-stretch {
  align-self: stretch !important;
}
@media (min-width: 576px) {
  .bootstrap .flex-sm-row {
    flex-direction: row !important;
  }
  .bootstrap .flex-sm-column {
    flex-direction: column !important;
  }
  .bootstrap .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .bootstrap .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .bootstrap .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .bootstrap .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .bootstrap .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .bootstrap .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .bootstrap .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .bootstrap .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .bootstrap .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .bootstrap .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .bootstrap .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .bootstrap .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .bootstrap .justify-content-sm-center {
    justify-content: center !important;
  }
  .bootstrap .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .bootstrap .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .bootstrap .align-items-sm-start {
    align-items: flex-start !important;
  }
  .bootstrap .align-items-sm-end {
    align-items: flex-end !important;
  }
  .bootstrap .align-items-sm-center {
    align-items: center !important;
  }
  .bootstrap .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .bootstrap .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .bootstrap .align-content-sm-start {
    align-content: flex-start !important;
  }
  .bootstrap .align-content-sm-end {
    align-content: flex-end !important;
  }
  .bootstrap .align-content-sm-center {
    align-content: center !important;
  }
  .bootstrap .align-content-sm-between {
    align-content: space-between !important;
  }
  .bootstrap .align-content-sm-around {
    align-content: space-around !important;
  }
  .bootstrap .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .bootstrap .align-self-sm-auto {
    align-self: auto !important;
  }
  .bootstrap .align-self-sm-start {
    align-self: flex-start !important;
  }
  .bootstrap .align-self-sm-end {
    align-self: flex-end !important;
  }
  .bootstrap .align-self-sm-center {
    align-self: center !important;
  }
  .bootstrap .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .bootstrap .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .bootstrap .flex-md-row {
    flex-direction: row !important;
  }
  .bootstrap .flex-md-column {
    flex-direction: column !important;
  }
  .bootstrap .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .bootstrap .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .bootstrap .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .bootstrap .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .bootstrap .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .bootstrap .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .bootstrap .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .bootstrap .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .bootstrap .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .bootstrap .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .bootstrap .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .bootstrap .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .bootstrap .justify-content-md-center {
    justify-content: center !important;
  }
  .bootstrap .justify-content-md-between {
    justify-content: space-between !important;
  }
  .bootstrap .justify-content-md-around {
    justify-content: space-around !important;
  }
  .bootstrap .align-items-md-start {
    align-items: flex-start !important;
  }
  .bootstrap .align-items-md-end {
    align-items: flex-end !important;
  }
  .bootstrap .align-items-md-center {
    align-items: center !important;
  }
  .bootstrap .align-items-md-baseline {
    align-items: baseline !important;
  }
  .bootstrap .align-items-md-stretch {
    align-items: stretch !important;
  }
  .bootstrap .align-content-md-start {
    align-content: flex-start !important;
  }
  .bootstrap .align-content-md-end {
    align-content: flex-end !important;
  }
  .bootstrap .align-content-md-center {
    align-content: center !important;
  }
  .bootstrap .align-content-md-between {
    align-content: space-between !important;
  }
  .bootstrap .align-content-md-around {
    align-content: space-around !important;
  }
  .bootstrap .align-content-md-stretch {
    align-content: stretch !important;
  }
  .bootstrap .align-self-md-auto {
    align-self: auto !important;
  }
  .bootstrap .align-self-md-start {
    align-self: flex-start !important;
  }
  .bootstrap .align-self-md-end {
    align-self: flex-end !important;
  }
  .bootstrap .align-self-md-center {
    align-self: center !important;
  }
  .bootstrap .align-self-md-baseline {
    align-self: baseline !important;
  }
  .bootstrap .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .bootstrap .flex-lg-row {
    flex-direction: row !important;
  }
  .bootstrap .flex-lg-column {
    flex-direction: column !important;
  }
  .bootstrap .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .bootstrap .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .bootstrap .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .bootstrap .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .bootstrap .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .bootstrap .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .bootstrap .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .bootstrap .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .bootstrap .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .bootstrap .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .bootstrap .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .bootstrap .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .bootstrap .justify-content-lg-center {
    justify-content: center !important;
  }
  .bootstrap .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .bootstrap .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .bootstrap .align-items-lg-start {
    align-items: flex-start !important;
  }
  .bootstrap .align-items-lg-end {
    align-items: flex-end !important;
  }
  .bootstrap .align-items-lg-center {
    align-items: center !important;
  }
  .bootstrap .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .bootstrap .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .bootstrap .align-content-lg-start {
    align-content: flex-start !important;
  }
  .bootstrap .align-content-lg-end {
    align-content: flex-end !important;
  }
  .bootstrap .align-content-lg-center {
    align-content: center !important;
  }
  .bootstrap .align-content-lg-between {
    align-content: space-between !important;
  }
  .bootstrap .align-content-lg-around {
    align-content: space-around !important;
  }
  .bootstrap .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .bootstrap .align-self-lg-auto {
    align-self: auto !important;
  }
  .bootstrap .align-self-lg-start {
    align-self: flex-start !important;
  }
  .bootstrap .align-self-lg-end {
    align-self: flex-end !important;
  }
  .bootstrap .align-self-lg-center {
    align-self: center !important;
  }
  .bootstrap .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .bootstrap .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .bootstrap .flex-xl-row {
    flex-direction: row !important;
  }
  .bootstrap .flex-xl-column {
    flex-direction: column !important;
  }
  .bootstrap .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .bootstrap .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .bootstrap .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .bootstrap .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .bootstrap .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .bootstrap .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .bootstrap .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .bootstrap .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .bootstrap .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .bootstrap .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .bootstrap .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .bootstrap .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .bootstrap .justify-content-xl-center {
    justify-content: center !important;
  }
  .bootstrap .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .bootstrap .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .bootstrap .align-items-xl-start {
    align-items: flex-start !important;
  }
  .bootstrap .align-items-xl-end {
    align-items: flex-end !important;
  }
  .bootstrap .align-items-xl-center {
    align-items: center !important;
  }
  .bootstrap .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .bootstrap .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .bootstrap .align-content-xl-start {
    align-content: flex-start !important;
  }
  .bootstrap .align-content-xl-end {
    align-content: flex-end !important;
  }
  .bootstrap .align-content-xl-center {
    align-content: center !important;
  }
  .bootstrap .align-content-xl-between {
    align-content: space-between !important;
  }
  .bootstrap .align-content-xl-around {
    align-content: space-around !important;
  }
  .bootstrap .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .bootstrap .align-self-xl-auto {
    align-self: auto !important;
  }
  .bootstrap .align-self-xl-start {
    align-self: flex-start !important;
  }
  .bootstrap .align-self-xl-end {
    align-self: flex-end !important;
  }
  .bootstrap .align-self-xl-center {
    align-self: center !important;
  }
  .bootstrap .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .bootstrap .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.bootstrap .float-left {
  float: left !important;
}
.bootstrap .float-right {
  float: right !important;
}
.bootstrap .float-none {
  float: none !important;
}
@media (min-width: 576px) {
  .bootstrap .float-sm-left {
    float: left !important;
  }
  .bootstrap .float-sm-right {
    float: right !important;
  }
  .bootstrap .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .bootstrap .float-md-left {
    float: left !important;
  }
  .bootstrap .float-md-right {
    float: right !important;
  }
  .bootstrap .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .bootstrap .float-lg-left {
    float: left !important;
  }
  .bootstrap .float-lg-right {
    float: right !important;
  }
  .bootstrap .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .bootstrap .float-xl-left {
    float: left !important;
  }
  .bootstrap .float-xl-right {
    float: right !important;
  }
  .bootstrap .float-xl-none {
    float: none !important;
  }
}
.bootstrap .overflow-auto {
  overflow: auto !important;
}
.bootstrap .overflow-hidden {
  overflow: hidden !important;
}
.bootstrap .position-static {
  position: static !important;
}
.bootstrap .position-relative {
  position: relative !important;
}
.bootstrap .position-absolute {
  position: absolute !important;
}
.bootstrap .position-fixed {
  position: fixed !important;
}
.bootstrap .position-sticky {
  position: sticky !important;
}
.bootstrap .fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}
.bootstrap .fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}
@supports (position: sticky) {
  .bootstrap .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.bootstrap .sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
.bootstrap .sr-only-focusable:active, .bootstrap .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}
.bootstrap .shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.bootstrap .shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.bootstrap .shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}
.bootstrap .shadow-none {
  box-shadow: none !important;
}
.bootstrap .w-25 {
  width: 25% !important;
}
.bootstrap .w-50 {
  width: 50% !important;
}
.bootstrap .w-75 {
  width: 75% !important;
}
.bootstrap .w-100 {
  width: 100% !important;
}
.bootstrap .w-auto {
  width: auto !important;
}
.bootstrap .h-25 {
  height: 25% !important;
}
.bootstrap .h-50 {
  height: 50% !important;
}
.bootstrap .h-75 {
  height: 75% !important;
}
.bootstrap .h-100 {
  height: 100% !important;
}
.bootstrap .h-auto {
  height: auto !important;
}
.bootstrap .mw-100 {
  max-width: 100% !important;
}
.bootstrap .mh-100 {
  max-height: 100% !important;
}
.bootstrap .min-vw-100 {
  min-width: 100vw !important;
}
.bootstrap .min-vh-100 {
  min-height: 100vh !important;
}
.bootstrap .vw-100 {
  width: 100vw !important;
}
.bootstrap .vh-100 {
  height: 100vh !important;
}
.bootstrap .stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}
.bootstrap .m-0 {
  margin: 0 !important;
}
.bootstrap .mt-0,
.bootstrap .my-0 {
  margin-top: 0 !important;
}
.bootstrap .mr-0,
.bootstrap .mx-0 {
  margin-right: 0 !important;
}
.bootstrap .mb-0,
.bootstrap .my-0 {
  margin-bottom: 0 !important;
}
.bootstrap .ml-0,
.bootstrap .mx-0 {
  margin-left: 0 !important;
}
.bootstrap .m-1 {
  margin: 0.25rem !important;
}
.bootstrap .mt-1,
.bootstrap .my-1 {
  margin-top: 0.25rem !important;
}
.bootstrap .mr-1,
.bootstrap .mx-1 {
  margin-right: 0.25rem !important;
}
.bootstrap .mb-1,
.bootstrap .my-1 {
  margin-bottom: 0.25rem !important;
}
.bootstrap .ml-1,
.bootstrap .mx-1 {
  margin-left: 0.25rem !important;
}
.bootstrap .m-2 {
  margin: 0.5rem !important;
}
.bootstrap .mt-2,
.bootstrap .my-2 {
  margin-top: 0.5rem !important;
}
.bootstrap .mr-2,
.bootstrap .mx-2 {
  margin-right: 0.5rem !important;
}
.bootstrap .mb-2,
.bootstrap .my-2 {
  margin-bottom: 0.5rem !important;
}
.bootstrap .ml-2,
.bootstrap .mx-2 {
  margin-left: 0.5rem !important;
}
.bootstrap .m-3 {
  margin: 1rem !important;
}
.bootstrap .mt-3,
.bootstrap .my-3 {
  margin-top: 1rem !important;
}
.bootstrap .mr-3,
.bootstrap .mx-3 {
  margin-right: 1rem !important;
}
.bootstrap .mb-3,
.bootstrap .my-3 {
  margin-bottom: 1rem !important;
}
.bootstrap .ml-3,
.bootstrap .mx-3 {
  margin-left: 1rem !important;
}
.bootstrap .m-4 {
  margin: 1.5rem !important;
}
.bootstrap .mt-4,
.bootstrap .my-4 {
  margin-top: 1.5rem !important;
}
.bootstrap .mr-4,
.bootstrap .mx-4 {
  margin-right: 1.5rem !important;
}
.bootstrap .mb-4,
.bootstrap .my-4 {
  margin-bottom: 1.5rem !important;
}
.bootstrap .ml-4,
.bootstrap .mx-4 {
  margin-left: 1.5rem !important;
}
.bootstrap .m-5 {
  margin: 3rem !important;
}
.bootstrap .mt-5,
.bootstrap .my-5 {
  margin-top: 3rem !important;
}
.bootstrap .mr-5,
.bootstrap .mx-5 {
  margin-right: 3rem !important;
}
.bootstrap .mb-5,
.bootstrap .my-5 {
  margin-bottom: 3rem !important;
}
.bootstrap .ml-5,
.bootstrap .mx-5 {
  margin-left: 3rem !important;
}
.bootstrap .p-0 {
  padding: 0 !important;
}
.bootstrap .pt-0,
.bootstrap .py-0 {
  padding-top: 0 !important;
}
.bootstrap .pr-0,
.bootstrap .px-0 {
  padding-right: 0 !important;
}
.bootstrap .pb-0,
.bootstrap .py-0 {
  padding-bottom: 0 !important;
}
.bootstrap .pl-0,
.bootstrap .px-0 {
  padding-left: 0 !important;
}
.bootstrap .p-1 {
  padding: 0.25rem !important;
}
.bootstrap .pt-1,
.bootstrap .py-1 {
  padding-top: 0.25rem !important;
}
.bootstrap .pr-1,
.bootstrap .px-1 {
  padding-right: 0.25rem !important;
}
.bootstrap .pb-1,
.bootstrap .py-1 {
  padding-bottom: 0.25rem !important;
}
.bootstrap .pl-1,
.bootstrap .px-1 {
  padding-left: 0.25rem !important;
}
.bootstrap .p-2 {
  padding: 0.5rem !important;
}
.bootstrap .pt-2,
.bootstrap .py-2 {
  padding-top: 0.5rem !important;
}
.bootstrap .pr-2,
.bootstrap .px-2 {
  padding-right: 0.5rem !important;
}
.bootstrap .pb-2,
.bootstrap .py-2 {
  padding-bottom: 0.5rem !important;
}
.bootstrap .pl-2,
.bootstrap .px-2 {
  padding-left: 0.5rem !important;
}
.bootstrap .p-3 {
  padding: 1rem !important;
}
.bootstrap .pt-3,
.bootstrap .py-3 {
  padding-top: 1rem !important;
}
.bootstrap .pr-3,
.bootstrap .px-3 {
  padding-right: 1rem !important;
}
.bootstrap .pb-3,
.bootstrap .py-3 {
  padding-bottom: 1rem !important;
}
.bootstrap .pl-3,
.bootstrap .px-3 {
  padding-left: 1rem !important;
}
.bootstrap .p-4 {
  padding: 1.5rem !important;
}
.bootstrap .pt-4,
.bootstrap .py-4 {
  padding-top: 1.5rem !important;
}
.bootstrap .pr-4,
.bootstrap .px-4 {
  padding-right: 1.5rem !important;
}
.bootstrap .pb-4,
.bootstrap .py-4 {
  padding-bottom: 1.5rem !important;
}
.bootstrap .pl-4,
.bootstrap .px-4 {
  padding-left: 1.5rem !important;
}
.bootstrap .p-5 {
  padding: 3rem !important;
}
.bootstrap .pt-5,
.bootstrap .py-5 {
  padding-top: 3rem !important;
}
.bootstrap .pr-5,
.bootstrap .px-5 {
  padding-right: 3rem !important;
}
.bootstrap .pb-5,
.bootstrap .py-5 {
  padding-bottom: 3rem !important;
}
.bootstrap .pl-5,
.bootstrap .px-5 {
  padding-left: 3rem !important;
}
.bootstrap .m-n1 {
  margin: -0.25rem !important;
}
.bootstrap .mt-n1,
.bootstrap .my-n1 {
  margin-top: -0.25rem !important;
}
.bootstrap .mr-n1,
.bootstrap .mx-n1 {
  margin-right: -0.25rem !important;
}
.bootstrap .mb-n1,
.bootstrap .my-n1 {
  margin-bottom: -0.25rem !important;
}
.bootstrap .ml-n1,
.bootstrap .mx-n1 {
  margin-left: -0.25rem !important;
}
.bootstrap .m-n2 {
  margin: -0.5rem !important;
}
.bootstrap .mt-n2,
.bootstrap .my-n2 {
  margin-top: -0.5rem !important;
}
.bootstrap .mr-n2,
.bootstrap .mx-n2 {
  margin-right: -0.5rem !important;
}
.bootstrap .mb-n2,
.bootstrap .my-n2 {
  margin-bottom: -0.5rem !important;
}
.bootstrap .ml-n2,
.bootstrap .mx-n2 {
  margin-left: -0.5rem !important;
}
.bootstrap .m-n3 {
  margin: -1rem !important;
}
.bootstrap .mt-n3,
.bootstrap .my-n3 {
  margin-top: -1rem !important;
}
.bootstrap .mr-n3,
.bootstrap .mx-n3 {
  margin-right: -1rem !important;
}
.bootstrap .mb-n3,
.bootstrap .my-n3 {
  margin-bottom: -1rem !important;
}
.bootstrap .ml-n3,
.bootstrap .mx-n3 {
  margin-left: -1rem !important;
}
.bootstrap .m-n4 {
  margin: -1.5rem !important;
}
.bootstrap .mt-n4,
.bootstrap .my-n4 {
  margin-top: -1.5rem !important;
}
.bootstrap .mr-n4,
.bootstrap .mx-n4 {
  margin-right: -1.5rem !important;
}
.bootstrap .mb-n4,
.bootstrap .my-n4 {
  margin-bottom: -1.5rem !important;
}
.bootstrap .ml-n4,
.bootstrap .mx-n4 {
  margin-left: -1.5rem !important;
}
.bootstrap .m-n5 {
  margin: -3rem !important;
}
.bootstrap .mt-n5,
.bootstrap .my-n5 {
  margin-top: -3rem !important;
}
.bootstrap .mr-n5,
.bootstrap .mx-n5 {
  margin-right: -3rem !important;
}
.bootstrap .mb-n5,
.bootstrap .my-n5 {
  margin-bottom: -3rem !important;
}
.bootstrap .ml-n5,
.bootstrap .mx-n5 {
  margin-left: -3rem !important;
}
.bootstrap .m-auto {
  margin: auto !important;
}
.bootstrap .mt-auto,
.bootstrap .my-auto {
  margin-top: auto !important;
}
.bootstrap .mr-auto,
.bootstrap .mx-auto {
  margin-right: auto !important;
}
.bootstrap .mb-auto,
.bootstrap .my-auto {
  margin-bottom: auto !important;
}
.bootstrap .ml-auto,
.bootstrap .mx-auto {
  margin-left: auto !important;
}
@media (min-width: 576px) {
  .bootstrap .m-sm-0 {
    margin: 0 !important;
  }
  .bootstrap .mt-sm-0,
.bootstrap .my-sm-0 {
    margin-top: 0 !important;
  }
  .bootstrap .mr-sm-0,
.bootstrap .mx-sm-0 {
    margin-right: 0 !important;
  }
  .bootstrap .mb-sm-0,
.bootstrap .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .bootstrap .ml-sm-0,
.bootstrap .mx-sm-0 {
    margin-left: 0 !important;
  }
  .bootstrap .m-sm-1 {
    margin: 0.25rem !important;
  }
  .bootstrap .mt-sm-1,
.bootstrap .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .bootstrap .mr-sm-1,
.bootstrap .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .bootstrap .mb-sm-1,
.bootstrap .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .bootstrap .ml-sm-1,
.bootstrap .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .bootstrap .m-sm-2 {
    margin: 0.5rem !important;
  }
  .bootstrap .mt-sm-2,
.bootstrap .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .bootstrap .mr-sm-2,
.bootstrap .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .bootstrap .mb-sm-2,
.bootstrap .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .bootstrap .ml-sm-2,
.bootstrap .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .bootstrap .m-sm-3 {
    margin: 1rem !important;
  }
  .bootstrap .mt-sm-3,
.bootstrap .my-sm-3 {
    margin-top: 1rem !important;
  }
  .bootstrap .mr-sm-3,
.bootstrap .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .bootstrap .mb-sm-3,
.bootstrap .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .bootstrap .ml-sm-3,
.bootstrap .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .bootstrap .m-sm-4 {
    margin: 1.5rem !important;
  }
  .bootstrap .mt-sm-4,
.bootstrap .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .bootstrap .mr-sm-4,
.bootstrap .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .bootstrap .mb-sm-4,
.bootstrap .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .bootstrap .ml-sm-4,
.bootstrap .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .bootstrap .m-sm-5 {
    margin: 3rem !important;
  }
  .bootstrap .mt-sm-5,
.bootstrap .my-sm-5 {
    margin-top: 3rem !important;
  }
  .bootstrap .mr-sm-5,
.bootstrap .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .bootstrap .mb-sm-5,
.bootstrap .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .bootstrap .ml-sm-5,
.bootstrap .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .bootstrap .p-sm-0 {
    padding: 0 !important;
  }
  .bootstrap .pt-sm-0,
.bootstrap .py-sm-0 {
    padding-top: 0 !important;
  }
  .bootstrap .pr-sm-0,
.bootstrap .px-sm-0 {
    padding-right: 0 !important;
  }
  .bootstrap .pb-sm-0,
.bootstrap .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .bootstrap .pl-sm-0,
.bootstrap .px-sm-0 {
    padding-left: 0 !important;
  }
  .bootstrap .p-sm-1 {
    padding: 0.25rem !important;
  }
  .bootstrap .pt-sm-1,
.bootstrap .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .bootstrap .pr-sm-1,
.bootstrap .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .bootstrap .pb-sm-1,
.bootstrap .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .bootstrap .pl-sm-1,
.bootstrap .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .bootstrap .p-sm-2 {
    padding: 0.5rem !important;
  }
  .bootstrap .pt-sm-2,
.bootstrap .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .bootstrap .pr-sm-2,
.bootstrap .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .bootstrap .pb-sm-2,
.bootstrap .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .bootstrap .pl-sm-2,
.bootstrap .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .bootstrap .p-sm-3 {
    padding: 1rem !important;
  }
  .bootstrap .pt-sm-3,
.bootstrap .py-sm-3 {
    padding-top: 1rem !important;
  }
  .bootstrap .pr-sm-3,
.bootstrap .px-sm-3 {
    padding-right: 1rem !important;
  }
  .bootstrap .pb-sm-3,
.bootstrap .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .bootstrap .pl-sm-3,
.bootstrap .px-sm-3 {
    padding-left: 1rem !important;
  }
  .bootstrap .p-sm-4 {
    padding: 1.5rem !important;
  }
  .bootstrap .pt-sm-4,
.bootstrap .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .bootstrap .pr-sm-4,
.bootstrap .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .bootstrap .pb-sm-4,
.bootstrap .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .bootstrap .pl-sm-4,
.bootstrap .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .bootstrap .p-sm-5 {
    padding: 3rem !important;
  }
  .bootstrap .pt-sm-5,
.bootstrap .py-sm-5 {
    padding-top: 3rem !important;
  }
  .bootstrap .pr-sm-5,
.bootstrap .px-sm-5 {
    padding-right: 3rem !important;
  }
  .bootstrap .pb-sm-5,
.bootstrap .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .bootstrap .pl-sm-5,
.bootstrap .px-sm-5 {
    padding-left: 3rem !important;
  }
  .bootstrap .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .bootstrap .mt-sm-n1,
.bootstrap .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .bootstrap .mr-sm-n1,
.bootstrap .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .bootstrap .mb-sm-n1,
.bootstrap .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .bootstrap .ml-sm-n1,
.bootstrap .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .bootstrap .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .bootstrap .mt-sm-n2,
.bootstrap .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .bootstrap .mr-sm-n2,
.bootstrap .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .bootstrap .mb-sm-n2,
.bootstrap .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .bootstrap .ml-sm-n2,
.bootstrap .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .bootstrap .m-sm-n3 {
    margin: -1rem !important;
  }
  .bootstrap .mt-sm-n3,
.bootstrap .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .bootstrap .mr-sm-n3,
.bootstrap .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .bootstrap .mb-sm-n3,
.bootstrap .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .bootstrap .ml-sm-n3,
.bootstrap .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .bootstrap .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .bootstrap .mt-sm-n4,
.bootstrap .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .bootstrap .mr-sm-n4,
.bootstrap .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .bootstrap .mb-sm-n4,
.bootstrap .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .bootstrap .ml-sm-n4,
.bootstrap .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .bootstrap .m-sm-n5 {
    margin: -3rem !important;
  }
  .bootstrap .mt-sm-n5,
.bootstrap .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .bootstrap .mr-sm-n5,
.bootstrap .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .bootstrap .mb-sm-n5,
.bootstrap .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .bootstrap .ml-sm-n5,
.bootstrap .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .bootstrap .m-sm-auto {
    margin: auto !important;
  }
  .bootstrap .mt-sm-auto,
.bootstrap .my-sm-auto {
    margin-top: auto !important;
  }
  .bootstrap .mr-sm-auto,
.bootstrap .mx-sm-auto {
    margin-right: auto !important;
  }
  .bootstrap .mb-sm-auto,
.bootstrap .my-sm-auto {
    margin-bottom: auto !important;
  }
  .bootstrap .ml-sm-auto,
.bootstrap .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .bootstrap .m-md-0 {
    margin: 0 !important;
  }
  .bootstrap .mt-md-0,
.bootstrap .my-md-0 {
    margin-top: 0 !important;
  }
  .bootstrap .mr-md-0,
.bootstrap .mx-md-0 {
    margin-right: 0 !important;
  }
  .bootstrap .mb-md-0,
.bootstrap .my-md-0 {
    margin-bottom: 0 !important;
  }
  .bootstrap .ml-md-0,
.bootstrap .mx-md-0 {
    margin-left: 0 !important;
  }
  .bootstrap .m-md-1 {
    margin: 0.25rem !important;
  }
  .bootstrap .mt-md-1,
.bootstrap .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .bootstrap .mr-md-1,
.bootstrap .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .bootstrap .mb-md-1,
.bootstrap .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .bootstrap .ml-md-1,
.bootstrap .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .bootstrap .m-md-2 {
    margin: 0.5rem !important;
  }
  .bootstrap .mt-md-2,
.bootstrap .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .bootstrap .mr-md-2,
.bootstrap .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .bootstrap .mb-md-2,
.bootstrap .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .bootstrap .ml-md-2,
.bootstrap .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .bootstrap .m-md-3 {
    margin: 1rem !important;
  }
  .bootstrap .mt-md-3,
.bootstrap .my-md-3 {
    margin-top: 1rem !important;
  }
  .bootstrap .mr-md-3,
.bootstrap .mx-md-3 {
    margin-right: 1rem !important;
  }
  .bootstrap .mb-md-3,
.bootstrap .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .bootstrap .ml-md-3,
.bootstrap .mx-md-3 {
    margin-left: 1rem !important;
  }
  .bootstrap .m-md-4 {
    margin: 1.5rem !important;
  }
  .bootstrap .mt-md-4,
.bootstrap .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .bootstrap .mr-md-4,
.bootstrap .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .bootstrap .mb-md-4,
.bootstrap .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .bootstrap .ml-md-4,
.bootstrap .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .bootstrap .m-md-5 {
    margin: 3rem !important;
  }
  .bootstrap .mt-md-5,
.bootstrap .my-md-5 {
    margin-top: 3rem !important;
  }
  .bootstrap .mr-md-5,
.bootstrap .mx-md-5 {
    margin-right: 3rem !important;
  }
  .bootstrap .mb-md-5,
.bootstrap .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .bootstrap .ml-md-5,
.bootstrap .mx-md-5 {
    margin-left: 3rem !important;
  }
  .bootstrap .p-md-0 {
    padding: 0 !important;
  }
  .bootstrap .pt-md-0,
.bootstrap .py-md-0 {
    padding-top: 0 !important;
  }
  .bootstrap .pr-md-0,
.bootstrap .px-md-0 {
    padding-right: 0 !important;
  }
  .bootstrap .pb-md-0,
.bootstrap .py-md-0 {
    padding-bottom: 0 !important;
  }
  .bootstrap .pl-md-0,
.bootstrap .px-md-0 {
    padding-left: 0 !important;
  }
  .bootstrap .p-md-1 {
    padding: 0.25rem !important;
  }
  .bootstrap .pt-md-1,
.bootstrap .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .bootstrap .pr-md-1,
.bootstrap .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .bootstrap .pb-md-1,
.bootstrap .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .bootstrap .pl-md-1,
.bootstrap .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .bootstrap .p-md-2 {
    padding: 0.5rem !important;
  }
  .bootstrap .pt-md-2,
.bootstrap .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .bootstrap .pr-md-2,
.bootstrap .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .bootstrap .pb-md-2,
.bootstrap .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .bootstrap .pl-md-2,
.bootstrap .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .bootstrap .p-md-3 {
    padding: 1rem !important;
  }
  .bootstrap .pt-md-3,
.bootstrap .py-md-3 {
    padding-top: 1rem !important;
  }
  .bootstrap .pr-md-3,
.bootstrap .px-md-3 {
    padding-right: 1rem !important;
  }
  .bootstrap .pb-md-3,
.bootstrap .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .bootstrap .pl-md-3,
.bootstrap .px-md-3 {
    padding-left: 1rem !important;
  }
  .bootstrap .p-md-4 {
    padding: 1.5rem !important;
  }
  .bootstrap .pt-md-4,
.bootstrap .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .bootstrap .pr-md-4,
.bootstrap .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .bootstrap .pb-md-4,
.bootstrap .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .bootstrap .pl-md-4,
.bootstrap .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .bootstrap .p-md-5 {
    padding: 3rem !important;
  }
  .bootstrap .pt-md-5,
.bootstrap .py-md-5 {
    padding-top: 3rem !important;
  }
  .bootstrap .pr-md-5,
.bootstrap .px-md-5 {
    padding-right: 3rem !important;
  }
  .bootstrap .pb-md-5,
.bootstrap .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .bootstrap .pl-md-5,
.bootstrap .px-md-5 {
    padding-left: 3rem !important;
  }
  .bootstrap .m-md-n1 {
    margin: -0.25rem !important;
  }
  .bootstrap .mt-md-n1,
.bootstrap .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .bootstrap .mr-md-n1,
.bootstrap .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .bootstrap .mb-md-n1,
.bootstrap .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .bootstrap .ml-md-n1,
.bootstrap .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .bootstrap .m-md-n2 {
    margin: -0.5rem !important;
  }
  .bootstrap .mt-md-n2,
.bootstrap .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .bootstrap .mr-md-n2,
.bootstrap .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .bootstrap .mb-md-n2,
.bootstrap .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .bootstrap .ml-md-n2,
.bootstrap .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .bootstrap .m-md-n3 {
    margin: -1rem !important;
  }
  .bootstrap .mt-md-n3,
.bootstrap .my-md-n3 {
    margin-top: -1rem !important;
  }
  .bootstrap .mr-md-n3,
.bootstrap .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .bootstrap .mb-md-n3,
.bootstrap .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .bootstrap .ml-md-n3,
.bootstrap .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .bootstrap .m-md-n4 {
    margin: -1.5rem !important;
  }
  .bootstrap .mt-md-n4,
.bootstrap .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .bootstrap .mr-md-n4,
.bootstrap .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .bootstrap .mb-md-n4,
.bootstrap .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .bootstrap .ml-md-n4,
.bootstrap .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .bootstrap .m-md-n5 {
    margin: -3rem !important;
  }
  .bootstrap .mt-md-n5,
.bootstrap .my-md-n5 {
    margin-top: -3rem !important;
  }
  .bootstrap .mr-md-n5,
.bootstrap .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .bootstrap .mb-md-n5,
.bootstrap .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .bootstrap .ml-md-n5,
.bootstrap .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .bootstrap .m-md-auto {
    margin: auto !important;
  }
  .bootstrap .mt-md-auto,
.bootstrap .my-md-auto {
    margin-top: auto !important;
  }
  .bootstrap .mr-md-auto,
.bootstrap .mx-md-auto {
    margin-right: auto !important;
  }
  .bootstrap .mb-md-auto,
.bootstrap .my-md-auto {
    margin-bottom: auto !important;
  }
  .bootstrap .ml-md-auto,
.bootstrap .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .bootstrap .m-lg-0 {
    margin: 0 !important;
  }
  .bootstrap .mt-lg-0,
.bootstrap .my-lg-0 {
    margin-top: 0 !important;
  }
  .bootstrap .mr-lg-0,
.bootstrap .mx-lg-0 {
    margin-right: 0 !important;
  }
  .bootstrap .mb-lg-0,
.bootstrap .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .bootstrap .ml-lg-0,
.bootstrap .mx-lg-0 {
    margin-left: 0 !important;
  }
  .bootstrap .m-lg-1 {
    margin: 0.25rem !important;
  }
  .bootstrap .mt-lg-1,
.bootstrap .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .bootstrap .mr-lg-1,
.bootstrap .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .bootstrap .mb-lg-1,
.bootstrap .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .bootstrap .ml-lg-1,
.bootstrap .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .bootstrap .m-lg-2 {
    margin: 0.5rem !important;
  }
  .bootstrap .mt-lg-2,
.bootstrap .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .bootstrap .mr-lg-2,
.bootstrap .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .bootstrap .mb-lg-2,
.bootstrap .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .bootstrap .ml-lg-2,
.bootstrap .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .bootstrap .m-lg-3 {
    margin: 1rem !important;
  }
  .bootstrap .mt-lg-3,
.bootstrap .my-lg-3 {
    margin-top: 1rem !important;
  }
  .bootstrap .mr-lg-3,
.bootstrap .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .bootstrap .mb-lg-3,
.bootstrap .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .bootstrap .ml-lg-3,
.bootstrap .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .bootstrap .m-lg-4 {
    margin: 1.5rem !important;
  }
  .bootstrap .mt-lg-4,
.bootstrap .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .bootstrap .mr-lg-4,
.bootstrap .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .bootstrap .mb-lg-4,
.bootstrap .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .bootstrap .ml-lg-4,
.bootstrap .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .bootstrap .m-lg-5 {
    margin: 3rem !important;
  }
  .bootstrap .mt-lg-5,
.bootstrap .my-lg-5 {
    margin-top: 3rem !important;
  }
  .bootstrap .mr-lg-5,
.bootstrap .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .bootstrap .mb-lg-5,
.bootstrap .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .bootstrap .ml-lg-5,
.bootstrap .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .bootstrap .p-lg-0 {
    padding: 0 !important;
  }
  .bootstrap .pt-lg-0,
.bootstrap .py-lg-0 {
    padding-top: 0 !important;
  }
  .bootstrap .pr-lg-0,
.bootstrap .px-lg-0 {
    padding-right: 0 !important;
  }
  .bootstrap .pb-lg-0,
.bootstrap .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .bootstrap .pl-lg-0,
.bootstrap .px-lg-0 {
    padding-left: 0 !important;
  }
  .bootstrap .p-lg-1 {
    padding: 0.25rem !important;
  }
  .bootstrap .pt-lg-1,
.bootstrap .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .bootstrap .pr-lg-1,
.bootstrap .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .bootstrap .pb-lg-1,
.bootstrap .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .bootstrap .pl-lg-1,
.bootstrap .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .bootstrap .p-lg-2 {
    padding: 0.5rem !important;
  }
  .bootstrap .pt-lg-2,
.bootstrap .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .bootstrap .pr-lg-2,
.bootstrap .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .bootstrap .pb-lg-2,
.bootstrap .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .bootstrap .pl-lg-2,
.bootstrap .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .bootstrap .p-lg-3 {
    padding: 1rem !important;
  }
  .bootstrap .pt-lg-3,
.bootstrap .py-lg-3 {
    padding-top: 1rem !important;
  }
  .bootstrap .pr-lg-3,
.bootstrap .px-lg-3 {
    padding-right: 1rem !important;
  }
  .bootstrap .pb-lg-3,
.bootstrap .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .bootstrap .pl-lg-3,
.bootstrap .px-lg-3 {
    padding-left: 1rem !important;
  }
  .bootstrap .p-lg-4 {
    padding: 1.5rem !important;
  }
  .bootstrap .pt-lg-4,
.bootstrap .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .bootstrap .pr-lg-4,
.bootstrap .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .bootstrap .pb-lg-4,
.bootstrap .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .bootstrap .pl-lg-4,
.bootstrap .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .bootstrap .p-lg-5 {
    padding: 3rem !important;
  }
  .bootstrap .pt-lg-5,
.bootstrap .py-lg-5 {
    padding-top: 3rem !important;
  }
  .bootstrap .pr-lg-5,
.bootstrap .px-lg-5 {
    padding-right: 3rem !important;
  }
  .bootstrap .pb-lg-5,
.bootstrap .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .bootstrap .pl-lg-5,
.bootstrap .px-lg-5 {
    padding-left: 3rem !important;
  }
  .bootstrap .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .bootstrap .mt-lg-n1,
.bootstrap .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .bootstrap .mr-lg-n1,
.bootstrap .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .bootstrap .mb-lg-n1,
.bootstrap .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .bootstrap .ml-lg-n1,
.bootstrap .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .bootstrap .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .bootstrap .mt-lg-n2,
.bootstrap .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .bootstrap .mr-lg-n2,
.bootstrap .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .bootstrap .mb-lg-n2,
.bootstrap .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .bootstrap .ml-lg-n2,
.bootstrap .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .bootstrap .m-lg-n3 {
    margin: -1rem !important;
  }
  .bootstrap .mt-lg-n3,
.bootstrap .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .bootstrap .mr-lg-n3,
.bootstrap .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .bootstrap .mb-lg-n3,
.bootstrap .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .bootstrap .ml-lg-n3,
.bootstrap .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .bootstrap .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .bootstrap .mt-lg-n4,
.bootstrap .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .bootstrap .mr-lg-n4,
.bootstrap .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .bootstrap .mb-lg-n4,
.bootstrap .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .bootstrap .ml-lg-n4,
.bootstrap .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .bootstrap .m-lg-n5 {
    margin: -3rem !important;
  }
  .bootstrap .mt-lg-n5,
.bootstrap .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .bootstrap .mr-lg-n5,
.bootstrap .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .bootstrap .mb-lg-n5,
.bootstrap .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .bootstrap .ml-lg-n5,
.bootstrap .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .bootstrap .m-lg-auto {
    margin: auto !important;
  }
  .bootstrap .mt-lg-auto,
.bootstrap .my-lg-auto {
    margin-top: auto !important;
  }
  .bootstrap .mr-lg-auto,
.bootstrap .mx-lg-auto {
    margin-right: auto !important;
  }
  .bootstrap .mb-lg-auto,
.bootstrap .my-lg-auto {
    margin-bottom: auto !important;
  }
  .bootstrap .ml-lg-auto,
.bootstrap .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .bootstrap .m-xl-0 {
    margin: 0 !important;
  }
  .bootstrap .mt-xl-0,
.bootstrap .my-xl-0 {
    margin-top: 0 !important;
  }
  .bootstrap .mr-xl-0,
.bootstrap .mx-xl-0 {
    margin-right: 0 !important;
  }
  .bootstrap .mb-xl-0,
.bootstrap .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .bootstrap .ml-xl-0,
.bootstrap .mx-xl-0 {
    margin-left: 0 !important;
  }
  .bootstrap .m-xl-1 {
    margin: 0.25rem !important;
  }
  .bootstrap .mt-xl-1,
.bootstrap .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .bootstrap .mr-xl-1,
.bootstrap .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .bootstrap .mb-xl-1,
.bootstrap .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .bootstrap .ml-xl-1,
.bootstrap .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .bootstrap .m-xl-2 {
    margin: 0.5rem !important;
  }
  .bootstrap .mt-xl-2,
.bootstrap .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .bootstrap .mr-xl-2,
.bootstrap .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .bootstrap .mb-xl-2,
.bootstrap .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .bootstrap .ml-xl-2,
.bootstrap .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .bootstrap .m-xl-3 {
    margin: 1rem !important;
  }
  .bootstrap .mt-xl-3,
.bootstrap .my-xl-3 {
    margin-top: 1rem !important;
  }
  .bootstrap .mr-xl-3,
.bootstrap .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .bootstrap .mb-xl-3,
.bootstrap .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .bootstrap .ml-xl-3,
.bootstrap .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .bootstrap .m-xl-4 {
    margin: 1.5rem !important;
  }
  .bootstrap .mt-xl-4,
.bootstrap .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .bootstrap .mr-xl-4,
.bootstrap .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .bootstrap .mb-xl-4,
.bootstrap .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .bootstrap .ml-xl-4,
.bootstrap .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .bootstrap .m-xl-5 {
    margin: 3rem !important;
  }
  .bootstrap .mt-xl-5,
.bootstrap .my-xl-5 {
    margin-top: 3rem !important;
  }
  .bootstrap .mr-xl-5,
.bootstrap .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .bootstrap .mb-xl-5,
.bootstrap .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .bootstrap .ml-xl-5,
.bootstrap .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .bootstrap .p-xl-0 {
    padding: 0 !important;
  }
  .bootstrap .pt-xl-0,
.bootstrap .py-xl-0 {
    padding-top: 0 !important;
  }
  .bootstrap .pr-xl-0,
.bootstrap .px-xl-0 {
    padding-right: 0 !important;
  }
  .bootstrap .pb-xl-0,
.bootstrap .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .bootstrap .pl-xl-0,
.bootstrap .px-xl-0 {
    padding-left: 0 !important;
  }
  .bootstrap .p-xl-1 {
    padding: 0.25rem !important;
  }
  .bootstrap .pt-xl-1,
.bootstrap .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .bootstrap .pr-xl-1,
.bootstrap .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .bootstrap .pb-xl-1,
.bootstrap .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .bootstrap .pl-xl-1,
.bootstrap .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .bootstrap .p-xl-2 {
    padding: 0.5rem !important;
  }
  .bootstrap .pt-xl-2,
.bootstrap .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .bootstrap .pr-xl-2,
.bootstrap .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .bootstrap .pb-xl-2,
.bootstrap .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .bootstrap .pl-xl-2,
.bootstrap .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .bootstrap .p-xl-3 {
    padding: 1rem !important;
  }
  .bootstrap .pt-xl-3,
.bootstrap .py-xl-3 {
    padding-top: 1rem !important;
  }
  .bootstrap .pr-xl-3,
.bootstrap .px-xl-3 {
    padding-right: 1rem !important;
  }
  .bootstrap .pb-xl-3,
.bootstrap .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .bootstrap .pl-xl-3,
.bootstrap .px-xl-3 {
    padding-left: 1rem !important;
  }
  .bootstrap .p-xl-4 {
    padding: 1.5rem !important;
  }
  .bootstrap .pt-xl-4,
.bootstrap .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .bootstrap .pr-xl-4,
.bootstrap .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .bootstrap .pb-xl-4,
.bootstrap .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .bootstrap .pl-xl-4,
.bootstrap .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .bootstrap .p-xl-5 {
    padding: 3rem !important;
  }
  .bootstrap .pt-xl-5,
.bootstrap .py-xl-5 {
    padding-top: 3rem !important;
  }
  .bootstrap .pr-xl-5,
.bootstrap .px-xl-5 {
    padding-right: 3rem !important;
  }
  .bootstrap .pb-xl-5,
.bootstrap .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .bootstrap .pl-xl-5,
.bootstrap .px-xl-5 {
    padding-left: 3rem !important;
  }
  .bootstrap .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .bootstrap .mt-xl-n1,
.bootstrap .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .bootstrap .mr-xl-n1,
.bootstrap .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .bootstrap .mb-xl-n1,
.bootstrap .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .bootstrap .ml-xl-n1,
.bootstrap .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .bootstrap .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .bootstrap .mt-xl-n2,
.bootstrap .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .bootstrap .mr-xl-n2,
.bootstrap .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .bootstrap .mb-xl-n2,
.bootstrap .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .bootstrap .ml-xl-n2,
.bootstrap .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .bootstrap .m-xl-n3 {
    margin: -1rem !important;
  }
  .bootstrap .mt-xl-n3,
.bootstrap .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .bootstrap .mr-xl-n3,
.bootstrap .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .bootstrap .mb-xl-n3,
.bootstrap .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .bootstrap .ml-xl-n3,
.bootstrap .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .bootstrap .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .bootstrap .mt-xl-n4,
.bootstrap .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .bootstrap .mr-xl-n4,
.bootstrap .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .bootstrap .mb-xl-n4,
.bootstrap .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .bootstrap .ml-xl-n4,
.bootstrap .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .bootstrap .m-xl-n5 {
    margin: -3rem !important;
  }
  .bootstrap .mt-xl-n5,
.bootstrap .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .bootstrap .mr-xl-n5,
.bootstrap .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .bootstrap .mb-xl-n5,
.bootstrap .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .bootstrap .ml-xl-n5,
.bootstrap .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .bootstrap .m-xl-auto {
    margin: auto !important;
  }
  .bootstrap .mt-xl-auto,
.bootstrap .my-xl-auto {
    margin-top: auto !important;
  }
  .bootstrap .mr-xl-auto,
.bootstrap .mx-xl-auto {
    margin-right: auto !important;
  }
  .bootstrap .mb-xl-auto,
.bootstrap .my-xl-auto {
    margin-bottom: auto !important;
  }
  .bootstrap .ml-xl-auto,
.bootstrap .mx-xl-auto {
    margin-left: auto !important;
  }
}
.bootstrap .text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}
.bootstrap .text-justify {
  text-align: justify !important;
}
.bootstrap .text-wrap {
  white-space: normal !important;
}
.bootstrap .text-nowrap {
  white-space: nowrap !important;
}
.bootstrap .text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.bootstrap .text-left {
  text-align: left !important;
}
.bootstrap .text-right {
  text-align: right !important;
}
.bootstrap .text-center {
  text-align: center !important;
}
@media (min-width: 576px) {
  .bootstrap .text-sm-left {
    text-align: left !important;
  }
  .bootstrap .text-sm-right {
    text-align: right !important;
  }
  .bootstrap .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .bootstrap .text-md-left {
    text-align: left !important;
  }
  .bootstrap .text-md-right {
    text-align: right !important;
  }
  .bootstrap .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .bootstrap .text-lg-left {
    text-align: left !important;
  }
  .bootstrap .text-lg-right {
    text-align: right !important;
  }
  .bootstrap .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .bootstrap .text-xl-left {
    text-align: left !important;
  }
  .bootstrap .text-xl-right {
    text-align: right !important;
  }
  .bootstrap .text-xl-center {
    text-align: center !important;
  }
}
.bootstrap .text-lowercase {
  text-transform: lowercase !important;
}
.bootstrap .text-uppercase {
  text-transform: uppercase !important;
}
.bootstrap .text-capitalize {
  text-transform: capitalize !important;
}
.bootstrap .font-weight-light {
  font-weight: 300 !important;
}
.bootstrap .font-weight-lighter {
  font-weight: lighter !important;
}
.bootstrap .font-weight-normal {
  font-weight: 400 !important;
}
.bootstrap .font-weight-bold {
  font-weight: 700 !important;
}
.bootstrap .font-weight-bolder {
  font-weight: bolder !important;
}
.bootstrap .font-italic {
  font-style: italic !important;
}
.bootstrap .text-white {
  color: #fff !important;
}
.bootstrap .text-primary {
  color: #e20079 !important;
}
.bootstrap a.text-primary:hover, .bootstrap a.text-primary:focus {
  color: #960050 !important;
}
.bootstrap .text-secondary {
  color: #6c757d !important;
}
.bootstrap a.text-secondary:hover, .bootstrap a.text-secondary:focus {
  color: #494f54 !important;
}
.bootstrap .text-success {
  color: #28a745 !important;
}
.bootstrap a.text-success:hover, .bootstrap a.text-success:focus {
  color: #19692c !important;
}
.bootstrap .text-info {
  color: #17a2b8 !important;
}
.bootstrap a.text-info:hover, .bootstrap a.text-info:focus {
  color: #0f6674 !important;
}
.bootstrap .text-warning {
  color: #ffc107 !important;
}
.bootstrap a.text-warning:hover, .bootstrap a.text-warning:focus {
  color: #ba8b00 !important;
}
.bootstrap .text-danger {
  color: #dc3545 !important;
}
.bootstrap a.text-danger:hover, .bootstrap a.text-danger:focus {
  color: #a71d2a !important;
}
.bootstrap .text-light {
  color: #f8f9fa !important;
}
.bootstrap a.text-light:hover, .bootstrap a.text-light:focus {
  color: #cbd3da !important;
}
.bootstrap .text-dark {
  color: #343a40 !important;
}
.bootstrap a.text-dark:hover, .bootstrap a.text-dark:focus {
  color: #121416 !important;
}
.bootstrap .text-body {
  color: #212529 !important;
}
.bootstrap .text-muted {
  color: #6c757d !important;
}
.bootstrap .text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}
.bootstrap .text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}
.bootstrap .text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.bootstrap .text-decoration-none {
  text-decoration: none !important;
}
.bootstrap .text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}
.bootstrap .text-reset {
  color: inherit !important;
}
.bootstrap .visible {
  visibility: visible !important;
}
.bootstrap .invisible {
  visibility: hidden !important;
}

form.bootstrap.fullwidth {
  max-width: none;
}
form.bootstrap.fullwidth .hidden {
  display: none;
}

.btn-block {
  display: block;
}

.videoconsult .hidden {
  display: none;
}
.videoconsult .radio-label {
  margin-right: 1rem;
}

#videoconsult-date {
  background-image: url(/typo3conf/ext/za_phoneconsult/Resources/Public/Icons/calendar.svg);
}

[rel=time-select] select {
  background-image: url(/typo3conf/ext/za_phoneconsult/Resources/Public/Icons/clock.svg);
}

.videoconsult-type-wrapper input[type=radio] + .radio-label {
  background-color: #f1f1f1;
  padding: 0.5rem 1rem;
}
.videoconsult-type-wrapper input[type=radio] + .radio-label:after {
  display: none;
}
.videoconsult-type-wrapper input[type=radio] + .radio-label:before {
  display: none;
}
.videoconsult-type-wrapper input[type=radio] + .radio-label svg {
  height: 1rem;
  width: auto;
  margin-right: 1rem;
}
.videoconsult-type-wrapper input[type=radio] + .radio-label svg * {
  fill: black;
}
.videoconsult-type-wrapper input[type=radio]:checked + .radio-label {
  background-color: #e20079;
  color: white;
}
.videoconsult-type-wrapper input[type=radio]:checked + .radio-label svg * {
  fill: white;
}

.alert {
  padding: 1rem;
  border: 1px solid #e20079;
  margin-bottom: 1rem;
}

.alert-danger {
  background-color: #e20079;
}
.alert-danger, .alert-danger * {
  color: white;
}

.subjectarea-teaser .subject-title, .subjectarea-teaser .subject-text {
  text-align: center;
}
.subjectarea-teaser .subject-text {
  height: 90px;
  overflow: hidden;
}
.subjectarea-teaser .subject-title {
  font-size: 1rem;
  font-weight: bold;
  overflow: hidden;
}
.subjectarea-teaser .subject-btn {
  width: 100%;
}
.subjectarea-teaser .subject-img {
  height: auto;
  max-height: 180px;
  width: 100%;
  overflow: hidden;
}

.subjarea-row {
  margin-right: calc(-100vw + 80rem);
}

.subjectarea-teaser.active .subject-btn {
  background: #fff;
  color: #e20079;
  border-color: #e20079;
}

body .tx-cart .bg-light-grey, body .tx-cart-products .bg-light-grey {
  background-color: white;
}
body .tx-cart fieldset, body .tx-cart-products fieldset {
  border: none;
  padding: 0;
}
body .tx-cart .checkout-step-content, body .tx-cart-products .checkout-step-content {
  padding: 0;
  padding-top: 1rem;
}
body .tx-cart .checkbox label, body .tx-cart-products .checkbox label {
  display: inline-block;
}
body .tx-cart form, body .tx-cart-products form {
  max-width: inherit;
}
body .tx-cart form .row .col-md-6:first-of-type, body .tx-cart-products form .row .col-md-6:first-of-type {
  margin-right: 0;
}
body .tx-cart form .row .col-md-6, body .tx-cart-products form .row .col-md-6 {
  width: 100%;
  display: table-cell;
}
body .tx-cart#cart-preview, body .tx-cart-products#cart-preview {
  height: 100%;
}
body .tx-cart#cart-preview .checkout-link, body .tx-cart-products#cart-preview .checkout-link {
  justify-content: center;
  align-items: center;
  height: 100%;
}
body .tx-cart#cart-preview .checkout-link.visible, body .tx-cart-products#cart-preview .checkout-link.visible {
  display: flex;
}
body .tx-cart#cart-preview .checkout-link.invisible, body .tx-cart-products#cart-preview .checkout-link.invisible {
  display: none;
}
body .tx-cart#cart-preview .checkout-link div, body .tx-cart-products#cart-preview .checkout-link div {
  position: relative;
  height: 1.5rem;
}
body .tx-cart#cart-preview .checkout-link div svg, body .tx-cart-products#cart-preview .checkout-link div svg {
  position: absolute;
  left: 0;
  top: 0;
  height: 1.5rem;
  width: 1.5rem;
  fill: white;
}
body .tx-cart#cart-preview .checkout-link div .cart-badge, body .tx-cart-products#cart-preview .checkout-link div .cart-badge {
  color: white;
  border-radius: 0.6125rem;
  height: 1.25rem;
  padding: 0 0.25rem;
  text-align: center;
  background-color: #e20079;
  margin-left: 1.2rem;
}
body .tx-cart .payment-radio, body .tx-cart-products .payment-radio {
  font-family: lato_regular, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  color: #929292;
  line-height: 2;
  letter-spacing: 0.0625rem;
  display: inline-block;
  position: relative;
  padding-left: 2.125rem;
  margin-bottom: 0.625rem;
  cursor: pointer;
  user-select: none;
  padding-bottom: 0;
}
body .tx-cart .payment-radio .checkmark, body .tx-cart-products .payment-radio .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 1.5rem;
  width: 1.5rem;
  background-color: #fff;
  border: 0.125rem solid #bdbdbd;
  border-radius: 50%;
}
body .tx-cart .payment-radio .checkmark.checked, body .tx-cart-products .payment-radio .checkmark.checked {
  background-color: #e20079;
  border: 0.125rem solid #e20079;
}
body .tx-cart .payment-radio .checkmark.checked:after, body .tx-cart-products .payment-radio .checkmark.checked:after {
  content: "";
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns= 'http://www.w3.org/2000/svg' xmlns:xlink= 'http://www.w3.org/1999/xlink' viewBox= '0 0 16 16' %3E%3Cdefs%3E%3Cpath fill= '%23fff' id= 'a' d= 'M14 2.8L6.8 14 2 9.2l1.485-2.012 3.046 3.046L11.6 2z' /%3E%3C/defs%3E%3Cuse xlink:href= '%23a' fill-rule= 'evenodd' /%3E%3C/svg%3E");
  background-size: 80%;
  background-position: 50%;
  background-repeat: no-repeat;
  position: absolute;
  width: 100%;
  height: 100%;
  display: none;
}
body .tx-cart .payment-radio .checkmark:before, body .tx-cart-products .payment-radio .checkmark:before {
  content: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 16 16'%3E%3Cdefs%3E%3Cpath fill='%23fff' id='a' d='M14 2.8L6.8 14 2 9.2l1.485-2.012 3.046 3.046L11.6 2z'/%3E%3C/defs%3E%3Cuse xlink:href='%23a' fill-rule='evenodd'/%3E%3C/svg%3E");
}
body .tx-cart .btn, body .tx-cart-products .btn {
  white-space: normal;
}
body .tx-cart .regular_price, body .tx-cart-products .regular_price {
  font-size: 150%;
}
body .tx-cart a.btn-default, body .tx-cart-products a.btn-default {
  appearance: none;
  background-color: #f1f1f1;
  padding: 1rem;
  color: #000;
  border: none;
  outline: none;
  border-radius: 0;
  box-shadow: none;
  transition: background-color 0.3s, color 0.3s;
  font-size: 0.875rem;
  width: 100%;
  font-family: lato_regular, Helvetica, sans-serif;
  font-weight: 400;
}
body .tx-cart #checkout-step-shipping-method, body .tx-cart-products #checkout-step-shipping-method {
  display: none;
}

.checkbox-optout {
  width: inherit;
  display: inline-block !important;
  appearance: auto;
  -webkit-appearance: checkbox;
  padding: 0;
  background-color: transparent;
  margin-right: 0.5rem;
  transform: translateY(-2px);
}

.cookie-choice .form-check-inline {
  margin-right: 0.75rem;
  transform: translateY(3px);
}

.easycaptcha {
  display: flex;
  flex-wrap: wrap;
}
.easycaptcha .easycaptcha {
  flex: 1 0 70%;
}
.easycaptcha .form-group {
  flex: 0 0 100%;
}
.easycaptcha .captcha-actions img {
  width: 2rem;
}
.easycaptcha .f3-form-error:after {
  display: block;
  content: "Dies ist ein Pflichtfeld.";
  color: #e20079;
  font-weight: bold;
}

@media (min-width: 64rem) {
  .frame-type-login {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
  }
}
.three-colored-border {
  box-shadow: 0px -5px 7px -5px rgba(0, 0, 0, 0.25);
}

.form-error {
  font-size: 0.75rem;
  color: #e20079;
}

.seminar-detail-form ::placeholder {
  color: #000;
}
.seminar-detail-form .checkbox-label {
  font-size: 1rem;
  color: #000;
}
.seminar-detail-form .checkbox-label .checkmark {
  border: 0.125rem solid #000;
}

#praxistrainerMasterclass-1241-date-1 {
  display: none;
}

.frame-type-form_formframework form h2 {
  font-family: lato_black, Helvetica, sans-serif;
  font-weight: 900;
  color: #000;
  line-height: 1.7142857143;
  font-size: 0.875rem;
  letter-spacing: 0.125rem;
  padding-bottom: 0.3125rem;
  display: inline-block;
  text-transform: capitalize;
  margin-bottom: 0;
}

.user-wrapper {
  width: 2rem;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  padding-left: 1rem;
}

/* The overlay effect with black background */
.searchbutton {
  width: 100%;
  text-align: right;
}
.searchbutton svg {
  height: 20px;
  float: right;
  text-align: right;
  width: 20px;
  fill: #e20079;
}

.solrOverlay {
  height: 100%;
  width: 100%;
  display: none;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: white;
  background-color: rgba(255, 255, 255, 0.9);
  /* Black with a little bit see-through */
  /* The content */
  /* Close button */
  /* Style the search field */
  /* Style the submit button */
}
.solrOverlay .main-search {
  width: 100%;
}
.solrOverlay .overlay-content {
  position: relative;
  top: 46%;
  width: 60%;
  text-align: center;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}
.solrOverlay .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 60px;
  cursor: pointer;
  color: #e20079;
}
.solrOverlay .closebtn:hover {
  color: #e20079;
}
.solrOverlay form {
  width: 100%;
}
.solrOverlay ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: grey;
  opacity: 1;
  /* Firefox */
}
.solrOverlay #tx-indexedsearch-searchbox-sword {
  padding: 15px;
  font-size: 17px;
  float: left;
  width: 100%;
  background: white;
  border: 2px solid #e20079;
}
.solrOverlay #tx-indexedsearch-searchbox-sword:hover {
  background: #f1f1f1;
}
.solrOverlay button {
  float: left;
  width: 20%;
  padding: 15px;
  background: #e20079;
  font-size: 17px;
  border: none;
  cursor: pointer;
}
.solrOverlay button svg {
  height: 30px;
  float: right;
  text-align: right;
  width: 30px;
  margin-top: -5px;
  fill: white;
}
.solrOverlay #tx-indexedsearch-searchbox-button-submit {
  position: relative;
  left: 90%;
  width: 60px;
  height: 45px;
  top: -52px;
}
.solrOverlay button:hover {
  background: white;
}
.solrOverlay button:hover svg {
  height: 30px;
  float: right;
  text-align: right;
  width: 30px;
  margin-top: -5px;
  fill: #e20079;
}

.tx-solr-autosuggest {
  background: white;
  padding: 5px;
  line-height: 30px;
  max-height: 190px !important;
  overflow-y: scroll;
  width: auto !important;
}

.search-browsebox ul {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 3.75rem 0;
}
.search-browsebox ul li {
  display: -ms-flexbox;
  display: flex;
  line-height: 1.5rem;
  margin-right: 1.25rem;
}
.search-browsebox ul li:before {
  display: none;
}
.search-browsebox ul li svg {
  height: 20px;
}
.search-browsebox ul li .next svg {
  transform: rotate(180deg);
}

.seminare-header {
  width: 100%;
  float: left;
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
}
.seminare-header #datepicker .datepicker-inline {
  background-color: #f5f5f5;
  width: 320px;
}
.seminare-header #datepicker .datepicker-inline td, .seminare-header #datepicker .datepicker-inline th {
  width: 45px;
  height: 30px;
}
.seminare-header #datepicker .datepicker-inline .prev, .seminare-header #datepicker .datepicker-inline .next, .seminare-header #datepicker .datepicker-inline .datepicker-switch {
  color: #e20079;
}
.seminare-header #datepicker .datepicker-inline .active {
  background-color: #e20079;
  background-image: none;
}
.seminare-header #datepicker .datepicker-inline .day {
  position: relative;
}
.seminare-header #datepicker .datepicker-inline .highlight:after {
  border-bottom: 3px solid #e20079;
  border-radius: 100%;
  content: "";
  width: 3px;
  position: absolute;
  margin: 0 auto;
  bottom: -2px;
  left: 0;
  right: 0;
}

.tx-px-seminars {
  min-height: 400px;
}
@media (max-width: 420px) {
  .tx-px-seminars .seminare-header {
    display: block;
    text-align: center;
  }
  .tx-px-seminars .seminare-header .filter.date {
    float: none;
    margin: 0 auto;
    width: 100%;
    text-align: center;
  }
  .tx-px-seminars .seminare-header .filter.date .datepicker-inline {
    margin: 0 auto;
  }
  .tx-px-seminars .filter {
    width: 100%;
    padding: 0;
    margin-bottom: 10px;
  }
}
.tx-px-seminars #resetdate {
  float: right;
  color: #e20079;
}
.tx-px-seminars .f3-widget-paginator {
  display: block;
  margin: 0 auto;
  width: 100%;
  float: left;
}

h2.quickentry-headline a {
  font-weight: 900 !important;
  font-family: lato_black, Helvetica, sans-serif !important;
}

.mobile-navigation-wrapper .meta-navigation-wrapper ul {
  margin-bottom: 100px;
  display: block;
}

.footer-middle .link-container .frame-type-menu_pages, .footer-left .link-container .frame-type-menu_pages {
  padding: 0;
}
.footer-middle .link-container .frame-type-menu_pages ul li, .footer-left .link-container .frame-type-menu_pages ul li {
  padding: 0;
}
.footer-middle .link-container .frame-type-menu_pages ul li:before, .footer-left .link-container .frame-type-menu_pages ul li:before {
  display: none;
}

.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
}

.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 28px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  display: block;
  padding-left: 8px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.select2-container .select2-selection--single .select2-selection__clear {
  background-color: transparent;
  border: none;
  font-size: 1em;
}

.select2-container[dir=rtl] .select2-selection--single .select2-selection__rendered {
  padding-right: 8px;
  padding-left: 20px;
}

.select2-container .select2-selection--multiple {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  min-height: 32px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: inline;
  list-style: none;
  padding: 0;
}

.select2-container .select2-selection--multiple .select2-selection__clear {
  background-color: transparent;
  border: none;
  font-size: 1em;
}

.select2-container .select2-search--inline .select2-search__field {
  box-sizing: border-box;
  border: none;
  font-size: 100%;
  margin-top: 5px;
  margin-left: 5px;
  padding: 0;
  max-width: 100%;
  resize: none;
  height: 18px;
  vertical-align: bottom;
  font-family: sans-serif;
  overflow: hidden;
  word-break: keep-all;
}

.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-dropdown {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051;
}

.select2-results {
  display: block;
}

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0;
}

.select2-results__option {
  padding: 6px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-results__option--selectable {
  cursor: pointer;
}

.select2-container--open .select2-dropdown {
  left: 0;
}

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-search--dropdown {
  display: block;
  padding: 4px;
}

.select2-search--dropdown .select2-search__field {
  padding: 4px;
  width: 100%;
  box-sizing: border-box;
}

.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-search--dropdown.select2-search--hide {
  display: none;
}

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0);
}

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}

.select2-container--default .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  height: 26px;
  margin-right: 20px;
  padding-right: 0px;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #999;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}

.select2-container--default[dir=rtl] .select2-selection--single .select2-selection__clear {
  float: left;
}

.select2-container--default[dir=rtl] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto;
}

.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default;
}

.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}

.select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
  padding-bottom: 5px;
  padding-right: 5px;
  position: relative;
}

.select2-container--default .select2-selection--multiple.select2-selection--clearable {
  padding-right: 25px;
}

.select2-container--default .select2-selection--multiple .select2-selection__clear {
  cursor: pointer;
  font-weight: bold;
  height: 20px;
  margin-right: 10px;
  margin-top: 5px;
  position: absolute;
  right: 0;
  padding: 1px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-sizing: border-box;
  display: inline-block;
  margin-left: 5px;
  margin-top: 5px;
  padding: 0;
  padding-left: 20px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: bottom;
  white-space: nowrap;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__display {
  cursor: default;
  padding-left: 2px;
  padding-right: 5px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  background-color: transparent;
  border: none;
  border-right: 1px solid #aaa;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  color: #999;
  cursor: pointer;
  font-size: 1em;
  font-weight: bold;
  padding: 0 4px;
  position: absolute;
  left: 0;
  top: 0;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover, .select2-container--default .select2-selection--multiple .select2-selection__choice__remove:focus {
  background-color: #f1f1f1;
  color: #333;
  outline: none;
}

.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice__display {
  padding-left: 5px;
  padding-right: 2px;
}

.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice__remove {
  border-left: 1px solid #aaa;
  border-right: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__clear {
  float: left;
  margin-left: 10px;
  margin-right: auto;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: solid black 1px;
  outline: 0;
}

.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default;
}

.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none;
}

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single, .select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
}

.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield;
}

.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1em;
  padding-left: 2em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2em;
  padding-left: 3em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3em;
  padding-left: 4em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -5em;
  padding-left: 6em;
}

.select2-container--default .select2-results__option--group {
  padding: 0;
}

.select2-container--default .select2-results__option--disabled {
  color: #999;
}

.select2-container--default .select2-results__option--selected {
  background-color: #ddd;
}

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: #5897fb;
  color: white;
}

.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

.select2-container--classic .select2-selection--single {
  background-color: #f7f7f7;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: 0;
  background-image: -webkit-linear-gradient(top, #fff 50%, #eee 100%);
  background-image: -o-linear-gradient(top, #fff 50%, #eee 100%);
  background-image: linear-gradient(to bottom, #fff 50%, #eee 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFFFFFFF", endColorstr="#FFEEEEEE", GradientType=0);
}

.select2-container--classic .select2-selection--single:focus {
  border: 1px solid #5897fb;
}

.select2-container--classic .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}

.select2-container--classic .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  height: 26px;
  margin-right: 20px;
}

.select2-container--classic .select2-selection--single .select2-selection__placeholder {
  color: #999;
}

.select2-container--classic .select2-selection--single .select2-selection__arrow {
  background-color: #ddd;
  border: none;
  border-left: 1px solid #aaa;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
  background-image: -webkit-linear-gradient(top, #eee 50%, #ccc 100%);
  background-image: -o-linear-gradient(top, #eee 50%, #ccc 100%);
  background-image: linear-gradient(to bottom, #eee 50%, #ccc 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFEEEEEE", endColorstr="#FFCCCCCC", GradientType=0);
}

.select2-container--classic .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}

.select2-container--classic[dir=rtl] .select2-selection--single .select2-selection__clear {
  float: left;
}

.select2-container--classic[dir=rtl] .select2-selection--single .select2-selection__arrow {
  border: none;
  border-right: 1px solid #aaa;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  left: 1px;
  right: auto;
}

.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
  background: transparent;
  border: none;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-image: -webkit-linear-gradient(top, #fff 0%, #eee 50%);
  background-image: -o-linear-gradient(top, #fff 0%, #eee 50%);
  background-image: linear-gradient(to bottom, #fff 0%, #eee 50%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFFFFFFF", endColorstr="#FFEEEEEE", GradientType=0);
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-image: -webkit-linear-gradient(top, #eee 50%, #fff 100%);
  background-image: -o-linear-gradient(top, #eee 50%, #fff 100%);
  background-image: linear-gradient(to bottom, #eee 50%, #fff 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFEEEEEE", endColorstr="#FFFFFFFF", GradientType=0);
}

.select2-container--classic .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
  outline: 0;
  padding-bottom: 5px;
  padding-right: 5px;
}

.select2-container--classic .select2-selection--multiple:focus {
  border: 1px solid #5897fb;
}

.select2-container--classic .select2-selection--multiple .select2-selection__clear {
  display: none;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  display: inline-block;
  margin-left: 5px;
  margin-top: 5px;
  padding: 0;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__display {
  cursor: default;
  padding-left: 2px;
  padding-right: 5px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
  background-color: transparent;
  border: none;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  color: #888;
  cursor: pointer;
  font-size: 1em;
  font-weight: bold;
  padding: 0 4px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #555;
  outline: none;
}

.select2-container--classic[dir=rtl] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--classic[dir=rtl] .select2-selection--multiple .select2-selection__choice__display {
  padding-left: 5px;
  padding-right: 2px;
}

.select2-container--classic[dir=rtl] .select2-selection--multiple .select2-selection__choice__remove {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.select2-container--classic.select2-container--open .select2-selection--multiple {
  border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--classic .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
  outline: 0;
}

.select2-container--classic .select2-search--inline .select2-search__field {
  outline: 0;
  box-shadow: none;
}

.select2-container--classic .select2-dropdown {
  background-color: #fff;
  border: 1px solid transparent;
}

.select2-container--classic .select2-dropdown--above {
  border-bottom: none;
}

.select2-container--classic .select2-dropdown--below {
  border-top: none;
}

.select2-container--classic .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--classic .select2-results__option--group {
  padding: 0;
}

.select2-container--classic .select2-results__option--disabled {
  color: grey;
}

.select2-container--classic .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: #3875d7;
  color: #fff;
}

.select2-container--classic .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #5897fb;
}

/*!
 * Datepicker for Bootstrap v1.9.0 (https://github.com/uxsolutions/bootstrap-datepicker)
 *
 * Licensed under the Apache License v2.0 (http://www.apache.org/licenses/LICENSE-2.0)
 */
.datepicker {
  padding: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  direction: ltr;
}

.datepicker-inline {
  width: 220px;
}

.datepicker-rtl {
  direction: rtl;
}

.datepicker-rtl.dropdown-menu {
  left: auto;
}

.datepicker-rtl table tr td span {
  float: right;
}

.datepicker-dropdown {
  top: 0;
  left: 0;
}

.datepicker-dropdown:before {
  content: "";
  display: inline-block;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #999;
  border-top: 0;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  position: absolute;
}

.datepicker-dropdown:after {
  content: "";
  display: inline-block;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  border-top: 0;
  position: absolute;
}

.datepicker-dropdown.datepicker-orient-left:before {
  left: 6px;
}

.datepicker-dropdown.datepicker-orient-left:after {
  left: 7px;
}

.datepicker-dropdown.datepicker-orient-right:before {
  right: 6px;
}

.datepicker-dropdown.datepicker-orient-right:after {
  right: 7px;
}

.datepicker-dropdown.datepicker-orient-bottom:before {
  top: -7px;
}

.datepicker-dropdown.datepicker-orient-bottom:after {
  top: -6px;
}

.datepicker-dropdown.datepicker-orient-top:before {
  bottom: -7px;
  border-bottom: 0;
  border-top: 7px solid #999;
}

.datepicker-dropdown.datepicker-orient-top:after {
  bottom: -6px;
  border-bottom: 0;
  border-top: 6px solid #fff;
}

.datepicker table {
  margin: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.datepicker td,
.datepicker th {
  text-align: center;
  width: 20px;
  height: 20px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border: none;
}

.table-striped .datepicker table tr td,
.table-striped .datepicker table tr th {
  background-color: transparent;
}

.datepicker table tr td.day:hover,
.datepicker table tr td.day.focused {
  background: #eee;
  cursor: pointer;
}

.datepicker table tr td.old,
.datepicker table tr td.new {
  color: #999;
}

.datepicker table tr td.disabled,
.datepicker table tr td.disabled:hover {
  background: none;
  color: #999;
  cursor: default;
}

.datepicker table tr td.highlighted {
  background: #d9edf7;
  border-radius: 0;
}

.datepicker table tr td.today,
.datepicker table tr td.today:hover,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover {
  background-color: #fde19a;
  background-image: -moz-linear-gradient(to bottom, #fdd49a, #fdf59a);
  background-image: -ms-linear-gradient(to bottom, #fdd49a, #fdf59a);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fdd49a), to(#fdf59a));
  background-image: -webkit-linear-gradient(to bottom, #fdd49a, #fdf59a);
  background-image: -o-linear-gradient(to bottom, #fdd49a, #fdf59a);
  background-image: linear-gradient(to bottom, #fdd49a, #fdf59a);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fdd49a", endColorstr="#fdf59a", GradientType=0);
  border-color: #fdf59a #fdf59a #fbed50;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  color: #000;
}

.datepicker table tr td.today:hover,
.datepicker table tr td.today:hover:hover,
.datepicker table tr td.today.disabled:hover,
.datepicker table tr td.today.disabled:hover:hover,
.datepicker table tr td.today:active,
.datepicker table tr td.today:hover:active,
.datepicker table tr td.today.disabled:active,
.datepicker table tr td.today.disabled:hover:active,
.datepicker table tr td.today.active,
.datepicker table tr td.today:hover.active,
.datepicker table tr td.today.disabled.active,
.datepicker table tr td.today.disabled:hover.active,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today:hover.disabled,
.datepicker table tr td.today.disabled.disabled,
.datepicker table tr td.today.disabled:hover.disabled,
.datepicker table tr td.today[disabled],
.datepicker table tr td.today:hover[disabled],
.datepicker table tr td.today.disabled[disabled],
.datepicker table tr td.today.disabled:hover[disabled] {
  background-color: #fdf59a;
}

.datepicker table tr td.today:active,
.datepicker table tr td.today:hover:active,
.datepicker table tr td.today.disabled:active,
.datepicker table tr td.today.disabled:hover:active,
.datepicker table tr td.today.active,
.datepicker table tr td.today:hover.active,
.datepicker table tr td.today.disabled.active,
.datepicker table tr td.today.disabled:hover.active {
  background-color: #fbf069 \9 ;
}

.datepicker table tr td.today:hover:hover {
  color: #000;
}

.datepicker table tr td.today.active:hover {
  color: #fff;
}

.datepicker table tr td.range,
.datepicker table tr td.range:hover,
.datepicker table tr td.range.disabled,
.datepicker table tr td.range.disabled:hover {
  background: #eee;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.datepicker table tr td.range.today,
.datepicker table tr td.range.today:hover,
.datepicker table tr td.range.today.disabled,
.datepicker table tr td.range.today.disabled:hover {
  background-color: #f3d17a;
  background-image: -moz-linear-gradient(to bottom, #f3c17a, #f3e97a);
  background-image: -ms-linear-gradient(to bottom, #f3c17a, #f3e97a);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f3c17a), to(#f3e97a));
  background-image: -webkit-linear-gradient(to bottom, #f3c17a, #f3e97a);
  background-image: -o-linear-gradient(to bottom, #f3c17a, #f3e97a);
  background-image: linear-gradient(to bottom, #f3c17a, #f3e97a);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f3c17a", endColorstr="#f3e97a", GradientType=0);
  border-color: #f3e97a #f3e97a #edde34;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.datepicker table tr td.range.today:hover,
.datepicker table tr td.range.today:hover:hover,
.datepicker table tr td.range.today.disabled:hover,
.datepicker table tr td.range.today.disabled:hover:hover,
.datepicker table tr td.range.today:active,
.datepicker table tr td.range.today:hover:active,
.datepicker table tr td.range.today.disabled:active,
.datepicker table tr td.range.today.disabled:hover:active,
.datepicker table tr td.range.today.active,
.datepicker table tr td.range.today:hover.active,
.datepicker table tr td.range.today.disabled.active,
.datepicker table tr td.range.today.disabled:hover.active,
.datepicker table tr td.range.today.disabled,
.datepicker table tr td.range.today:hover.disabled,
.datepicker table tr td.range.today.disabled.disabled,
.datepicker table tr td.range.today.disabled:hover.disabled,
.datepicker table tr td.range.today[disabled],
.datepicker table tr td.range.today:hover[disabled],
.datepicker table tr td.range.today.disabled[disabled],
.datepicker table tr td.range.today.disabled:hover[disabled] {
  background-color: #f3e97a;
}

.datepicker table tr td.range.today:active,
.datepicker table tr td.range.today:hover:active,
.datepicker table tr td.range.today.disabled:active,
.datepicker table tr td.range.today.disabled:hover:active,
.datepicker table tr td.range.today.active,
.datepicker table tr td.range.today:hover.active,
.datepicker table tr td.range.today.disabled.active,
.datepicker table tr td.range.today.disabled:hover.active {
  background-color: #efe24b \9 ;
}

.datepicker table tr td.selected,
.datepicker table tr td.selected:hover,
.datepicker table tr td.selected.disabled,
.datepicker table tr td.selected.disabled:hover {
  background-color: #9e9e9e;
  background-image: -moz-linear-gradient(to bottom, #b3b3b3, #808080);
  background-image: -ms-linear-gradient(to bottom, #b3b3b3, #808080);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#b3b3b3), to(#808080));
  background-image: -webkit-linear-gradient(to bottom, #b3b3b3, #808080);
  background-image: -o-linear-gradient(to bottom, #b3b3b3, #808080);
  background-image: linear-gradient(to bottom, #b3b3b3, #808080);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#b3b3b3", endColorstr="#808080", GradientType=0);
  border-color: #808080 #808080 #595959;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.datepicker table tr td.selected:hover,
.datepicker table tr td.selected:hover:hover,
.datepicker table tr td.selected.disabled:hover,
.datepicker table tr td.selected.disabled:hover:hover,
.datepicker table tr td.selected:active,
.datepicker table tr td.selected:hover:active,
.datepicker table tr td.selected.disabled:active,
.datepicker table tr td.selected.disabled:hover:active,
.datepicker table tr td.selected.active,
.datepicker table tr td.selected:hover.active,
.datepicker table tr td.selected.disabled.active,
.datepicker table tr td.selected.disabled:hover.active,
.datepicker table tr td.selected.disabled,
.datepicker table tr td.selected:hover.disabled,
.datepicker table tr td.selected.disabled.disabled,
.datepicker table tr td.selected.disabled:hover.disabled,
.datepicker table tr td.selected[disabled],
.datepicker table tr td.selected:hover[disabled],
.datepicker table tr td.selected.disabled[disabled],
.datepicker table tr td.selected.disabled:hover[disabled] {
  background-color: #808080;
}

.datepicker table tr td.selected:active,
.datepicker table tr td.selected:hover:active,
.datepicker table tr td.selected.disabled:active,
.datepicker table tr td.selected.disabled:hover:active,
.datepicker table tr td.selected.active,
.datepicker table tr td.selected:hover.active,
.datepicker table tr td.selected.disabled.active,
.datepicker table tr td.selected.disabled:hover.active {
  background-color: #666666 \9 ;
}

.datepicker table tr td.active,
.datepicker table tr td.active:hover,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled:hover {
  background-color: #006dcc;
  background-image: -moz-linear-gradient(to bottom, #08c, #0044cc);
  background-image: -ms-linear-gradient(to bottom, #08c, #0044cc);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#08c), to(#0044cc));
  background-image: -webkit-linear-gradient(to bottom, #08c, #0044cc);
  background-image: -o-linear-gradient(to bottom, #08c, #0044cc);
  background-image: linear-gradient(to bottom, #08c, #0044cc);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#08c", endColorstr="#0044cc", GradientType=0);
  border-color: #0044cc #0044cc #002a80;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.datepicker table tr td.active:hover,
.datepicker table tr td.active:hover:hover,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active.disabled:hover:hover,
.datepicker table tr td.active:active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:hover.active,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active:hover.disabled,
.datepicker table tr td.active.disabled.disabled,
.datepicker table tr td.active.disabled:hover.disabled,
.datepicker table tr td.active[disabled],
.datepicker table tr td.active:hover[disabled],
.datepicker table tr td.active.disabled[disabled],
.datepicker table tr td.active.disabled:hover[disabled] {
  background-color: #0044cc;
}

.datepicker table tr td.active:active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:hover.active {
  background-color: #003399 \9 ;
}

.datepicker table tr td span {
  display: block;
  width: 23%;
  height: 54px;
  line-height: 54px;
  float: left;
  margin: 1%;
  cursor: pointer;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.datepicker table tr td span:hover,
.datepicker table tr td span.focused {
  background: #eee;
}

.datepicker table tr td span.disabled,
.datepicker table tr td span.disabled:hover {
  background: none;
  color: #999;
  cursor: default;
}

.datepicker table tr td span.active,
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active.disabled:hover {
  background-color: #006dcc;
  background-image: -moz-linear-gradient(to bottom, #08c, #0044cc);
  background-image: -ms-linear-gradient(to bottom, #08c, #0044cc);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#08c), to(#0044cc));
  background-image: -webkit-linear-gradient(to bottom, #08c, #0044cc);
  background-image: -o-linear-gradient(to bottom, #08c, #0044cc);
  background-image: linear-gradient(to bottom, #08c, #0044cc);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#08c", endColorstr="#0044cc", GradientType=0);
  border-color: #0044cc #0044cc #002a80;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.datepicker table tr td span.active:hover,
.datepicker table tr td span.active:hover:hover,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active.disabled:hover:hover,
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:hover.active,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active:hover.disabled,
.datepicker table tr td span.active.disabled.disabled,
.datepicker table tr td span.active.disabled:hover.disabled,
.datepicker table tr td span.active[disabled],
.datepicker table tr td span.active:hover[disabled],
.datepicker table tr td span.active.disabled[disabled],
.datepicker table tr td span.active.disabled:hover[disabled] {
  background-color: #0044cc;
}

.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:hover.active {
  background-color: #003399 \9 ;
}

.datepicker table tr td span.old,
.datepicker table tr td span.new {
  color: #999;
}

.datepicker .datepicker-switch {
  width: 145px;
}

.datepicker .datepicker-switch,
.datepicker .prev,
.datepicker .next,
.datepicker tfoot tr th {
  cursor: pointer;
}

.datepicker .datepicker-switch:hover,
.datepicker .prev:hover,
.datepicker .next:hover,
.datepicker tfoot tr th:hover {
  background: #eee;
}

.datepicker .prev.disabled,
.datepicker .next.disabled {
  visibility: hidden;
}

.datepicker .cw {
  font-size: 10px;
  width: 12px;
  padding: 0 2px 0 5px;
  vertical-align: middle;
}

.input-append.date .add-on,
.input-prepend.date .add-on {
  cursor: pointer;
}

.input-append.date .add-on i,
.input-prepend.date .add-on i {
  margin-top: 3px;
}

.input-daterange input {
  text-align: center;
}

.input-daterange input:first-child {
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
}

.input-daterange input:last-child {
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
}

.input-daterange .add-on {
  display: inline-block;
  width: auto;
  min-width: 16px;
  height: 18px;
  padding: 4px 5px;
  font-weight: normal;
  line-height: 18px;
  text-align: center;
  text-shadow: 0 1px 0 #fff;
  vertical-align: middle;
  background-color: #eee;
  border: 1px solid #ccc;
  margin-left: -5px;
  margin-right: -5px;
}

/*# sourceMappingURL=bootstrap-datepicker.css.map */
.select2-container .selection .select2-selection {
  border: none;
  background-color: #f1f1f1;
  height: 2.875rem;
  font-size: 0.875rem;
  padding: 0.5rem;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 16 16'%3E%3Cdefs%3E%3Cpath fill='%23E20079' id='a' d='M9.883 13a.216.216 0 01-.155-.066L5.064 8.158a.227.227 0 010-.317l4.664-4.775a.216.216 0 01.31 0l.897.919a.228.228 0 010 .317L7.324 8l3.612 3.698a.228.228 0 010 .317l-.898.92a.218.218 0 01-.155.065z'/%3E%3C/defs%3E%3Cuse transform='rotate(-90 8 8)' xlink:href='%23a'/%3E%3C/svg%3E");
  background-position: right 0.75rem center;
  background-size: 1.5rem 1.5rem;
  background-repeat: no-repeat;
}

.select2-selection__arrow {
  display: none;
}

@media (max-width: 500px) {
  .frame-type-text img {
    width: 100% !important;
    height: auto !important;
    display: block;
  }

  .header {
    word-wrap: break-word;
    overflow-wrap: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
  }
}